import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import { FloatButton, Modal } from 'antd';
import { RxVideo } from 'react-icons/rx';
import { BiVideo, BiSolidBot, BiPlay } from 'react-icons/bi';
import ReactPlayer from 'react-player';
import { FiArrowRight } from 'react-icons/fi';
// import videoOne from '../../assets/cloud.mp4';
import BreadCrum from '../../Components/BreadCrum';
import ChatNew from '../../Components/Chat2';
import Casestudy from '../../Components/Casestudy';
import A1 from '../../assets/GCP.png';
import A2 from '../../assets/AWS.png';
import A3 from '../../assets/Azure.png';
import A4 from '../../assets/Linode.png';
import cloud_img from "../../assets/cloud.gif";
import cloud_gif from "../../assets/cloud-1.gif";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
const Cloud = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Platforms | Cloud';
    AOS.init();
  });
  const arrayOfImages = [
    {
      imageUrl: A1,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: A2,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: A3,
      alt: 'Logo-A-Series'
    },

    {
      imageUrl: A4,
      alt: 'Logo-A-Series'
    }
  ];
  return (
    <div>
      {/* navbar here */}
      <Navbar mode="light" />
      <ChatNew />
      {/* First Page starts */}

      <div className="welcome-page-2">
        <center>
          <p style={{textAlign: "center"}}>Resilience</p>
          <h1
            style={{
              color: '#343F52',
              width:'100%'
            }}
          >
            Unlock the Power of Cloud Computing for Your Business
          </h1>
          <p
            style={{
              color: '#343F52'
            }}
          >
            Embark on a journey of technological advancement and optimization with FocusR's groundbreaking cloud
            computing solutions. Our innovative approach integrates cutting-edge cloud technology to redefine the way
            your business operates. Harness the power of cloud computing to enhance collaboration, streamline processes,
            and drive unprecedented growth. With FocusR, you'll experience seamless integration, unmatched reliability,
            and unparalleled scalability, empowering your organization to thrive in the digital age. Embrace the future
            of computing with FocusR, where excellence meets innovation in the cloud.
          </p>
        </center>
      </div>
      {/* <center>
        <section className="center-cloud-div">
          <img src={cloud_img} alt="cvbn" width='70%' />
        </section>
      </center> */}
      <br />
      <br />
      <br />
      <center>
        <h1 className="cloud-h1" >
          Partnerships <br /> With Leading Cloud Providers
        </h1>
      </center>
      <br />
   
      <div className="new-logo-container" >
        {arrayOfImages.map((x, i) => {
          return <img src={x.imageUrl} alt={x.alt} />;
        })}
      </div>

      <br />
      <br />
      <br />
      <div className="ai-new-div-1-wrapper">
        <div className="ai-new-div-1-wrapper-left-1" >
          <img  className='done-img' src={cloud_img} alt='noe' width='80%'/>
        </div>
        <div className="ai-new-div-1-wrapper-right">
          <h1>Why Choose FocusR Cloud ERP Services?</h1>
          <ul className="cloud-ul-new">
            <li>Agile Development Environment</li>
            <li>Scalability</li>
            <li>Security</li>
            <li>Reliability</li>
            <li>Innovation</li>
          </ul>
        </div>
      </div>

      {/* cloud offerings */}
      <br />
      <br />
      <br />
      <center>
        <h1 className="cloud-h1" >Advanced Analytics</h1>
      </center>
      <br />
      <br />
      <br />

      <div className="ai-new-div-1-wrapper">
        <div className="ai-new-div-1-wrapper-right">
        <h1 data-aos="fade-right" >Why Choose FocusR Cloud Development Services?</h1>
          <ol className="cloud-ul-new">
            <li data-aos="fade-right" >
              Automation and Orchestration: Streamline workflows and enhance operational efficiency with intelligent
              automation.
            </li>
            <li data-aos="fade-right" >
              Multi-Cloud Compatibility: Seamlessly integrate and manage services across various cloud providers for
              optimal flexibility.
            </li>
            <li data-aos="fade-right" >
              Disaster Recovery and Business Continuity: Ensure business resilience with robust disaster recovery plans
              and continuous operations.
            </li>
            <li data-aos="fade-right" >
              AI and Machine Learning Integration: Harness the power of AI and machine learning for predictive analysis
              and enhanced user experiences.
            </li>
          </ol>
        </div>
        <div className="ai-new-div-1-wrapper-left-2">
        <img  src={cloud_gif} alt='noe' width='100%'/>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <center>
        <Casestudy />
      </center>

      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
          <br />
      <br />
      <Footer />
    </div>
  );
};

export default Cloud;
