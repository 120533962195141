/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import Products from './Components/Products';
import techstephubMockup from '../../assets/techzard.svg';
import Chat from '../../Components/Chat';
import { Collapse } from 'antd';
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled
} from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TicketManagement = () => {
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    document.title = 'Product | Techzard';

    AOS.init();
  });

  const techzardFAQ = [
    {
      key: '1',
      label: <h3>1. What is the purpose of the Techzard application?</h3>,
      children: (
        <p>
          The Techzard application serves as a comprehensive platform for ticket management, including robust SLA tracking and project management capabilities.
        </p>
      ),
    },
    {
      key: '2',
      label: <h3>2. How do I get started with the Techzard application?</h3>,
      children: (
        <p>
          To get started, access the Techzard application through our designated platform. Set up your account, configure ticket management preferences, and start efficiently tracking and resolving issues.
        </p>
      ),
    },
    {
      key: '3',
      label: <h3>3. What features does Techzard offer for SLA tracking?</h3>,
      children: (
        <p>
          Techzard provides robust SLA tracking features, ensuring that service commitments are met. Monitor response and resolution times to meet and exceed service level agreements.
        </p>
      ),
    },
    {
      key: '4',
      label: <h3>4. Can I manage projects within the Techzard application?</h3>,
      children: (
        <p>
          Absolutely. Techzard offers project management capabilities alongside ticket management. Track project progress, assign tasks, and ensure seamless coordination between support and project teams.
        </p>
      ),
    },
    {
      key: '5',
      label: <h3>5. How does Techzard ensure data security for ticket management?</h3>,
      children: (
        <p>
          Techzard prioritizes data security, implementing encryption and following industry-standard security practices. This ensures that sensitive ticket information is stored securely and accessible only to authorized users.
        </p>
      ),
    },
    {
      key: '6',
      label: <h3>6. Can I track the status of my tickets in real-time?</h3>,
      children: (
        <p>
          Yes, Techzard provides real-time ticket tracking features. Users can monitor the status of their tickets, view progress, and receive timely updates for enhanced visibility and control.
        </p>
      ),
    },
    {
      key: '7',
      label: <h3>7. Is customer support available for Techzard?</h3>,
      children: (
        <p>
          Yes, we offer dedicated customer support to assist users with any issues or questions. Our support team is accessible through the Techzard platform or our website.
        </p>
      ),
    },
    {
      key: '8',
      label: <h3>8. Can I customize ticket management preferences in Techzard?</h3>,
      children: (
        <p>
          Absolutely. Techzard provides flexibility in customizing ticket management preferences. You can configure settings based on your specific requirements for a personalized experience.
        </p>
      ),
    },
    {
      key: '9',
      label: <h3>9. How often are updates released for Techzard?</h3>,
      children: (
        <p>
          We are committed to regular improvements. Updates with new features, enhancements, and bug fixes are released periodically to ensure Techzard remains efficient and up-to-date.
        </p>
      ),
    },
    {
      key: '10',
      label: <h3>10. Does Techzard support multi-user collaboration on projects?</h3>,
      children: (
        <p>
          Yes, Techzard supports multi-user collaboration on projects. It enables teams to work together efficiently on both ticket management and project tracking for improved productivity.
        </p>
      ),
    },
    {
      key: '11',
      label: <h3>11. What security measures are in place for Techzard?</h3>,
      children: (
        <p>
          Techzard implements robust security measures, including encryption and adherence to industry-standard practices. This ensures that data is handled securely and only accessible to authorized users.
        </p>
      ),
    },
    {
      key: '12',
      label: <h3>12. How does Techzard integrate ticket management with project tracking?</h3>,
      children: (
        <p>
          Techzard seamlessly integrates ticket management with project tracking, providing a unified platform for efficient issue resolution and project coordination. This integration enhances data accuracy and synchronicity between the two functionalities.
        </p>
      ),
    },
  ];
  
  

  return (
    <div>
      <Navbar mode="dark" />
      <Chat />
      <Products
        title="Techzard"
        mockupSrc={techstephubMockup}
        mockupAlt="TechstepHub-Mockup"
        technologyUsed={['React ', 'Java ', 'Oracle ', 'Spring-Boot ']}
        ratingCount={5}
        reviewCount="131"
        width="100%"
        videoUrl="https://www.youtube.com/watch?v=DYkEZzAzFT8"
        benefitTitle="Benefits of Techzard"
        benefitArray1={[
          {
            icon: <TbSquareRoundedNumber1Filled className="num-icon" />,
            strongText: 'Effortless Ticket Management',
            pText: 'Simplify ticket management with Techzard, ensuring a seamless and efficient process for tracking, prioritizing, and resolving issues.'
          },
          {
            icon: <TbSquareRoundedNumber2Filled className="num-icon" />,
            strongText: 'Intelligent Ticket Routing',
            pText: 'Optimize ticket resolution with intelligent routing features in Techzard, ensuring tickets are directed to the right teams for swift and accurate solutions.'
          },
          {
            icon: <TbSquareRoundedNumber3Filled className="num-icon" />,
            strongText: 'Real-time Collaboration',
            pText: 'Facilitate real-time collaboration among support teams and stakeholders using Techzard. Enhance communication and expedite issue resolution.'
          }
          // Add more benefits following the same structure
        ]}
        benefitArray2={[
          {
            icon: <TbSquareRoundedNumber4Filled className="num-icon" />,
            strongText: 'Knowledge Base Integration',
            pText: 'Integrate a comprehensive knowledge base with Techzard to empower support teams with the information needed for quick and accurate issue resolution.'
          },
          {
            icon: <TbSquareRoundedNumber5Filled className="num-icon" />,
            strongText: 'Automated Ticket Updates',
            pText: 'Keep stakeholders informed with automated ticket updates in Techzard. Enhance transparency and provide real-time status information.'
          },
          {
            icon: <TbSquareRoundedNumber6Filled className="num-icon" />,
            strongText: 'Performance Analytics and Reporting',
            pText: 'Gain insights into ticket resolution performance with Techzard\'s analytics and reporting features. Utilize data for continuous improvement and efficiency.'
          }
        ]}
      />
      <br />
      <br />
      <h1
        style={{
          textAlign: 'center'
        }}
      >
        FAQ
      </h1>
      <br />
      <Collapse
        style={{
          width: '90%',
          position: 'relative',
          left: '5%',
          backgroundColor: '#fff',
          marginBottom: '5em',
          color:"#343F52"
        }}
        items={techzardFAQ}
        onChange={onChange}
        defaultActiveKey={['1']}
      />
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default TicketManagement;
