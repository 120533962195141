import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const CustomDevelopment = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Data Migration",
      description:
        "Specializing in robust data migration strategies, we ensure that your legacy data from Oracle E-Business Suite (EBS) is accurately and efficiently migrated to Oracle Fusion. Our team employs advanced data extraction, transformation, and loading (ETL) methodologies, supported by Oracle's Data Integrator (ODI) and GoldenGate tools. We address data cleansing, validation, and reconciliation to guarantee data integrity and continuity across your business processes. Our expertise ensures minimal downtime and eliminates the risk of data loss or corruption during the transition.",
    },
    {
      title: "Application Integration",
      description:
        "We excel in integrating disparate applications within your enterprise suite to the Oracle Fusion environment. Utilizing Oracle Integration Cloud Services (OIC), we facilitate seamless connectivity between on-premises systems and cloud applications. Our approach includes using REST APIs and SOAP web services to ensure smooth and secure data flow, while maintaining business logic and governance. We also focus on optimizing integration touchpoints to enhance operational efficiency and provide a unified platform that supports your business needs.",
    },
    {
      title: "Business Process Alignment",
      description:
        "Our services extend beyond mere technical transition to encompass aligning your business processes with Oracle Fusion’s capabilities. We conduct thorough business process re-engineering (BPR) to optimize workflows and leverage Oracle Fusion's advanced features, such as the Oracle Business Process Management Suite (BPMS). This ensures that your operations become more agile, efficient, and aligned with industry best practices. Our experts work closely with your stakeholders to map out current processes and to design future-state processes that align with strategic objectives.",
    },
    {
      title: "Customization and Configuration",
      description:
        "Tailoring Oracle Fusion applications to meet your specific business requirements is where we shine. We employ Oracle Application Framework (OAF) and Oracle Visual Builder to develop custom extensions and configurations that integrate smoothly with your existing systems. Our technical consultants are adept at customizing Fusion modules such as Financials, HCM, SCM, and CRM. Through meticulous design and rigorous testing, we ensure that customizations enhance user experience and business functionality without compromising the core system integrity.",
    },

    {
      title: "Performance Optimization and Auditing",
      description:
        "Ensuring that your new Oracle Fusion environment operates at peak performance is a critical focus. Our team conducts comprehensive performance auditing and optimization using Oracle Enterprise Manager (OEM) and Application Performance Monitoring (APM). We identify and resolve potential bottlenecks, enabling efficient resource utilization and improved response times. Furthermore, our continuous monitoring services ensure that your Oracle Fusion applications remain compliant with SLAs and deliver superior performance, supporting your dynamic business environment.",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "50px",
            }}
          >
            EBS to Fusion Transition
          </h1>
          <p style={{ fontSize: "150x" }}>
            At Focusr, we excel in transitioning Oracle E-Business Suite (EBS)
            environments to Oracle Fusion. Our comprehensive services include
            data migration, seamless application integration, and business
            process alignment, ensuring minimal disruption. We offer expert
            customization, robust performance optimization, and continuous
            support. Through strategic planning, effective change management,
            and stringent security measures, we ensure a smooth, efficient
            transition that meets your business goals and maximizes your Oracle
            investments.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>
            Strategic Planning and Advisory Services
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "1em" }}>
            Our transition process starts with a strategic planning phase, where
            our seasoned Oracle consultants conduct in-depth assessments of your
            current EBS environment. Through detailed gap analysis and system
            audits, we identify potential challenges and opportunities for
            improvement. Our advisory services are designed to help you chart a
            clear roadmap for your transition, ensuring alignment with your
            corporate strategy and long-term objectives.
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>
            Change Management
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "1em" }}>
            Transitioning from EBS to Oracle Fusion is not just a technical
            upgrade but a significant transformational change for your
            organization. Effective change management is crucial to the success
            of this transition. Focusr’s experts deliver comprehensive change
            management services, including stakeholder engagement, communication
            planning, and user training. We leverage tools such as Oracle User
            Productivity Kit (UPK) to create interactive training modules,
            ensuring that your team is well-prepared to leverage the new system
            from day one.
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>
            Security and Compliance
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "1em" }}>
            Ensuring your data's security and compliance with relevant
            regulations is a paramount concern during the transition. Focusr
            places a strong emphasis on adhering to best practices for data
            privacy, security, and regulatory compliance. Our team implements
            robust security measures including data encryption, access controls,
            and regular security audits to protect your sensitive information.
            We also ensure that the Oracle Fusion environment complies with
            industry regulations such as GDPR, HIPAA, and SOX, providing peace
            of mind that your system is both secure and compliant.
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>
            Post-Implementation Support
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "1em" }}>
            Our commitment to your success goes beyond the implementation phase.
            We provide extensive post-implementation support, including 24/7
            technical assistance, periodic system health checks, and regular
            updates to keep your Oracle Fusion environment running smoothly. Our
            support team utilizes advanced monitoring tools to proactively
            identify and address any issues before they impact your operations.
            We also offer tailored support packages to meet your specific needs,
            ranging from basic troubleshooting to comprehensive managed
            services.
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>
            Continuous Improvement
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "1em" }}>
            The business landscape is continually evolving, and so are your
            needs. Focusr is dedicated to helping you stay ahead by implementing
            continuous improvement methodologies. We regularly revisit and
            assess your Oracle Fusion environment to identify areas for
            enhancement. By incorporating the latest Oracle updates, patches,
            and best practices, we ensure your system continues to deliver
            optimal performance and supports your evolving business goals.
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>
            Proven Methodology
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "1em" }}>
            Our proven methodology for transitioning from Oracle E-Business
            Suite to Oracle Fusion is built on years of experience and industry
            best practices. We employ a phased approach, starting with a
            thorough discovery and planning phase, followed by a detailed design
            and configuration phase, and culminating in a robust testing and
            deployment phase. Each step is meticulously documented and executed
            to ensure that every aspect of your transition is carefully managed
            and aligned with your expectations.
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>
            Client Success Stories
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "1em" }}>
            Focusr has successfully transitioned numerous organizations from
            Oracle EBS to Fusion. Our clients span various industries, including
            manufacturing, healthcare, finance, and retail. Each success story
            reflects our commitment to delivering tailored solutions that meet
            unique business requirements. By leveraging our deep Oracle
            expertise, we have helped clients achieve increased operational
            efficiency, enhanced data accuracy, and improved business insights,
            ultimately driving their business success.
          </p>
          <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>Thoughts</p>
          <p style={{ marginLeft: "1.6em", marginTop: "1em" }}>
            Transitioning from Oracle EBS to Oracle Fusion is a significant
            undertaking that offers substantial benefits if executed well.
            Focusr's comprehensive suite of services ensures a smooth,
            cost-effective, and minimally disruptive transition. By partnering
            with us, you gain access to a team of experts dedicated to
            empowering your business with cutting-edge Oracle solutions,
            enabling you to achieve your strategic goals and maintain a
            competitive edge in the market.
          </p>
          <p
            style={{
              marginLeft: "1.6em",
              marginTop: "2em",
            //   fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            At Focusr, we understand that each organization's journey to Oracle
            Fusion is unique and requires meticulous planning and execution to
            meet specific business goals and technical requirements. Therefore,
            after addressing the core elements-data migration, application
            integration, business process alignment, customization and
            configuration, and performance optimization—we continue to work
            closely with our clients to ensure comprehensive support and value
            realization from their Oracle Fusion investment.
          </p>
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CustomDevelopment;
