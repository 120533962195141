import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import { useWindupString } from "windups";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const CloudMigration = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Configuration and Customization of Oracle Fusion Applications",
      description:
        "Oracle Fusion applications offer an extensive range of configurable features that enable us to tailor the applications to align precisely with your business needs. Leveraging our technical expertise in EBS and Fusion, we delve into:",

      details: [
        "Setup and Configuration: Utilizing Oracle's Application Implementation Methodology (AIM) and Oracle Unified Method (OUM), we perform end-to-end setups, covering modules like Financials, SCM, HCM, and CRM. Our process includes defining business units, chart of accounts, ledgers, and other foundational elements.",
        "Form Personalization and Customization: Through the usage of Oracle ADF (Application Development Framework) and SOA (Service-Oriented Architecture) frameworks, we can customize Fusion applications without compromising on upgrade paths.",
        "Workflow Customization: We leverage Oracle Workflow Builder and BPM Suite to create custom workflows that streamline and automate business processes, enhancing operational efficiency.",
        "Security Configuration: With a focus on RBAC (Role-Based Access Control), we ensure that security setups in both EBS and Fusion protect sensitive data while providing the necessary access to authorized users.",
        "Data Migration: Utilizing Oracle Data Integrator (ODI) and data loader tools, we manage complex data migrations from legacy systems or Oracle EBS to Oracle Fusion, ensuring data integrity and minimal disruption to business processes.",
      ],
    },
    {
      title: "Advanced Reporting with Oracle Business Intelligence (BI)",
      description:
        "Our proficiency in Oracle BI tools enables the transformation of raw data into meaningful insights that drive decision-making:",
      details: [
        "OBIEE (Oracle Business Intelligence Enterprise Edition): We implement and configure OBIEE to create interactive dashboards and analytical reports. This includes setting up data warehouses using Oracle Data Warehousing solutions and configuring the necessary ETL (Extract, Transform, Load) processes.",
        "Fusion Analytics Warehouse: Utilizing the pre-built data models and KPIs offered by Fusion Analytics Warehouse, we expedite the reporting process, providing out-of-the-box insights tailored to your Fusion applications.",
        "Smart View and Hyperion Integration: We ensure seamless integration between Oracle BI and Fusion EPM (Enterprise Performance Management) tools, such as Smart View, for real-time financial reporting and planning.",
        "Custom BI Publisher Reports: Designing and deploying custom reports using Oracle BI Publisher, we can extract data from both EBS and Fusion environments, ensuring that reporting needs are met comprehensively.",
        "Machine Learning and Advanced Analytics: Leveraging Oracle Advanced Analytics options like R integration and Oracle Machine Learning, we deliver predictive analytics and data mining capabilities directly within the Oracle environment.",
      ],
    },
    {
      title: "Optimization of Oracle Fusion and BI Solutions",
      description:
        "Continuous optimization ensures that your Oracle investments yield the highest returns. Our services include:",
      details: [
        "Performance Tuning: Using advanced performance tuning techniques and tools such as SQL Tuning Advisor and Automatic Database Diagnostic Monitor (ADDM), we ensure optimal performance of Oracle Fusion and BI environments.",
        "Custom Extensions and Enhancements: Utilizing Oracle PaaS (Platform-as-a-Service), we develop custom extensions that enhance the functionalities of standard Fusion applications, ensuring they fit unique business processes without disrupting future updates.",
        "Upgrade Management: We manage and execute upgrades to the latest Oracle Fusion and BI releases, ensuring new features and functionalities are leveraged while maintaining customization integrity.",
        "Integrations and Interoperability: Seamless integration with other Oracle and third-party systems using Oracle Integration Cloud (OIC) and SOA Suite, thereby enhancing overall system efficiency.",
        "Capacity Planning and Scalability: Conducting regular assessments and predictive analyses to ensure that Fusion applications and BI tools scale efficiently as business requirements grow.",
      ],
    },
    {
      title: "Data-Driven Decision Support Systems",
      description:
        "Our Ongoing Cloud Management services provide continuous support and management of your Oracle EBS and Fusion environments in the cloud. Key aspects include:",
      details: [
        "Real-Time Data Reporting: Implementing real-time data integration techniques using Oracle GoldenGate to ensure that decision-makers have the most current data at their fingertips.",
        "Predictive Analytics: Using Oracle Advanced Analytics, we deliver predictive modeling capabilities within BI solutions, allowing for proactive business decisions.",
        "Drill-Down Analysis: Configuring complex drill-down capabilities within Oracle Fusion BI, enabling users to trace data back to transactional sources in both Fusion and EBS.",
        "Scenario Analysis and Forecasting: Implementing advanced scenario modeling tools within Oracle Hyperion to predict the impact of business changes and future trends.",
        "Data Governance and Quality: Ensuring high data quality and governance through Oracle Enterprise Data Management (EDM), enabling accurate and consistent data across BI tools and Fusion applications.",
      ],
    },

    {
      title: "Enterprise-Wide Collaboration and Integration",
      description:
        "Enhancing collaboration and data flow across the enterprise with Oracle solutions:",
      details: [
        "Unified Cloud and On-Premises Integration: Using Oracle Cloud Managed Services and hybrid cloud strategies, we ensure seamless operations between Oracle Fusion Cloud applications and on-premises EBS systems.",
        "SOA and Web Services: Deploying SOA and REST/SOAP web services for real-time data exchange and integration between Oracle BI, Fusion applications, and other enterprise systems.",
        "APIs and Middleware: Utilizing Oracle API Gateway and Oracle Fusion Middleware to build robust, secure, and scalable integration solutions, aiding consistent enterprise data communication.",
        "Enterprise Collaboration Platforms: Integrating Oracle Social Network (OSN) functionalities and Oracle Content and Experience Cloud to promote effective collaboration amongst enterprise stakeholders.",
        "Centralized Monitoring and Management: Implementing Oracle Enterprise Manager (OEM) to provide comprehensive monitoring and management capabilities for both Oracle BI and Fusion environments, ensuring proactive issue resolution and system health.",
      ],
    },
    {
      title: "Predictive and Prescriptive Analytics in Oracle BI",
      description:
        "Harnessing advanced analytical techniques to forecast future trends and recommend actions:",
      details: [
        "Machine Learning Models: Integrating Oracle Machine Learning with Oracle BI to build, train, and deploy predictive models directly within the BI environment. This includes using algorithms like regression analysis, clustering, and decision trees.",
        "Anomaly Detection: Utilizing Oracle’s AI and Machine Learning capabilities to identify patterns and anomalies in business processes, helping stakeholders make proactive decisions.",
        "Recommendation Systems: Developing prescriptive analytics models that use optimization algorithms to suggest the best course of action for operational and strategic planning.",
        "Time Series Analysis: Implementing time series forecasting methods in Oracle BI to predict future performance metrics based on historical data trends.",
        "Integration with Oracle Data Mining: Leveraging Oracle Data Mining features within Oracle Database to perform in-database mining and apply these findings directly in Oracle BI dashboards and reports.",
      ],
    },
    {
      title: "Real-Time Business Intelligence and Data Visualization",
      description:
        "Delivering real-time insights through innovative data visualization and live analytical processing:",
      details: [
        "Real-Time ETL with Oracle GoldenGate: Implementing real-time data integration and synchronization between transactional systems (both EBS and Fusion) and Oracle BI using Oracle GoldenGate.",
        "Interactive Dashboards and Reports: Designing interactive and dynamic dashboards using Oracle Data Visualization Desktop and Oracle Analytics Cloud, enabling users to slice, dice, and drill into data in real-time.",
        "Geospatial Analytics: Utilizing Oracle Spatial and Graph to incorporate geospatial data into Oracle BI reports, providing location-based insights and visualizations.",
        "Real-Time Data Widgets: Incorporating real-time data widgets into Oracle BI dashboards, displaying live metrics and KPIs from Oracle Fusion transactional data.",
        "Streaming Analytics: Implementing Oracle Stream Analytics to process and analyze streaming data feeds, enabling immediate insights and reaction to live events in the business environment.",
      ],
    },
    {
      title: "Oracle Cloud Infrastructure (OCI) for BI and Fusion Applications",
      description:
        "Leveraging Oracle’s cloud infrastructure to enhance performance, scalability, and security:",
      details: [
        "Deployment on OCI: Setting up Oracle Fusion and Oracle BI environments on Oracle Cloud Infrastructure, taking advantage of Oracle’s robust cloud services, high availability, and disaster recovery solutions.",
        "Scalable Architectures: Designing scalable architecture using OCI services like Oracle Autonomous Data Warehouse, Oracle Exadata, and Oracle Cloud Storage, ensuring seamless growth as data volumes and user counts increase.",
        "Security and Compliance: Implementing OCI’s advanced security features, including Oracle Identity Cloud Service (IDCS), Data Safe, and Cloud Guard for compliance with regulatory standards and protection against cyber threats.",
        "High-Performance Computing (HPC): Utilizing OCI’s HPC capabilities to manage extensive data processing tasks, enabling high-speed analytics and large-scale query execution within Oracle BI.",
        "Automated Patch Management: Leveraging OCI’s automated patching features to keep Oracle Fusion applications and underlying infrastructure updated with the latest security and performance patches.",
      ],
    },
    {
      title: "Enhanced User Experience and UX/UI Optimization",
      description:
        "Ensuring a streamlined and intuitive user experience across Oracle applications:",
      details: [
        "Integrated Risk Management: Implementing Oracle Risk Management Cloud to streamline risk identification, assessment, and mitigation processes within Oracle Fusion applications.",
        "Automated Audit Trails: Setting up comprehensive audit trails and log management using Oracle Audit Vault and Database Firewall (AVDF), ensuring all actions are tracked and compliant with regulations.",
        "Data Privacy and Protection: Employing Oracle Advanced Security features like Transparent Data Encryption (TDE) and Data Redaction to protect sensitive data in Oracle Fusion and BI systems.",
        "Regulatory Compliance Reporting: Utilizing Oracle’s GRC suite to generate compliance reports for standards such as GDPR, SOX, and HIPAA, directly from Oracle BI environments.",
        "Policy Automation: Using Oracle Policy Automation (OPA) to enforce business rules and policies across Oracle Fusion applications, ensuring consistent adherence to regulatory mandates and internal standards.",
      ],
    },
    {
      title: "Governance, Risk Management, and Compliance (GRC)",
      description:
        "Ensuring robust governance and compliance using Oracle technologies:",
      details: [
        "Data Warehouse Cloud Service (DWCS): Migrating existing data warehouses to Oracle Autonomous Data Warehouse (ADW) for highly scalable, self-managing analytics capabilities.",
        "Big Data Integration: Integrating Oracle Big Data Service with Oracle EBS/Fusion data to provide comprehensive big data analytics, leveraging tools like Oracle Data Integrator.",
        "Advanced Analytics: Implementing advanced analytics and machine learning models using Oracle Analytics Cloud to derive actionable insights from your data.",
        "Real-Time Analytics: Enabling real-time analytics with Oracle Stream Analytics for immediate insights into your Oracle EBS and Fusion data.",
        "Visualization and Reporting: Developing advanced dashboards and reports using Oracle BI Publisher and Oracle Analytics Cloud, tailored to meet your specific business intelligence needs.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
            }}
          >
            ORACLE FUSION & ORACLE BI
          </h1>
          <p style={{ fontSize: "18px" }}>
            At FocusR, we specialize in harnessing the full potential of Oracle
            Fusion and Oracle BI tools to provide tailored solutions that drive
            innovation and operational excellence. Our expertise spans across
            configuration and customization, advanced analytics and reporting,
            optimization strategies, data-driven decision support, enterprise
            integration, predictive analytics, real-time BI, cloud
            infrastructure, enhanced user experience, and robust governance. By
            leveraging these capabilities, we ensure seamless integration,
            efficient performance, and data-driven insights that empower
            organizations to achieve their strategic objectives and maintain a
            competitive edge.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CloudMigration;
