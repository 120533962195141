import React, { createContext, useContext, useState } from 'react';

const ProductContext = createContext();

const ProductLogicProvider = ({ children }) => {
  const [isProductOpen, setIsProductOpen] = useState(false);
  return <ProductContext.Provider value={{ isProductOpen, setIsProductOpen }}>{children}</ProductContext.Provider>;
};

export const useProductState = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProductState must be used within a ProductLogicProvider");
  }
  return context;
};

export default ProductLogicProvider;
