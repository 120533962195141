import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { FloatButton, Modal, Button, Checkbox, Input, Upload } from 'antd';
import { Form, message } from "antd";
import { MdKey, MdKeyboardArrowRight } from "react-icons/md";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import ReCAPTCHA from "react-google-recaptcha";
import ChatNew from "../Components/Chat2";
import axios from "axios";
import './CareerJobs.css'
import { UploadOutlined } from "@ant-design/icons";
import gmailIcon from '../assets/gmail.png'
import { useNavigate } from "react-router-dom";

const Company = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [newform] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [jobTitlestate, setJobtitlestate] = useState("");
  const [jobIdstate, setJobIdstate] = useState("");
  const [jobDetails, setJobDetails] = useState([]);
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [activeTab, setActiveTab] = useState("Transparency");
  const [verified, setVerified] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 500 && window.innerWidth <= 900);

  const iconStyle = {
    width: '24px', // You can adjust the size here
    height: '24px',
    margin: '0 10px',
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
      setIsTablet(window.innerWidth > 500 && window.innerWidth <= 900)
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content: `You've Successfully Applied for ${jobTitlestate} role \n Our HR Team will contact you shortly`,
      duration: 5,
    });
  };
  const error = () => {
    messageApi.open({
      type: "error",
      content: "This is an error message",
      duration: 5,
    });
  };

  const perjob = (title, id) => {
    console.log("Per-Job : ", title);
    console.log("Per-Job ID: ", id);
    setOpen(true);
    setJobtitlestate(title);
    setJobIdstate(id);
  };

  const handleOk = () => {
    setLoading(true);
    onFinish();
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    error();
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList.slice(-1));
  };

  const onChange = (value) => {
    // value will be null if reCAPTCHA was not verified
    setVerified(value !== null);
  };
  useEffect(() => {
    AOS.init();
  });

  const onFinish = (values) => {
    if (verified) {
      console.log("Success:", values);

      const formData = new FormData();
      const jsonData = {
        name: newform.getFieldValue("name"),
        email: newform.getFieldValue("email"),
        mobile: newform.getFieldValue("mobile"),
        notificationPeriod: newform.getFieldValue("notificationPeriod"),
        yearsOfExperience: newform.getFieldValue("yearsOfExperience"),
        llmskills: newform.getFieldValue("llmskills"),
        jobId_id: jobIdstate,
      };

      formData.append("json", JSON.stringify(jsonData));

      if (fileList.length > 0) {
        const file = fileList[0].originFileObj;
        formData.append("file", file);
      }
      setLoading(true);
      axios
        .post(
          "https://hireflowapi.focusrtech.com:90/hiring/entryLevel/applyJob",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log("Response after sending Contact Form", res);
          setLoading(false);
          success();
          newform.resetFields();
          setFileList([]);
          setFile(null);
          setOpen(false);
        })
        .catch((err) => {
          error(`Error while submitting form: ${err}`);
          setLoading(false);
        });
    } else {
      message.error(`Please verify reCAPTCHA`);
    }
  };

  useEffect(() => {
    axios
      .get(
        `https://hireflowapi.focusrtech.com:90/hiring/entryLevel/postJob`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("this is response data", res.data);
        setJobDetails(res.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  useEffect(() => {
    if (jobDetails.length > 0) {
      const job = jobDetails.find(job => job.id === parseInt(id));
      setSelectedJob(job);
    }
  }, [jobDetails, id]);

  useEffect(() => {
    document.title = "About | Careers";
    AOS.init();
  }, []);

  if (!selectedJob) {
    return <div>Loading...</div>; // Show loading state while fetching job details
  }

  return (
    <div>
      <Navbar mode="dark"/>
      <ChatNew />
      <div className="careerjob-banner">
        <div style={{textAlign: "center"}}>
          <p>FocusR <span className="banner-div"></span><span>Full Time</span></p>
          <h1>{selectedJob?.jobTitle}</h1>
          <button className="careerjob-btn1" onClick={() => perjob(selectedJob.jobTitle, selectedJob.id)}>Apply <MdKeyboardArrowRight style={{
            width: "25px",
            height: "25px",
            marginLeft: "1em",
          }}/></button>
          <div style={{marginTop: "35px"}}>
            <span style={{fontSize: "10px"}}>SHARE IT TO</span>
            <a style={{display: "block", marginTop: "5px"}} href="mailto:resume@focusrtech.com" target="_blank" rel="noopener noreferrer">
              <img src={gmailIcon} alt="Twitter" style={iconStyle} />
            </a>
          </div>
        </div>
      </div>
      <div className="careerjob-description">
        <div className="description-wrapper">
          <div className="description-inner">
            <div className="description-left">
              <h2 style={{fontSize: '36px', paddingLeft: "0", fontWeight: "normal"}}>Job Description</h2>
              <div className="description-data">
                <ul>
                  {selectedJob.jobDescription.split('\n').map((line, index) => (
                  <li key={index}>{line.trim()}</li>
                  ))}
                </ul>
                <button style={{display: "flex", alignSelf: 'flex-start'}} className="careerjob-btn2" onClick={() => perjob(selectedJob.jobTitle, selectedJob.id)}>Apply <MdKeyboardArrowRight style={{
            width: "25px",
            height: "25px",
            marginLeft: "1em",
          }}/></button>
              </div>
            </div>
            <div className="description-right">
              <h2 style={{fontSize: '36px', fontWeight: 'normal', paddingLeft: '0'}}>Job Information</h2>
              <ul>
              <li>
                <span style={{ color: "#555555", fontSize: '16px', display: 'block', marginBottom: '10px' }}>Work Location</span>
                <span style={{ display: 'block', fontSize: '18px', marginBottom: '15px' }}>{selectedJob.jobLocation}</span>
              </li>
              <li>
                <span style={{ color: "#555555", fontSize: '16px', display: 'block', marginBottom: '10px' }}>Experience</span>
                <span style={{ display: 'block', fontSize: '18px' }}>{selectedJob.experience}</span>
              </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="description-last">
        <button className="careerjob-last-btn" onClick={()=> navigate('/careers')}>View all Jobs</button>
      </div>
      <Modal
        open={open}
        title={jobTitlestate}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="custome-apply-now-bn"
      >
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            minWidth: "100%",
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={newform}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
              {
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name="mobile"
            rules={[
              {
                required: true,
                message: "Please input your Mobile No!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Notice Period"
            name="notificationPeriod"
            rules={[
              {
                required: true,
                message: "Please input your Notice Period!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Years of Experience"
            name="yearsOfExperience"
            rules={[
              {
                required: true,
                message: "Please input your Experience!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Skills"
            name="llmskills"

rules={[
  {
    required: true,
    message: "Please input your skills!",
  },
]}>
  <Input />
</Form.Item>
<Form.Item
  label="Upload Resume"
  name="resume"
  rules={[
    {
      required: true,
      message: "Please upload your resume!",
    },
  ]}
>
  <Upload
    beforeUpload={() => false}
    fileList={fileList}
    onChange={handleFileChange}
  >
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  </Upload>
</Form.Item>
<Form.Item
  wrapperCol={{
    offset: 8,
    span: 16,
  }}
>
  <ReCAPTCHA
    sitekey="6LdWF7ApAAAAABLvVOKY0om3CK-cJwmSqbJqviPv"
    onChange={onChange}
  />
</Form.Item>
<Form.Item
  wrapperCol={{
    offset: 8,
    span: 16,
  }}
>
  <Button
    type="primary"
    htmlType="submit"
    loading={loading}
  >
    Submit Application
  </Button>
</Form.Item>
</Form>
</Modal>
    <Footer/>
    </div>
  );
};

export default Company;
