import { useEffect, useRef, useState } from 'react';
import NET from 'vanta/dist/vanta.net.min';
import CLOUDS from 'vanta/dist/vanta.clouds.min';
// import * as THREE from 'three';
import { AiFillControl, AiOutlineApi, AiOutlineBranches, AiOutlineSetting, AiOutlineArrowRight } from 'react-icons/ai';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { useNavigate } from 'react-router-dom';
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbTextScan2
} from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contactform from '../Components/Contactform';
import { useWindupString } from 'windups';
import ChatNew from '../Components/Chat2';
import aboutIllustration from '../assets/about-page-bg.png';
import rightImage from '../assets/i5@2x.png';
import leftImage from "../assets/D3-imghome.svg";
import newImage from '../assets/why-us.jpg';
import whyUsGif from '../assets/Animation - 1709622059494.gif';
import { Progress, Collapse, Row, Col, Card, Carousel, Button } from 'antd';
import { FaGears, FaWandMagicSparkles } from 'react-icons/fa6';
import { SiCodeclimate } from 'react-icons/si';
import { BsGlobeCentralSouthAsia, BsTruckFront } from 'react-icons/bs';
import { FaArrowRight, FaHandshake } from 'react-icons/fa';
import { TiLightbulb } from 'react-icons/ti';
import { GoLightBulb } from 'react-icons/go';
import { BiSolidPieChartAlt2 } from 'react-icons/bi';
import { PiChartPieSliceDuotone, PiMagicWandLight, PiScanThin } from 'react-icons/pi';
import { CaretRightOutlined } from '@ant-design/icons';
import CardCount from '../Components/CardCount';
import * as THREE from 'three';
import Clouds from 'vanta/dist/vanta.clouds.min';
import salessyncx from '../assets/salessyncx-mockup.svg';
import { GiDiscussion, GiMisdirection } from 'react-icons/gi';
import newmock from '../assets/dashboard-example-applify-edited.png';
import newmock2 from '../assets/Custom-Prod-Illustration/Ticketting-side.svg';
import { LuTicket } from 'react-icons/lu';
import { LiaTruckLoadingSolid } from 'react-icons/lia';
import atOfc from '../assets/Custom-Prod-Illustration/at-the-ofc.svg';
import { CiDeliveryTruck } from 'react-icons/ci';
import greenIllu from '../assets/Custom-Prod-Illustration/Data-extraction.gif';
import sap from '../assets/Logos/A4.png';
import oracle from '../assets/Logos/A1.png';
import { TypeAnimation } from 'react-type-animation';
import { GoDotFill } from "react-icons/go";
import { MdKeyboardArrowRight } from "react-icons/md";
import './Home.css'
import brainstorming from '../assets/Brainstorm.png'
import mechanics from '../assets/Home_mechanics.png'
import styled from 'styled-components';
import arrow from "../assets/Icons-lunacy/right-arrow2.png";
import coreErpServicesbackground from '../assets/ERP_Services_core.png'
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
 
import image1 from "../assets/Icons-lunacy/image1.png";
import image from "../assets/Icons-lunacy/image2.png";
import image2 from "../assets/Icons-lunacy/image3.png";
import image3 from "../assets/Icons-lunacy/image4.png";
 
import achievement from "../assets/achievement_home_jpg-removebg.png"
import AiGif from "../assets/network-robot-assistant-working-on-laptop.gif"
 
import productillu from "../assets/product-development-illu.svg"
 
 
// import { Button } from 'antd';
const Home = () => {
  const [mouse, setMouse] = useState(false);
  const vantaRef = useRef(null);
 
  useEffect(() => {
    const vantaEffect = CLOUDS({
      el: vantaRef.current,
      THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      // minHeight: 300.00,
      // minWidth: 200.00,
      // backgroundColor: "0xffffff",  
      // skyColor: "#daf0ff",        
      // cloudColor: "rgb(252, 252, 252)",      
      // cloudShadowColor: "rgb(40,40,40)",
      // sunColor: "rgb(181, 224, 255)",        
      // sunGlareColor: "rgb(79, 69, 69)",    
      // sunlightColor: "rgb(82, 72, 57)",    
      skyColor: "#daf0ff", cloudColor: "#e9e9f5", cloudShadowColor: "#183550", sunColor: "#d77d13", sunGlareColor: "#ab9f9b", sunlightColor: "#e0d9d1",
      speed: 1,                  
    });
 
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);
 
  const myRef = useRef(null);
 
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 500 && window.innerWidth <= 900);
 
 
  const StatsCounter = ({ end, duration }) => {
    const [count, setCount] = useState(0);
 
    useEffect(() => {
      let start = 0;
      const increment = end / (duration / 10);
      const interval = setInterval(() => {
        start += increment;
        if (start >= end) {
          start = end;
          clearInterval(interval);
        }
        setCount(Math.floor(start));
      }, 10);
 
      return () => clearInterval(interval);
    }, [end, duration]);
 
    return <span style={{ fontSize: '2rem' }}>{count}+</span>;
  };
 
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
      setIsTablet(window.innerWidth > 500 && window.innerWidth <= 900)
    };
 
    window.addEventListener('resize', handleResize);
 
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
 
  useEffect(() => {
    if (!myRef.current) return;
 
    const effect = Clouds({
      el: myRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      speed: 0.5
    });
 
    return () => {
      if (effect) effect.destroy();
    };
  }, []);
 
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'FocusR Technologies | Home';
    AOS.init();
  }, []);
 
  const handleClick = () => {
    console.log("Button clicked");
    navigate("/services/AI");
  };
 
 
 
 
 
  const whyChooseUSArray = [
    {
      key: '1',
      label: <b>Collaborative Approach</b>,
      children: <span>Working closely with clients, we ensure their vision is integrated into our solutions.</span>,
      style: {
        border: 'none'
      }
    },
    {
      key: '2',
      label: <b>Communication</b>,
      children: (
        <span>Transparent and effective communication is our priority, fostering strong client relationships.</span>
      ),
      style: {
        border: 'none'
      }
    },
    {
      key: '3',
      label: <b>Cost-Effective</b>,
      children: <span>We offer competitive pricing without compromising on the quality of our services.</span>,
      style: {
        border: 'none'
      }
    }
  ];
 
  const servicesNewArray = [
    {
      icon: (
        <FaGears
          style={{
            color: '#89CFF3'
          }}
        />
      ),
      title: 'ERP',
      description:
        'Streamlining and integrating business processes through comprehensive ERP solutions for efficient resource management.'
    },
    {
      icon: (
        <SiCodeclimate
          style={{
            color: '#FBF0B2'
          }}
        />
      ),
      title: 'Application',
      description:
        'Crafting innovative and user friendly software solutions, tailoring applications to meet your business needs.'
    },
    {
      icon: (
        <BsGlobeCentralSouthAsia
          style={{
            color: '#BEADFA'
          }}
        />
      ),
      title: 'Out Sourcing',
      description:
        'Your outsourcing partner for seamless task delegation, efficiency optimization, and global collaboration.'
    },
    {
      icon: (
        <FaHandshake
          style={{
            color: '#FBA1B7'
          }}
        />
      ),
      title: 'Customer Centric',
      description:
        'Delivering customer centric solutions, prioritizing satisfaction, and tailoring offerings to individual needs.'
    }
  ];
 
  const howItWorks = [
    {
      icon: (
        <GiDiscussion
          style={{
            fontSize: '48px',
            color: '#FF9800'
          }}
        />
      ),
      title: 'Discussion',
      description: 'Unlocking Ideas, Sharing Insights,Crafting Solutions - Together'
    },
    {
      icon: (
        <GoLightBulb
          style={{
            fontSize: '48px',
            color: '#756AB6'
          }}
        />
      ),
      title: 'Decision',
      description: 'Empowered Minds, Decisive Moves, Fusling Progress - With Purpose'
    },
    {
      icon: (
        <GiMisdirection
          style={{
            fontSize: '48px',
            color: 'blue'
          }}
        />
      ),
      title: 'Direction',
      description: 'Charting cousres, Guiding Teams, Leading Towards Success - Vision in Motion'
    }
  ];
 
  const products = [
    {
      appName: "Oracle Fusion",
      // desc: "Ticket tracking system",
      icon: (
        <GoDotFill
          style={{
            width: "22px",
            height: "22px",
            color: "#226DB4",
          }}
        />
      ),
      icon1: (
        <MdKeyboardArrowRight
          style={{
            color: "gray",
            width: "25px",
            height: "25px",
            marginLeft: "1em",
          }}
        />
      ),
      link: "/platforms/Oracle",
    },
    {
      appName: "Oracle EBS",
      icon: (
        <GoDotFill
          style={{
            width: "22px",
            height: "22px",
            color: "#E42527",
          }}
        />
      ),
      icon1: (
        <MdKeyboardArrowRight
          style={{
            color: "gray",
            width: "25px",
            height: "25px",
            marginLeft: "2.4em",
          }}
        />
      ),
      link: "/platforms/Oracle",
    },
    {
      appName: "SAP S4HANA",
      icon: (
        <GoDotFill
          style={{
            width: "22px",
            height: "22px",
            color: "#F9B21D",
          }}
        />
      ),
      icon1: (
        <MdKeyboardArrowRight
          style={{
            color: "gray",
            width: "25px",
            height: "25px",
            marginLeft: "1em",
          }}
        />
      ),
      link: "/platforms/SAP",
    },
    {
      appName: "AI Services",
      icon: (
        <GoDotFill
          style={{
            width: "22px",
            height: "22px",
            color: "#089949",
          }}
        />
      ),
      icon1: (
        <MdKeyboardArrowRight
          style={{
            color: "gray",
            width: "25px",
            height: "25px",
            marginLeft: "2.8em",
          }}
        />
      ),
      link: "/services/AI",
    },
    {
      appName: "Cloud Services",
      icon: (
        <GoDotFill
          style={{
            width: "22px",
            height: "22px",
            color: "#CA5C43",
          }}
        />
      ),
      icon1: (
        <MdKeyboardArrowRight
          style={{
            color: "gray",
            width: "25px",
            height: "25px",
            marginLeft: "0.6em",
          }}
        />
      ),
      link: "/platforms/Cloud",
    },
    {
      appName: "Application DEV",
      icon: (
        <GoDotFill
          style={{
            width: "22px",
            height: "22px",
            color: "#9DC2CB",
          }}
        />
      ),
      icon1: (
        <MdKeyboardArrowRight
          style={{
            color: "gray",
            width: "25px",
            height: "25px",
          }}
        />
      ),
      link: "/services/Product-Development",
    },
    {
      appName: "EXPLORE ALL SERVICES",
      icon1: (
        <MdKeyboardArrowRight
          style={{
            width: "25px",
            height: "25px",
            marginLeft: "1.5em",
          }}
        />
      ),
      link: "/contact",
    },
  ];
 
  return (
    <div style={{backgroundColor:"rgb(248, 249, 251)"}}>
      <Navbar mode="dark" />
      <ChatNew />
      <div ref={vantaRef} className="vanta-bg">
      <div className="new-home-page" style={{ justifyContent: "center", boxSizing: isMobile ? "" : isTablet ? "border-box" : "" }}>
     
      <div
        className="content-container"
        style={{
          display: 'flex',
          paddingTop: '5%',
          flexDirection: isMobile ? 'column' : isTablet? 'column':'row',
          width: isMobile ? '100%' : isTablet? '100%': '80%',
        }}
      >
        <div
          className="new-home-left"
          style={{
            width: isMobile ? '100%' : isTablet? '100%': '50%',
            textAlign: isMobile ? 'center' : isTablet? 'center': 'left',
          }}
        >
          <h1 data-aos="fade-right" data-aos-delay="200">
            <span>Go</span> Live {" "}
            <span className="bordered-text">and</span> beyond
          </h1>
          <hr style={{width:"12%", height:"3px", backgroundColor:"red", marginTop:"4px", border: "none"}}/>
          <br />
          <br />
          <p>
            Empowering success through <br /> Collaborative software solutions with
            Right Focus
          </p>
          <br />
          <br />
          <button className="red-home-btn" onClick={() => navigate('/contact')}>
            GET STARTED HERE
          </button>
          <img className="at-ofc" src={atOfc} alt="hh" width="600px" />
        </div>
       
        <div
          className="new-home-right-prod"
          style={{
            width: isMobile ? '100%' : isTablet? '100%': '20rem',
            marginLeft: isMobile ? '0' : isTablet? '0': '10em',
            marginTop: isMobile ? '2em' : isTablet? '2em': '0',
            display: isMobile ? 'flex' : isTablet? 'flex': 'flex',
            justifyContent: isMobile ? 'center' : isTablet? 'center': 'flex-start',
            alignItems: 'center',
          }}
        >
          <div className="box-of-prods" style={{ width: isMobile ? '80%' : isTablet? '50%': '20rem' }}>
            <p style={{ fontSize: "small" }}>
              FEATURED SERVICES
            </p>
            {isMobile&&<br/>}
            {products.map((x, i) => {
              const isLastItem = i === products.length - 1;
              return (
                <div style={{}}>
                <div
                  key={i}
                  className={`kutty-box ${isLastItem ? "explore-all" : ""}`}
                  onClick={() => navigate(x.link)}
                >
                  <div
                    className={`left-kutty-box ${
                      isLastItem ? "explore-all-icon" : ""
                    }`}
                  >
                    {x.icon}
                  </div>
                  <div className="right-kutty-box" style={{fontSize: isMobile?"7px":isTablet? "":"", marginTop: isMobile?"7px":isTablet? "":""}}>
                    <p
                      className={`title-kutty-box ${
                        isLastItem ? "explore-all-title" : ""
                      }`}
                    >
                      {x.appName}
                    </p>
                  </div>
                  <div
                    className={`left-kutty-box1 ${
                      isLastItem ? "explore-all-icon1" : ""
                    }`}
                  >
                    {x.icon1}
                  </div>
                </div>
                </div>
              );
            })}
          </div>
        </div>
        </div>
      </div>
    </div>
 
 
{/* completed    */}
 
 
     
 
 
<div style={{ position: "relative", width: "100vw", height: "fit-content", marginTop: isMobile?"10px": isTablet?"":"", backgroundColor: isMobile?"rgb(248, 249, 251)":isTablet?"rgb(248, 249, 251)":"" }}>
<div style={{ position: "relative", width: "80%", height: "fit-content" }}>
  <img
    src={brainstorming}
    alt="Your Image Description"
    style={{ position: "absolute", marginTop:isMobile? "-60px": isTablet? "-90px": "-120px",marginLeft:isMobile? "10%": isTablet? "25%": "15%",  maxWidth: isMobile? "321px": isTablet? "481px": "642px", maxheight: isMobile? "72px": isTablet? "107px": "143px" }}
  />
  </div>
  <div style={{ width: "100vw", backgroundColor: "rgb(44, 44, 44)", height: "fit-content", display: "flex", justifyContent: "center", marginTop:"-12px" }}>
    <div style={{ backgroundColor: "rgb(44, 44, 44)", marginTop:"50px" ,width: "100%", display: "flex",flexDirection:"column" ,alignItems: "center", marginBottom:"30px" }}>
      <h1 style={{ fontWeight: "bold", color: "rgb(246, 201, 101)", fontSize: "36px" }}>#makeERPSimple</h1>
 
     
 
    <svg style={{ width: "90vw", height: "40vh" }} xmlns="http://www.w3.org/2000/svg">
      <rect x="10%" y="10%" width="80%" height={isMobile ? "60%": isTablet? "80%": "80%"} rx="30" ry="30" fill="rgb(243, 227, 209)" stroke="none" />
 
      <image href={mechanics} x={isMobile?"30%":isTablet?"30%":"7%"} y={isMobile?"":isTablet?"-10%":"7%"} width="40%" height="80%" style={{display: isMobile ? "none": "block"}} />
 
      <foreignObject x={isMobile?"20%":isTablet?"25%":"50%"} y={isMobile?"20%":isTablet?"55%":"30%"} width={isMobile?"60%":isTablet?"50%":"37%"} height={isMobile?"45%":isTablet?"45%":"60%"}>
        <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: isMobile?"14px":isTablet?"18px": "20px", color: "#000", overflow: "visible", wordWrap: "break-word" }}>
          Optimize your ERP systems with FocusR's comprehensive suite of ERP services. We break down silos between departments, ensuring your business runs smoothly and effectively.
        </div>
      </foreignObject>
 
      <line x1="15%" y1="calc(90% + 30px)" x2="25%" y2="calc(90% + 30px)" stroke="#FF0" strokeWidth="2" />
      <line x1="45%" y1="calc(90% + 30px)" x2="55%" y2="calc(90% + 30px)" stroke="#F00" strokeWidth="2" />
      <line x1="75%" y1="calc(90% + 30px)" x2="85%" y2="calc(90% + 30px)" stroke="rgb(143, 218, 203)" strokeWidth="2" />
    </svg>
 
    <div style={{width:isMobile?"":isTablet?"":"72vw", display:isMobile?"":isTablet?"":"flex", justifyContent:isMobile?"":isTablet?"":"end"}}>    
    <button className="red-home-btn" style={{fontSize: isMobile?"10px": isTablet?"": ""}} onClick={() => navigate("/contact")}>
            CONNECT WITH US
            <br /> TO LEARN MORE
            <img src={arrow} alt="arrow icon" className="arrow-icon" />
          </button>
    </div>
 
    </div>
 
   
  </div>
 
</div>
 
{/* completed */}
 
<div style={{width: "100vw", backgroundColor: "rgb(255, 217, 216)"}}>
  <div style={{width: "100%", display: "flex", justifyContent: "space-between", flexDirection:isMobile?"column":isTablet?"":""}}>
    <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", marginTop: isMobile?"20px":isTablet?"":""}}>
      <h1
        style={{
          fontSize: "4rem",
          fontWeight: "bold",
          color: "#333",
          textAlign: "center",
          margin: 0,
          lineHeight: "1.2",
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        data-aos="fade-right"
        data-aos-delay="200"
      >
        <span style={{ display: "block", fontSize: isMobile?"2.5rem":isTablet?"":"3.5rem" }}>
          Focus<span style={{ color: "red" }}>R</span>'s
        </span>
        <span style={{ display: "block", fontSize: isMobile?"2.5rem":isTablet?"": "5rem", color: "#E94E77" }}>D3</span>
        <span style={{ display: "block", fontSize: isMobile?"2.5rem":isTablet?"": "3.5rem" }}>Approach</span>
      </h1>
    </div>
    <div style={{ flex: 1 , display:"flex", justifyContent: isMobile?"center":isTablet?"":""}}>
      <img src={leftImage} alt="left-illustration" width="80%" />
    </div>
  </div>
</div>
 
{/* completed */}
 
 
 
<div
  style={{
    position: "relative",
    width: "100%",
    height: "fit-content", // Adjust height as needed
    backgroundImage: `url(${coreErpServicesbackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden"
  }}
>
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Black with 50% opacity; adjust color and opacity as needed
      zIndex: 1
    }}
  />
  <div style={{ position: "relative", zIndex: 2, width:"100%", display:"flex", justifyContent:"center" }}>
  <div style={{width:"80%", marginBottom:"10px"}}>
    <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
          <h1
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              fontWeight: "normal",
              // textAlign: "center",
            }}
          >
            <span style={{ color: "rgb(255, 255, 255)" }}>Our Core </span> <span style={{ color: "#6187FF" }}>ERP Services</span>
          </h1>
        </div>
 
        <div className="values-container">
          <div
            className="value-card"
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#323232",
            }}
          >
            <img
              style={{ width: "15%", marginRight: "10px", marginTop: "-15%" }}
              src={image}
              alt="Card 1"
            />
            <div>
              <h2
                style={{
                  fontWeight: "normal",
                  color: "#FFFFFF",
                  fontSize: "23px",
                  marginTop: "-15%",
                }}
                className="h2-values"
              >
                Implementation
              </h2>
              <p
                className="p-values"
                style={{ marginTop: "-5%", color: "#E5E5E5" }}
                data-aos="fade-right"
                data-aos-delay="200"
              >
                FocusR provides end-to-end ERP implementation services and specializes in products from leading ERP solution
                 providers, Oracle and SAP.
              </p>
            </div>
          </div>
 
          <div
            className="value-card"
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#323232",
            }}
          >
            <img
              style={{ width: "15%", marginRight: "10px", marginTop: "-15%" }}
              src={image1}
              alt="Card 1"
            />
            <div>
              <h2
                style={{
                  fontWeight: "normal",
                  color: "#FFFFFF",
                  fontSize: "23px",
                  marginTop: "-15%",
                }}
                className="h2-values"
              >
                Upgrade
              </h2>
              <p
                className="p-values"
                style={{ marginTop: "-5%", color: "#E5E5E5" }}
                data-aos="fade-right"
                data-aos-delay="200"
              >
                If you are looking for application, database, hardware, or middleware upgrades, we are your ideal choice. 
                We work closely with you to ensure timely execution.
              </p>
            </div>
          </div>
          <div
            className="value-card"
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#323232",
            }}
          >
            <img
              style={{ width: "15%", marginRight: "10px", marginTop: "-15%" }}
              src={image2}
              alt="Card 1"
            />
            <div>
              <h2
                style={{
                  fontWeight: "normal",
                  color: "#FFFFFF",
                  fontSize: "23px",
                  marginTop: "-15%",
                }}
                className="h2-values"
              >
                Support
              </h2>
              <p
                className="p-values"
                style={{ marginTop: "-5%", color: "#E5E5E5" }}
                data-aos="fade-right"
                data-aos-delay="200"
              >
                Application Maintenance and Support(AMS) service at FocusR,
                delivers high quality 24 X 7 support for Oracle and
                SAP applications.
              </p>
            </div>
          </div>
 
          <div
            className="value-card"
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#323232",
            }}
          >
            <img
              style={{ width: "15%", marginRight: "10px", marginTop: "-15%" }}
              src={image3}
              alt="Card 1"
            />
            <div>
              <h2
                style={{
                  fontWeight: "normal",
                  color: "#FFFFFF",
                  fontSize: "23px",
                  marginTop: "-15%",
                }}
                className="h2-values"
              >
                Development
              </h2>
              <p
                className="p-values"
                style={{ marginTop: "-5%", color: "#E5E5E5" }}
                data-aos="fade-right"
                data-aos-delay="200"
              >
                Over the years we have learnt that there is no such thing as "one size fits all" approach when it comes to ERP systems. 
                So, We tailor our solutions to ensure your ERP system supports your unique goals.
              </p>
            </div>
          </div>
        </div>
        </div>
  </div>
</div>
 
{/* completed */}
 
<div style={{ width: "100vw", display: "flex", justifyContent: "center", marginTop: "30px" }}>
  <div style={{
    border: "5px double gold",
    width: "90%", /* Adjusts to 90% of the screen width */
    maxWidth: isMobile?"400px":"1000px", /* Limits the maximum width to 600px */
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    boxSizing: "border-box"
  }}>
      <h1
        style={{ fontWeight: "normal" }}
        data-aos="fade-right"
        data-aos-delay="200"
      >
        Helped in transforming 100+ companies <br/> with 10+ years of experience
      </h1>
      <h2
        style={{ fontWeight: "normal" }}
        data-aos="fade-right"
        data-aos-delay="400"
      >
        Trusted by organizations worldwide, Our solutions and services make your business stand out and succeed.
      </h2>
  </div>
</div>
 
{/* completed */}
 
 
 
     
 
 
 
      {/* <CardCount /> */}
     
 
 
 
      {/* <div style={{ width: "100vw", padding: "4rem 0", display: "flex", justifyContent: "center", marginTop: "30px", backgroundColor: "rgb(40, 94, 174)" }}>
  <div style={{ width: "80%", display:isMobile?"flex":"", justifyContent:isMobile?"center":"" }}>
    <Row justify="center" gutter={32}>
      <Col xs={24} md={12}>
        <div style={{ textAlign: "left" }}>
          <h3 style={{ fontSize: "4rem", color: "white" }}>Focus Right  With FocusR</h3>
          <br/>
 
          <Row justify="start" gutter={[56, 56]} style={{ marginBottom: "3rem" }}>
            <Col>
              <div style={{ border: "2px solid white", padding: "1rem", borderRadius: "8px" }}>
                <h3 style={{ fontSize: "2rem", marginBottom: "1rem", color: "white" }}>
                  <StatsCounter end={185} duration={2000} />
                  <br />
                  Ideas Realized
                </h3>
              </div>
            </Col>
            <Col>
              <div style={{ border: "2px solid white", padding: "1rem", borderRadius: "8px" }}>
                <h3 style={{ fontSize: "2rem", marginBottom: "1rem", color: "white" }}>
                  <StatsCounter end={120} duration={2000} />
                  <br />
                  Projects Done
                </h3>
              </div>
            </Col>
          </Row>
 
          <Row justify="start" gutter={[56, 56]} style={{ marginBottom: "3rem" }}>
            <Col>
              <div style={{ border: "2px solid white", padding: "1rem", borderRadius: "8px" }}>
                <h3 style={{ fontSize: "2rem", marginBottom: "1rem", color: "white" }}>
                  <StatsCounter end={85} duration={2000} />
                  <br />
                  Happy Clients
                </h3>
              </div>
            </Col>
            <Col>
              <div style={{ border: "2px solid white", padding: "1rem", borderRadius: "8px" }}>
                <h3 style={{ fontSize: "2rem", marginBottom: "1rem", color: "white" }}>
                  <StatsCounter end={250} duration={2000} />
                  <br />
                  Workforce
                </h3>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
     
      <Col xs={24} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div style={{ textAlign: "center" }}>
          <img src={achievement} alt="Your Image" style={{ maxWidth: "100%", height: "auto", position:"relative" }} />
        </div>
      </Col>
    </Row>
  </div>
</div> */}
 
 
<div className="custome-erp-count" style={{marginTop:"20px"}} ref={ref}>
  <div className="custome-erp-count-left">
    <h1>
    Focus Right  With FocusR
    </h1>
    <hr
      style={{
        width: "2em",
        border: "none",
        borderTop: "0.1em solid #FFBD1E",
        marginTop: "1em",
      }}
    />
 
    <div className="custome-erp-count-container">
      <div className="custome-erp-client">
        <div className="custome-erp-client-inner">
          <h3 style={{ fontSize: "1.4rem" }}>
            <span
              style={{
                fontSize: "30px",
              }}
            >
              {inView ? <CountUp start={0} end={185} duration={2.75} /> : 0}+
            </span>
            <p>Ideas Realized</p>
          </h3>
        </div>
        <div className="custome-erp-client-inner">
          <h3 style={{ fontSize: "1.4rem" }}>
            <span
              style={{
                fontSize: "30px",
              }}
            >
              {inView ? <CountUp start={0} end={150} duration={2.75} /> : 0}+
            </span>
            <p>Projects Done</p>
          </h3>
        </div>
      </div>
      <div className="custome-erp-client">
       
        <div className="custome-erp-client-inner">
          <h3 style={{ fontSize: "1.4rem" }}>
            <span
              style={{
                fontSize: "30px",
              }}
            >
              {inView ? <CountUp start={0} end={85} duration={2.75} /> : 0}+
            </span>
            <p>Happy Clients</p>
          </h3>
        </div>
 
        <div className="custome-erp-client-inner">
          <h3 style={{ fontSize: "1.4rem" }}>
            <span
              style={{
                fontSize: "30px",
              }}
            >
              {inView ? <CountUp start={0} end={250} duration={2.75} /> : 0}+
            </span>
            <p>Workforce</p>
          </h3>
        </div>
      </div>
      <div className="custome-erp-client">
       
        <div className="custome-more">
          <div className="custome-more-about">
            <p
              onClick={() => {
                navigate("/contact");
              }}
            >
              More About Us
            </p>
          </div>
        </div>
      </div>
    </div>
    <hr
      style={{
        width: "2em",
        border: "none",
        borderTop: "0.1em solid red",
        marginTop: "20px",
      }}
    />
  </div>
  <div className="custome-erp-count-right">
    <img src={achievement} alt="countman" style={{ width: "100%" }} />
  </div>
</div>
 
 
 
 
<section className="oracle-explian-hero-home">
        <div className="oracle-explian-hero-left-home">
          <h1
            className="oracle-explain-hero-h1-home"
            style={{ fontWeight: "normal", color: "#FFFFFF" }}
          >
            AI and the new world
          </h1>
          <p
            style={{ color: "#FFFFFF" }}
            className="oracle-explain-hero-p-home"
            data-aos="fade-right"
            data-aos-delay="400"
          >
           AI tailored for you, using algorithms and innovative approaches, can significantly enhance your company's potential.
            Join us as we explore a new, digitized, and automated world today. 
          </p>
          <div className='button-ai-home'>
          <button
            className="glass-btn-home1"
            onClick={handleClick}
            data-aos="fade-right"
            data-aos-delay="400"
          >
            Learn more
          </button>
          </div>
        </div>
 
        <div className="oracle-explian-hero-right-home">
          <img src={AiGif} alt="fghj" width="380px" />
        </div>
      </section>
 
 
 
      <div className="customInput-Whole-Wrapper1">
  <div className="form-right-product-dev-home">
    <h1 data-aos="fade-right" data-aos-delay="200">
      Infrastructure Management
    </h1>
    <p data-aos="fade-right" data-aos-delay="400">
    We offer dependable and scalable infrastructure management services, covering application, database, hardware, 
    middleware upgrades and maintenance. Our tailored solutions keep your environment secure and optimized, allowing you to focus on driving business growth.
    </p>
    <h1 data-aos="fade-right" data-aos-delay="200">
      Custom Product Development
    </h1>
    <p data-aos="fade-right" data-aos-delay="400">
      Our product development process is dedicated to creating solutions to match your company’s esteemed values by combining market insights, upcoming trends, and customer feedback to drive a range of successes and sales.
    </p>
    <div className="glass-btn-home2-div">
      <button
        className="glass-btn-home2"
        onClick={() => navigate("/services/Product-Development")}
        data-aos="fade-right"
        data-aos-delay="400"
      >
        Learn more
      </button>
    </div>
  </div>
  <div className="form-left12">
    <img className="at-ofc1" src={productillu} alt="Product Illustration" />
  </div>
</div>
 
     
 
 
 
 
 
 
      <Footer />
    </div>
  );
};
export default Home;
