import { useParams } from 'react-router-dom';
import { HiMenuAlt1 } from "react-icons/hi";
import {
    Button,
    Collapse,
    Drawer,
    Dropdown,
    Popover,
    Space,
    message,
    Tooltip,
  } from "antd";
import focusRlogo from "../assets/logo.png";
import { FaSearch } from "react-icons/fa";
import { IoMdHome } from "react-icons/io";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import focusRlogoRight from "../assets/FocusR_100px.png";
// import image from "../assets/Icons-lunacy/image2.png";
import axios from "axios";
import { Link } from 'react-router-dom'
import './Blog.css'

function AuthorPage() {
  const { authorName } = useParams();
  const [navbarStatus, setnavbarStatus] = useState(false);
  const navigate = useNavigate();
  const [navBarMonitor, setnavBarMonitor] = useState(false);
  const [hover, setHover] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const searchRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [highlightHome, sethighlightHome] = useState(false);
  const [open, setOpen] = useState(false);
  const [blogDetails, setBlogDetails] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://focusrblogsapi.focusrtech.com:91/blog/retrievepublishedposts/nothing`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("this is response data", res.data);
        setBlogDetails(res.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  function formatAuthorName(author) {
    return author.toLowerCase().replace(/\s+/g, '-');
  }

  const handleBlogClick = (blog) => {
    navigate(`/blogContent/${blog.postId}`, { state: { blog } });
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSearch(false);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };
  

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (e.isTrusted && window.scrollY > 0) {
        setnavbarStatus(true);
        setHover(true);
      } else {
        setnavbarStatus(false);
        setHover(false);
      }
    });
    console.log("Window y position", window.scrollY);
    console.log("windowWidth", window.innerWidth);
    //"1px 1px 50px 1px #fff"
    if (window.innerWidth < 1150) {
      setnavBarMonitor(true);
    } else {
      setnavBarMonitor(false);
    }
    window.addEventListener("resize", (e) => {
      e.isTrusted && window.innerWidth < 1150
        ? setnavBarMonitor(true)
        : setnavBarMonitor(false);
    });
  }, [window.innerWidth]);

  const filteredBlogs = blogDetails.filter((blog) => 
    formatAuthorName(blog.author) === authorName
  );

  const filteredSearchedBlogs = filteredBlogs.filter((data) => {
    const searchTerms = Object.values(data).join(" ").toLowerCase();
    return searchTerms.includes(searchValue.toLowerCase());
  });

  const originalAuthorName = blogDetails.find(blog => formatAuthorName(blog.author) === authorName)?.author || '';

  return (
    <div>
      <div>
        {navBarMonitor ? (
          <div className="navbar2" style={{ backgroundColor: "#fff" }}>
            <button className="hamburger-cus" onClick={showDrawer}>
              <HiMenuAlt1 style={{ width: "3em", height: "3em" }} />
            </button>
            <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
          </div>
        ) : (
          <div
            className="navbar-blog"
            style={{
              boxShadow: hover ? "1px 1px 10px 1px rgb(227, 218, 218)" : "none",
            }}
          >
            <div className="navbar-wrap">
            {/* <ul className="left-nav"> */}
              <li
                className="logo"
                // style={{ color: "#423E3B" }}
                onClick={() => navigate("/")}
              >
                <img
                  src={focusRlogo}
                  width="90em"
                  alt="logo"
                  style={{
                    // marginTop: "0.1em",
                    // position: "absolute",
                    // left: "1.5em",
                    cursor: "pointer",
                    backgroundSize: "100%",
                    textIndent: "-99999px",
                    backgroundPosition: "1px 5px",
                  }}
                />
              </li>
              <ul className="left-nav">
              <li className="handwritten-font"
                onClick={() => navigate("/blog")}
                style={{
                  // marginLeft: "90%",
                  // marginTop: "0.4em",
                  // fontWeight: "normal",
                  // fontSize: "22px",
                  float: "left",
                  // opacity: "1",
                  padding: "5px 0 0 15px",
                  flex: "1",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  fontWeight: "normal",
                  fontSize: "25px",
                  letterSpacing: '1px'
                }}
              >
                Blog
              </li>
            </ul>
            <ul
              className="right-nav"
              style={{ display: "flex", alignItems: "center" }}
            >
              <li
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#423E3B",
                  cursor: "pointer",
                  position: "relative",
                  marginLeft: "90%",
                }}
              >
                <FaSearch onClick={() => setShowSearch(!showSearch)} />
                <div
                  ref={searchRef}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: showSearch ? "-265px" : "10px",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    padding: "0.5em",
                    display: "flex",
                    alignItems: "center",
                    transition: "left 0.4s, opacity 0.1s",
                    opacity: showSearch ? 1 : 0,
                    pointerEvents: showSearch ? "auto" : "none",
                  }}
                >
                  {/* <input
                    type="text"
                    placeholder="Search..."
                    style={{
                      border: "none",
                      outline: "none",
                      padding: "0.5em",
                      width: "230px",
                    }}
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                  /> */}
                </div>
              </li>
              <li
                onClick={() => navigate("/")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#423E3B",
                  marginLeft: "0em",
                }}
              >
                <IoMdHome />
                <span style={{ marginLeft: "0.1em", marginTop: "2%" }}>
                  Home
                </span>
                {highlightHome ? (
                  <span
                    style={{
                      width: "2em",
                      height: "3px",
                      display: "inline-block",
                      backgroundColor: "red",
                      transition: "all 400ms",
                    }}
                  ></span>
                ) : null}
              </li>
            </ul>
            </div>
          </div>
        )}
        <Drawer
          title={
            <div className="logo-small">
              <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
            </div>
          }
          placement="top"
          onClose={onClose}
          open={open}
          height="100%"
        >
          <button
            className="drawer-antd-btn"
            onClick={() => {
              navigate("/");
              onClose();
            }}
          >
            Home
          </button>
        </Drawer>
      </div>
      <div className="author-container">
      <div style={{ marginLeft: '1em'}}>
              <div style={{fontSize: "35px",  marginTop: '1em'}}>{`${originalAuthorName}'s Blogs`}</div>
           </div>
        <div className="author-content">
            {filteredSearchedBlogs.map((blog) => (
            <div
            className="author-card"
            onClick={() => handleBlogClick(blog)}
            style={{
              alignItems: "center",
              backgroundColor: "white",
            }}
              key={blog.id}
            >
              <img src={blog.thumbnail} alt={`Post ${blog.id}`} />
              <div style={{display: "flex", flexDirection: "column", marginBottom: "5px"}}>
              <h2 style={{ fontWeight: "bold", fontSize: "18px", paddingLeft: '0', paddingTop: '0' }}>
                {blog.title}: {blog.footer}
              </h2>
                <p style={{ marginBottom: "2px", marginTop: "-3%", color: "#5a5a5a;"}}>
                    {formatDate(blog.publishedDate)}
                </p>
              </div>
            </div>
          ))}
        </div>
        </div>
    <Footer />
    </div>
  );
}

export default AuthorPage;
