import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const ExtendedOracle = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Custom Development",
    //   description:
    //     "Our certified Oracle professionals are highly skilled in deploying Oracle E-Business Suite (EBS) and Oracle Fusion applications. Our thorough implementation process includes:",

      details: [
        "Tailor-Made Solutions: We specialize in developing custom solutions tailored to your specific business requirements. Whether it's creating new modules or enhancing existing functionality, our experts utilize the full capabilities of Oracle EBS and Fusion to deliver solutions that align perfectly with your organizational goals.",
        "Extensible Flexfields (EFF): Our development services include configuring Extensible Flexfields (EFF) in Oracle Fusion to capture additional business-specific information, enhancing the granularity and flexibility of your data management processes.",
        "ERP Custom Utilities: We build custom utilities to automate routine ERP tasks, thereby improving productivity and reducing human error. These utilities are designed to seamlessly integrate with both Oracle EBS and Fusion environments.",
        "Workflow Customizations: We offer advanced workflow customizations to streamline your business processes. By modifying standard workflows or creating new custom workflows, we help you achieve operational efficiency while ensuring compliance with business rules and policies.",
        "User Interface Personalization: Our team is proficient in personalizing the Oracle Fusion user interface to meet the unique needs of your users. This includes custom layouts, tailored dashboards, and enhanced interaction capabilities to improve user experience and productivity.",
      ],
    },
    {
      title: "Integrations",
    //   description:
    //     "Ensuring a streamlined roll-out of Oracle solutions across your organization involves:",
      details: [
        "Inter-Module Integration: We offer specialized services for integrating various Oracle EBS and Fusion modules, ensuring seamless data flow and business process continuity across your Oracle ecosystem.",
        "Third-Party Integration: Our experts are adept at integrating Oracle solutions with third-party applications, including CRM, SCM, and legacy systems, thereby extending the capabilities of your Oracle environment.",
        "API Development: We develop robust Application Programming Interfaces (APIs) to facilitate smooth and secure data exchange between Oracle EBS/Fusion and other external systems, ensuring real-time data synchronization and process automation.",
        "Middleware Solutions: Leveraging Oracle SOA Suite (BPEL, OSB), we provide middleware solutions that enable efficient data transformation, routing, and connectivity between disparate systems, fostering a cohesive and integrated IT landscape.",
        "Cloud-to-On-Premises Integration: We facilitate the integration of Oracle Fusion Cloud solutions with on-premises Oracle EBS systems, ensuring a hybrid IT environment that leverages the strengths of both cloud and traditional infrastructure.",
      ],
    },
    {
      title: "Upgrades",
    //   description:
    //     "Providing seamless upgrades to Oracle EBS and Fusion platforms to leverage enhanced capabilities:",
      details: [
        "EBS to Fusion Upgrade: We offer specialized services to upgrade your Oracle EBS environment to Oracle Fusion applications. Our comprehensive upgrade methodology includes assessment, planning, testing, and execution to ensure a smooth transition with minimal disruption.",
        "Patch Management: Our team provides extensive patch management services, including the analysis, testing, and deployment of patches for Oracle EBS and Fusion applications to maintain system security and performance.",
        "Performance Optimization during Upgrades: We conduct detailed performance tuning exercises during upgrades to ensure that your Oracle systems perform optimally and meet demanding business requirements post-upgrade.",
        "Feature Adoption: We help organizations adopt new features and functionalities introduced in the latest Oracle EBS and Fusion releases, maximizing the benefits derived from your Oracle investments.",
        "Compliance and Security Upgrades: Our services cover critical compliance and security upgrades, ensuring that your Oracle applications remain compliant with regulatory requirements and protected against emerging security threats.",
      ],
    },
    {
      title: "Migrations",
      details: [
        "Data Migration Strategy: We devise comprehensive data migration strategies tailored to your business needs, ensuring secure, accurate, and efficient transfer of data from Oracle EBS to Oracle Fusion or other target environments.",
        "Automated Migration Tools: Using advanced data migration tools and scripts, we automate the migration process to minimize manual intervention, reduce errors, and expedite project timelines.",
        "Validation and Reconciliation: Post-migration, we perform thorough validation and reconciliation to ensure data consistency and integrity between the source and target systems.",
        "Hybrid Migration Solutions: For complex scenarios, we offer hybrid migration solutions that facilitate phased transitions and coexistence of Oracle EBS and Fusion environments, ensuring business continuity throughout the migration process.",
        "Legacy System Retirement: Our migration services include the planned decommissioning of legacy systems, ensuring a seamless transfer of functions and data to the new Oracle environment while mitigating risks and maintaining data integrity.",
      ],
    },

    {
      title: "Consulting Services",
      details: [
        "Strategic Roadmapping: We provide strategic roadmapping services to help organizations plan and execute their Oracle EBS and Fusion initiatives. Our experts work with you to develop long-term strategies that align with your business objectives.",
        "Business Process Reengineering: Our consultants specialize in business process reengineering, leveraging Oracle EBS and Fusion capabilities to redesign and optimize your business processes for maximum efficiency and effectiveness.",
        "Change Management: We offer comprehensive change management services to support your transition to Oracle Fusion, including stakeholder engagement, communication planning, and training to ensure user adoption and minimize resistance.",
        "Compliance and Risk Management: We provide expert guidance on managing compliance and risk within your Oracle environment. Our consultants work with you to identify potential risks, implement mitigation strategies, and ensure regulatory compliance across your Oracle applications.",
        "Technology Assessments: Our technology assessment services involve a thorough evaluation of your existing Oracle EBS and Fusion infrastructure, providing actionable insights and recommendations to enhance performance, reliability, and scalability.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "40px",
            }}
          >
            Extended Oracle Services
          </h1>
          <p style={{ fontSize: "158x" }}>
          At FocusR, we offer a comprehensive array of extended Oracle
          services designed to maximize the value of your Oracle investments.
          Our expertise spans custom development, seamless integrations, efficient upgrades,
          precise migrations, and insightful consulting services. Leveraging
          the combined strengths of Oracle E-Business Suite (EBS) and Oracle Fusion,
          we help organizations achieve operational excellence and drive strategic success.
          Explore our specialized offerings to unlock the full potential of your Oracle environment.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24"  ref={welcomePageRef}>
        <div className="left-side">
        <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              {/* <p style={{ marginLeft: "1.6em" }}>{section.description}</p> */}
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "0 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ExtendedOracle;
