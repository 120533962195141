/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import {message} from "antd";
import axios from "axios";
import Contactform from "../../Components/Contactform";
import Footer from "../../Components/Footer";
import Products from "./Components/Products";
import techstephubMockup from "../../assets/Hirecentral-mockup.svg";
import adminnav from "../../assets/Icons-lunacy/adminnav01.png";
import datavisuals from "../../assets/Icons-lunacy/datavisuals.png";
import chats from "../../assets/Icons-lunacy/chats .png";
import kanban from "../../assets/Icons-lunacy/kanban.png";
import AI from "../../assets/Icons-lunacy/AI.png";

import adminnavmain from "../../assets/Icons-lunacy/adminnavmain.png";

import evaluation from "../../assets/Icons-lunacy/evaluation.png";
import lady from "../../assets/Icons-lunacy/mobile-login-concept-illustration.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import emoticons from "../../assets/Icons-lunacy/emoticons .png";

import { FcGoogle } from "react-icons/fc";

import Chat from "../../Components/Chat";
import { Collapse } from "antd";
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import "./HiringApplication.css"

const HiringApplication = () => {
  library.add(fab);
  const navigate = useNavigate();
  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  const [about,setAbout] = useState("")
  const handleSubmit = () => {
    if (!name || !email || !about) {
      message.error("All fields are required!");
      return;
    }
    const payload = {
      full_Name: name,
      email: email,
      about: about,
      type: "HireFlow",
    };
    axios
      .post("https://focusrapi.focusrtech.com:83/post/enquery", payload)
      .then((response) => {
        console.log(response.data);
        message.success("Submitted Successfully!");
        setName("");
        setEmail("");
        setAbout("");
      })
      .catch((error) => {
        console.error("There was an error making the request!", error);
        message.error("Failed to Submit");
      });
  };

  return (
    <>
      <Navbar mode="dark" />

      {/*Overall Divider   */}
      <div className="custome-hire-first-page-wrapper-career-hireflow-bg">
        <div className="custome-hire-inner-page-left-product-dealerportal-hireflow-heading">
          <h1 style={{ marginTop: "3%", fontWeight: "normal" }}>
            Welcome to{" "}
            <span style={{ color: "#1738A9", fontWeight: "normal" }}>
              HIREFLOW
            </span>
          </h1>
          <h1 style={{ fontWeight: "normal" }}>
            {" "}
            Let HireFlow Seal the Deal with candidates
          </h1>
          <h1 style={{ fontWeight: "normal" }}>while you Grow in Flow!</h1>
          <div className="custome-hire-hireflow-para">
            <p className="custome-hire-hireflow-align-para-center">
              With HireFlow, shortlist, select and sail through with the top
              talents within minutes! <br /> The system leverages advanced AI algorithms to ensure a systematic
              entry of candidate applications and presents accurate analytics in no time! Join hands with
              HireFlow and make your minds enter a seamless experience hiring top-grade
              candidates <br/> Lean on us for the support of your hiring process.
            </p>
          </div>
          <div className="custome-hire-inner-page-left-product-dealerportal-hireflow-heading hireflow-down-divine">
            <h1 style={{ fontWeight: "normal" }}>Dive in with HireFlow </h1>
            <h1 style={{ fontWeight: "normal" }}>and see what you can do</h1>
          </div>
        </div>
      </div>
{/* ver */}
      <div className="custome-hire-hireflow-container-kanban">
        <div className="custome-hire-hireflow-kandban-dashboard">
          <h4 style={{ color: "black" }} className="hireflow-h4">
            Kanban dashboard
          </h4>
          <h1 style={{ marginTop: "1em", fontWeight: "normal" }}>
            Admin Navigation
          </h1>
          <p style={{ marginTop: "1em", lineHeight: "1.5em" }}>
            With HireFlow’s user management plan, companies <br />
            can efficiently assign different roles, ensuring each <br />
            user has correct permissions. The platform allows <br />
            customization of interview process providing <br />
            flexibility and control to meet hiring requirements
          </p>
        </div>
        <div className="custome-hire-hireflow-image-container">
          <img
            src={adminnavmain}
            alt="Admin Navigation Image"
            className="custome-hire-recruiter-img"
          />
        </div>
      </div>

      <div className="custome-hire-hireflow-container-kanban">
        <div className="custome-hire-hireflow-image-container">
          <img
            src={datavisuals}
            alt="Data visuals Image"
            className="custome-hire-recruiter-img"
          />
        </div>
        <div className="custome-hire-hireflow-kandban-dashboard">
          <h4 style={{ color: "black" }} className="custome-hire-hireflow-h4">
            Data Visuals
          </h4>
          <h1 style={{ marginTop: "1em", fontWeight: "normal" }}>
            Get immediate visual insights from data
          </h1>
          <p style={{ marginTop: "1em", lineHeight: "1.5em" }}>
            With FocusR’s HireFlow get to see data visuals and get to make
            data-driven decisions. The platforms analytics helps C-level
            recruiters to technical interviewers to track various metrics of the
            hiring process such as experience and job statuses.
          </p>
        </div>
      </div>

      <div className="custome-hire-hireflow-container-kanban">
        <div className="custome-hire-hireflow-kandban-dashboard">
          <h4 style={{ color: "black" }} className="custome-hire-hireflow-h4">
            ChatBox
          </h4>
          <h1 style={{ marginTop: "1em", fontWeight: "normal" }}>
            Chat interface
          </h1>
          <p style={{ marginTop: "1em", lineHeight: "1.5em" }}>
            HireFlow chats are a quick connectivity hub between the recruiters
            and the applicants. This allows the user to communicate with
            candidates through HireFlow’s in-built messaging module.
          </p>
        </div>
        <div className="custome-hire-hireflow-image-container">
          <img src={chats} alt="Chats Image" className="custome-hire-recruiter-img" />
        </div>
      </div>

      <div className="custome-hire-hireflow-container-kanban">
        <div className="custome-hire-hireflow-image-container">
          <img src={kanban} alt="Kanban Image" className="custome-hire-recruiter-img" />
        </div>
        <div className="custome-hire-hireflow-kandban-dashboard">
          <h4 style={{ color: "black" }} className="hireflow-h4">
            Kanban
          </h4>
          <h1 style={{ marginTop: "1em", fontWeight: "normal" }}>
            Our Kanban board
          </h1>
          <p style={{ marginTop: "1em", lineHeight: "1.5em" }}>
            Kanban board clearly outlines and displays the hiring process of the
            applicants allowing users to share updates and simplifies issue
            resolution
          </p>
        </div>
      </div>

      <div className="custome-hire-hireflow-container-kanban">
        <div className="custome-hire-hireflow-kandban-dashboard">
          <h4 style={{ color: "black" }} className="custome-hire-hireflow-h4">
            Application Screening
          </h4>
          <h1 style={{ marginTop: "1em", fontWeight: "normal" }}>
            AI-based resume screening
          </h1>
          <p style={{ marginTop: "1em", lineHeight: "1.5em" }}>
            With HireFlow reinforce your hiring process with multiple
            application options including LinkedIn, your website and various
            input methods. For a more productive process the AI-based resume
            screening, will provide a calculated and compared analysis of the
            applicants skills with the pre-defined skill set.
          </p>
        </div>
        <div className="custome-hire-hireflow-image-container">
          <img src={AI} alt="AI Image" className="custome-hire-recruiter-img" />
        </div>
      </div>

      <div className="custome-hire-hireflow-container-kanban">
        <div className="custome-hire-hireflow-image-container">
          <img
            src={adminnav}
            alt="evaluation Image"
            className="custome-hire-recruiter-img"
          />
        </div>
        <div className="custome-hire-hireflow-kandban-dashboard">
          <h4 style={{ color: "black" }} className="custome-hire-hireflow-h4">
            Evaluate
          </h4>
          <h1 style={{ marginTop: "1em", fontWeight: "normal" }}>
            Evaluate your candidates
          </h1>
          <p style={{ marginTop: "1em", lineHeight: "1.5em" }}>
            The platform allows the interviewers to evaluate the candidates
            including options to pause or hold while admins handle the final
            shortlisting.
          </p>
        </div>
      </div>

      <div className="custome-hire-first-page-wrapper-career-hireflow-bg-footer">
        <div className="custome-hire-inner-page-left-product-dealerportal-hireflow-heading">
          <h1 style={{ color: "#162FB1" }}>
            AI Driven Software to <br />
            accelerate your HR team
          </h1>

          <div className="custome-hire-hireflow-para">
            <p
              className="custome-hire-hireflow-align-para-center"
              style={{ color: "#162FB1" }}
            >
              Build Your Road Map to Success and grow in the futuristic 
              path along with us. <br />Tag along and become Royal customers
              of FocusR as <br /> we accomplish achievements for your
              services
            </p>
          </div>
        </div> 
        <div className="custome-hire-hireflow-last-container">
          <div className="custome-hire-hireflow-signup">
            <span style={{ color: "#162FB1" }}>
              <i>Register now for a free trial now!</i>
            </span>
            <br />
            <div className="custome-hire-signup-container" style={{ marginTop: "2em" }}>
            <input className="custome-hire-signup-field" placeholder="FullName" value={name} onChange={(e) => setName(e.target.value)}  />
              <br />
              <input className="custome-hire-signup-field" placeholder="Email-Id" value={email} onChange={(e) => setEmail(e.target.value)} />
              <br />
              <input className="custome-hire-signup-field" placeholder="Phone Number" value={about} onChange={(e) => setAbout(e.target.value)} />
            </div>
            <br />
            <button
              className="custome-hire-submit"
              onClick={() => handleSubmit()}
            >
              START
            </button>
            <div></div>
            <p style={{ color: "black", marginTop: "1em" }}>
             
            </p>
          </div>
          <div className="custome-hire-signup-lady">
            <img src={lady} alt="signuplady" className="custome-hire-signuplady-img"></img>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HiringApplication;
