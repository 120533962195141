import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import { FloatButton, Modal } from 'antd';
import { RxVideo } from 'react-icons/rx';
import { BiVideo, BiSolidBot, BiPlay } from 'react-icons/bi';
import ReactPlayer from 'react-player';
import { FiArrowRight } from 'react-icons/fi';
// import videoOne from "../../assets/product-dev.mp4"
import BreadCrum from '../../Components/BreadCrum';
import ChatNew from '../../Components/Chat2';
import Casestudy from '../../Components/Casestudy';
const ProductDevelopment = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    document.title = 'Platforms | Product Development';
  });
  return (
    <div>
      {/* navbar here */}
      <Navbar mode="dark" />
      <ChatNew />
      {/* First Page starts */}
      <div className="first-page-wrapper-platforms-dev">
        {/* <video autoPlay loop muted className="videoBg-1">
          <source src={videoOne} type='video/mp4'/>
        </video> */}
        <div className="inner-page-left-product-dealerportal">
          {/* <span
            style={{
              fontWeight: "600",
            }}
          >
            FocusR <FiArrowRight /> Platforms <FiArrowRight /> Product
            Development
          </span> */}
          <BreadCrum color="#fff" items={['FocusR', 'Platforms', 'Product Development']} />
          <br />
          <h1>FocusR Where success gets showcased.</h1>
          <br />
          <p>
            We're a full-cycle web and mobile application development company that creates software solutions tailored
            to the demands of businesses, seamlessly integrating Oracle and SAP ERPs to fuel efficient product
            development and innovation.
          </p>
          <br />
          <br />
          <a className="primary-btn-cus2" href="#Contact-Form">
            Get Details
          </a>
        </div>
      </div>
      <br />
      <br />
      {/* first page ends here */}

      {/* explanation page starts here */}
      <center>
        <Casestudy />
      </center>
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default ProductDevelopment;
