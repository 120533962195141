/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import Products from './Components/Products';
import techstephubMockup from '../../assets/techstephub-mockup.svg';
import Chat from '../../Components/Chat';
import { Collapse } from 'antd';
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled
} from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TimesheetApplication = () => {
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    document.title = 'Product | TechstepHub';

    AOS.init();
  });

  const faqData = [
    {
      key: '1',
      label: <h3>1. What is the purpose of the TechstepHub application?</h3>,
      children: (
        <p>
          The TimeSheet application is designed to help individuals and organizations track and manage their time
          effectively. It simplifies the process of recording work hours, tasks, and projects.
        </p>
      )
    },
    {
      key: '2',
      label: <h3>2. How do I get started with the TechstepHub application?</h3>,
      children: (
        <p>
          To get started, download the TimeSheet app from the App Store/Google Play Store or visit our website. Create
          an account, log in, and begin entering your time entries.
        </p>
      )
    },
    {
      key: '3',
      label: <h3>3. What platforms does the TechstepHub application support?</h3>,
      children: (
        <p>
          The TimeSheet application is available for download on iOS and Android devices. Additionally, a web version is
          accessible from any modern web browser.
        </p>
      )
    },
    {
      key: '4',
      label: <h3>4. Can I use the TimeSheet app for both personal and professional time tracking?</h3>,
      children: (
        <p>
          Yes, the TimeSheet app is versatile and can be used for both personal and professional time tracking. You can
          create separate projects and categories to distinguish between different types of activities.
        </p>
      )
    },
    {
      key: '5',
      label: <h3>5. How do I track my working hours?</h3>,
      children: (
        <p>
          To track working hours, simply create a new entry and specify the start and end times of your work. You can
          also add details such as project name, task description, and any additional notes.
        </p>
      )
    },
    {
      key: '6',
      label: <h3>6. Can I track billable hours and expenses?</h3>,
      children: (
        <p>
          Absolutely. The TimeSheet app allows you to mark hours as billable and record associated expenses. You can
          generate reports to facilitate billing processes.
        </p>
      )
    },
    {
      key: '7',
      label: <h3>7. Is it possible to export TechstepHub data?</h3>,
      children: (
        <p>
          Yes, the application provides export options. You can export timesheet data in various formats such as CSV or
          PDF, making it easy to share or integrate with other tools.
        </p>
      )
    },
    {
      key: '8',
      label: <h3>8. Can I set reminders for time tracking?</h3>,
      children: (
        <p>
          Yes, the TimeSheet app supports reminders. You can set reminders for starting or stopping work, ensuring you
          don't forget to log your hours.
        </p>
      )
    },
    {
      key: '9',
      label: <h3>9. How secure is my data in the TechstepHub application?</h3>,
      children: (
        <p>
          We take data security seriously. All data is encrypted, and our application complies with industry-standard
          security practices. Your information is stored securely and is only accessible to you.
        </p>
      )
    },
    {
      key: '10',
      label: <h3>10. Is there customer support available if I encounter issues?</h3>,
      children: (
        <p>
          Yes, we offer customer support to assist you with any issues or questions you may have. You can reach out to
          our support team through the app or by visiting our website.
        </p>
      )
    },
    {
      key: '11',
      label: <h3>11. Can I use the TechstepHub app offline?</h3>,
      children: (
        <p>
          Yes, the TimeSheet app has offline functionality. You can continue tracking your time even when you are not
          connected to the internet. The data will be synchronized when you are back online.
        </p>
      )
    },
    {
      key: '12',
      label: <h3>12. How often are updates released for the TechstepHub application?</h3>,
      children: (
        <p>
          We strive to improve the app regularly. Updates with new features, improvements, and bug fixes are released
          periodically.
        </p>
      )
    }
  ];

  return (
    <div>
      <Navbar mode="dark" />
      <Chat />
      <Products
        title="TechstepHub"
        mockupSrc={techstephubMockup}
        mockupAlt="TechstepHub-Mockup"
        technologyUsed={['React', 'Java', 'Spring Boot']}
        ratingCount={4}
        reviewCount="120"
        width="100%"
        videoUrl="https://www.youtube.com/watch?v=f-V1bMnAeb0"
        benefitTitle="Benefits of Techstephub"
        benefitArray1={[
          {
            icon: <TbSquareRoundedNumber1Filled className="num-icon" />,
            strongText: 'Enhanced Flexibility',
            pText: 'Track hours anywhere, adapt to changing schedules seamlessly.'
          },
          {
            icon: <TbSquareRoundedNumber2Filled className="num-icon" />,
            strongText: 'Efficient Workflow',
            pText: 'Streamline tasks, boost productivity with easy project management.'
          },
          {
            icon: <TbSquareRoundedNumber3Filled className="num-icon" />,
            strongText: 'Transparent Accountability',
            pText: 'Promote team accountability through clear task tracking.'
          }
          // Add more benefits following the same structure
        ]}
        benefitArray2={[
          {
            icon: <TbSquareRoundedNumber4Filled className="num-icon" />,
            strongText: 'Accurate Billing',
            pText: 'Ensure precise billing with detailed and error-free time tracking.'
          },
          {
            icon: <TbSquareRoundedNumber5Filled className="num-icon" />,
            strongText: 'Resource Optimization',
            pText: 'Optimize resource allocation with insights from efficient time data.'
          },
          {
            icon: <TbSquareRoundedNumber6Filled className="num-icon" />,
            strongText: 'Project Visibility',
            pText: 'Enhance project visibility with real-time progress tracking and reporting.'
          }
        ]}
      />
      <br />
      <br />
      <h1
        style={{
          textAlign: 'center'
        }}
      >
        FAQ
      </h1>
      <br />
      <Collapse
        style={{
          width: '90%',
          position: 'relative',
          left: '5%',
          backgroundColor: '#fff',
          marginBottom: '5em',
          color:"#343F52"
        }}
        items={faqData}
        onChange={onChange}
        defaultActiveKey={['1']}
      />
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default TimesheetApplication;
