import React, { useState,useEffect } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import { FloatButton, Modal } from 'antd';
import { RxVideo } from 'react-icons/rx';
import { BiVideo, BiSolidBot, BiPlay } from 'react-icons/bi';
import ReactPlayer from 'react-player';
import { FiArrowRight } from 'react-icons/fi';
// import videoOne from "../../assets/video-7-T.mp4"
import BreadCrum from '../../Components/BreadCrum';
import ChatNew from '../../Components/Chat2';
import CallToAction from '../../Components/CallToAction';
import OutSourcingComponent from './Components/OutSourcingComponent';
const Outsourcing = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    document.title = 'Services | Outsourcing';
  });
  return (
    <div>
      {/* navbar here */}
      <Navbar mode="dark" />
      <ChatNew />
      {/* First Page starts */}
      <OutSourcingComponent/>
      <br/>
      <br/>
      <br/>
      <CallToAction
        title="Efficient Outsourcing for Business Excellence"
        description="Boost your productivity and focus on core competencies by leveraging our outsourcing solutions. Our outsourcing services cover a wide range of business functions, including customer support, IT services, and back-office operations. With a global team of experts, we ensure cost-effective, high-quality services tailored to your specific needs. Our commitment to security, compliance, and innovation makes us the ideal partner for businesses looking to optimize resources and achieve operational excellence through strategic outsourcing."
        buttonTitle="Outsource with Confidence"
      />
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default Outsourcing;
