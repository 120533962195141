/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import Products from './Components/Products';
import techstephubMockup from '../../assets/salessyncx-mockup.svg';
import Chat from '../../Components/Chat';
import { Collapse } from 'antd';
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled
} from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';

const DealerPortal = () => {
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    document.title = 'Product | SaleSyncX';

    AOS.init();
  });

  const salesSyncXFAQ = [
    {
      key: '1',
      label: <h3>1. What is the purpose of the SalesSyncX application?</h3>,
      children: (
        <p>
          The SalesSyncX application serves as a dealer portal, streamlining the process of creating purchase orders and
          facilitating a three-stage approval workflow seamlessly integrated with Oracle ERP.
        </p>
      ),
    },
    {
      key: '2',
      label: <h3>2. How do I access the SalesSyncX dealer portal?</h3>,
      children: (
        <p>
          To access the SalesSyncX dealer portal, log in through the provided platform or visit our website. The portal
          enables dealers to efficiently create and manage purchase orders.
        </p>
      ),
    },
    {
      key: '3',
      label: <h3>3. What platforms does SalesSyncX support?</h3>,
      children: (
        <p>
          SalesSyncX is available for download on iOS and Android devices, ensuring flexibility and accessibility for dealers
          on the go.
        </p>
      ),
    },
    {
      key: '4',
      label: <h3>4. How does the three-stage approval process work in SalesSyncX?</h3>,
      children: (
        <p>
          SalesSyncX implements a three-stage approval workflow for purchase orders. This ensures that orders go through a
          thorough review and approval process before being integrated with Oracle ERP.
        </p>
      ),
    },
    {
      key: '5',
      label: <h3>5. Can I customize the approval workflow in SalesSyncX?</h3>,
      children: (
        <p>
          Yes, SalesSyncX provides flexibility in customizing the three-stage approval workflow to align with your business
          processes and hierarchy.
        </p>
      ),
    },
    {
      key: '6',
      label: <h3>6. Is SalesSyncX integrated with Oracle ERP?</h3>,
      children: (
        <p>
          Yes, SalesSyncX seamlessly integrates with Oracle ERP, ensuring accurate and efficient transfer of purchase order
          data for further processing.
        </p>
      ),
    },
    {
      key: '7',
      label: <h3>7. How secure is the data in SalesSyncX?</h3>,
      children: (
        <p>
          SalesSyncX prioritizes data security. All information is encrypted, and the application complies with
          industry-standard security practices, ensuring your data is stored securely and accessible only to authorized
          users.
        </p>
      ),
    },
    {
      key: '8',
      label: <h3>8. Can purchase orders be exported from SalesSyncX to Oracle ERP?</h3>,
      children: (
        <p>
          Absolutely. SalesSyncX facilitates seamless export of purchase orders to Oracle ERP, streamlining the data transfer
          and ensuring accuracy in the Oracle system.
        </p>
      ),
    },
    {
      key: '9',
      label: <h3>9. Is customer support available for SalesSyncX?</h3>,
      children: (
        <p>
          Yes, we offer dedicated customer support to assist dealers with any issues or questions they may have. Our support
          team is accessible through the portal or our website.
        </p>
      ),
    },
    {
      key: '10',
      label: <h3>10. Can SalesSyncX be accessed offline?</h3>,
      children: (
        <p>
          Yes, SalesSyncX supports offline functionality. Dealers can continue creating and managing purchase orders even
          when not connected to the internet, and the data will be synchronized once back online.
        </p>
      ),
    },
    {
      key: '11',
      label: <h3>11. How often are updates released for SalesSyncX?</h3>,
      children: (
        <p>
          We are committed to regular improvements. Updates with new features, enhancements, and bug fixes are released
          periodically to ensure SalesSyncX remains efficient and up-to-date.
        </p>
      ),
    },
    {
      key: '12',
      label: <h3>12. Are there any training resources available for using SalesSyncX?</h3>,
      children: (
        <p>
          Yes, we provide comprehensive training resources, including tutorials and documentation, to help dealers navigate
          and utilize the features of SalesSyncX effectively.
        </p>
      ),
    },
  ];
  
  

  return (
    <div>
      <Navbar mode="dark" />
      <Chat />
      <Products
        title="SaleSyncX"
        mockupSrc={techstephubMockup}
        mockupAlt="TechstepHub-Mockup"
        technologyUsed={['Angular ', 'Java ', 'Oracle ','Spring-Boot ']}
        ratingCount={5}
        reviewCount="131"
        videoUrl="https://www.youtube.com/watch?v=S8dSelVG1qY"
        width="100%"
        benefitTitle="Benefits of SaleSyncX"
        benefitArray1={[
          {
            icon: <TbSquareRoundedNumber1Filled className="num-icon" />,
            strongText: 'Streamlined Purchase Orders',
            pText:
              'Efficiently create and manage purchase orders through the SalesSyncX dealer portal, streamlining the procurement process.'
          },
          {
            icon: <TbSquareRoundedNumber2Filled className="num-icon" />,
            strongText: 'Three-Stage Approval Workflow',
            pText:
              'Implement a structured three-stage approval process for purchase orders, ensuring thorough review and compliance before integration with Oracle ERP.'
          },
          {
            icon: <TbSquareRoundedNumber3Filled className="num-icon" />,
            strongText: 'Integration with Oracle ERP',
            pText:
              'Seamlessly integrate purchase order data with Oracle ERP, enhancing accuracy and efficiency in the overall enterprise resource planning.'
          }
          // Add more benefits following the same structure
        ]}
        benefitArray2={[
          {
            icon: <TbSquareRoundedNumber4Filled className="num-icon" />,
            strongText: 'Data Security and Encryption',
            pText:
              'Prioritize data security with SalesSyncX, featuring encryption and adherence to industry-standard security practices to protect sensitive information.'
          },
          {
            icon: <TbSquareRoundedNumber5Filled className="num-icon" />,
            strongText: 'Offline Functionality',
            pText:
              'Access and manage purchase orders even in offline mode, ensuring uninterrupted workflow that synchronizes seamlessly when back online.'
          },
          {
            icon: <TbSquareRoundedNumber6Filled className="num-icon" />,
            strongText: 'Regular Updates and Support',
            pText:
              'Stay up-to-date with regular updates featuring new features, enhancements, and bug fixes, supported by a dedicated customer support team.'
          }
        ]}
      />
      <br />
      <br />
      <h1
        style={{
          textAlign: 'center'
        }}
      >
        FAQ
      </h1>
      <br />
      <Collapse
        style={{
          width: '90%',
          position: 'relative',
          left: '5%',
          backgroundColor: '#fff',
          marginBottom: '5em',
          color:"#343F52"
        }}
        items={salesSyncXFAQ}
        onChange={onChange}
        defaultActiveKey={['1']}
      />
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default DealerPortal;
