// /* eslint-disable react/jsx-no-target-blank */
// import { Button, Form, Input, message, Space } from 'antd';
// import React, { useEffect, useState } from 'react';
// import img from '../assets/network-robot-assistant-working-on-laptop.gif';
// import { LuFacebook, LuLinkedin } from 'react-icons/lu';
// import { TbBrandGmail } from 'react-icons/tb';
// import axios from 'axios';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import ReCAPTCHA from 'react-google-recaptcha';

// const Contactform = () => {
//   const [form] = Form.useForm();
//   const [verified, setVerified] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const { TextArea } = Input;
//   const onFinish = () => {
//     if (verified) {
//       // Proceed with form submission
//       setLoading(true);
//       console.log('Form submitted successfully');
//       axios
//         .post('https://focusrapi.focusrtech.com:85/post/enquery', {
//           full_Name: form.getFieldValue('FullName'),
//           email: form.getFieldValue('Email'),
//           about: form.getFieldValue('About')
//         })
//         .then((res) => {
//           console.log('Resposne after send Contact Form', res);
//           message.success(`Thanks For Contacting Us :) ${form.getFieldValue('FullName')}`);
//           form.setFieldValue('FullName', '');
//           form.setFieldValue('Email', '');
//           form.setFieldValue('About', '');
//           setLoading(false);
//         })
//         .catch((err) => {
//           message.success(`Error while submitting form ${err} \n Please Try again`);
//         });
//     } else {
//       // Show error message or prevent form submission
//       message.error(`Please verify reCAPTCHA`);
//     }
//     //http://172.235.10.116:8000/post/enquery
//   };
//   const onFinishFailed = () => {
//     message.error('Please Fill Required Fields :-(');
//   };
//   const onFill = () => {
//     form.setFieldsValue({
//       url: 'https://taobao.com/'
//     });
//   };
//   const onChange = (value) => {
//     // value will be null if reCAPTCHA was not verified
//     setVerified(value !== null);
//   };
//   useEffect(() => {
//     AOS.init();
//   });

//   return (
//     <div className="customInput-Whole-Wrapper">
//       <div className="form-right">
//         <h1>Let's chat. Tell us about your Project</h1>

//         <p>Let's create something together</p>

//         <button className="Mail-Btn">
//           Mail us <b>admin@focusrtech.com</b>
//         </button>
//         <div className="social-media">
//           <a href="https://www.linkedin.com/company/focusr-consultancy-and-technologies-pvt-ltd/" target="_blank">
//             <LuLinkedin />
//           </a>
//           <a href="https://www.facebook.com/focusrtechnologies/" target="_blank">
//             <LuFacebook />
//           </a>
//           <a href="mailto:info@focusrtech.com">
//             <TbBrandGmail />
//           </a>
//         </div>
//       </div>
//       <div className="form-left">
//         <h1
//           style={{
//             color: '#000'
//           }}
//         >
//           Build the next big with Us
//         </h1>
//         <br />
//         <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
//           <Form.Item
//             name="FullName"
//             label={
//               <p
//                 style={{
//                   color: '#000'
//                 }}
//               >
//                 Your Fullname
//               </p>
//             }
//             rules={[
//               {
//                 required: true
//               },
//               {
//                 type: 'string',
//                 min: 6
//               }
//             ]}
//           >
//             <Input
//               size="large"
//               style={{
//                 borderRadius: 0
//               }}
//             />
//           </Form.Item>

//           <Form.Item
//             name="Email"
//             label={
//               <p
//                 style={{
//                   color: '#000'
//                 }}
//               >
//                 Your email
//               </p>
//             }
//             rules={[
//               {
//                 required: true
//               },
//               {
//                 type: 'email'
//               }
//             ]}
//           >
//             <Input
//               size="large"
//               style={{
//                 borderRadius: 0
//               }}
//             />
//           </Form.Item>
//           <Form.Item
//             style={{
//               color: 'white'
//             }}
//             name="About"
//             label={
//               <p
//                 style={{
//                   color: '#000'
//                 }}
//               >
//                 Please tell us how we can assist you
//               </p>
//             }
//             rules={[
//               {
//                 required: true
//               },
//               {
//                 type: 'string',
//                 min: 6
//               }
//             ]}
//           >
//             <TextArea />
//           </Form.Item>

//           <Form.Item>
//             <ReCAPTCHA
//               sitekey="6LdWF7ApAAAAABLvVOKY0om3CK-cJwmSqbJqviPv"
//               //  sitekey="6LfrzqspAAAAAOjVDCQNqjM3hetPVPjrQwdH_o3b"
//               //sitekey="6LdJ66wpAAAAAA0csc40WCqkIurLkaiRxHbnqQY9"
//               // sitekey="6LdX_qwpAAAAANIk9jGIFk_obIVf-F-bvQ4aRTA_"
//               onChange={onChange}
//             />
//           </Form.Item>
//           <Form.Item>
//             <Space>
//               <Button
//                 size="large"
//                 type="primary"
//                 htmlType="submit"
//                 style={{
//                   borderRadius: '0px',
//                   background: '#2e5be0'
//                 }}
//                 loading={loading}
//               >
//                 {loading ? 'Loading' : 'Shoot Msg'}
//               </Button>
//             </Space>
//           </Form.Item>
//         </Form>
//       </div>
//     </div>
//   );
// };

// export default Contactform;

import { Button, Form, Input, message, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import img from '../assets/network-robot-assistant-working-on-laptop.gif';
import { LuFacebook, LuLinkedin } from 'react-icons/lu';
import { TbBrandGmail } from 'react-icons/tb';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReCAPTCHA from 'react-google-recaptcha';
 
 
const Contactform = () => {
  const [form] = Form.useForm();
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const { TextArea } = Input;
  const onFinish = () => {
    if (verified) {
      // Proceed with form submission
      setLoading(true);
      console.log('Form submitted successfully');
      axios
        .post('https://focusrapi.focusrtech.com:83/post/enquery', {
          full_Name: form.getFieldValue('FullName'),
          email: form.getFieldValue('Email'),
          about: form.getFieldValue('About'),
          type: "Contact-Us"
        })
        .then((res) => {
          console.log('Resposne after send Contact Form', res);
          message.success(`Thanks For Contacting Us :) ${form.getFieldValue('FullName')}`);
          form.setFieldValue('FullName', '');
          form.setFieldValue('Email', '');
          form.setFieldValue('About', '');
          setLoading(false);
        })
        .catch((err) => {
          message.success(`Error while submitting form ${err} \n Please Try again`);
        });
    } else {
      // Show error message or prevent form submission
      message.error(`Please verify reCAPTCHA`);
    }
    //http://172.235.10.116:8000/post/enquery
  };
  const onFinishFailed = () => {
    message.error('Please Fill Required Fields :-(');
  };
  const onFill = () => {
    form.setFieldsValue({
      url: 'https://taobao.com/'
    });
  };
  const onChange = (value) => {
    // value will be null if reCAPTCHA was not verified
    setVerified(value !== null);
  };
  useEffect(() => {
    AOS.init();
  });
 
  return (
    <div className="customInput-Whole-Wrapper">
      <div className="form-right">
        <h1>Let's chat. Tell us about your Project</h1>
 
        <p>Let's create something together</p>
 
        <button className="Mail-Btn">
          Mail us <b>info@focusrtech.com</b>
        </button>
        <div className="social-media">
          <a href="https://www.linkedin.com/company/focusr-consultancy-and-technologies-pvt-ltd/" target="_blank">
            <LuLinkedin />
          </a>
          <a href="https://www.facebook.com/focusrtechnologies/" target="_blank">
            <LuFacebook />
          </a>
          <a href="mailto:info@focusrtech.com">
            <TbBrandGmail />
          </a>
        </div>
      </div>
      <div className="form-left">
        <h1
          style={{
            color: '#000'
          }}
        >
          Build the next big with Us
        </h1>
        <br />
        <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
          <Form.Item
            name="FullName"
            label={
              <p
                style={{
                  color: '#000'
                }}
              >
                Your Fullname
              </p>
            }
            rules={[
              {
                required: true
              },
              {
                type: 'string',
                min: 6
              }
            ]}
          >
            <Input
              size="large"
              style={{
                borderRadius: 0
              }}
            />
          </Form.Item>
 
          <Form.Item
            name="Email"
            label={
              <p
                style={{
                  color: '#000'
                }}
              >
                Your email
              </p>
            }
            rules={[
              {
                required: true
              },
              {
                type: 'email'
              }
            ]}
          >
            <Input
              size="large"
              style={{
                borderRadius: 0
              }}
            />
          </Form.Item>
          <Form.Item
            style={{
              color: 'white'
            }}
            name="About"
            label={
              <p
                style={{
                  color: '#000'
                }}
              >
                Please tell us how we can assist you
              </p>
            }
            rules={[
              {
                required: true
              },
              {
                type: 'string',
                min: 6
              }
            ]}
          >
            <TextArea />
          </Form.Item>
 
          <Form.Item>
            <ReCAPTCHA
              sitekey="6LdWF7ApAAAAABLvVOKY0om3CK-cJwmSqbJqviPv"
              //  sitekey="6LfrzqspAAAAAOjVDCQNqjM3hetPVPjrQwdH_o3b"
              //sitekey="6LdJ66wpAAAAAA0csc40WCqkIurLkaiRxHbnqQY9"
              // sitekey="6LdX_qwpAAAAANIk9jGIFk_obIVf-F-bvQ4aRTA_"
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{
                  borderRadius: '0px',
                  background: '#2e5be0'
                }}
                loading={loading}
              >
                {loading ? 'Loading' : 'Shoot Msg'}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
 
export default Contactform;