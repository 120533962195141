/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
import ChatNew from "../../Components/Chat2";
import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const Consulting = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Oracle E-Business Suite (EBS) Training",
      description:
        "Our Oracle E-Business Suite training programs cover a broad spectrum of modules and functionalities, aiming to equip your team with in-depth knowledge and hands-on experience.",
      details: [
        "Module-specific Training: Detailed training sessions focused on specific modules like Financials, Human Capital Management (HCM), Supply Chain Management, and Project Management, ensuring your team is proficient in each area.",
        "Technical Training: Comprehensive training on EBS architecture, database integration, and application development. This includes advanced topics like PL/SQL, Oracle Forms and Reports, and Workflow.",
        "Functional Training: Workshops and training sessions focused on business processes and best practices for using EBS functionalities efficiently, enhancing your team’s ability to leverage Oracle EBS for operational excellence.",
        "Customization and Extensions: Training on building custom extensions, personalizations, and using Oracle Application Framework (OAF), enabling your team to tailor EBS applications to your specific business needs.",
        "Upgrades and Patching: Focused training on EBS upgrade methodologies, critical patch updates (CPUs), and patch management techniques to ensure your system remains current and compliant.",
      ],
    },
    {
      title: "Oracle Cloud (Fusion) Training",
      description:
        "Oracle Fusion Applications represent the next generation of Oracle's enterprise applications. Our training programs are designed to help your team transition smoothly and utilize Fusion's capabilities fully.",
      details: [
        "Fusion Financials: Deep dive into Oracle Fusion Financials, covering General Ledger, Accounts Payable, Accounts Receivable, Asset Management, and Cash Management modules, emphasizing scalability and streamlined financial operations.",
        "Fusion HCM: Intensive training on Oracle Fusion Human Capital Management, including Core HR, Talent Management, Compensation Management, and Workforce Management modules, optimized for modern HR practices.",
        "Fusion Procurement: Detailed training programs focused on Fusion Procurement modules, covering Sourcing, Supplier Management, Procurement Contracts, and Self Service Procurement, ensuring efficient procurement processes.",
        "Integration and SOA: Training on integrating Oracle Fusion Applications with other systems using Service-Oriented Architecture (SOA), including the use of Oracle Integration Cloud Service (OIC) for seamless data exchange.",
        "Governance, Risk, and Compliance (GRC): Training sessions on Oracle Fusion GRC solutions, focusing on risk management, compliance reporting, and internal controls to align with regulatory requirements.",
      ],
    },
    {
      title: "Technical Oracle Database Training",
      description:
        "Our Oracle Database training focuses on both foundational and advanced topics, ensuring your team is well-versed in managing, securing, and optimizing Oracle databases.",
      details: [
        "Database Administration: Comprehensive training on Oracle Database architecture, installation, configuration, and administration, covering Oracle RAC, Data Guard, and ASM.",
        "Performance Tuning: Advanced sessions on Oracle Database performance tuning, including query optimization, indexing strategies, and memory management to achieve optimal database performance.",
        "Backup and Recovery: Detailed training on Oracle RMAN, Data Pump, and other backup and recovery solutions to ensure data integrity and availability.",
        "Security: In-depth training on Oracle Database security features, including user management, roles and privileges, encryption, and auditing to safeguard data against threats.",
        "PL/SQL and Advanced SQL: Training on developing efficient PL/SQL procedures and functions, using advanced SQL techniques, and employing development tools like SQL*Plus and Oracle SQL Developer.",
      ],
    },
    {
      title: "Custom Application Development Training",
      description:
        "Our training programs for custom application development focus on leveraging Oracle's application development tools and platforms.",
      details: [
        "Oracle Application Express (APEX): Training on developing web applications using Oracle APEX, including topics like creating interactive reports, dynamic actions, and utilizing APEX APIs.",
        "Java and Oracle JET: Specialized training on building applications using Java and Oracle JavaScript Extension Toolkit (JET), focusing on RESTful web services and modern web application development.",
        "Oracle Mobile Hub: Training on developing and deploying mobile applications using Oracle Mobile Hub, covering aspects like mobile backend services, security, and analytics.",
        "Oracle Integration Cloud (OIC): Training on using OIC for developing integrations and workflows between various Oracle and third-party applications.",
        "API Development: Detailed sessions on developing, deploying, and managing APIs using Oracle API Gateway and Oracle API Manager.",
      ],
    },

    {
      title: "Oracle Analytics and BI Training",
      description:
        "Our Oracle Analytics and Business Intelligence (BI) training programs are designed to help your team leverage Oracle’s analytical tools to gain insights and drive data-driven decision-making.",
      details: [
        "Oracle Analytics Cloud (OAC): Training on OAC setup, data visualization, and analytic workflows, focusing on building dynamic, interactive dashboards and reports.",
        "OBIEE (Oracle Business Intelligence Enterprise Edition): Comprehensive training on OBIEE, including RPD modeling, report development, and dashboard design to deliver actionable insights.",
        "Oracle Data Integrator (ODI): In-depth sessions on using ODI for data integration, migration, and ETL processes, ensuring efficient data movement and transformation.F",
        "Big Data and Machine Learning: Training on Oracle Big Data Cloud Service and Oracle Machine Learning, emphasizing data processing, analysis, and developing machine learning models.",
        "Oracle Essbase: Sessions on using Oracle Essbase for multidimensional database management, covering data modeling, cube design, and advanced analytics for financial reporting and analysis.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      <ChatNew />
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              textAlign: "center",
              width: "100%",
            }}
            // data-aos="fade-right"
            // data-aos-delay="200"
          >
            Oracle Training and Education Services
          </h1>
          <p style={{ fontSize: "18px" }}>
            FocusR offers a suite of Oracle Training and Education Services
            designed to empower your team with the essential knowledge and
            skills for maximizing the benefits of Oracle technologies. From
            Oracle E-Business Suite and Cloud (Fusion) to Middleware, Cloud
            Infrastructure, ERP Cloud, and beyond, our tailored training
            programs ensure you can leverage Oracle's capabilities to their
            fullest potential. Whether through onsite sessions, remote
            workshops, or customized learning paths, our goal is to make your
            team proficient in using Oracle solutions to drive business success.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to Know More
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "16px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Consulting;
