/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
import ChatNew from "../../Components/Chat2";
import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const Consulting = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Bespoke Software Development for Oracle EBS and Fusion",
      description:
        "Our Oracle EBS and Fusion bespoke software development services cater to unique business requirements, ensuring tailored solutions for optimal efficiency.",
      details: [
        "Customized Module Creation: We develop tailor-made modules within Oracle EBS and Oracle Fusion applications, such as custom Financials, Supply Chain Management, and Human Resources modules, leveraging Oracle's flexible architecture.",
        "Advanced PL/SQL and Java Programming: Utilizing Oracle PL/SQL and Java for developing complex business logic and customized workflows to meet specific organizational needs.",
        "User Interface Enhancements: Implementing Oracle Application Express (APEX) and Oracle JET for creating intuitive and responsive user interfaces, enhancing user experience and productivity.",
        "Extension of Standard Functionality: Customizing Oracle AIM methodologies and Oracle Integration Cloud (OIC) for extending the core functionality of EBS and Fusion applications.",
      ],
    },
    {
      title: "APIs and Web Services Integration for Oracle EBS and Fusion",
      description:
        "We provide comprehensive API and web service integration services for seamless interoperability between Oracle EBS, Fusion applications, and external systems.",
      details: [
        "REST/SOAP Web Services: Developing and consuming RESTful and SOAP-based web services using Oracle's SOA Suite and Oracle Integration Cloud to enable seamless data exchange.",
        "API Management: Utilizing Oracle API Platform to design, prototype, and publish APIs securely, ensuring scalability and reliability.",
        "EDI Integration: Implementing Electronic Data Interchange (EDI) solutions for robust B2B integrations, adhering to industry standards and ensuring secure data transmission.",
        "Microservices Architecture: Designing microservices-based architectures with Oracle Kubernetes Engine (OKE) and Oracle Cloud Infrastructure (OCI), allowing modular and scalable integrations.",
      ],
    },
    {
      title: "Oracle EBS and Fusion Data Integrations",
      description:
        "Our data integration solutions ensure smooth and efficient data flow within Oracle EBS, Fusion applications, and other enterprise systems.",
      details: [
        "ETL Processes: Utilizing Oracle Data Integrator (ODI) for extracting, transforming, and loading data across heterogeneous environments, ensuring data consistency and integrity.",
        "Real-time Data Synchronization: Implementing Oracle GoldenGate for real-time data replication and synchronization between Oracle EBS, Fusion, and other databases.",
        "Data Warehousing: Designing and building comprehensive data warehousing solutions with Oracle Autonomous Data Warehouse, enhancing data analysis and reporting capabilities.",
        "Master Data Management (MDM): Establishing MDM solutions using Oracle Enterprise Data Management Cloud to ensure high-quality, consistent, and accurate master data across the organization.",
      ],
    },
    {
      title:
        "Custom Reporting and Business Intelligence Solutions for Oracle EBS and Fusion",
      description:
        "We create tailored reporting and BI solutions to provide actionable insights, enabling informed decision-making.",
      details: [
        "BI Publisher: Custom report development using Oracle BI Publisher, enabling the creation and distribution of pixel-perfect reports tailored to specific business requirements.",
        "Oracle Analytics Cloud: Implementing data visualization and advanced analytics solutions using Oracle Analytics Cloud, including dashboards, KPIs, and predictive analytics.",
        "OTBI (Oracle Transactional Business Intelligence): Creating custom Oracle OTBI reports to provide real-time insights directly from Oracle Fusion transactional data.",
        "Ad-hoc Reporting: Developing capabilities for ad-hoc reporting using Oracle Fusion Financial Reporting Studio and Oracle EBS Discoverer, allowing users to create, modify, and analyze reports on-the-fly.",
      ],
    },

    {
      title:
        "Enterprise Application Integration (EAI) with Oracle EBS and Fusion",
      description:
        "Our EAI solutions integrate Oracle EBS and Fusion with third-party applications, ensuring seamless operational workflows.",
      details: [
        "Middleware Solutions: Leveraging Oracle's SOA Suite and Oracle Integration Cloud Service to integrate on-premises and cloud applications, ensuring seamless communication between disparate systems.",
        "Business Process Management (BPM): Designing and implementing BPM solutions using Oracle BPM Suite to automate and optimize business processes, improving efficiency and compliance.",
        "ERP Integration: Seamlessly integrating Oracle EBS and Fusion with other ERP systems (e.g., SAP, Microsoft Dynamics) enabling unified business processes across platforms.",
        "Real-Time Event Processing: Utilizing Oracle Stream Analytics and Oracle Event-Driven Architecture (EDA) to process and analyze real-time event streams, providing immediate insights and actions.",
      ],
    },
    {
      title: "AI-Driven Process Automation for Oracle EBS and Fusion",
      description:
        "We leverage AI to automate complex processes within Oracle EBS and Fusion, enhancing operational efficiency and reducing manual intervention.",
      details: [
        "Robotic Process Automation (RPA): Utilizing Oracle Robotic Process Automation to automate repetitive tasks in Oracle EBS and Fusion, such as data entry, report generation, and order processing.",
        "Intelligent Workflow Automation: Implementing AI-driven workflow automation using Oracle Intelligent Process Automation (IPA) to streamline processes like invoice processing and customer service management.",
        "Natural Language Processing (NLP): Integrating NLP capabilities to automate and improve text-based processes, such as extracting data from unstructured documents and email triaging.",
        "AI-Powered Decision Support: Utilizing Oracle's AI and Machine Learning algorithms to develop decision-support systems that provide real-time recommendations and actionable insights for business users.",
      ],
    },
    {
      title: "Predictive Analytics for Oracle EBS and Fusion",
      description:
        "Our predictive analytics solutions harness ML algorithms to forecast trends and outcomes, empowering proactive decision-making.",
      details: [
        "Demand Forecasting: Building predictive models using Oracle Machine Learning (OML) to forecast demand in supply chain management, helping in inventory optimization and planning.",
        "Customer Behavior Analysis: Implementing ML models to analyze historical data from Oracle EBS and Fusion CRM applications, identifying patterns and predicting customer behavior and preferences.",
        "Predictive Maintenance: Developing predictive maintenance solutions utilizing IoT and ML for Oracle EBS and Fusion Asset Management, reducing equipment downtime and maintenance costs.",
        "Financial Forecasting: Leveraging ML algorithms to create financial forecasting models, predicting revenue, expenses, and cash flow to aid in strategic planning.",
      ],
    },
    {
      title:
        "AI-Powered Chatbots and Virtual Assistants for Oracle EBS and Fusion",
      description:
        "Our AI-powered chatbots and virtual assistants provide exceptional user experience by automating routine inquiries and tasks.",
      details: [
        "Oracle Digital Assistant: Deploying Oracle Digital Assistant to create intelligent chatbots that can handle user interactions within Oracle EBS and Fusion applications, such as querying financial data or updating employee records.",
        "Conversational Interfaces: Integrating conversational AI capabilities into Oracle HR, Sales, and Service modules, enabling users to interact through natural language queries and voice commands.",
        "Self-Service Portals: Developing self-service portals utilizing AI virtual assistants for tasks like expense reporting, leave management, and order status inquiries.",
        "Contextual Understanding: Implementing sophisticated NLP techniques for better contextual understanding and query resolution, improving the accuracy and relevance of responses provided by the AI assistants.",
      ],
    },
    {
      title: "Advanced Machine Learning Models for Oracle EBS and Fusion",
      description:
        "We develop advanced ML models tailored to optimize and enhance various aspects of Oracle EBS and Fusion applications.",
      details: [
        "Anomaly Detection: Using advanced ML models to detect anomalies in financial transactions and operational data, identifying potential frauds and compliance breaches in real-time.",
        "Personalized Recommendations: Implementing recommendation engines in Oracle Fusion Sales and Procurement to provide personalized product and supplier recommendations based on user behavior and historical data.",
        "Churn Prediction: Developing churn prediction models to identify potential customer and employee churn, allowing proactive strategies for retention in Oracle CRM and HCM systems.",
        "Dynamic Pricing Models: Utilizing ML models to create dynamic pricing strategies in Oracle EBS and Fusion Commerce, optimizing pricing based on demand, competition, and inventory levels.",
      ],
    },
    {
      title:
        "Large Language Models (LLMs) Integration with Oracle EBS and Fusion",
      description:
        "We integrate LLMs like GPT to enhance data analysis, reporting, and interaction capabilities within Oracle EBS and Fusion applications.",
      details: [
        "Automated Content Generation: Leveraging LLMs to generate automated content for reports, emails, and documentation within Oracle EBS Financial Management and HR modules.",
        "Advanced Data Analysis: Utilizing LLMs for sophisticated data analysis, extracting insights from vast amounts of unstructured data in Oracle Fusion Analytics, enhancing decision-making processes.",
        "Contextual Search and Query: Implementing LLM-powered search functionality for intuitive and context-aware querying across Oracle EBS and Fusion databases, improving user experience and efficiency.",
        "Document Summarization and Analysis: Using LLMs to summarize lengthy documents and analyze contracts and agreements within Oracle EBS and Fusion Legal and Contract Management systems, speeding up review processes.",
        "Intelligent Customer Interactions: Enhancing customer service interactions by integrating LLMs with Oracle Fusion Service Cloud, enabling more natural and context-aware conversations with customers.",
      ],
    },
  ];

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  return (
    <div>
      <Navbar mode="dark" />
      <ChatNew />
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "40px",
            }}
            // data-aos="fade-right"
            // data-aos-delay="200"
          >
            Custom Development and Integration Solutions for Oracle EBS and
            Fusion
          </h1>
          <p style={{ fontSize: "16px" }}>
            At FocusR, we offer a comprehensive suite of custom development and
            integration services to optimize Oracle E-Business Suite (EBS) and
            Oracle Fusion applications. Our services include bespoke software
            development, API and web services integration, data integration,
            custom reporting, and enterprise application integration. Leveraging
            cutting-edge AI, Machine Learning (ML), and Large Language Models
            (LLM), we enhance automation, predictive analytics, chatbots, and
            advanced ML models. This holistic approach ensures seamless
            interoperability, operational efficiency, and actionable insights,
            empowering your business to excel in a dynamic marketplace. Discover
            our tailored solutions to elevate your Oracle EBS and Fusion
            systems.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to Know More
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "13px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Consulting;
