import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import { useWindupString } from "windups";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const Optimization = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Performance Tuning for Oracle EBS and Fusion Applications",
      description: "SQL Query Optimization",

      details: [
        "Execution Plan Analysis: We use tools like SQL Tuning Advisor and SQL Plan Management to analyze and optimize SQL execution plans, ensuring minimal resource consumption and faster response times.",
        "Index Tuning: Implementing bitmap indexes, B-tree indexes, and partitioned indexes to expedite data retrieval processes.",
        "Bind Variables and Literals: Configuring bind variables to reduce parsing overhead and enhance query performance.",
        "Histograms and Statistics: Using DBMS_STATS to gather and manage table and index statistics to improve the optimizer’s decision-making.",
        "SQL Profiles: Creating and applying SQL profiles to influence the optimizer for more efficient query execution.",
      ],
      description1: "Database Indexing",
      details1: [
        "Index Monitoring: Continuously monitoring index usage with the V$OBJECT_USAGE view to identify and eliminate unused indexes that could consume resources.",
        "Partitioning Strategies: Implementing range, list, and hash partitioning to improve I/O performance and manage large datasets more efficiently.",
        "Rebuilding Indexes: Automating index rebuilds for fragmented indexes using Oracle’s DBMS_REDEFINITION package.",
        "Global vs. Local Indexes: Analyzing the use of global vs. local indexes in partitioned tables for improved query performance.",
        "Composite Indexes: Utilizing composite indexes to simultaneously index multiple columns, reducing the need for multiple single-column indexes",
      ],
      description2: "Workload Management",
      details2: [
        "Resource Manager Configuration: Utilizing Oracle Resource Manager to define and control workloads, ensuring equitable distribution of resources among sessions.",
        "Load Balancing: Configuring Oracle RAC (Real Application Clusters) for load distribution and improved scalability.",
        "Concurrency Controls: Implementing advanced queuing and job scheduling to manage high concurrent user workloads.",
        "Critical Session Management: Identifying and prioritizing critical sessions using Oracle's QOS (Quality of Service) Management.",
        "Dynamic Workload Management: Utilizing Oracle’s Automatic Workload Repository (AWR) to automatically adjust workload distributions based on demand",
      ],
      description3: "Application Server Configuration",
      details3: [
        "Thread Pool Tuning: Adjusting the thread pool settings of Oracle WebLogic Server to optimize resource utilization and response times.",
        "JVM Performance Tuning: Configuring Java Virtual Machine (JVM) parameters, including heap sizes and garbage collection strategies for performance improvements.",
        "Session Management: Optimizing session persistence and replication settings for Oracle Fusion Middleware to ensure session consistency and performance.",
        "Connection Pooling: Fine-tuning JDBC connection pooling to maintain high throughput and low latency.",
        "Cache Configurations: Tuning in-memory caches and implementing distributed caching strategies with Oracle Coherence.",
      ],
      description4: "Custom Code Review",
      details4: [
        "PL/SQL Optimization: Analyzing and optimizing PL/SQL code using PL/SQL Profiler and Hierarchical Profiler tools.",
        "API Performance: Reviewing and optimizing custom APIs and web services for efficient interaction with Oracle EBS and Fusion.",
        "Batch Job Optimization: Identifying and streamlining high-impact batch jobs to mitigate performance issues during peak loads.",
        "Code Refactoring: Conducting detailed code reviews to identify inefficient code segments and refactor them for better performance.",
        "Best Practices Implementation: Ensuring adherence to Oracle’s coding standards and best practices to maintain sustainable and high-performing custom code",
      ],
    },
    {
      title: "Capacity Planning for Oracle Environments",
      description: "Resource Utilization Analysis",
      details: [
        "CPU and Memory Profiling: Employing tools like ADDM (Automatic Database Diagnostic Monitor) and Oracle Enterprise Manager to profile and analyze CPU and memory usage.",
        "Storage Utilization Metrics: Using Oracle Cloud Infrastructure (OCI) metrics and AWR reports to monitor storage utilization across databases.",
        "Network Throughput Analysis: Monitoring network I/O performance using Oracle's V𝑆𝑌𝑆𝑆𝑇𝐴𝑇𝑎𝑛𝑑𝑉SYSSTATandVSYSTEM_EVENT views.",
        "Resource Bottleneck Identification: Identifying CPU, memory, and I/O bottlenecks using OEM's diagnostic features and proposing mitigation strategies.",
        "Resource Allocation Policies: Implementing resource consumer groups to allocate database resources based on business priorities",
      ],
      description1: "Predictive Modeling  ",
      details1: [
        "	Historical Data Analysis: Leveraging AWR and ASH reports to collect and analyze historical performance data for trend analysis.",
        " Machine Learning: Utilizing Oracle’s Machine Learning features to build predictive models for future capacity requirements.",
        "Scenario Simulation: Running what -if scenarios using Oracle’s holistic simulation tools to predict future resource needs.",
        "Scalability Metrics: Developing and analyzing key performance indicators (KPIs) such as transactions per second and response time latencies.",
        "Capacity Thresholds: Defining and configuring alerts for critical capacity thresholds to preemptively address resource limitations.",
      ],
      description2: "Scalability Testing",
      details2: [
        "Load Testing: Using Oracle Application Testing Suite (OATS) for comprehensive load testing and identifying scalability limits.",
        "Stress Testing: Conducting stress tests to understand the break points and failure modes of Oracle applications.",
        "Performance Benchmarks: Establishing performance benchmarks utilizing real-life usage patterns and synthetic loads.",
        "Vertical vs. Horizontal Scaling: Analyzing the impact of vertical (adding more resources to existing nodes) versus horizontal (adding more nodes) scaling.",
        "Hybrid Scaling: Implementing a hybrid scaling model that leverages both on-premises and cloud infrastructures to meet scaling needs",
      ],
      description3: "Infrastructure Assessment",
      details3: [
        "Hardware Adequacy: Reviewing server hardware configurations to ensure they meet Oracle’s recommended specifications for CPU, memory, and storage.",
        "Network Latency Analysis: Assessing network configurations and implementing optimizations to reduce latency and improve data transfer speeds.",
        "Storage Solutions: Recommending advanced storage solutions such as Oracle Exadata for high-performance and high-availability requirements.",
        "Cloud Integration: Evaluating existing infrastructure for cloud compatibility and recommending hybrid or full-cloud deployments.",
        "Future-proofing: Proposing hardware and software investments to ensure infrastructure scalability in line with business growth projections.",
      ],
      description4: "Cloud Readiness",
      details4: [
        "Cloud Suitability Assessment: Evaluating applications and databases for their suitability to migrate to Oracle Cloud Infrastructure (OCI).",
        "Cloud Architecture Design: Designing cloud architectures that leverage OCI’s flexible compute, storage, and networking options.",
        "Migration Strategy: Developing a comprehensive cloud migration strategy, including phase-wise implementation and rollback plans.",
        "Security & Compliance: Ensuring robust security and compliance measures are in place for cloud-based Oracle deployments.",
        "Performance Optimization in Cloud: Fine-tuning cloud resources and services, including OCI’s Autonomous Database capabilities, for optimal performance.",
      ],
    },
    {
      title: "Architectural Reviews for Oracle Solutions",
      description: "System Design Evaluation",
      details: [
        "Architecture Compliance: Reviewing current system architecture for compliance with Oracle's Reference Architecture guidelines.",
        "Tier Structure: Evaluating the efficiency of multi-tier application architecture and making recommendations for improvements.",
        "Integration Layers: Assessing middleware and integration layers (like Oracle Fusion Middleware) for scalability and performance.",
        "Component Analysis: Conducting a detailed analysis of each architectural component (database, application, middleware) for optimization.",
        "Data Flow Optimization: Analyzing data flow and process patterns to enhance performance and reduce latency.",
      ],
      description1: "Best Practices Implementation",
      details1: [
        "SOA Integration: Implementing Service-Oriented Architecture (SOA) principles to promote loose coupling and reusability of services within Oracle EBS and Fusion.",
        "Microservices Adoption: Advising on the adoption of microservices architecture where applicable for improved scalability and maintenance.",
        "High-Performance Design Patterns: Utilizing proven design patterns like CQRS (Command Query Responsibility Segregation) and Event Sourcing for better performance.",
        "Enterprise Architecture: Aligning IT architecture with business objectives using Oracle Enterprise Architecture Framework (OEAF) methodologies.",
        "Continuous Improvement: Implementing a continuous improvement cycle to regularly update and optimize the architectural design based on feedback and performance metrics",
      ],
      description2: "Redundancy and High Availability",
      details2: [
        "Data Guard Setup: Implementing Oracle Data Guard for disaster recovery and high availability with minimal data loss.",
        "Oracle RAC Configuration: Configuring Oracle Real Application Clusters (RAC) for high availability and scalability across multiple nodes.",
        "Backup and Recovery Plans: Developing robust backup and recovery strategies, including RMAN configurations and snapshots.",
        "Failover Mechanisms: Designing and implementing automatic failover mechanisms to switch operations to standby databases with minimal downtime.",
        "Geographical Redundancy: Implementing multi-region failover strategies to ensure business continuity across different geographical locations",
      ],
      description3: "Integration Architecture",
      details3: [
        "Connector Optimization: Using and optimizing Oracle Integration Cloud (OIC) and Oracle SOA Suite for seamless data flow across enterprise systems.",
        "API Gateway Configuration: Configuring Oracle API Gateway for efficient and secure management of API traffic.",
        "Middleware Performance: Optimizing Oracle Middleware components, including WebLogic and SOA, for better integration performance.",
        "Data Synchronization: Implementing efficient data synchronization techniques using Oracle GoldenGate.",
        "Message Queuing: Utilizing Oracle Advanced Queuing (AQ) for reliable message delivery and asynchronous processing",
      ],
      description4: "Security Architecture",
      details4: [
        "Encryption Practices: Implementing advanced encryption techniques such as Transparent Data Encryption (TDE) and SSL/TLS for secure data communication.",
        "Identity Management: Leveraging Oracle Identity Management Suite for robust access controls, single sign-on (SSO), and multi-factor authentication (MFA).",
        "Security Policies: Establishing and enforcing security policies using Oracle Database Vault and Oracle Advanced Security.",
        "Compliance Audits: Conducting regular compliance audits to ensure adherence to standards like GDPR, HIPAA, and PCI-DSS.",
        "Vulnerability Management: Implementing a proactive vulnerability management practice using Oracle’s Critical Patch Updates (CPU) and third-party tools",
      ],
    },
    {
      title: "Load Balancing and Application Clustering",
      description: "Load Balancer Configuration",
      details: [
        "Algorithm Selection: Configuring load balancers with algorithms like round-robin, least connections, and IP hash for optimal load distribution.",
        "Health Checks: Implementing TCP, HTTP, and custom health checks to ensure only healthy instances receive traffic.",
        "SSL Offloading: Configuring SSL offloading on load balancers to reduce the SSL processing burden on backend servers.",
        "Layer 4 and Layer 7 Load Balancing: Utilizing both network-level (L4) and application-level (L7) load balancing strategies for improved efficiency.",
        "Traffic Shaping: Implementing traffic shaping policies to prioritize certain types of traffic and ensure quality of service.",
      ],
      description1: "Cluster Management",
      // description:
      //     "Load Balancer Configuration",
      details1: [
        "Oracle RAC Configuration: Setting up and fine-tuning Oracle Real Application Clusters (RAC) for high availability and load balancing.",
        "WebLogic Server Clustering: Configuring Oracle WebLogic Server clusters to distribute application loads and provide failover capabilities.",
        "Failover Strategy: Designing and implementing failover strategies to minimize service disruptions.",
        "Cluster Health Monitoring: Utilizing Oracle Enterprise Manager Cluster Health Monitor (CHM) for real-time insights into cluster performance and health.",
        "Dynamic Scaling: Implementing dynamic scaling mechanisms to add or remove nodes based on current load conditions.",
      ],
      description2: "Disaster Recovery Planning",
      // description:
      //     "Load Balancer Configuration",
      details2: [
        "Active-Active Failover: Implementing active-active failover strategies for zero-downtime recovery using Oracle RAC and Data Guard.",
        "Data Replication: Using Oracle’s native data replication tools like Oracle GoldenGate for real-time data replication across different geographical locations.",
        "Backup Strategies: Incorporating comprehensive backup strategies, including periodic snapshots and incremental backups.",
        "Recovery Time Objectives (RTO): Defining and meeting stringent RTOs to ensure minimal service disruption.",
        "Periodic Drills: Conducting periodic disaster recovery drills to ensure the effectiveness of the failover mechanisms.",
      ],
      description3: "Network Optimization",
      details3: [
        "Latency Reduction: Implementing advanced network routing and latency reduction techniques using Virtual Cloud Network (VCN) and FastConnect in OCI.",
        "Bandwidth Allocation: Allocating sufficient bandwidth to critical applications to avoid network congestion.",
        "Network Segmentation: Segmenting network traffic using VLANs and subnets for better security and performance.",
        "Quality of Service (QoS): Establishing QoS policies to prioritize critical traffic and manage network resources effectively.",
        "Content Delivery Network (CDN): Using Oracle CDN services to reduce latency and load times for global users",
      ],
      description4: "Load Balancer Configuration",
      details4: [
        "Algorithm Selection: Configuring load balancers with algorithms like round-robin, least connections, and IP hash for optimal load distribution.",
        "Health Checks: Implementing TCP, HTTP, and custom health checks to ensure only healthy instances receive traffic.",
        "SSL Offloading: Configuring SSL offloading on load balancers to reduce the SSL processing burden on backend servers.",
        "Layer 4 and Layer 7 Load Balancing: Utilizing both network-level (L4) and application-level (L7) load balancing strategies for improved efficiency.",
        "Traffic Shaping: Implementing traffic shaping policies to prioritize certain types of traffic and ensure quality of service.",
      ],
    },
    {
      title: "Monitoring and Diagnostics for Oracle EBS and Fusion",
      description: "Real-time Monitoring",
      details: [
        "Telemetry Setup: Setting up comprehensive telemetry for monitoring key performance metrics using Oracle Management Cloud (OMC) and Oracle Enterprise Manager (OEM).",
        "Dashboard Configurations: Creating custom dashboards to visualize performance metrics in real-time.",
        "Advanced Alerting: Configuring advanced alerts and notifications for critical performance metrics using Oracle’s built-in tools.",
        "End-User Monitoring: Implementing Real User Experience Insight (RUEI) for monitoring end-user experience and pinpointing performance issues.",
        "Log Analysis: Utilizing Oracle Log Analytics for real-time log monitoring and anomaly detection",
      ],
      description1: "Performance Baselines",
      details1: [
        "Baseline Creation: Establishing performance baselines using AWR snapshots and ASH reports.",
        "Deviation Analysis: Identifying deviations from established baselines and implementing corrective measures.",
        "Trend Analysis: Conducting trend analysis to understand performance variations over time.",
        "Capacity Benchmarking: Using baseline data for capacity planning and benchmarking.",
        "Continuous Improvement: Implementing a continuous improvement cycle to regularly update and optimize performance baselines based on trends and deviations.",
      ],
      description2: "Diagnostic Tools",
      details2: [
        "OEM Diagnostic Packs: Utilizing Oracle Enterprise Manager's diagnostic packs (AWR, ASH, ADDM) for in-depth performance diagnostics.",
        "SQL Monitoring: Monitoring SQL performance using Oracle's SQL Monitor and SQL Trace.",
        "Database Diagnostics: Using Data Dictionary views and dynamic performance views (V$ views) for low-level database diagnostics.",
        "Middleware Diagnostics: Employing Oracle Fusion Middleware Control and DMS (Dynamic Monitoring Service) for in-depth middleware diagnostics.",
        "Application Diagnostics: Implementing Oracle Application Performance Monitoring (APM) for end-to-end application performance visibility",
      ],
      description3: "Automated Alerts",
      details3: [
        "Custom Alert Policies: Defining custom alert policies for specific performance and health metrics.",
        "Escalation Mechanisms: Implementing multi-level escalation mechanisms for critical alerts.",
        "Integration with ITSM Tools: Integrating alerts with IT Service Management (ITSM) tools like ServiceNow for streamlined incident management.",
        "Threshold Configuration: Setting and adjusting thresholds for various metrics to trigger alerts appropriately.",
        "Alert Automation: Utilizing Oracle Cloud's event-driven automation capabilities to act on alerts automatically through pre-defined response actions",
      ],
      description4: "Regular Health Checks",
      details4: [
        "Health Check Scripts: Using Oracle’s SQL scripts and built-in utilities for periodic health checks.",
        "Scheduled Audits: Scheduling regular audits using Oracle Enterprise Manager's Health Checks to proactively identify potential issues.",
        "Compliance Monitoring: Continuously monitoring for compliance with internal and regulatory standards.",
        "Capacity Health Checks: Combining health checks with capacity assessments to ensure resource adequacy.",
        "Performance Reviews: Conducting regular performance reviews and making optimization recommendations based on health check findings.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
            }}
          >
            Performance Optimization and Scalability for Oracle EBS and Fusion
          </h1>
          <p>
            FocusR ensures your Oracle EBS and Fusion applications achieve peak
            performance and scalability. Through advanced tuning, precise
            capacity planning, architectural reviews, robust load balancing, and
            continuous monitoring, our expert services enhance efficiency and
            reliability. We leverage best practices and tools to meet the
            growing demands of your business seamlessly
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <h3 style={{ marginLeft: "1.6em" }}>{section.description}</h3>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
              <h3 style={{ marginLeft: "1.6em", marginTop: "1em" }}>
                {section.description1}
              </h3>
              {Array.isArray(section.details1) &&
                section.details1.length > 0 && (
                  <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                    {section.details1.map((detail, detailIndex) => (
                      <li key={detailIndex} style={{ padding: "0.5em" }}>
                        {detail.includes(":") ? (
                          <span>
                            <strong>{detail.split(":")[0]}</strong>:{" "}
                            {detail.split(":")[1]}
                          </span>
                        ) : (
                          detail
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              <h3 style={{ marginLeft: "1.6em", marginTop: "1em" }}>
                {section.description2}
              </h3>
              {Array.isArray(section.details2) &&
                section.details2.length > 0 && (
                  <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                    {section.details2.map((detail, detailIndex) => (
                      <li key={detailIndex} style={{ padding: "0.5em" }}>
                        {detail.includes(":") ? (
                          <span>
                            <strong>{detail.split(":")[0]}</strong>:{" "}
                            {detail.split(":")[1]}
                          </span>
                        ) : (
                          detail
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              <h3 style={{ marginLeft: "1.6em", marginTop: "1em" }}>
                {section.description3}
              </h3>
              {Array.isArray(section.details3) &&
                section.details3.length > 0 && (
                  <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                    {section.details3.map((detail, detailIndex) => (
                      <li key={detailIndex} style={{ padding: "0.5em" }}>
                        {detail.includes(":") ? (
                          <span>
                            <strong>{detail.split(":")[0]}</strong>:{" "}
                            {detail.split(":")[1]}
                          </span>
                        ) : (
                          detail
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              <h3 style={{ marginLeft: "1.6em", marginTop: "1em" }}>
                {section.description4}
              </h3>
              {Array.isArray(section.details4) &&
                section.details4.length > 0 && (
                  <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                    {section.details4.map((detail, detailIndex) => (
                      <li key={detailIndex} style={{ padding: "0.5em" }}>
                        {detail.includes(":") ? (
                          <span>
                            <strong>{detail.split(":")[0]}</strong>:{" "}
                            {detail.split(":")[1]}
                          </span>
                        ) : (
                          detail
                        )}
                      </li>
                    ))}
                  </ul>
                )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Optimization;
