import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'


const CustomDevelopment = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Inventory Management Integration",
      description:
        "Our barcode automation solutions seamlessly integrate with Oracle E-Business Suite (EBS) and Oracle Fusion environments to enhance inventory management processes. By incorporating barcode scanning capabilities:",

      details: [
        "Real-time Data Sync: Ensure real-time synchronization of inventory data between barcode devices and Oracle systems, enabling immediate updates and minimizing discrepancies.",
        "Automatic Replenishment: Utilize barcode data to automate inventory replenishment processes within Oracle EBS and Fusion, reducing stockouts and overstock situations.",
        "Smart Reporting: Enable advanced, real-time reporting on inventory levels, turnover rates, and order histories directly within Oracle Fusion dashboards for strategic decision-making.",
      ],
    },
    {
      title: "Asset Tracking Optimization",
      description:
        "Our barcode automation solutions provide unparalleled precision in asset tracking within Oracle EBS and Oracle Fusion environments. Key features include:",
      details: [
        "Accurate Asset Identification: Implement barcode tagging for assets, ensuring precise identification and tracking throughout their lifecycle.",
        "Lifecycle Management: Integrate with Oracle EBS and Fusion for comprehensive asset lifecycle management, covering acquisition, maintenance, and disposal phases.",
        "Maintenance Scheduling: Use barcode data to streamline maintenance scheduling and tracking within Oracle Fusion, optimizing asset uptime and reducing operational disruptions.",
      ],
    },
    {
      title: "Supply Chain Efficiency",
      description:
        "Leveraging barcode technology within Oracle environments significantly boosts supply chain efficiency. Core functionalities encompass:",
      details: [
        "Streamlined Receiving and Shipping: Enhance the speed and accuracy of receiving and shipping processes by integrating barcode scanning with Oracle EBS and Fusion.",
        "Dynamic Inventory Allocation: Utilize real-time barcode data to dynamically allocate inventory across multiple locations managed by Oracle Fusion, improving fulfillment accuracy and customer satisfaction.",
        "Automated Compliance: Ensure supply chain compliance with industries and regulatory standards by automating traceability requirements through barcode solutions in Oracle EBS and Fusion.",
      ],
    },
    {
      title: "Enhanced Warehouse Operations",
      description:
        "Our barcode solutions revolutionize warehouse operations by integrating seamlessly with Oracle EBS and Fusion. Advantages include:",
      details: [
        "Optimized Picking and Put-away: Enable efficient picking and put-away processes using barcode technology, reducing errors and increasing throughput in Oracle-managed warehouses.",
        "Real-time Inventory Adjustments: Perform real-time inventory adjustments through barcode scans, immediately reflected in Oracle EBS and Fusion systems, ensuring accurate stock levels.",
        "Warehouse Performance Metrics: Gather and analyze performance metrics from warehouse operations within Oracle Fusion, driving continuous improvement initiatives and operational excellence.",
      ],
    },

    {
      title: "Quality Control and Compliance",
      description:
        "Incorporating barcode automation into Oracle environments elevates quality control and compliance standards:",
      details: [
        "Precision Quality Checks: Conduct precise and efficient quality checks using barcode scanning, integrated seamlessly with Oracle EBS and Fusion quality management modules.",
        "Audit Trails: Maintain stringent audit trails and histories of quality inspections and batch releases within Oracle environments, fulfilling regulatory and compliance requirements.",
        "Real-time Quality Alerts: Utilize barcode data to trigger real-time quality alerts and actions within Oracle Fusion, ensuring immediate response to potential quality issues and minimizing risk.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "50px",
            }}
          >
            Automation Solutions - Barcode
          </h1>
          <p style={{ fontSize: "158x" }}>
            Focusr embeds advanced barcode automation within Oracle E-Business
            Suite (EBS) and Oracle Fusion. Our services cover inventory
            management, asset tracking, supply chain efficiency, and more for
            real-time data sync and operational precision. Leveraging mobile
            solutions, productivity tools, predictive analytics, and enhanced
            customer service, we provide comprehensive, data-driven insights to
            streamline processes and drive business success.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>
            By leveraging our barcode automation solutions within Oracle
            E-Business Suite and Oracle Fusion environments, Focusr delivers
            unparalleled technical capabilities to streamline operations, ensure
            data accuracy, and enhance overall business efficiency.
          </p>
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CustomDevelopment;
