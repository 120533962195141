import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const OnPremise = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Hybrid Cloud Integration",
      description:
        "Seamlessly integrating cloud and on-premise systems to ensure operational efficiency and data consistency:",

      details: [
        "Oracle Integration Cloud (OIC): Utilize OIC to create seamless integrations between on-premise Oracle E-Business Suite (EBS) and Oracle Fusion Cloud applications. Implement pre-built adapters and recipes to streamline integrations and ensure reliable data synchronization.",
        "Secure Connectivity with FastConnect: Establish secure, high- bandwidth connections between on - premise data centers and Oracle Cloud Infrastructure(OCI) using Oracle FastConnect.This reduces latency and enhances data transfer speeds, ensuring seamless hybrid cloud operations.",
        "Data Sync with GoldenGate: Deploy Oracle GoldenGate for real-time data replication and synchronization between on-premise EBS and cloud-based Fusion environments, ensuring data consistency and availability across hybrid architectures.",
        "Unified Management with Oracle Enterprise Manager (OEM): Use OEM for integrated monitoring and management of hybrid environments, providing a single console to manage both on-premise and cloud resources effectively.",
        "Hybrid Data Integration: Implement Oracle Data Integrator (ODI) for hybrid data integration, facilitating complex ETL processes that move and transform data between on-premise and cloud systems",
      ],
    },
    {
      title: "Cloud-Native Development and Deployment ",
      description:
        "Leveraging cloud-native technologies to enhance application development and deployment:",
      details: [
        "Oracle Container Engine for Kubernetes (OKE): Use OKE for deploying and managing containerized applications in the cloud. This includes orchestrating microservices and ensuring scalability and high availability of Oracle applications.",
        "Oracle Functions: Implement Oracle Functions for serverless computing, enabling developers to run code without managing servers and simplifying the deployment of scalable, event-driven applications within Oracle Cloud.",
        "Oracle Autonomous Services: Utilize Oracle Autonomous Database and Oracle Autonomous Data Warehouse for self-managing, self-securing, and self-repairing database solutions. These services ensure optimal performance and ease of management for both on-premise and cloud deployments.",
        "DevOps with Oracle Developer Cloud Service: Leverage Oracle Developer Cloud Service for continuous integration and continuous delivery (CI/CD) pipelines, automating the build, test, and deployment processes for Oracle applications, enhancing development efficiency and reducing time-to-market.",
        "Custom PaaS Extensions: Develop and deploy custom applications on Oracle Cloud Platform-as-a-Service (PaaS) to extend the functionalities of Oracle Fusion applications. Use Oracle Visual Builder for rapid development of web and mobile applications.",
      ],
    },
    {
      title: "On-Premise Infrastructure Optimization ",
      description:
        "Optimizing on-premise environments to maximize performance and control:",
      details: [
        "Oracle Exadata: Deploy Oracle Exadata Database Machine to achieve extreme performance and consolidation for Oracle databases. Use Exadata’s optimized hardware and software to run EBS and Fusion applications with superior performance and reliability.",
        "Oracle RAC (Real Application Clusters): Implement Oracle RAC for high availability and scalability of on-premise databases, ensuring continuous uptime and effective load balancing for mission-critical Oracle applications.",
        "Storage Solutions with Oracle ZFS: Utilize Oracle ZFS Storage Appliance for high-performance, scalable storage solutions that integrate seamlessly with Oracle databases and applications, optimizing storage efficiency and data access speeds.",
        "Performance Monitoring and Tuning: Apply Oracle Enterprise Manager (OEM) and Automatic Workload Repository (AWR) to monitor, analyze, and tune the performance of on-premise systems, ensuring optimal resource utilization and response times.",
        "Disaster Recovery Planning: Implement robust disaster recovery strategies using Oracle Data Guard to ensure data protection and business continuity for on-premise deployments.",
      ],
    },
    {
      title: "Security and Compliance",
      description:
        "Ensuring robust security and compliance for both cloud and on-premise environments:",
      details: [
        "Identity and Access Management (IAM): Utilize Oracle Identity Cloud Service (IDCS) for unified identity and access management across cloud and on-premise applications. Implement secure Single Sign-On (SSO) and Multi-Factor Authentication (MFA) to protect enterprise resources.",
        "Data Encryption and Masking: Implement Oracle Advanced Security options like Transparent Data Encryption (TDE) and Oracle Database Vault to protect sensitive data in transit and at rest across both cloud and on-premise environments.",
        "Audit and Monitoring: Deploy Oracle Audit Vault and Database Firewall (AVDF) to monitor and log database activities, ensuring compliance with regulatory standards such as GDPR, SOX, and HIPAA for hybrid environments.",
        "Security Patching and Updates: Automate security patching and updates using Oracle Cloud Infrastructure (OCI) tools for cloud deployments and Oracle Enterprise Manager (OEM) for on-premise systems, ensuring both environments are protected against vulnerabilities.",
        "Compliance Management: Utilize Oracle GRC (Governance, Risk, and Compliance) Cloud to implement compliance management frameworks, automating risk assessments and generating compliance reports for both cloud and on-premise systems.",
      ],
    },

    {
      title: "Scalability and Performance Tuning",
      description:
        "Ensuring scalable and high-performance environments tailored to business needs:",
      details: [
        "Dynamic Scaling in Oracle Cloud: Leverage Oracle Cloud Infrastructure’s (OCI) auto-scaling capabilities to automatically adjust compute resources based on application load, ensuring cost-effective scaling and performance optimization for cloud-based Oracle applications.",
        "Partitioning and Data Sharding: Implement Oracle Database partitioning and sharding techniques to enhance query performance and manage large datasets efficiently in both on-premise and cloud environments.",
        "Performance Tuning with Oracle AWR and ASH: Use Automatic Workload Repository (AWR) and Active Session History (ASH) to analyze and tune database performance, identifying and resolving performance bottlenecks in hybrid architectures.",
        "Load Balancing with Oracle Traffic Director: Deploy Oracle Traffic Director to distribute incoming application traffic across multiple servers, ensuring high availability and improved performance for both cloud and on-premise applications.",
        "Enhanced Network Performance with OCI FastConnect: Optimize network performance by using OCI FastConnect for dedicated, high-speed connections between on-premise data centers and Oracle Cloud, reducing latency and ensuring smooth data flow.",
      ],
    },
    {
      title: "Disaster Recovery and Business Continuity",
      description:
        "Implementing robust strategies to ensure data protection and business continuity:",
      details: [
        "Oracle Data Guard: Utilize Oracle Data Guard for creating standby databases and enabling disaster recovery for both on-premise and cloud environments. This ensures high availability and data protection, allowing for seamless failover in case of primary database failure.",
        "Oracle Cloud Disaster Recovery (DRaaS): Implement Oracle Cloud Disaster Recovery as a Service (DRaaS) for automated disaster recovery solutions. This provides a comprehensive DR strategy that includes data replication, automated failover and failback processes for Oracle EBS and Fusion applications.",
        "Backup and Recovery Solutions: Use Oracle Recovery Manager (RMAN) and Oracle Cloud Infrastructure (OCI) Backup to automate database backups and recoveries. Ensure that critical data is regularly backed up and easily restorable in both on-premise and cloud environments.",
        "High Availability Architectures: Design high availability architectures using Oracle Real Application Clusters (RAC) and Oracle Maximum Availability Architecture (MAA) principles. This enables seamless continuity for mission-critical applications in the event of failures.",
        "Failover Testing and Drills: Regularly perform failover testing and disaster recovery drills to ensure that DR plans are effective and that teams are prepared to respond swiftly and efficiently in the event of a disaster",
      ],
    },
    {
      title: "DevOps and Continuous Integration/Continuous Deployment (CI/CD)",
      description:
        "Enhancing development efficiency through DevOps best practices and CI/CD:",
      details: [
        "Oracle Developer Cloud Service: Leverage Oracle Developer Cloud Service to create CI/CD pipelines automating the build, testing, and deployment processes for Oracle applications. This helps in rapidly delivering software updates while maintaining high quality.",
        "Infrastructure as Code (IaC): Implement Infrastructure as Code using Oracle Resource Manager and Terraform to automate the provisioning and management of cloud resources, ensuring consistent and repeatable environment setups.",
        "Version Control with Git: Use version control systems like Git integrated with Oracle Developer Cloud Service to manage and track code changes. Implement branching and merging strategies to support parallel development and ensure code quality.",
        "Automated Testing: Integrate automated testing tools such as Selenium and Oracle Application Testing Suite (OATS) into the CI/CD pipeline. This ensures that every code change is rigorously tested before deployment, reducing defects and improving reliability.",
        "Monitoring and Log Management: Implement application performance monitoring and log management solutions using Oracle Management Cloud (OMC). This provides real-time insights into application performance and helps in quickly identifying and resolving issues.",
      ],
    },
    {
      title: " Advanced Analytics and Machine Learning",
      description:
        "Leveraging analytics and machine learning to drive business insights and performance:",
      details: [
        "Oracle Analytics Cloud (OAC): Implement Oracle Analytics Cloud to provide advanced data visualization, analysis, and interactive dashboards. Integrate data from both on-premise Oracle EBS and cloud-based Fusion applications to deliver comprehensive insights.",
        "Oracle Machine Learning (OML): Utilize Oracle Machine Learning capabilities to build and deploy machine learning models directly within Oracle Autonomous Database. This enables predictive analytics and advanced data modeling for use across Oracle applications.",
        "Data Integration with Oracle Data Flow: Use Oracle Data Flow to create and manage Apache Spark-based data processing applications. This facilitates data preparation and transformation for analytics, leveraging both cloud and on-premise data sources.",
        "Real-Time Analytics with Oracle Stream Analytics: Deploy Oracle Stream Analytics to process and analyze real-time streaming data. Integrate data streams from IoT devices, social media, and transactional systems to deliver timely insights and actions.",
        "Big Data Solutions with Oracle Big Data Service: Implement Oracle Big Data Service to manage and analyze large data sets. This includes integrating big data sources with Oracle EBS and Fusion applications for holistic business intelligence.",
      ],
    },
    {
      title: "Cost Management and Optimization",
      description:
        "Efficiently managing and optimizing costs across cloud and on-premise environments:",
      details: [
        "•	Cloud Cost Management with OCI: Leverage Oracle Cloud Infrastructure (OCI) tools for monitoring and managing cloud costs. Implement resource tagging, budgeting, and cost analysis features to optimize cloud spending and avoid budget overruns.",
        "Right-Sizing Infrastructure: Continuously evaluate and right-size cloud and on-premise infrastructure to match the actual workload requirements. This includes scaling down unused resources and optimizing resource allocation for cost efficiency.",
        "Reserved Instances and Savings Plans: Utilize OCI Reserved Instances and Savings Plans to benefit from significant cost savings on long-term cloud commitments. This helps in reducing the operational expenditure while meeting performance needs.",
        "Cost Allocation and Chargeback: Implement cost allocation and chargeback mechanisms using Oracle Fusion Financials to accurately track and allocate costs to different departments, business units, or projects. This ensures transparent cost management and accountability.",
        "Performance vs. Cost Analysis: Perform regular analysis to balance performance requirements with cost considerations. Use Oracle Enterprise Manager (OEM) and Oracle Analytics Cloud (OAC) to assess performance metrics and align them with cost optimization strategies.",
      ],
    },
    {
      title: "Compliance and Regulatory Adherence",
      description:
        "Ensuring that both cloud and on-premise solutions adhere to regulatory requirements:",
      details: [
        "Oracle Governance, Risk, and Compliance (GRC) Cloud: Implement Oracle GRC Cloud to manage and automate compliance processes, risk assessments, and audits. This supports adherence to regulatory standards such as GDPR, SOX, HIPAA, and PCI-DSS.",
        "Data Privacy and Protection: Utilize Oracle Advanced Security features to implement data privacy measures such as encryption, data masking, and user access controls, ensuring sensitive information is protected in compliance with regulatory mandates.",
        "Automated Compliance Reporting: Deploy automated compliance reporting tools using Oracle BI Publisher and Oracle Analytics Cloud to generate real-time compliance reports, ensuring timely and accurate reporting for regulatory authorities.",
        "Audit Trails and Monitoring: Set up comprehensive audit trails and monitoring processes using Oracle Audit Vault and Database Firewall (AVDF) to record and detect unauthorized access or suspicious activities. This ensures that all actions are traceable and compliant.",
        "Policy Implementation and Enforcement: Use Oracle Policy Automation (OPA) to define and enforce business policies and rules. This ensures consistent implementation of compliance policies across cloud and on-premise Oracle applications.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "35px",
            }}
          >
            Cloud and On-Premise Solutions: Flexible, Scalable, and Secure
            Oracle Environments
          </h1>
          <p style={{ fontSize: "18px" }}>
            At FocusR, we provide comprehensive cloud and on-premise solutions
            tailored to meet the evolving needs of modern organizations. Our
            services encompass hybrid cloud integration, cloud-native
            development, on-premise infrastructure optimization, advanced
            security, compliance, disaster recovery, DevOps, advanced analytics,
            cost management, and regulatory adherence. Leveraging Oracle
            technologies like Oracle Integration Cloud (OIC), Oracle Enterprise
            Manager (OEM), Oracle GoldenGate, and Oracle Analytics Cloud (OAC),
            we ensure seamless connectivity, robust performance, and enhanced
            operational efficiency across Oracle EBS, Fusion applications, and
            other enterprise systems.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default OnPremise;
