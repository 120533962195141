/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import Products from './Components/Products';
import techstephubMockup from '../../assets/Supplier-portal-mockup.svg';
import Chat from '../../Components/Chat';
import { Collapse } from 'antd';
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled
} from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';

const SupplierPortal = () => {
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    document.title = 'Product | SupplySyncX';

    AOS.init();
  });

  const supplySyncXFAQ = [
    {
      key: '1',
      label: <h3>1. What is the purpose of SupplySyncX?</h3>,
      children: (
        <p>
          SupplySyncX serves as a comprehensive platform for supply chain management, facilitating seamless collaboration and integration with Oracle ERP for efficient operations.
        </p>
      ),
    },
    {
      key: '2',
      label: <h3>2. How do I get started with SupplySyncX?</h3>,
      children: (
        <p>
          To get started, access SupplySyncX through our website or designated platform. Connect with Oracle ERP, configure your preferences, and enjoy streamlined communication and transactions.
        </p>
      ),
    },
    {
      key: '3',
      label: <h3>3. What platforms does SupplySyncX support?</h3>,
      children: (
        <p>
          SupplySyncX is accessible on various platforms, including web browsers, making it convenient for users on different devices. It ensures flexibility and ease of use for a diverse user base.
        </p>
      ),
    },
    {
      key: '4',
      label: <h3>4. Can I manage orders and transactions through SupplySyncX?</h3>,
      children: (
        <p>
          Absolutely. SupplySyncX empowers users to efficiently manage orders and transactions, enhancing collaboration and transparency in the supply chain.
        </p>
      ),
    },
    {
      key: '5',
      label: <h3>5. How does SupplySyncX ensure data security?</h3>,
      children: (
        <p>
          SupplySyncX prioritizes data security, implementing encryption and following industry-standard security practices. This ensures that data is stored securely and accessible only to authorized users.
        </p>
      ),
    },
    {
      key: '6',
      label: <h3>6. Can I track the status of my deliveries through SupplySyncX?</h3>,
      children: (
        <p>
          Yes, SupplySyncX provides real-time tracking features for deliveries. Users can monitor the status of their deliveries and receive timely updates for enhanced visibility and control.
        </p>
      ),
    },
    {
      key: '7',
      label: <h3>7. Is customer support available for SupplySyncX?</h3>,
      children: (
        <p>
          Yes, we offer dedicated customer support to assist users with any issues or questions. Our support team is accessible through the SupplySyncX platform or our website.
        </p>
      ),
    },
    {
      key: '8',
      label: <h3>8. Can I customize my preferences in SupplySyncX?</h3>,
      children: (
        <p>
          Absolutely. SupplySyncX provides flexibility in customizing preferences. You can configure settings based on your specific requirements for a personalized experience.
        </p>
      ),
    },
    {
      key: '9',
      label: <h3>9. How often are updates released for SupplySyncX?</h3>,
      children: (
        <p>
          We are committed to regular improvements. Updates with new features, enhancements, and bug fixes are released periodically to ensure SupplySyncX remains efficient and up-to-date.
        </p>
      ),
    },
    {
      key: '10',
      label: <h3>10. Does SupplySyncX support multi-user collaboration?</h3>,
      children: (
        <p>
          Yes, SupplySyncX supports multi-user collaboration. It enables teams to work together efficiently on supply chain operations and transactions for improved productivity.
        </p>
      ),
    },
    {
      key: '11',
      label: <h3>11. What security measures are in place for SupplySyncX?</h3>,
      children: (
        <p>
          SupplySyncX implements robust security measures, including encryption and adherence to industry-standard practices. This ensures that data is handled securely and only accessible to authorized users.
        </p>
      ),
    },
    {
      key: '12',
      label: <h3>12. How does SupplySyncX integrate with Oracle ERP?</h3>,
      children: (
        <p>
          SupplySyncX seamlessly integrates with Oracle ERP, providing a unified platform for supply chain management and collaboration. This integration enhances data accuracy and synchronicity between the two systems.
        </p>
      ),
    },
  ];
  

  return (
    <div>
      <Navbar mode="dark" />
      <Chat />
      <Products
        title="SupplySyncX"
        mockupSrc={techstephubMockup}
        mockupAlt="TechstepHub-Mockup"
        technologyUsed={['React ', 'Java ', 'Oracle ', 'Spring-Boot ']}
        ratingCount={5}
        width="100%"
        videoUrl="https://www.youtube.com/watch?v=SVG9U6oa2u8"
        reviewCount="131"
        benefitTitle="Benefits of SupplySyncX"
        benefitArray1={[
          {
            icon: <TbSquareRoundedNumber1Filled className="num-icon" />,
            strongText: 'Efficient Dispatch Planning with Oracle Integration',
            pText:
              'Streamline dispatch operations with SupplySyncX, integrating seamlessly with Oracle for efficient planning and optimal resource allocation, ensuring timely deliveries.'
          },
          {
            icon: <TbSquareRoundedNumber2Filled className="num-icon" />,
            strongText: 'Real-time Route Optimization for Oracle-based Logistics',
            pText:
              'Optimize delivery routes in real-time with SupplySyncX, leveraging Oracle integration to minimize travel time and enhance overall logistics efficiency.'
          },
          {
            icon: <TbSquareRoundedNumber3Filled className="num-icon" />,
            strongText: 'Enhanced Visibility through Oracle Integration',
            pText:
              'Achieve heightened visibility into dispatch activities with SupplySyncX, seamlessly integrated with Oracle. Real-time tracking and reporting empower informed decision-making.'
          }
          // Add more benefits following the same structure
        ]}
        benefitArray2={[
          {
            icon: <TbSquareRoundedNumber4Filled className="num-icon" />,
            strongText: 'Dynamic Supplier Collaboration with Oracle Connectivity',
            pText:
              'Foster dynamic collaboration with suppliers through SupplySyncX, intricately connected with Oracle. Strengthen communication and coordination for efficient supply chain relationships.'
          },
          {
            icon: <TbSquareRoundedNumber5Filled className="num-icon" />,
            strongText: 'Automated Documentation and Compliance Checks',
            pText:
              "Ensure regulatory compliance with SupplySyncX's automated documentation and compliance checks. Seamlessly integrated with Oracle, it guarantees accuracy and reduces manual effort."
          },
          {
            icon: <TbSquareRoundedNumber6Filled className="num-icon" />,
            strongText: 'Intuitive Analytics Dashboard for Oracle-Backed Insights',
            pText:
              'Access a user-friendly analytics dashboard in SupplySyncX, powered by Oracle insights. Visualize key performance indicators and make data-driven decisions for continuous optimization.'
          }
        ]}
      />
      <br />
      <br />
      <h1
        style={{
          textAlign: 'center'
        }}
      >
        FAQ
      </h1>
      <br />
      <Collapse
        style={{
          width: '90%',
          position: 'relative',
          left: '5%',
          backgroundColor: '#fff',
          marginBottom: '5em',
          color:"#343F52"
        }}
        items={supplySyncXFAQ}
        onChange={onChange}
        defaultActiveKey={['1']}
      />
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default SupplierPortal;
