/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import Products from './Components/Products';
import assesmentMockup from '../../assets/assessment-mockup.svg';
import Chat from '../../Components/Chat';
import { Collapse } from 'antd';
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled
} from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AssesmentPortal = () => {
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    document.title = 'Product | Quizlink';

    AOS.init();
  });

  const assessmentPortalFAQ = [
    {
      key: '1',
      label: <h3>1. What is the purpose of the Quizlink application?</h3>,
      children: (
        <p>
          The Assessment Portal is designed to facilitate efficient online evaluations, providing a platform for educators
          to assess and grade students effectively.
        </p>
      ),
    },
    {
      key: '2',
      label: <h3>2. How do I get started with the Quizlink application?</h3>,
      children: (
        <p>
          To get started, log in to the Quizlink. Educators can create customized assessments, and students can
          access assessments through their accounts.
        </p>
      ),
    },
    {
      key: '3',
      label: <h3>3. What platforms does the Quizlink application support?</h3>,
      children: (
        <p>
          The Assessment Portal supports various platforms, including web browsers for both educators and students to
          access assessments online.
        </p>
      ),
    },
    {
      key: '4',
      label: <h3>4. Can I customize assessments in the Quizlink?</h3>,
      children: (
        <p>
          Absolutely. The Assessment Portal allows educators to create and customize assessments based on specific
          courses, learning objectives, and topics.
        </p>
      ),
    },
    {
      key: '5',
      label: <h3>5. How does the Quizlink provide feedback?</h3>,
      children: (
        <p>
          The Assessment Portal provides real-time feedback, enabling educators to offer instant insights, enhancing the
          learning experience for students.
        </p>
      ),
    },
    {
      key: '6',
      label: <h3>6. How secure and fair are assessments on the Quizlink?</h3>,
      children: (
        <p>
          The Assessment Portal ensures a secure and fair assessment environment through advanced online proctoring
          features, maintaining the integrity of the evaluation process.
        </p>
      ),
    },
    {
      key: '7',
      label: <h3>7. Can students collaborate on assessments in the Quizlink?</h3>,
      children: (
        <p>
          Yes, the Assessment Portal supports collaborative learning, allowing students to participate in group
          assessments and interactive features.
        </p>
      ),
    },
    {
      key: '8',
      label: <h3>8. Are assessments accessible offline in the Quizlink?</h3>,
      children: (
        <p>
          No, assessments in the Assessment Portal require an internet connection. Offline access is not currently
          supported.
        </p>
      ),
    },
    {
      key: '9',
      label: <h3>9. Is there a limit to the number of assessments I can create?</h3>,
      children: (
        <p>
          The Assessment Portal allows educators to create an unlimited number of assessments, providing flexibility and
          scalability.
        </p>
      ),
    },
    {
      key: '10',
      label: <h3>10. How often are updates released for the Quizlink?</h3>,
      children: (
        <p>
          We strive to improve the Assessment Portal regularly. Updates with new features, improvements, and bug fixes are
          released periodically.
        </p>
      ),
    },
    {
      key: '11',
      label: <h3>11. Can assessments be exported for external analysis?</h3>,
      children: (
        <p>
          Yes, the Assessment Portal provides export options. You can export assessment data in various formats for external
          analysis.
        </p>
      ),
    },
    {
      key: '12',
      label: <h3>12. How can I access analytics and insights from assessments?</h3>,
      children: (
        <p>
          Access comprehensive analytics and insights from assessments within the Assessment Portal for informed
          decision-making and continuous improvement.
        </p>
      ),
    },
  ];
   

  

  return (
    <div>
      <Navbar mode="dark" productOpen={true} />
      {/* <Chat /> */}
      <Products
        title="Quizlink"
        mockupSrc={assesmentMockup}
        mockupAlt="TechstepHub-Mockup"
        technologyUsed={['React', 'Java', 'Spring Boot']}
        ratingCount={5}
        reviewCount="155"
        width="100%"
        benefitTitle="Benefits of Quizlink"
        videoUrl="https://www.youtube.com/watch?v=jYp2bGoOWEQ"
        benefitArray1={[
          {
            icon: <TbSquareRoundedNumber1Filled className="num-icon" />,
            strongText: 'Streamlined Evaluation',
            pText: 'Efficiently assess and grade students with a streamlined evaluation process.',
          },
          {
            icon: <TbSquareRoundedNumber2Filled className="num-icon" />,
            strongText: 'Customizable Assessments',
            pText: 'Create and customize assessments tailored to specific courses and learning objectives.',
          },
          {
            icon: <TbSquareRoundedNumber3Filled className="num-icon" />,
            strongText: 'Real-time Feedback',
            pText: 'Provide instant feedback to students, enhancing the learning experience and facilitating improvement.',
          }
          // Add more benefits following the same structure
        ]}
        benefitArray2={[
          {
            icon: <TbSquareRoundedNumber4Filled className="num-icon" />,
            strongText: 'Secure and Fair',
            pText: 'Ensure a secure and fair assessment environment with advanced online proctoring features.',
          },
          {
            icon: <TbSquareRoundedNumber5Filled className="num-icon" />,
            strongText: 'Data-Driven Insights',
            pText: 'Access comprehensive analytics and insights for informed decision-making and continuous improvement.',
          },
          {
            icon: <TbSquareRoundedNumber6Filled className="num-icon" />,
            strongText: 'Collaborative Learning',
            pText: 'Promote collaborative learning through group assessments and interactive features.',
          }
        ]}
      />
      <br />
      <br />
      <h1
        style={{
          textAlign: 'center'
        }}
      >
        FAQ
      </h1>
      
      <Collapse
        style={{
          width: '90%',
          position: 'relative',
          left: '5%',
          backgroundColor: '#fff',
          marginBottom: '5em',
          color:"#343F52"
        }}
        items={assessmentPortalFAQ}
        onChange={onChange}
        defaultActiveKey={['1']}
      />
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default AssesmentPortal;
