/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import Products from './Components/Products';
import techstephubMockup from '../../assets/planify-mockup.svg';
import Chat from '../../Components/Chat';
import { Collapse } from 'antd';
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled
} from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';

const DispatchPlanner = () => {
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    document.title = 'Product | Planify';

    AOS.init();
  });

  const planifyFAQ = [
    {
      key: '1',
      label: <h3>1. What is the purpose of the Planify application?</h3>,
      children: (
        <p>
          The Planify application serves as a comprehensive dispatch planner, facilitating the creation of purchase orders
          (PO) and integrating seamlessly with Oracle ERP for an efficient and organized workflow.
        </p>
      ),
    },
    {
      key: '2',
      label: <h3>2. How do I get started with the Planify application?</h3>,
      children: (
        <p>
          To get started, download the Planify app from the App Store/Google Play Store or visit our website. Set up your
          Oracle ERP integration, configure dispatch planning preferences, and begin creating purchase orders with ease.
        </p>
      ),
    },
    {
      key: '3',
      label: <h3>3. What platforms does the Planify application support?</h3>,
      children: (
        <p>
          The Planify application is available for download on iOS and Android devices. It is also accessible through web
          browsers for added convenience.
        </p>
      ),
    },
    {
      key: '4',
      label: <h3>4. Can I create purchase orders (PO) using Planify?</h3>,
      children: (
        <p>
          Absolutely. Planify empowers users to create purchase orders seamlessly, streamlining the procurement process for
          efficient dispatch planning.
        </p>
      ),
    },
    {
      key: '5',
      label: <h3>5. What are the three stages of approval in Planify?</h3>,
      children: (
        <p>
          Planify incorporates a three-stage approval process to ensure thorough verification and authorization of purchase
          orders, enhancing control and compliance within your organization.
        </p>
      ),
    },
    {
      key: '6',
      label: <h3>6. How does Planify integrate with Oracle ERP?</h3>,
      children: (
        <p>
          Planify seamlessly integrates with Oracle ERP, providing a unified platform for dispatch planning and purchase
          order management. This integration enhances data accuracy and synchronicity between the two systems.
        </p>
      ),
    },
    {
      key: '7',
      label: <h3>7. Is customer support available for Planify?</h3>,
      children: (
        <p>
          Yes, we offer dedicated customer support to assist you with any issues or questions you may have. Our support team
          is accessible through the app or our website.
        </p>
      ),
    },
    {
      key: '8',
      label: <h3>8. Can Planify be used in offline mode?</h3>,
      children: (
        <p>
          Yes, Planify features offline functionality. You can continue dispatch planning and creating purchase orders even
          without an internet connection, and the data will be synchronized once you're back online.
        </p>
      ),
    },
    {
      key: '9',
      label: <h3>9. How often are updates released for Planify?</h3>,
      children: (
        <p>
          We are committed to regular improvements. Updates with new features, enhancements, and bug fixes are released
          periodically to ensure Planify remains efficient and up-to-date.
        </p>
      ),
    },
    {
      key: '10',
      label: <h3>10. Can I customize approval workflows in Planify?</h3>,
      children: (
        <p>
          Yes, Planify provides flexibility in customizing approval workflows. You can configure the three stages of
          approval based on your organization's specific requirements.
        </p>
      ),
    },
    {
      key: '11',
      label: <h3>11. Are there any specific security measures in place for Planify?</h3>,
      children: (
        <p>
          Planify prioritizes data security. All information is encrypted, and the application follows industry-standard
          security practices, ensuring your data is stored securely and accessible only to authorized users.
        </p>
      ),
    },
    {
      key: '12',
      label: <h3>12. Does Planify support multi-user collaboration?</h3>,
      children: (
        <p>
          Yes, Planify supports multi-user collaboration, enabling teams to work together on dispatch planning and purchase
          order creation for improved efficiency.
        </p>
      ),
    },
  ];
  
  
  

  return (
    <div>
      <Navbar mode="dark" />
      <Chat />
      <Products
        title="Planify"
        mockupSrc={techstephubMockup}
        mockupAlt="TechstepHub-Mockup"
        technologyUsed={['React ', 'Java ', 'Oracle ','Spring-Boot ']}
        ratingCount={5}
        reviewCount="131"
        videoUrl="https://www.youtube.com/watch?v=p6LxQ3j779E"
        width="100%"
        benefitTitle="Benefits of Planify"
        benefitArray1={[
          {
            icon: <TbSquareRoundedNumber1Filled className="num-icon" />,
            strongText: 'Efficient Dispatch Planning',
            pText:
              'Optimize dispatch operations with Planify, ensuring efficient planning and allocation of resources for timely deliveries.'
          },
          {
            icon: <TbSquareRoundedNumber2Filled className="num-icon" />,
            strongText: 'Real-time Route Optimization',
            pText:
              'Utilize real-time route optimization features to enhance delivery routes, reducing travel time and improving overall logistics efficiency.'
          },
          {
            icon: <TbSquareRoundedNumber3Filled className="num-icon" />,
            strongText: 'Enhanced Visibility',
            pText:
              'Gain enhanced visibility into dispatch activities with real-time tracking and reporting, allowing for better decision-making.'
          }
          // Add more benefits following the same structure
        ]}
        benefitArray2={[
          {
            icon: <TbSquareRoundedNumber4Filled className="num-icon" />,
            strongText: 'Resource Utilization',
            pText:
              'Optimize resource utilization by effectively assigning tasks and ensuring balanced workloads among dispatch teams.'
          },
          {
            icon: <TbSquareRoundedNumber5Filled className="num-icon" />,
            strongText: 'Customer Satisfaction',
            pText:
              'Improve customer satisfaction by ensuring on-time deliveries and providing accurate, real-time information on shipment status.'
          },
          {
            icon: <TbSquareRoundedNumber6Filled className="num-icon" />,
            strongText: 'Integration Capabilities',
            pText:
              'Integrate seamlessly with existing systems and tools, facilitating a smooth workflow and enhancing overall operational efficiency.'
          }
        ]}
      />
      <br />
      <br />
      <h1
        style={{
          textAlign: 'center'
        }}
      >
        FAQ
      </h1>
      <br />
      <Collapse
        style={{
          width: '90%',
          position: 'relative',
          left: '5%',
          backgroundColor: '#fff',
          marginBottom: '5em',
          color:"#343F52"
        }}
        items={planifyFAQ}
        onChange={onChange}
        defaultActiveKey={['1']}
      />
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default DispatchPlanner;
