import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import { useWindupString } from "windups";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const CloudMigration = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "API Management and Integration",
      description:
        "Enabling seamless data exchange and business process automation through robust API management strategies:",

      details: [
        "Oracle API Gateway: Utilize Oracle API Gateway to develop, secure, and manage APIs that seamlessly facilitate data communication between Oracle Fusion applications, Oracle E-Business Suite (EBS), and third-party systems. Implement RESTful and SOAP-based web services for real-time integration.",
        "API Orchestration with OIC: Implement Oracle Integration Cloud (OIC) to orchestrate and manage complex API workflows, ensuring seamless connectivity among disparate systems. OIC provides pre-built adapters for various applications, simplifying integrations with ERP, CRM, and other enterprise systems.",
        "Custom API Development: Develop custom APIs using Oracle REST Data Services (ORDS) to expose data and business logic from Oracle databases and applications, enabling flexible and efficient integration solutions.",
        "Identity and Access Management: Ensure secure access to APIs with Oracle Identity Cloud Service (IDCS) and Oracle API Security features. Implement OAuth2, JWT tokens, and API key management for robust authentication and authorization.",
        "Monitoring and Analytics: Leverage Oracle API Platform Cloud Service to monitor API usage, performance, and security. Utilize built-in analytics tools to gain insights into API traffic, identify bottlenecks, and optimize API performance.",
      ],
    },
    {
      title: "Service-Oriented Architecture (SOA) and Middleware",
      description:
        "Implementing a robust SOA framework to facilitate interoperable services across the enterprise:",
      details: [
        "Oracle SOA Suite: Deploy Oracle SOA Suite to create, orchestrate, and manage service-oriented integrations between Oracle Fusion applications, EBS, and external systems. Utilize BPEL for business process execution and ESB for message brokering and routing.",
        "Enterprise Service Bus (ESB): Implement Oracle Service Bus (OSB) to enable reliable message exchange and transformation across different systems. This ensures smooth data flow and service interactions between Oracle applications and third-party services.",
        "Reusable Services: Develop reusable web services and business components that can be easily invoked by multiple applications within the enterprise. This reduces redundancy and fosters a modular and scalable integration architecture.",
        "Event-Driven Architecture: Utilize Oracle Event Processing (OEP) to handle event-driven integrations, enabling real-time response to business events. Integrate OEP with Fusion applications and EBS to capture and process events such as order completions, inventory updates, and customer actions.",
        "Middleware Performance Optimization: Optimize the performance of Oracle SOA Suite and middleware components using Oracle Enterprise Manager (OEM). Monitor service metrics, analyze bottlenecks, and fine-tune middleware configurations for optimal performance.",
      ],
    },
    {
      title: "Data Synchronization and Real-Time Integration",
      description:
        "Ensuring accurate and consistent data across the enterprise with real-time data synchronization solutions:",
      details: [
        "Oracle GoldenGate: Deploy Oracle GoldenGate for real-time data replication and synchronization between Oracle EBS, Fusion applications, and other databases. This ensures data consistency and availability across different systems without impacting performance.",
        "ETL with Oracle Data Integrator (ODI): Implement ODI to design, execute, and monitor Extract, Transform, Load (ETL) processes for batch and real-time data integration. ODI’s declarative design and knowledge modules support complex data transformations and integrations..",
        "Data Consolidation: Utilize Oracle Data Integrator (ODI) and Oracle Autonomous Data Warehouse to consolidate data from multiple sources into a central repository. This supports unified reporting, analytics, and data warehousing initiatives.",
        "Master Data Management (MDM): Implement Oracle Master Data Management solutions to create a single, consistent, and accurate view of critical enterprise data across EBS, Fusion, and other systems. MDM ensures data integrity and improves decision-making.",
        "Data Quality Assurance: Leverage Oracle Enterprise Data Quality (EDQ) tools to profile, clean, and standardize data during integration processes, ensuring high data quality and mitigating the risk of inconsistencies and errors.",
      ],
    },
    {
      title: "Business Process Integration and Automation",
      description:
        "Streamlining business processes through integrated and automated workflows:",
      details: [
        "Oracle BPM Suite: Deploy Oracle Business Process Management (BPM) Suite to design, simulate, and automate business processes that span across Oracle Fusion applications, EBS, and third-party systems. BPM Suite’s intuitive design environment allows for rapid process modeling and optimization.",
        "Robotic Process Automation (RPA): Integrate RPA tools such as UiPath and Automation Anywhere to automate repetitive and manual tasks within Oracle EBS and Fusion environments. RPA reduces human error and increases operational efficiency.",
        "Approval Workflow Integration: Implement Oracle Workflow and BPM tools to design custom approval workflows that integrate with existing Fusion and EBS processes. This ensures streamlined and consistent approval mechanisms for procurement, HR, finance, and other business functions.",
        "End-to-End Process Automation: Utilize Oracle Integration Cloud (OIC) to automate end-to-end business processes, encompassing applications, data, and services. Configure triggers and actions to automate tasks based on specific business events.",
        "Monitoring and Optimization: Monitor business process performance using Oracle Business Activity Monitoring (BAM) and Oracle Analytics. Identify process bottlenecks and optimize workflows to enhance efficiency and responsiveness.",
      ],
    },

    {
      title: "Hybrid Cloud Integration",
      description:
        "Providing seamless integration between on-premise systems and cloud environments:",
      details: [
        "Oracle Integration Cloud (OIC): Utilize OIC to integrate on-premise Oracle EBS systems with Oracle Fusion Cloud applications. OIC offers pre-built connectors and recipe integrations that simplify the hybrid cloud integration process..",
        "Oracle Data Sync: Implement Oracle Data Sync tools to harmonize data across on-premise and cloud deployments, ensuring real-time data accuracy and consistency between EBS and Fusion instances.",
        "Secure Connectivity: Leverage Oracle FastConnect to establish secure, low-latency connectivity between on-premise data centers and Oracle Cloud Infrastructure (OCI), ensuring fast and reliable data transfer.",
        "Unified Management: Utilize Oracle Enterprise Manager Cloud Control to manage and monitor both on-premise and cloud resources from a single console. This unified approach facilitates streamlined administration and improved visibility across the enterprise.",
        "Disaster Recovery and High Availability: Implement Oracle Cloud Infrastructure (OCI) for disaster recovery solutions, ensuring that critical business processes and data in Fusion and EBS systems remain available and protected in the event of system failures or disasters.",
      ],
    },
    {
      title: "Integration with Third-Party Applications",
      description:
        "Ensuring seamless connectivity and data exchange with external systems to enhance business capabilities:",
      details: [
        "Pre-built Adapters: Utilize Oracle Integration Cloud (OIC) pre-built adapters for various third-party applications such as Salesforce, ServiceNow, Workday, and SAP to streamline integrations with Oracle Fusion and EBS. This accelerates deployment and reduces the complexity of integrations.",
        "Custom Connectors: Develop custom connectors using Oracle SOA Suite or Oracle API Platform Cloud Service to connect bespoke or less common third-party applications with Oracle environments. This ensures that all critical systems within the enterprise are interconnected.",
        "Web Services and RESTful APIs: Implement and expose RESTful and SOAP-based web services to facilitate real-time communication and data exchange between Oracle applications and third-party systems. Utilize Oracle REST Data Services (ORDS) for API development and management.",
        "Message Queuing and Broker Services: Leverage Oracle Advanced Queuing (AQ) and Oracle Message Broker to manage and process messages exchanged between Oracle and non-Oracle systems. This ensures reliable and asynchronous communication for tasks such as order processing and inventory updates.",
        "Data Virtualization: Use Oracle Data Virtualization technologies to create virtual views of integrated data from multiple sources, including third-party applications. This allows for real-time data access and unified analytics without the need for data replication.",
      ],
    },
    {
      title: "Integration for Internet of Things (IoT) and Edge Devices",
      description:
        "Expanding the enterprise ecosystem by integrating IoT and edge devices with core systems:",
      details: [
        "Oracle IoT Cloud Service: Deploy Oracle IoT Cloud Service to capture, analyze, and integrate data from IoT devices with Oracle Fusion and EBS applications. This enables real-time monitoring and decision-making based on IoT data.",
        "Edge Device Integration: Utilize edge computing solutions to preprocess data locally on edge devices before integrating it with central Oracle applications. This can reduce latency and bandwidth usage for time-sensitive data processing.",
        "IoT Data Storage and Management: Implement Oracle Autonomous Data Warehouse to store and manage large volumes of IoT-generated data, providing seamless data access for analytics and reporting within Oracle BI and Fusion environments.",
        "Custom IoT APIs: Develop custom APIs for IoT devices using Oracle API Platform Cloud Service, enabling secure and scalable data exchange between edge devices and enterprise systems.",
        "Predictive Maintenance: Integrate IoT data with Oracle Maintenance Cloud to develop predictive maintenance models, improving asset utilization and reducing downtime by proactively addressing maintenance needs using real-time sensor data.",
      ],
    },
    {
      title: "Industry-Specific Integration Solutions",
      description:
        "Providing tailored integration solutions that meet the unique requirements of different industries:",
      details: [
        "Healthcare Integration: Implement HL7 and FHIR standards for integrating Oracle applications with healthcare systems like Electronic Health Records (EHR) and Hospital Information Systems (HIS). Utilize Oracle SOA Suite and OIC for seamless data exchange and interoperability.",
        "Financial Services Integration: Deploy ISO 20022 and SWIFT standards for integrating Oracle Fusion Financials with banking and financial institutions. Implement Oracle Financial Services Analytical Applications (OFSAA) for risk management, compliance, and financial analytics.",
        "Manufacturing Integration: Leverage Oracle Manufacturing Cloud to integrate with PLCs, SCADA systems, and MES (Manufacturing Execution Systems). This ensures seamless data flow and synchronization between manufacturing operations and enterprise systems.",
        "High-Performance Computing (HPC): Utilizing OCI’s HPC capabilities to manage extensive data processing tasks, enabling high-speed analytics and large-scale query execution within Oracle BI.",
        "Utilities Integration: Utilize Oracle Utilities applications to integrate with smart grid systems, meter data management systems (MDMS), and customer information systems (CIS) using Oracle Integration Cloud and SOA Suite.",
      ],
    },
    {
      title: "Robust Data Governance and Compliance",
      description:
        "Implementing comprehensive data governance frameworks to ensure compliance and data integrity:",
      details: [
        "Oracle Data Governance Solutions: Deploy Oracle Enterprise Data Governance solutions to establish and enforce data governance policies across integrated systems. This includes data stewardship, lineage tracking, and metadata management.",
        "Data Quality Management: Utilize Oracle Enterprise Data Quality (EDQ) tools to perform data profiling, cleansing, matching, and monitoring, ensuring high-quality and accurate data across integrated systems.",
        "Compliance Management: Implement Oracle Governance, Risk, and Compliance (GRC) Cloud to ensure compliance with industry regulations and standards such as GDPR, SOX, HIPAA, and PCI-DSS. This includes setting up automated compliance workflows and reporting tools.",
        "Audit and Monitoring: Leverage Oracle Audit Vault and Database Firewall (AVDF) to monitor and log data access and transactions across Oracle and integrated third-party systems. This provides a comprehensive audit trail for regulatory compliance and forensics.",
        "Master Data Management (MDM): Deploy Oracle MDM solutions to create a single, consistent, and accurate view of enterprise data. MDM ensures that integrated systems share reliable master data for improved decision-making and operational efficiency.",
      ],
    },
    {
      title: "Scalable Integration Architecture",
      description:
        "Building scalable integration architectures that support growing business needs and technological advancements:",
      details: [
        "Microservices Architecture: Implement microservices-based integrations using Oracle Cloud Native services and Kubernetes. This approach enhances scalability and resilience by encapsulating integration logic into independent, deployable units.",
        "Event-Driven Integrations: Utilize Oracle Event Hub Cloud Service and Apache Kafka to build event-driven architectures that enable real-time data streaming and processing across Oracle and third-party systems.",
        "Serverless Integrations: Deploy serverless functions using Oracle Functions to handle integration tasks such as data transformation and processing on-demand, reducing infrastructure overhead and improving scalability.",
        "Container Orchestration: Leverage Oracle Container Engine for Kubernetes (OKE) to manage and orchestrate containerized integration services, ensuring seamless deployment, scaling, and management of integration workloads.",
        "Hybrid Cloud Scalability: Utilize Oracle Multi-Cloud and Hybrid solutions to integrate and scale services across on-premise, Oracle Cloud, and other public clouds, ensuring robust and flexible integration architectures that adapt to evolving business demands.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);
  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
            }}
          >
            Solutions for Enterprise Integration
          </h1>
          <p style={{ fontSize: "18px" }}>
            At FocusR, we specialize in providing comprehensive enterprise
            integration solutions, enabling seamless connectivity and data
            exchange between Oracle applications and other systems such as ERP,
            CRM, IoT devices, and third-party applications. Our expertise covers
            robust API management, SOA, real-time data synchronization, business
            process automation, hybrid cloud integration, and industry-specific
            solutions. We ensure data governance, compliance, and scalable
            architectures to support business growth. Leveraging advanced Oracle
            technologies like OIC, SOA Suite, and GoldenGate, we streamline
            processes, improve data accuracy, and enhance operational
            efficiency, ensuring your systems operate in perfect harmony.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24"  ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CloudMigration;
