import { useEffect, useRef, useState } from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { Link, Element, scroller } from "react-scroll";
import {
    AiFillControl,
    AiOutlineApi,
    AiOutlineBranches,
    AiOutlineSetting,
    AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import FocusrAIArrow from '../../assets/focusrarrowai.png'
import IntelligenceIcon from '../../assets/intelligence (1).svg'
import FeatureDots from '../../assets/smb_repeat_circle_bg.svg'
import { MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
    TbNumber10Small,
    TbNumber11Small,
    TbNumber1Small,
    TbNumber2Small,
    TbNumber3Small,
    TbNumber4Small,
    TbNumber5Small,
    TbNumber6Small,
    TbNumber7Small,
    TbNumber8Small,
    TbNumber9Small,
    TbSquareRoundedNumber1Filled,
    TbSquareRoundedNumber2Filled,
    TbSquareRoundedNumber3Filled,
    TbSquareRoundedNumber4Filled,
    TbSquareRoundedNumber5Filled,
    TbSquareRoundedNumber6Filled,
    TbSquareRoundedNumber7Filled,
    TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import { useWindupString } from "windups";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import UiPath from '../../assets/UiPath_Logo.png'
import Pega from '../../assets/pega-logo-horizontal-positive-rgb-01.png'
import BluePrism from '../../assets/blue-prism-logo.png'
import Automation from '../../assets/Automation Anywhere.png'
import PowerBIIcon from '../../assets/Power-BI-Logo.png'
import MSFabric from '../../assets/msfabric.png'
import Synapse from '../../assets/Azure Synapse Analytics.png'
import '../aiservices/aiServices.css'
import focusRlogoRight from "../../assets/FocusR_100px.png";
import { HiMenuAlt1 } from "react-icons/hi";
import {
    Button,
    Drawer,
    Dropdown,
    Popover,
    Space,
    message,
    Tooltip,
  } from "antd";
import { text } from "@fortawesome/fontawesome-svg-core";

const PowerBI = () => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const contentRefs = useRef([]);
    const [bottomBoundary, setBottomBoundary] = useState(0);
    const welcomePageRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [navbarStatus, setnavbarStatus] = useState(false);
  const [hover, setHover] = useState(false);
    const [navBarMonitor, setnavBarMonitor] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      window.addEventListener("scroll", (e) => {
        if (e.isTrusted && window.scrollY > 0) {
          setnavbarStatus(true);
          setHover(true);
        } else {
          setnavbarStatus(false);
          setHover(false);
        }
      });
      console.log("Window y position", window.scrollY);
      console.log("windowWidth", window.innerWidth);
      //"1px 1px 50px 1px #fff"
      if (window.innerWidth < 1150) {
        setnavBarMonitor(true);
      } else {
        setnavBarMonitor(false);
      }
      window.addEventListener("resize", (e) => {
        e.isTrusted && window.innerWidth < 1150
          ? setnavBarMonitor(true)
          : setnavBarMonitor(false);
      });
    }, [window.innerWidth]);

    const items2 = [
      {
        key: "1",
        label: <span className="drawer-antd-btn1">Gen AI</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/llmframework");
                onClose();
              }}
            >
              LLM Framework
            </li>
            <li
              onClick={() => {
                navigate("/finetuning");
                onClose();
              }}
            >
              Fine-Tuning
            </li>
          </ul>
        ),
      },
      {
        key: "3",
        label: <span className="drawer-antd-btn1">ERP</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/seamlessintegration");
                onClose();
              }}
            >
              Integration
            </li>
            <li
              onClick={() => {
                navigate("/dataprotection");
                onClose();
              }}
            >
              Data Protection
            </li>
            <li
              onClick={() => {
                navigate("/unstructureddata");
                onClose();
              }}
            >
              Data Processing
            </li>
            <li
              onClick={() => {
                navigate("/cloudai");
                onClose();
              }}
            >
              Cloud AI
            </li>
            <li
              onClick={() => {
                navigate("/rpaeuc");
                onClose();
              }}
            >
              RPA
            </li>
          </ul>
        ),
      },
      {
        key: "4",
        label: <span className="drawer-antd-btn1">AI APP</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/products/HireCentral");
                onClose();
              }}
            >
              Hireflow
            </li>
          </ul>
        ),
      },
      {
        key: "5",
        label: <span className="drawer-antd-btn1">Analytics</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/powerbianalytics");
                onClose();
              }}
            >
              Power BI
            </li>
            <li
              onClick={() => {
                navigate("/oraclesapanalytics");
                onClose();
              }}
            >
              Oracle and SAP
            </li>
            <li
              onClick={() => {
                navigate("/googleanalytics");
                onClose();
              }}
            >
              Google
            </li>
          </ul>
        ),
      },
    ];

    const isMobile = window.innerWidth < 768; // Example mobile check
    const isTablet = window.innerWidth <= 1024 && window.innerWidth >= 768; // Example tablet check

    window.onload = function () {
        const elements = document.querySelectorAll('.framework-icons');
        elements.forEach(el => {
            el.classList.add('anim-init');
        });
    };

    const sections = [
        {
            title: "Ready-to-Use KPIs for Oracle and SAP ERP",
            description: "FocusR offers pre-built KPIs for over 2,000 Oracle ERP processes and 3,000 SAP ERP processes, allowing businesses to quickly get actionable insights without the hassle of building metrics from scratch. Our analytics solutions are tailored to meet industry-specific requirements and help you track key metrics such as:",
            parahs: [
                {
                    details: [
                        "Financial performance",
                        "Inventory management",
                        "Supply chain efficiency",
                        "Customer service levels ",
                        "Sales and revenue growth"
                    ],
                },
            ]
        },
        {
            title: "Power BI Services We Offer",
            parahs: [
                {
                    text: "1. Power BI Dashboard Development:",
                    details: [
                        "Custom Dashboards: We design and develop highly customizable Power BI dashboards to fit your business’s unique needs, offering real-time insights and interactive reporting.",
                        "Pre-Built Templates: Leverage FocusR’s pre-built Power BI templates for quick deployment, especially for ERP analytics, financial reporting, and operational monitoring.",
                    ]
                },
                {
                    text: "2. Data Pipeline Development:",
                    details:[
                        "Data Integration: We ensure seamless data integration from various sources, including Oracle, SAP, Google Analytics, and other third-party platforms, into Power BI for unified reporting.",
                        "ETL (Extract, Transform, Load): FocusR builds powerful ETL pipelines to prepare, transform, and load data into Power BI, ensuring clean, accurate, and up-to-date datasets.",
                        "Data Modeling: Our experts create robust data models that underpin your Power BI reports, ensuring they are scalable, maintainable, and aligned with your business requirements."
                    ]
                },
                {
                    text: "3. Microsoft Fabric Integration:",
                    details : [
                        "We leverage Microsoft Fabric to enhance Power BI’s capabilities with real-time data streaming, big data analytics, and AI integration, making your reports smarter and more responsive to changing business conditions.",
                        "Fabric AI Integration: Incorporating advanced AI models directly within Power BI dashboards allows businesses to perform predictive analytics and automate insights generation.",
                    ]
                },
                {
                    text: "4. Advanced Analytics with Power BI:",
                    details: [
                        "Predictive Analytics: We enable predictive analytics within Power BI, helping you forecast future trends and business outcomes based on historical data.",
                        "Machine Learning Integration: Use Power BI’s AI and machine learning capabilities to gain deeper insights into your data. We integrate ML models for anomaly detection, trend analysis, and more."
                    ]
                }
            ],
        },
        {
            title: "Seamless Integration with Other Analytics Platforms",
            description: "FocusR ensures that Power BI works in harmony with other business intelligence tools such as:",
            parahs: [
                {
                    details: [
                        "Google Analytics: Integrate web and digital marketing data into Power BI, enabling a comprehensive view of your online performance alongside other business metrics.",
                        "Oracle Analytics: Combine the power of Oracle Analytics with Power BI to drive advanced financial reporting and deep ERP insights.",
                        "SAP Analytics: Use Power BI in conjunction with SAP Analytics Cloud to gain real-time insights into your supply chain, sales, and operations.",
                    ]
                }
            ]
        },
        {
            title: "Why Choose FocusR for Power BI Services?",
            parahs: [
                {
                    details: [
                        "Scalable Solutions: Our Power BI solutions grow with your business, providing flexibility as your data and reporting needs evolve.",
                        "Industry Expertise: With pre-built KPIs and years of experience working with Oracle and SAP ERPs, we deliver industry-specific analytics that drive measurable outcomes.",
                        "Data Security: We ensure the security of your data with strong governance policies, role-based access controls, and encryption, keeping sensitive information protected.",
                        "Ongoing Support: FocusR provides continuous support for Power BI implementations, including updates, troubleshooting, and performance optimization to keep your analytics systems running smoothly."
                    ]
                }
            ]
        },
        {
            title: "Elevate Your Business with Power BI and FocusR",
            description: "With FocusR’s Power BI services, your organization can unlock deep, data-driven insights, streamline reporting, and make better business decisions. Whether it's through custom dashboards, seamless ERP integration, or advanced analytics, we provide the tools you need to stay competitive in today’s data-centric world."
        }
    ];
    useEffect(() => {
        AOS.init();
    }, []);

    const handleScroll = () => {
        const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
        const currentSectionIndex = contentRefs.current.findIndex(
            (ref) => ref && ref.offsetTop > scrollPosition
        );
        setActiveIndex(
            currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
        );
    };

    // useEffect(() => {
    //   // Set bottom boundary after DOM is rendered
    //   const rightSideElement = document.querySelector(".right-side");
    //   if (rightSideElement) {
    //     setBottomBoundary(
    //       rightSideElement.offsetTop + rightSideElement.offsetHeight
    //     );
    //   }

    //   window.addEventListener("scroll", handleScroll);
    //   return () => window.removeEventListener("scroll", handleScroll);
    // }, []);

    useEffect(() => {
        const updateBottomBoundary = () => {
            const rightSideElement = document.querySelector(".right-side");
            if (rightSideElement) {
                setBottomBoundary(
                    rightSideElement.offsetTop + rightSideElement.offsetHeight
                );
            }
        };

        const observer = new ResizeObserver(updateBottomBoundary);
        const rightSideElement = document.querySelector(".right-side");

        if (rightSideElement) {
            observer.observe(rightSideElement);
        }

        // Initial boundary calculation
        updateBottomBoundary();

        window.addEventListener("scroll", updateBottomBoundary);

        return () => {
            window.removeEventListener("scroll", updateBottomBoundary);
            if (rightSideElement) {
                observer.unobserve(rightSideElement);
            }
        };
    }, []);

    window.addEventListener("scroll", handleScroll);

    const arrayOfImages = [
        {
          imageUrl: PowerBIIcon,
          alt: 'Logo-A-Series'
        },
        {
          imageUrl: MSFabric,
          alt: 'Logo-A-Series'
        },
        {
          imageUrl: Synapse,
          alt: 'Logo-A-Series'
        },
    ]

    return (
        <div>
            {/* <Navbar mode="dark" /> */}
            {navBarMonitor ? (
          <div className="navbar2" style={{ backgroundColor: "#fff" }}>
            <button className="hamburger-cus" onClick={showDrawer}>
              <HiMenuAlt1 style={{ width: "3em", height: "3em" }} />
            </button>
            <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
          </div>
        ) : (
          <Navbar mode="dark" />
        )}
        <Drawer
          title={
            <div className="logo-small">
              <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
            </div>
          }
          placement="top"
          onClose={onClose}
          open={open}
          height="100%"
        >
          <button
            className="drawer-antd-btn"
            onClick={() => {
              navigate("/");
              onClose();
            }}
          >
            Home
          </button>
          <Collapse ghost items={items2} expandIconPosition="right" />
        </Drawer>
            {/* <ChatNew /> */}
            <div className="welcome-page-23-llm">
                <center>
                    <div className='welcome-ai-page-2-header' style={{ marginBottom: '3em' }}>
                        <div className='welcome-ai-page-2-wrap' style={{ width: isMobile ? "" : isTablet ? '' : "90%" }}>
                            <div className='welcome-ai-page-2-title' onClick={() => navigate('/services/AI')} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={FocusrAIArrow} width="auto" height={ isMobile? "30px" : "45px"} />
                                <img src={IntelligenceIcon} width={ isMobile? "100px" : "150px"} style={{ marginLeft: '15px' }} />
                            </div>
                            <div className='welcome-ai-page-2-navlinks'>
                                <div className='welcome-ai-page-2-menu' style={{ height: 'auto', top: 'auto', display: 'block' }}>
                                <span className='menu-item'>Gen AI<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/llmframework')} className="dropdown-item">LLM Framework</Link>
      <Link onClick={() => navigate('/finetuning')} className="dropdown-item">Fine-tuning</Link>
    </div>
  </span>
  <span className='menu-item'>ERP<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/seamlessintegration')} className="dropdown-item">Integration</Link>
      <Link onClick={() => navigate('/dataprotection')} className="dropdown-item">Data Protection</Link>
      <Link onClick={() => navigate('/unstructureddata')} className="dropdown-item">Data Processing</Link>
      <Link onClick={() => navigate('/cloudai')} className="dropdown-item">Cloud AI</Link>
      <Link onClick={() => navigate('/rpaeuc')} className="dropdown-item">RPA</Link>
    </div>
  </span>
  <span className='menu-item'>AI APP<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/products/HireCentral')} className="dropdown-item">Hireflow</Link>
    </div>
  </span>
  <span className='menu-item'>Analytics<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/powerbianalytics')} className="dropdown-item">Power BI</Link>
      <Link onClick={() => navigate('/oraclesapanalytics')} className="dropdown-item">Oracle and SAP</Link>
      <Link onClick={() => navigate('/googleanalytics')} className="dropdown-item">Google</Link>
    </div>
  </span>
                                </div>
                                {/* <a href='#aiform' style={{ display: isMobile? 'none': 'flex', backgroundColor: '#E42527', minWidth: '100px', marginLeft: '10px', justifyContent: 'center', borderRadius: '30px', padding: '7px 20px', color: 'white' }}>
                                    Get Started
                                </a> */}
                            </div>
                        </div>
                    </div>
                    <h1
                        style={{
                            color: "#0067FF",
                            textAlign: "center",
                            width: "80%",
                            fontSize: '46px',
                            fontWeight: '800',
                            lineHeight: '52px',
                            marginBottom: '0.5em'
                        }}
                    >
                        Power BI with Advanced Business Analytics Solutions 
                    </h1>
                    <p style={{ fontSize: "18px", letterSpacing: '1px' }}>
                    At FocusR, we empower businesses to harness the full potential of Power BI, providing robust and customizable business analytics solutions. Our services help organizations leverage Power BI along with Microsoft Fabric, ensuring seamless data integration, visualization, and reporting across their operations. Whether you're using Google Analytics, Oracle Analytics, or SAP Analytics, FocusR ensures that your business intelligence systems are seamlessly integrated for comprehensive, data-driven decision-making. {" "}
                    </p>
                    <div className="button-container1">
                        <a
                            className="primary-btn-cus"
                            href="#careers"
                            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
                            onClick={() => {
                                navigate("/contact");
                            }}
                        >
                            Connect to know more
                        </a>
                    </div>
                </center>
            </div>
            <center>
        <section className="unstructured-powerbi-box"></section>
      </center>
      <div style={{ display: 'flex', paddingTop: '100px', height: 'fit-content', justifyContent: 'space-evenly', width: '100%' }} >
          {/* <div className="new-logo-icon-container" data-aos="fade-right" style={{ display: "flex", alignItems: 'center'}}
        data-aos-delay="100">
            <span className="framework-icons-pow" style={{marginRight: "8em"}}>
                <img width={150} src={PowerBIIcon} />
            </span>
            <span className="framework-icons-pow" style={{marginRight: "8em"}}>
                <img width={200} src={MSFabric} />
            </span>
            <span className="framework-icons-pow">
                <img width={180} src={Synapse} />
            </span>
          </div> */}
          <div className="new-logo-container-ai" data-aos="fade-right"
        data-aos-delay="200">
          {arrayOfImages.map((x, i) => {
            return <img src={x.imageUrl} alt={x.alt} />;
          })}
        </div>
        </div>
            {/* <div className="llmframework-feature1">
        <div className="anim-init" style={{ color: '#0067ff', textAlign: 'center', fontSize: '60px' }} data-aos="fade-right"
        data-aos-delay="200">
          LLMS we work with
        </div>
        <div style={{ display: 'flex', paddingTop: '35px', height: 'fit-content', justifyContent: 'space-evenly', width: '100%' }} >
          <div className="framework-icon-wrapper" data-aos="fade-right"
        data-aos-delay="300">
            <span className="framework-icons anim-init">
                <img width={150} src={GeminiIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={200} src={OpenAIIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={180} src={ClaudeIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={80} style={{ paddingTop: '2em' }} src={LlamaIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={180} style={{ paddingTop: '2.5em' }} src={HuggingIcon} />
            </span>
          </div>
        </div>
      </div> */}
            {/* <div className="llmframework-feature1">
      <div className="anim-init" style={{ color: '#0067ff', textAlign: 'center', fontSize: isMobile? '35px': '60px' }}>
          LLMS we work with
        </div>
      <div className="new-logo-container" data-aos="fade-right"
        data-aos-delay="200">
          {arrayOfImages.map((x, i) => {
            return <img src={x.imageUrl} alt={x.alt} />;
          })}
        </div>
        </div>
        <div className="feature1-main" style={{maxWidth: '1140px', margin: '130px auto 0', position: 'relative'}}>
          <div className="feature1-dots"></div>
          <div style={{background: 'white'}}>
      <div style={{ color: '#0067ff', textAlign: 'center', fontSize:isMobile? '30px': '60px', marginBottom: '1em' }}>
        Other LLMs that can be integrated
      </div>
      <div className="llmframework-feature2">
        <div className="feature2-table">
          <ul className="summary_lst">
            <li style={{ minHeight: '60px' }}>
              <span>AI21 Jur</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Aleph Alpha Luminous</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Baichuan LLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>ChatGLM-6B</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>GigaChat</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>GPT4All</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>IBM Watsonx.ai</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>KoboldAI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Moonshot LLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>MosaicML</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>NLP Cloud</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>OctoAI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>OpenLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Petals</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>SambaNova Sambaverse</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>SparkLLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Symbl.ai Nebula</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Together AI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Tongyi Qwen</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>vLLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Volc Engine Maas</span>
            </li>
          </ul>
        </div>
      </div>
      </div>
      </div>
      <div className="features-vectorbg">
        <div style={{ color: '#0067ff', textAlign: 'center', fontSize: '60px', marginBottom: '1em', marginTop: '1.5em' }}>
          <span style={{position: 'relative', top: '30px', fontSize: isMobile ? '26px' : ''}}>Vector stores we can work with</span>
        </div>
        <div className="llmframework-feature2" style={{borderRadius: '30px'}}>
          <div className="feature2-table" >
            <ul className="summary_lst">
              <li style={{ minHeight: '60px' }}><span>Activeloop Deep Lake</span></li>
              <li style={{ minHeight: '60px' }}><span>Aerospike Vector Search (AVS)</span></li>
              <li style={{ minHeight: '60px' }}><span>Alibaba Cloud OpenSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>ApertureDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Astra DB Vector Store</span></li>
              <li style={{ minHeight: '60px' }}><span>Atlas</span></li>
              <li style={{ minHeight: '60px' }}><span>AwaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Azure AI Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Baidu Cloud ElasticSearch VectorSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Baidu VectorDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Chroma</span></li>
              <li style={{ minHeight: '60px' }}><span>Clarifai</span></li>
              <li style={{ minHeight: '60px' }}><span>DashVector</span></li>
              <li style={{ minHeight: '60px' }}><span>Databricks Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>DingoDB</span></li>
              <li style={{ minHeight: '60px' }}><span>DocArray HnswSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>DocArray InMemorySearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Elasticsearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Epsilla</span></li>
              <li style={{ minHeight: '60px' }}><span>Faiss</span></li>
              <li style={{ minHeight: '60px' }}><span>Google BigQuery Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Google Vertex AI Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Hippo</span></li>
              <li style={{ minHeight: '60px' }}><span>Jaguar Vector Database</span></li>
              <li style={{ minHeight: '60px' }}><span>KDB.AI</span></li>
              <li style={{ minHeight: '60px' }}><span>Kinetica</span></li>
              <li style={{ minHeight: '60px' }}><span>LanceDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Lantern</span></li>
              <li style={{ minHeight: '60px' }}><span>ManticoreSearch VectorStore</span></li>
              <li style={{ minHeight: '60px' }}><span>Milvus</span></li>
              <li style={{ minHeight: '60px' }}><span>Momento Vector Index (MVI)</span></li>
              <li style={{ minHeight: '60px' }}><span>MongoDB Atlas Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Neo4j Vector Index</span></li>
              <li style={{ minHeight: '60px' }}><span>NucliaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>OpenSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Oracle AI Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>PGVector</span></li>
              <li style={{ minHeight: '60px' }}><span>Pinecone</span></li>
              <li style={{ minHeight: '60px' }}><span>Qdrant</span></li>
              <li style={{ minHeight: '60px' }}><span>Redis</span></li>
              <li style={{ minHeight: '60px' }}><span>SAP HANA Cloud Vector Engine</span></li>
              <li style={{ minHeight: '60px' }}><span>ScaNN</span></li>
              <li style={{ minHeight: '60px' }}><span>SemaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>SingleStoreDB</span></li>
              <li style={{ minHeight: '60px' }}><span>SQLite-VSS</span></li>
              <li style={{ minHeight: '60px' }}><span>SurrealDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Tencent Cloud VectorDB</span></li>
              <li style={{ minHeight: '60px' }}><span>ThirdAI NeuralDB</span></li>
              <li style={{ minHeight: '60px' }}><span>TiDB Vector</span></li>
              <li style={{ minHeight: '60px' }}><span>Timescale Vector (Postgres)</span></li>
              <li style={{ minHeight: '60px' }}><span>Upstash Vector</span></li>
              <li style={{ minHeight: '60px' }}><span>USearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Vald</span></li>
              <li style={{ minHeight: '60px' }}><span>Vearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Vectara</span></li>
              <li style={{ minHeight: '60px' }}><span>Vespa</span></li>
              <li style={{ minHeight: '60px' }}><span>viking DB</span></li>
              <li style={{ minHeight: '60px' }}><span>vlite</span></li>
              <li style={{ minHeight: '60px' }}><span>Weaviate</span></li>
            </ul>
          </div>
        </div>
      </div> */}
            <div className="welcome-page-24" ref={welcomePageRef} style={{ marginTop: '5em' }}>
                <div className="left-side">
                    <Sticky top={100} bottomBoundary={bottomBoundary}>
                        {({ style }) => (
                            <div className="left-side" style={style}>
                                {sections.map((section, index) => (
                                    <h2
                                        style={{ fontWeight: "normal", fontSize: "14px" }}
                                        key={index}
                                        className={`left-side-heading ${activeIndex === index ? "active" : ""
                                            }`}
                                        onClick={() =>
                                            window.scrollTo({
                                                top: contentRefs.current[index]?.offsetTop - 100,
                                                behavior: "smooth",
                                            })
                                        }
                                    >
                                        {section.title}
                                    </h2>
                                ))}
                            </div>
                        )}
                    </Sticky>
                </div>
                <div className="right-side" style={{ paddingRight: '0' }}>
                {sections && sections.map((section, index) => (
  <div
    key={index}
    ref={(el) => (contentRefs.current[index] = el)}
    className="content-section"
  >
    <h2>{section.title}</h2>
    <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
    
    {Array.isArray(section.parahs) && section.parahs.length > 0 && section.parahs.map((paragraph, paraIndex) => (
      <div key={paraIndex}>
        {paragraph.image && (
          <div className="new-logo-container" style={{ marginTop: '0',  display: 'flex', justifyContent: 'flex-start', paddingLeft: '1.5em' }}>
            <img src={paragraph.image.imageUrl} alt={paragraph.image.alt} />
          </div>
        )}
        {paragraph.text && (
          <p style={{ marginLeft: "1.6em" }}>
            {paragraph.text.includes(":") ? (
              <span>
                <strong>{paragraph.text.split(":")[0]}</strong>: {paragraph.text.split(":")[1]}
              </span>
            ) : (
              paragraph.text
            )}
          </p>
        )}
        {Array.isArray(paragraph.details) && paragraph.details.length > 0 && (
          <ul style={{ listStyle: "square", padding: "1.5em 0 2em 6em" }}>
            {paragraph.details.map((detail, detailIndex) => (
              <li key={detailIndex} style={{ padding: "0.5em" }}>
                {detail.includes(":") ? (
                  <span>
                    <strong>{detail.split(":")[0]}</strong>: {detail.split(":")[1]}
                  </span>
                ) : (
                  detail
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    ))}
  </div>
))}

                </div>
            </div>
            <div className="bottom-ai-services">
                <h1 style={{ fontSize: '25px', width: '80%' }}>Ready to transform your business analytics? <br/> Let FocusR help you take full advantage of Power BI and other leading analytics platforms to achieve operational excellence and data-driven success.</h1>
                <div className="button-container1">
                    <a
                        className="primary-btn-cus"
                        href="#careers"
                        style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
                        onClick={() => {
                            navigate("/contact");
                        }}
                    >
                        Learn More
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default PowerBI;
