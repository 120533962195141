import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const CustomDevelopment = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Troubleshooting and Issue Resolution",
      description:
        "Our troubleshooting and issue resolution services for Oracle environments are designed to address and resolve both common and rare issues quickly and effectively. We utilize advanced diagnostics tools and techniques to identify root causes and provide actionable solutions.",
      parah: "Technical Focus:",
      details: [
        "Error Log Analysis: We meticulously analyze log files and error messages in both Oracle EBS and Oracle Fusion environments to identify underlying issues. This includes database logs, application server logs, and specific module logs.",
        "Performance Bottlenecks: Identification and resolution of performance bottlenecks using Oracle AWR (Automatic Workload Repository) and ADDM (Automatic Database Diagnostic Monitor) reports.",
        "Patch Conflicts: We resolve conflicts that arise from patching activities, ensuring all patches applied are compatible with the existing Oracle environment.",
        "Debugging Custom Code: Our experts debug customizations and extensions within Oracle's RICEFW (Reports, Interfaces, Conversions, Extensions, Forms, and Workflows) components.",
        "Service Requests (SR): Filing and managing SRs with Oracle Support to expedite high-priority issues and escalate critical incidents as necessary.",
      ],
    },
    {
      title: "Performance Tuning",
      description:
        "We offer advanced performance tuning services to ensure that both your Oracle EBS and Oracle Fusion environments operate at peak efficiency. Our performance tuning encompasses both server-side and application-level optimizations.",
      parah: "Technical Focus:",
      details: [
        "Database Optimization: Tuning database parameters, optimizing SQL queries, and indexing strategies using Oracle's performance tuning advisors.",
        "Application Server Tuning: Fine-tuning Oracle WebLogic Server and Oracle Application Server configurations to maximize response times and throughput.",
        "Resource Allocation: Using Oracle Enterprise Manager (OEM) to monitor and allocate system resources effectively, including CPU, memory, storage, and network bandwidth.",
        "Concurrent Processing: Tuning Oracle EBS concurrent managers and concurrent programs for optimal job scheduling and execution.",
        "Caching Strategies: Implementing advanced caching solutions, including WebCache, to reduce latency and improve user experience.",
      ],
    },
    {
      title: "Patch Management",
      description:
        "Our comprehensive patch management services ensure that both Oracle EBS and Oracle Fusion environments are always up-to-date with the latest security patches, enhancements, and bug fixes.",
      parah: "Technical Focus:",
      details: [
        "Patch Strategy Planning: Developing a customized patch management strategy, which includes defining patching cycles, and identifying test and approval workflows.",
        "Patch Testing and Validation: Rigorous testing of patches in a controlled staging environment to ensure compatibility and functionality before production deployment.",
        "Automated Deployment: Leveraging tools such as Oracle OPatch and Oracle Enterprise Manager for automated, seamless deployment of patches across multiple environments.",
        "Security Patch Updates (SPU): Keeping your applications secure by regularly applying Oracle’s Critical Patch Updates (CPU) and Security Patch Updates (SPU).",
        "Rollback Procedures: Establishing and rehearsing rollback plans to ensure quick recovery in case a patch application does not go as planned.",
      ],
    },
    {
      title: "Ongoing Monitoring and Proactive Maintenance",
      description:
        "Our ongoing monitoring and proactive maintenance services ensure continuous stability and reliability of your Oracle environments, preventing issues before they arise.",
      parah: "Technical Focus:",
      details: [
        "Real-Time Monitoring: Deploying Oracle Enterprise Manager for real-time health check monitoring of databases, applications, and middleware components.",
        "Alerting Systems: Configuring diagnostic alerts for critical performance metrics and failure points, enabling rapid response to any potential issues.",
        "Scheduled Health Checks: Conducting regular health checks, including database integrity checks, application security audits, and compliance reviews.",
        "Capacity Planning: Forecasting future growth and scaling needs based on usage patterns, enabling proactive resource allocation.",
        "Disaster Recovery Planning: Implementing robust disaster recovery solutions using Oracle Data Guard and Oracle Recovery Manager (RMAN), ensuring minimal data loss and downtime.",
      ],
    },

    {
      title: "Configuration and Change Management",
      description:
        "We provide expert configuration and change management services to handle additions, modifications, and deprecations within your Oracle EBS and Oracle Fusion environments without disrupting business operations.",
      parah: "Technical Focus:",
      details: [
        "Configuration Management: Ensuring consistent configuration across environments (development, testing, and production), using Oracle Configuration Management tools.",
        "Change Control: Implementing stringent change control procedures, including impact analysis, and validation, to ensure any changes do not adversely affect system stability.",
        "Custom Workflow Deployment: Managing custom workflows and business processes configurations, ensuring they align with business needs and Oracle standards.",
        "Version Control: Utilizing version control systems for managing code scripts, database schemas, and configuration files.",
        "Audit Trails: Maintaining comprehensive audit trails and logs of all configuration changes and updates for compliance and traceability.",
      ],
    },
    {
      title: "Backup and Recovery Solutions",
      description:
        "Our comprehensive backup and recovery solutions for Oracle environments ensure data integrity and availability in case of any unexpected incidents. This includes both on-premise and cloud-based backup strategies tailored to meet your organization’s specific requirements.",
      parah: "Technical Focus:",
      details: [
        "RMAN (Recovery Manager): Leveraging Oracle RMAN for efficient backup and recovery processes, supporting full, incremental, and differential backups.",
        "Oracle Data Guard: Implementing Oracle Data Guard for high availability, data protection, and disaster recovery, ensuring minimal data loss and downtime.",
        "Automated Backup Schedules: Scheduling automated backups using Oracle Enterprise Manager or custom scripts, ensuring regular and reliable data protection.",
        "Data Archiving: Setting up data archiving processes to manage historical data efficiently, optimizing storage costs while maintaining data accessibility.",
        "Recovery Testing: Conducting periodic recovery drills to validate backup integrity and readiness, ensuring swift recovery during actual incidents.",
      ],
    },
    {
      title: "Security Management and Compliance",
      description:
        "We provide robust security management and compliance services to safeguard your Oracle environments against internal and external threats while ensuring adherence to industry regulations and standards.",
      parah: "Technical Focus:",
      details: [
        "Access Control: Implementing fine-grained access control policies using Oracle Advanced Security and Oracle IAM (Identity and Access Management) to manage user roles and permissions.",
        "Data Encryption: Utilizing Transparent Data Encryption (TDE) and Oracle Advanced Security to encrypt sensitive data both at rest and in transit.",
        "Security Audits: Conducting regular security audits and vulnerability assessments to identify potential security gaps and remediate them promptly.",
        "Compliance Monitoring: Ensuring ongoing compliance with industry standards such as GDPR, HIPAA, and SOX by implementing continuous monitoring and reporting mechanisms.",
        "Incident Response: Establishing robust incident response protocols to swiftly address and mitigate security breaches and anomalies.",
      ],
    },
    {
      title: "Custom Development and Enhancements",
      description:
        "Our custom development and enhancements services enable you to extend and tailor Oracle EBS and Oracle Fusion applications to meet your unique business requirements.",
      parah: "Technical Focus:",
      details: [
        "Custom Interfaces and Integrations: Developing custom interfaces to integrate Oracle applications with other enterprise systems using Oracle SOA Suite and Oracle Integration Cloud.",
        "Custom Reports and Dashboards: Creating tailored reports and interactive dashboards using Oracle BI Publisher, Oracle Analytics, and Oracle Reports.",
        "Extensions and Custom APIs: Building extensions and custom APIs to enhance existing functionalities, ensuring seamless operations and user experiences.",
        "Forms and Workflow Customization: Customizing Oracle Forms, Workflows, and Oracle ADF (Application Development Framework) applications to align with business processes.",
        "Quality Assurance: Implementing rigorous testing procedures including unit testing, integration testing, and user acceptance testing (UAT) to ensure high-quality deliverables.",
      ],
    },
    {
      title: "Database Administration Services",
      description:
        "Our comprehensive database administration services are designed to ensure the optimal performance, reliability, and security of your Oracle databases in both EBS and Fusion environments.",
      parah: "Technical Focus:",
      details: [
        "Database Provisioning: Initial setup and configuration of Oracle databases, including creating instances, configuring parameters, and setting up user access.",
        "Performance Monitoring: Continuous monitoring of database performance using Oracle Enterprise Manager to identify and address latency, bottlenecks, and resource contention issues.",
        "High Availability Solutions: Implementing Oracle RAC (Real Application Clusters) to provide high availability and scalability for critical applications.",
        "Data Integrity Checks: Performing regular consistency checks and validation using tools like Oracle DBMS_REPAIR and Data Pump.",
        "Upgrades and Migrations: Carrying out seamless database upgrades and migrations with minimal downtime using Oracle GoldenGate and Oracle Data Pump.",
      ],
    },
    {
      title: "Training and Knowledge Transfer",
      description:
        "We offer specialized training and knowledge transfer services to empower your team with the skills and knowledge required to manage and maintain Oracle environments effectively.",
      parah: "Technical Focus:",
      details: [
        "Customized Training Programs: Developing and delivering tailored training sessions focused on specific Oracle technologies and business processes.",
        "Hands-on Workshops: Conducting interactive workshops and labs, enabling participants to gain practical experience in managing Oracle applications and databases.",
        "Documentation and Manuals: Providing comprehensive documentation and user manuals for standard operating procedures, configurations, and custom functionalities.",
        "Knowledge Transfer Sessions: Facilitating knowledge transfer sessions post-implementation and during critical phases to ensure your internal team is well-equipped to handle routine operations.",
        "Continuous Learning: Offering e-learning modules and regular updates on new features, best practices, and emerging trends in Oracle technologies.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "50px",
            }}
          >
            Support & Maintenance Services
          </h1>
          <p style={{ fontSize: "20px" }}>
            At Focusr, we specialize in providing comprehensive support and
            maintenance services for Oracle E-Business Suite (EBS) and Oracle
            Fusion environments. Our offerings include advanced troubleshooting,
            performance tuning, patch management, ongoing monitoring, security
            management, backup and recovery, custom developmet, database
            administration, and training. By leveraging our extensive expertise,
            we ensure that your Oracle systems operate at peak efficiency and
            align seamlessly with your business objectives, delivering
            unparalleled stability, reliability, and performance.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>
                {section.parah}
              </p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <p style={{ marginLeft: "1.6em", marginTop: "2em" }}>
            With our extensive expertise in Oracle environments, Focusr ensures
            that your Oracle E-Business Suite and Oracle Fusion applications are
            robustly managed and maintained, delivering exceptional performance,
            reliability, and security. Our end-to-end support and maintenance
            services encompass a holistic approach, from advanced
            troubleshooting and performance tuning to proactive monitoring,
            patch management, and beyond. By leveraging our deep technical
            knowledge and cutting-edge tools, we align our services with your
            business objectives, enabling you to maximize the ROI on your Oracle
            investments. At Focusr, we are committed to empowering your
            organization to achieve operational excellence, scalability, and
            sustained growth through our unparalleled Oracle expertise.
          </p>
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CustomDevelopment;
