import { Col, Row, Form, message, Input, Space, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import officiallogo from '../assets/FocurR_arrow 1.svg';
import { useNavigate } from 'react-router-dom';
import { LuFacebook, LuLinkedin } from 'react-icons/lu';
import { TbBrandGmail } from 'react-icons/tb';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Footer.css'
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { CiMail } from "react-icons/ci";

import facebookIcon from '../assets/facebook-round-color-icon.png';
import linkedinIcon from '../assets/linkedin-app-icon.png';
import twitterIcon from '../assets/x-social-media-black-icon.png';
import instaIcon from '../assets/ig-instagram-icon.png';
import gmailIcon from '../assets/gmail.png'
import locationIcon from '../assets/google-maps.png'
import { color } from 'framer-motion';
import focusrLogo from '../assets/Footerlogo.png'

const Footer = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    AOS.init();
  }, []);
  

  const iconStyle = {
    width: '24px', // You can adjust the size here
    height: '24px',
    margin: '0 10px',
  };

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [newform] = Form.useForm();
  // const [email, setEmail] = useState();
  
  const onChange = (value) => {
    setVerified(value !== null);
  };

  const onFinish = (values) => {
    if (verified) {
      setLoading(true);

      axios
        .post("https://focusrblogsapi.focusrtech.com:91/blog/addsubscriber", {
          email: values.email, 
        })
        .then((res) => {
          console.log("Response after sending Contact Form", res);
          message.success("Subscribed");
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error while submitting", err);
          setLoading(false);
        });
    } else {
      message.error("Please verify reCAPTCHA");
    }
  };
  const onFinishFailed = () => {
    message.error("Please Fill Required Fields");
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 500 && window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
      setIsTablet(window.innerWidth > 500 && window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <div style={{ width: "100vw", backgroundColor: "rgb(240, 243, 248)" }}>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "90%", marginTop: "35px" }}>
        

          <Row className="custom-footer-row">
            <Col className="custom-footer-column">
              <div className="custom-footer-heading">Services</div>
              <div className="custom-footer-link" onClick={() => navigate('/services/Product-Development')}>Product Development</div>
              <div className="custom-footer-link" onClick={() => navigate('/services/ERP-Service')}>ERP Services</div>
              <div className="custom-footer-link" onClick={() => navigate('/services/AI')}>Artificial Intelligence / RPA</div>
              <div className="custom-footer-link" onClick={() => navigate('/services/Out-Sourcing')}>Out Sourcing</div>
            </Col>

            <Col className="custom-footer-column">
              <div className="custom-footer-heading">Platforms</div>
              <div className="custom-footer-link" onClick={() => navigate('/platforms/Cloud')}>Cloud</div>
              <div className="custom-footer-link" onClick={() => navigate('/platforms/Oracle')}>Oracle</div>
              <div className="custom-footer-link" onClick={() => navigate('/platforms/SAP')}>SAP</div>
            </Col>

            <Col className="custom-footer-column">
              <div className="custom-footer-heading">Products</div>
              <div className="custom-footer-link" onClick={() => navigate('/products/TechstepHub')}>TechstepHub</div>
              <div className="custom-footer-link" onClick={() => navigate('/products/Techzard')}>Techzard</div>
              <div className="custom-footer-link" onClick={() => navigate('/products/SupplySyncX')}>SupplySyncX</div>
              <div className="custom-footer-link" onClick={() => navigate('/products/Planify')}>Planify</div>
              <div className="custom-footer-link" onClick={() => navigate('/products/ScanlogiX')}>ScanlogiX</div>
              <div className="custom-footer-link" onClick={() => navigate('/products/Quizlink')}>Quizlink</div>
            </Col>

            <Col className="custom-footer-column">
              <div className="custom-footer-heading">Office</div>
              <div className="custom-footer-location">Corporate Office<br />Chennai</div>
              <div className="custom-footer-location">Competency & Product Division<br />Salem</div>
              <div className="custom-footer-location">Digital & AI Technologies<br />Malaysia</div>
              <div className="custom-footer-location">Consultancy and Technology<br />Singapore</div>
              <div className="custom-footer-location">Digital Transformation <br/> USA</div>
              <div className="custom-footer-location">Consultancy and Technology <br/> UAE</div>
            </Col>

            <Col className="custom-footer-column">
              <div className="custom-footer-heading">Newsletter</div>
              <div className="custom-footer-heading" style={{ color: "#5F6368", marginTop: "0.5em" }}>
                Register now to get latest
                <br />
                updates
                <br />
              </div>

              <div className="custom-footer-newsletter">
                <div style={{width:"100%"}}>
                <Form
  form={form}
  onFinish={onFinish}
>
  <Form.Item
    name="email"
    rules={[
      {
        type: 'email',
        message: 'The input is not valid E-mail!',
      },
      {
        required: true,
        message: 'Please input your E-mail!',
      },
    ]}
  >
    <Input
      placeholder="Your email address"
      style={{
        height: "40px",
        width: "50%",
        backgroundColor: "#F6F5F2",
        marginBottom: "10px",
      }}
    />
  </Form.Item>

  <div style={{
    height: "fit-content",
    width: "100%",
    backgroundColor: "#F6F5F2",
    marginBottom: "15px",
  }}>
    <ReCAPTCHA
      sitekey="6LdWF7ApAAAAABLvVOKY0om3CK-cJwmSqbJqviPv"
      onChange={onChange}
    />
  </div>

  <div style={{ width: "100%" }}>
    <Space>
      <Button
        size="large"
        type="primary"
        htmlType="submit"
        style={{
          background: "#2e5be0",
          display: "flex",
          alignItems: "center",
          width: "8em",
          justifyContent: "space-between",
          marginTop: "-1em",
        }}
        loading={loading}
      >
        {loading ? "Loading" : "Subscribe"} <CiMail />
      </Button>
    </Space>
  </div>
</Form>
                </div>
              </div>
            </Col>
          </Row>

          
        </div>
      </div>

      <div style={{width:"100%", display:"flex", justifyContent:"center", marginBottom:"20px", marginTop:"20px"}}>
      <p style={{ margin: '0', padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <span style={{ marginRight: '15px' }}>info@focusrtech.com</span>
      {!isMobile&&
      <span style={{ marginRight: '15px' }}>|</span>
      }
      <span style={{marginTop: isMobile?"5px":""}}>Call: +91 44 45500999</span>
      </p>

        
      </div>

       <div style={{width:"100%", display:"flex", justifyContent:"center"}}>             
       <hr style={{ border: "none", borderTop: "2px solid grey", width: "70%" }} />

      </div>  

      <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
          <div style={{width:"fit-content", marginTop:"30px"}}>
              <a href="https://www.facebook.com/focusrtechnologies/" target="_blank" rel="noopener noreferrer">
        <img src={facebookIcon} alt="Facebook" style={iconStyle} />
      </a>
      <a href="https://www.linkedin.com/company/focusr-consultancy-and-technologies-pvt-ltd/" target="_blank" rel="noopener noreferrer">
        <img src={linkedinIcon} alt="LinkedIn" style={iconStyle} />
      </a>

      

      <a href="mailto:info@focusrtech.com" target="_blank" rel="noopener noreferrer">
        <img src={gmailIcon} alt="Twitter" style={iconStyle} />
      </a>

      <a href="https://www.google.com/maps/place/FocusR+Consultancy+and+Technologies+Private+Limited/@13.0872497,80.2118551,15z/data=!4m6!3m5!1s0x3a5264224052ea55:0x841cfbc882321b36!8m2!3d13.0872497!4d80.2118551!16s%2Fg%2F1q2w8c9kx?entry=ttu" target="_blank" rel="noopener noreferrer">
        <img src={locationIcon} alt="Twitter" style={iconStyle} />
      </a>
          </div>
        
      </div>

      <ul style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  width: "100%",
  maxWidth: "780px",
  margin: "20px auto",
  listStyleType: "none",
  padding: 0
}}>
  
  <li><a onClick={() => navigate('/contact')} style={{ color: "#5f6368", cursor:"pointer" }}>Help</a></li>
  <span style={{ margin: "0 8px" }}>|</span>
  
  
  
  <li><a onClick={() => navigate('/privacypolicy')} style={{ color: "#5f6368", cursor:"pointer" }}>Privacy</a></li>
  <span style={{ margin: "0 8px" }}>|</span>
  
  
  <li><a onClick={() => navigate('/terms')} style={{ color: "#5f6368", cursor:"pointer" }}>Terms</a></li>
</ul>




      

      <div style={{
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  height: "fit-content",
  backgroundColor: "rgb(83, 102, 117)",
  padding: "20px 0"  
}}>
  <div style={{
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center'
  }}>
    <img
      src={focusrLogo}
      width="70px"
      height="auto"
      alt="logo"
      style={{
        cursor: 'pointer',
      }}
    />
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "rgb(207, 207, 210)", textAlign: "center", width: isMobile?"50%":"", marginTop:"10px" }}>
    {/* <span style={{ display: "flex", justifyContent: "center",alignItems:"center" ,color: "rgb(207, 207, 210)", fontSize: "30px" }}>
  &copy;
</span> */}
<span style={{ color: "rgb(207, 207, 210)" }}>&copy; 2024, FocusR Consultancy and Technologies Pvt. Ltd. All Rights Reserved.</span>

</div>

  </div>
</div>

    </div>
  );
};

export default Footer;
