import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const CustomDevelopment = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Advanced Planning and Scheduling Capabilities",
      description:
        "Our expertise in Oracle Primavera enables us to deliver sophisticated planning and scheduling solutions tailored to the unique needs of your projects. By leveraging Primavera’s robust capabilities, we facilitate:",

      details: [
        "Critical Path Method (CPM) Analysis: Utilizing Primavera's CPM scheduling to conduct advanced temporal analysis, we pinpoint sequence-sensitive activities that define the project's minimum duration. This methodology enables the identification and mitigation of schedule risks such as float consumption and resource bottlenecks.",
        "Resource Loading and Leveling: Employing resource loading techniques to assign workforce, equipment, and materials within the project schedule. Advanced resource leveling algorithms are applied to minimize peaks and troughs in resource demand, ensuring uniform utilization and preventing over-allocation scenarios.",
        "Baseline Performance Management: Implementing baseline management protocols to capture planned project metrics. Through periodic performance reviews and deviation analysis, we deploy corrective actions leveraging Primavera’s baseline comparison features to ensure adherence to strategic project objectives.",
        "Integrated Cost and Schedule Management: Integrating cost datasets with project schedules to execute comprehensive Earned Value Management (EVM). This integration enables precise financial forecasting and contingency planning based on real-time performance variances detected using Primavera's analytics.",
        "Multi-Project Portfolio Management: Managing interdependent projects with Primavera's multi-project scheduling capabilities. This involves creating integrated master schedules that provide a holistic view of resource allocation, project interconnectivity, and management of global changes across the portfolio.",
      ],
    },
    {
      title: "Risk Analysis and Mitigation",
      description:
        "Oracle Primavera's advanced risk analysis tools allow us to identify, assess, and mitigate risks effectively, ensuring project robustness and resilience. Key services include:",
      details: [
        "Monte Carlo Simulative Forecasting: Utilizing Monte Carlo simulations to project a range of potential outcomes across probabilistic models. By iterating thousands of potential scenarios, we derive statistically significant insights for preemptive risk management and scenario-based decision-making.",
        "Quantitative Risk Analysis (QRA): Conducting in-depth QRA to quantify risk impacts on cost and schedule. This involves sensitivity analysis and application of risk quantification models (e.g., PERT distribution) to predict the probabilistic distribution of project KPIs.",
        "Dynamic Risk Register Integration: Developing dynamic risk registers equipped with real-time tracking capabilities. Risk registers are integrated into Primavera P6 for automated update cycles and synchronized risk management workflows.",
        "Stochastic Scenario Planning: Crafting multiple stochastic scenarios to analyze potential disruptions. This includes iterative what-if analyses that test resilience strategies under varied assumptions, offering strategic agility against unforeseen events.",
        "Risk Contingency Allocation: Establishing and allocating risk contingency reserves using advanced risk management principles. This allows for the creation of flexible funding models that can quickly adapt to emerging project risks without halting progress.",
      ],
    },
    {
      title: "Collaboration and Communication",
      description:
        "With Oracle Primavera, we enhance collaboration and communication across all project stakeholders, ensuring a unified approach to project management. Our services include:",
      details: [
        "Enterprise-level Document Repository: Implementing enterprise-grade document management systems that facilitate centralized storage, version control, and secure access. Enhanced indexing and retrieval mechanisms ensure rapid information dissemination among global teams.",
        "Granular Role-Based Access Control (RBAC): Designing RBAC frameworks that adhere to the principle of least privilege. Detailed permission sets ensure data integrity and confidentiality, while facilitating seamless collaboration among multi-disciplinary teams.",
        "Real-Time Executive Dashboards: Developing real-time, interactive dashboards using Oracle Primavera’s reporting tools. These dashboards offer comprehensive views into project health, schedule adherence, resource allocation, and risk exposure.",
        "Unified Communication Platforms: Integrating unified communication and collaboration solutions (e.g., Oracle Social Network) directly with Primavera P6, streamlining discussions, document sharing, and decision-making processes.",
        "Automated Workflow Engines: Deploying automated workflow engines to manage project lifecycles. This includes configuring workflow templates for key activities such as approvals, change requests, and milestone completions, significantly reducing manual intervention and errors.",
      ],
    },
    {
      title: "Custom Reporting and Analytics",
      description:
        "Our expertise in Oracle Primavera extends to creating custom reports and analytics that provide actionable insights into project performance. Our technical services include:",
      details: [
        "Tailored BI Report Creation: Customizing Business Intelligence (BI) reports specific to project stakeholders' requirements. Utilizing Oracle BI Publisher integrated with Primavera P6, we generate detailed reports that synthesize complex datasets into actionable insights.",
        "Custom KPI Frameworks: Developing and configuring custom KPIs using Oracle Primavera’s extensible framework. This includes creating performance metrics that are precisely aligned with strategic objectives and operational benchmarks.",
        "Comprehensive Data Integration: Facilitating the seamless integration of disparate data sources into Primavera’s central repository. Advanced ETL (Extract, Transform, Load) processes ensure consistent data formats and enhance the integrity and reliability of analytics.",
        "High-dimensional Data Analytics: Leveraging high-dimensional data analysis techniques to uncover deep insights. This includes time-series analysis, anomaly detection, and multivariate regression models to predict project outcomes and optimize performance measures.",
        "Interactive Data Visualization: Configuring dynamic and interactive data visualizations using Oracle's data visualization tools. This approach empowers stakeholders to explore data interactively, apply filters, and generate ad-hoc reports intuitively.",
      ],
    },

    {
      title: "Implementation and Training",
      description:
        "We deliver comprehensive implementation and training services for Oracle Primavera, ensuring your team can leverage its full capabilities effectively. Our offerings include:",
      details: [
        "Comprehensive Implementation Roadmaps: Developing detailed implementation blueprints that encapsulate end-to-end deployment processes. These roadmaps encompass initiation, planning, execution, monitoring, and closure phases, with specific milestones and deliverables.",
        "Custom System Configuration: Tailoring Oracle Primavera settings to fit organizational processes and standards. This includes configuring global data dictionaries, enterprise project structures (EPS), organizational breakdown structures (OBS), and custom fields.",
        "Efficient Data Migration Strategies: Designing robust data migration frameworks to transition legacy datasets into Primavera P6. Utilizing automated data migration tools and validation scripts ensures data integrity and minimizes business disruption.",
        "Specialized Training Modules: Creating comprehensive training curricula that cover various proficiency levels—from novice users to advanced project managers. Training methods include hands-on workshops, virtual labs, detailed user guides, and e-learning modules.",
        "Ongoing System Optimization: Providing continuous system optimization services, including routine health checks, performance tuning, and application of best practices. This ensures the Primavera environment remains aligned with evolving requirements and maximizes return on investment.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "50px",
            }}
          >
            Oracle Primavera
          </h1>
          <p style={{ fontSize: "158x" }}>
            We specialize in implementing Oracle Primavera, providing an
            extensive range of advanced project portfolio management
            capabilities. Our services encompass sophisticated planning and
            scheduling, rigorous risk analysis and mitigation, enhanced
            collaboration, custom reporting and analytics, and seamless
            implementation and training. By leveraging Oracle Primavera’s robust
            tools and our technical expertise, we enable organizations to manage
            complex projects with precision, ensuring optimal performance and
            successful outcomes.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CustomDevelopment;
