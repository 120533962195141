// import React, { useEffect, useState } from 'react';
// // import Navbar from "../../Components/Navbar";
// // import Contactform from "../../Components/Contactform";
// // import Footer from "../../Components/Footer";
// import { FloatButton, Modal, Button, Checkbox, Form, Input, message } from 'antd';
// import { RxVideo } from 'react-icons/rx';
// import { BiVideo, BiSolidBot, BiPlay } from 'react-icons/bi';
// import ReactPlayer from 'react-player';
// import Navbar from '../Components/Navbar';
// import Contactform from '../Components/Contactform';
// import Footer from '../Components/Footer';
// import CardCount from '../Components/CardCount';
// import manImage from '../assets/man and woman working with laptop.png';
// import { MdLocationOn } from 'react-icons/md';
// import { BsFillBuildingFill } from 'react-icons/bs';
// import FocusRMap from '../assets/Map-Of-FocusR.svg';
// import IndianFlag from '../assets/India (IN).png';
// import AmericanFlag from '../assets/United States of America (US).png';
// import DubaiFlag from '../assets/United Arab Emirates (AE).png';
// import MalaysiaFlag from '../assets/Malaysia (MY).png';
// import SingaporeFlag from '../assets/Singapore (SG).png';
// import { FiArrowRight } from 'react-icons/fi';
// // import videoOne from '../assets/video-7-T.mp4';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import BreadCrum from '../Components/BreadCrum';
// import ChatNew from '../Components/Chat2';
// import axios from 'axios';
// const Company = () => {
//   const [newform] = Form.useForm();
//   const [loading, setLoading] = useState(false);
//   const [open, setOpen] = useState(false);
//   const [jobTitlestate, setJobtitlestate] = useState('');
//   // const showModal = () => {
//   //   setOpen(true);
//   // };
//   const [messageApi, contextHolder] = message.useMessage();
//   const success = () => {
//     messageApi.open({
//       type: 'success',
//       content: `You've Sucessfully Applied for ${jobTitlestate} role \n Our HR Team will contact you shortly`,
//       duration: 5
//     });
//   };
//   const error = () => {
//     messageApi.open({
//       type: 'error',
//       content: 'This is an error message',
//       duration: 5
//     });
//   };
//   const perjob = (x) => {
//     console.log('Per-Job : ', x);
//     setOpen(true);
//     setJobtitlestate(x);
//   };
//   const handleOk = () => {
//     setLoading(true);
//     onFinish();
//     setTimeout(() => {
//       setLoading(false);
//       setOpen(false);
//     }, 3000);
//   };
//   const handleCancel = () => {
//     setOpen(false);
//   };
//   const onFinish = (values) => {
//     /*
//     {
//     "first_Name": "John",
//     "last_Name": "Doe",
//     "email": "john.doe@example.com",
//     "mobile": "1234567890",
//     "notice_Period": "30 days",
//     "years_Of_Experience": "5",
//     "skills": "Python, Django, JavaScript",
//     "applied_Role": "Software Developer"
// }
//     */
//     console.log('Success:', values);
//     axios
//       .post('https://focusrapi.focusrtech.com:85/post/apply', {
//         first_Name: newform.getFieldValue('firstname'),
//         last_Name: newform.getFieldValue('lastname'),
//         email: newform.getFieldValue('email'),
//         mobile: newform.getFieldValue('mobile'),
//         notice_Period: newform.getFieldValue('noticeperiod'),
//         years_Of_Experience: newform.getFieldValue('experience'),
//         skills: newform.getFieldValue('skills'),
//         applied_Role: jobTitlestate
//       })
//       .then((res) => {
//         console.log('Resposne after send Contact Form', res);
//         success();
//         newform.resetFields();
//         setOpen(false);
//       })
//       .catch((err) => {
//         message.error(`Error while submitting form${err}`);
//       });
//     newform.resetFields();
//   };
//   const onFinishFailed = (errorInfo) => {
//     console.log('Failed:', errorInfo);
//     error();
//   };

//   useEffect(() => {
//     document.title = 'About | Careers';
//     AOS.init();
//   });

//   const jobDetails = [
//     {
//       id: 1,
//       jobTitle: 'Oracle EBS Finance Functional Consultant',
//       location: 'Delhi, NCR',
//       workType: 'Work From Office',
//       jobDescription:
//         'We Are Hiring For A 2+ years of experience in oracle finance modules such as AP, AR, GL, FA etc., Strong experience in GST, Ebtax.'
//     },
//     {
//       id: 2,
//       jobTitle: 'Fullstack Developer',
//       location: 'Salem',
//       workType: 'Work From Office',
//       jobDescription:
//         'We Are Hiring For A 4+ yrs in Java, Spring, Web services, Spring Boot based REST API development . Strong SQL experience with the ability to write Complex Queries, and Triggers. Strong understanding of J2EE'
//     },
//     {
//       id: 3,
//       jobTitle: 'DBA - Core / APPS',
//       location: 'Salem',
//       workType: 'Work From Office',
//       jobDescription:
//         ' Core DBA with good knowledge in Database Upgrade, Migration, Patch Installation 12c &19c versions and Implementation. Oracle apps DBA experience added advantage'
//     },
//     {
//       id: 4,
//       jobTitle: 'Oracle Technical Consultant',
//       location: 'Salem',
//       workType: 'Work From Office',
//       jobDescription:
//         'We Are Hiring For A Minimum 2+ years of relevant experience in Oracle Apps R12. Experience on interfaces, Oracle forms, Oracle reports and workflows. Strong in SQL and PL/SQL . Experience in implementation/Support projects on R12'
//     },
//     {
//       id: 4,
//       jobTitle: 'Oracle HRMS/HCM Functional Consultant',
//       location: 'Salem',
//       workType: 'Work From Office',
//       jobDescription:
//         'We Are Hiring For A 2-5 yrs exp with EBS R12 & in Oracle Upgrade Implementations.Expertise in Oracle EBS R12 HRMS –Core HR, Absence Management, OAB, Compensation, Payroll.Have been part of various Implementation as well as support projects in Oracle HRMS/HCM.'
//     },
//     {
//       id: 4,
//       jobTitle: 'Oracle Developer- PL/SQL',
//       location: 'Salem',
//       workType: 'Work From Office',
//       jobDescription:
//         'We Are Hiring For A 2+ years of strong exp in Oracle PL/SQL and Unix Shell scripting, Oracle 11g, Forms 6i/10g/11g & Reports. Should be interested in moving to Oracle apps technical & Should not have any travel constraints'
//     },
//     {
//       id: 4,
//       jobTitle: 'SAP BASIS Consultant',
//       location: 'Salem',
//       workType: 'Work From Office',
//       jobDescription:
//         'We Are Hiring For A 2+ yrs exp and Good in Communication &client exposure. Must have 1 implementation and Support project experience in SAP ECC 6 & S4 HANA (Support ). Should not have travel constraints'
//     },
//     {
//       id: 4,
//       jobTitle: 'SAP FICO Consultant',
//       location: 'Salem',
//       workType: 'Work From Office',
//       jobDescription:
//         'We Are Hiring For A 3+ yrs exp team member with good communication & client exposure.Must have 2 implementation and Support project experience in SAP ECC 6 & S4 HANA.Good in Finance modules (AP / AR/ CM/ FA/ GL).'
//     },
//     {
//       id: 4,
//       jobTitle: ' Oracle Finance Functional Consultant - Domain Trainee',
//       location: 'Salem',
//       workType: 'Work From Office',
//       jobDescription:
//         'We Are Hiring For A CA Final / CA Final/ICWA Final/ICWA Completed with 3+ years of articleship / Domain experience · MBA - Finance, B. Com/ M. Com - with not less than 75% score in academic & 3+ years of domain experience in Financial Accounting, Taxation (GST, TDS)'
//     },
//     {
//       id: 4,
//       jobTitle: 'Oracle apps finance Functional consultant',
//       location: 'Salem',
//       workType: 'Work From Office',
//       jobDescription:
//         'We Are Hiring For A Candidate should possess 4+ years of experience in Oracle EBS Finance Modules Configuration with at least three implementations. Strong Knowledge of Oracle R12 Modules AP, AR, FA, GL, India localization and Project Accounting (Chennai & NCR)'
//     }
//   ];
//   return (
//     <div>
//       {/* navbar here */}
//       <Navbar mode="dark" />
//       <ChatNew />
//       {/* First Page starts */}
//       <div className="first-page-wrapper-company">
//         {/* <video autoPlay loop muted className="videoBg-1">
//           <source src={videoOne} type='video/mp4'/>
//         </video> */}
//         <div className="inner-page-left-product-dealerportal">
//           {/* <span  style={{
//           fontWeight:'600'
//         }}>
//             FocusR <FiArrowRight /> About <FiArrowRight /> Company
//           </span> */}
//           {/* <BreadCrum color="#fff" items={['FocusR', 'About', 'Company']} /> */}
//           <br />
//           <h1
//             style={{
//               color: '#fff'
//             }}
//           >
//             Join us in creating state of the art solutions
//           </h1>
//           <br />
//           <p
//             style={{
//               color: '#fff'
//             }}
//           >
//             At FocusR, you will find trusted colleagues, committed senior management and a culture that thrives on
//             innovation and cross-collaboration
//           </p>
//           <br />
//           <br />
//           <a className="primary-btn-newcus" href="#careers">
//             Grow with us
//           </a>
//         </div>
//       </div>

//       <div className="div-prod">
//         <h1 className="div-prod-new-h1" >
//           Being on the market alive, we have managed to gain the trust and respect from our clients. Long-term
//           relationships and happy customers have always been our main goals.
//         </h1>
//       </div>
//       <div className="our-Values">
//         <h1>
//           Our Values
//         </h1>
//         <ul>
//           <li>
//             <div>
//               <h2 className='h2-values'>Transparency</h2>

//               <p className='p-values'>
//                 At FocusR, we believe that honesty with clients, partners, and
//                 <br />
//                 employees is a fundamental step to creating great products.
//               </p>
//             </div>
//           </li>

//           <div >
//             <h2 className='h2-values'>Flexibility</h2>

//             <p className='p-values'>
//               Our team quickly responds to new requirements and changes things
//               <br />
//               to the elaborate development process and no bureaucracy.
//             </p>
//           </div>

//           <div >
//             <h2 className='h2-values'>Quality</h2>

//             <p className='p-values'>
//               We control product quality at all levels due to collecting input
//               <br />
//               requirements and defining a realistic scope of work in the early stage.
//             </p>
//           </div>

//           <div >
//             <h2 className='h2-values'>Professionalism</h2>
//             <p className='p-values'>
//               Our software engineering teams consist of high-qualified and certified <br /> developers with domain
//               expertise across industries.
//             </p>
//           </div>
//         </ul>
//       </div>

//       <section className="careers" id="careers">
//         <div className="careers-top">
//           <h1 >
//             Open Vacancies
//           </h1>
//           <br/>
//           <p >
//             Climb new career heights with us! Send a CV to the vacancy suiting you best, and join the FocusR team!
//           </p>
//         </div>
//         <br />
//         <br />
//         {jobDetails.map((x, i) => {
//           return (
//             <div className="career-item" >
//               <div className="career-item-left">
//                 <h1>{x.jobTitle}</h1>
//                 <div className="career-item-left-inner">
//                   <p>
//                     <MdLocationOn className="job-icon" />
//                     {x.location}
//                   </p>
//                   <p>
//                     <BsFillBuildingFill className="job-icon" />
//                     {x.workType}
//                   </p>
//                 </div>
//               </div>
//               <div className="career-item-right">
//                 <p>{x.jobDescription}</p>
//                 <button className="lite-btn" onClick={() => perjob(x.jobTitle)}>
//                   Apply Now
//                 </button>
//               </div>
//             </div>
//           );
//         })}
//       </section>
//       {/* first page ends here */}

//       {/* focusR Map Ends Here */}
//       <Modal open={open} title={jobTitlestate} onOk={handleOk} onCancel={handleCancel} footer={false}>
//         <Form
//           name="basic"
//           labelCol={{
//             span: 8
//           }}
//           wrapperCol={{
//             span: 16
//           }}
//           style={{
//             minWidth: '100%'
//           }}
//           initialValues={{
//             remember: true
//           }}
//           onFinish={onFinish}
//           onFinishFailed={onFinishFailed}
//           autoComplete="off"
//           form={newform}
//         >
//           <Form.Item
//             label="FirstName"
//             name="firstname"
//             rules={[
//               {
//                 required: true,
//                 message: 'Please input your FirstName!'
//               }
//             ]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             label="LastName"
//             name="lastname"
//             rules={[
//               {
//                 required: true,
//                 message: 'Please input your LastName!'
//               }
//             ]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             label="Email"
//             name="email"
//             rules={[
//               {
//                 required: true,
//                 message: 'Please input your Email!'
//               },
//               {
//                 type: 'email'
//               }
//             ]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             label="Mobile"
//             name="mobile"
            
//             rules={[
//               {
//                 required: true,
//                 message: 'Please input your Mobile No!'
//               }
//             ]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             label="Notice Period"
//             name="noticeperiod"
//             rules={[
//               {
//                 required: true,
//                 message: 'Please input your Notice Period!'
//               }
//             ]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             label="Years of Experience"
//             name="experience"
//             rules={[
//               {
//                 required: true,
//                 message: 'Please input your Experience!'
//               }
//             ]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             label="Skills"
//             name="skills"
//             rules={[
//               {
//                 required: true,
//                 message: 'Please input your Skills!'
//               }
//             ]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             wrapperCol={{
//               offset: 8,
//               span: 16
//             }}
//           >
//             <Button type="primary" htmlType="submit">
//               Submit
//             </Button>
//           </Form.Item>
//         </Form>
//       </Modal>
//       <div className="explanation-wrapper-product">{/* empty space for now */}</div>
//       {/* <div id="Contact-Form">
//         <Contactform />
//       </div> */}
//       <Footer />
//       {contextHolder}
//     </div>
//   );
// };

// export default Company;



import React, { useEffect, useState } from "react";

import { UploadOutlined } from "@ant-design/icons";
import {
  FloatButton,
  Modal,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Upload,
} from "antd";
import { RxVideo } from "react-icons/rx";
import { BiVideo, BiSolidBot, BiPlay } from "react-icons/bi";
import ReactPlayer from "react-player";
import Navbar from "../Components/Navbar";
import Contactform from "../Components/Contactform";
import Footer from "../Components/Footer";
import CardCount from "../Components/CardCount";
import manImage from "../assets/man and woman working with laptop.png";
import { MdLocationOn } from "react-icons/md";
import { MdBadge } from "react-icons/md";
import { BsFillBuildingFill } from "react-icons/bs";
import FocusRMap from "../assets/Map-Of-FocusR.svg";
import CareersImf from "../assets/carriers-img3.svg";
import IndianFlag from "../assets/India (IN).png";
import AmericanFlag from "../assets/United States of America (US).png";
import DubaiFlag from "../assets/United Arab Emirates (AE).png";
import MalaysiaFlag from "../assets/Malaysia (MY).png";
import SingaporeFlag from "../assets/Singapore (SG).png";
import { FiArrowRight } from "react-icons/fi";
import ReCAPTCHA from "react-google-recaptcha";
// import videoOne from '../assets/video-7-T.mp4';
import AOS from "aos";
import "aos/dist/aos.css";
import BreadCrum from "../Components/BreadCrum";
import ChatNew from "../Components/Chat2";
import axios from "axios";
import { GrUserExpert } from "react-icons/gr";
import './Company.css'
import { useNavigate } from "react-router-dom";
// import focusrlogo from '../../assests/Images'

const Company = () => {
  const [newform] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [jobTitlestate, setJobtitlestate] = useState("");
  const [jobIdstate, setJobIdstate] = useState("");
  const [jobDetails, setJobDetails] = useState([]);
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [activeTab, setActiveTab] = useState("Transparency");
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 500 && window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
      setIsTablet(window.innerWidth > 500 && window.innerWidth <= 900)
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tabContent = {
    Transparency:
      "At FocusR, we believe that honesty with clients, partners, and employees is a fundamental step to creating great products.",
    Flexibility:
      "Our team quickly responds to new requirements and changes things to the elaborate development process and no bureaucracy.",
    Quality:
      "We control product quality at all levels due to collecting input requirements and defining a realistic scope of work in the early stage.",
    Professionalism:
      "Our software engineering teams consist of high-qualified and certified developers with domain expertise across industries.",
  };
  // const showModal = () => {
  //   setOpen(true);
  // };
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content: `You've Sucessfully Applied for ${jobTitlestate} role \n Our HR Team will contact you shortly`,
      duration: 5,
    });
  };
  const error = () => {
    // messageApi.open({
    //   type: "error",
    //   content: "This is an error message",
    //   duration: 5,
    // });
  };
  const perjob = (title, id) => {
    console.log("Per-Job : ", title);
    console.log("Per-Job ID: ", id);
    setOpen(true);
    setJobtitlestate(title);
    setJobIdstate(id);
  };

  const handleOk = () => {
    setLoading(true);
    onFinish();
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  //   const onFinish = (values) => {
  //     /*
  //     {
  //     "first_Name": "John",
  //     "last_Name": "Doe",
  //     "email": "john.doe@example.com",
  //     "mobile": "1234567890",
  //     "notice_Period": "30 days",
  //     "years_Of_Experience": "5",
  //     "skills": "Python, Django, JavaScript",
  //     "applied_Role": "Software Developer"
  // }
  //     */
  //     console.log("Success:", values);
  //     axios
  //       .post("https://focusrapi.focusrtech.com:85/post/apply", {
  //         name: newform.getFieldValue("name"),
  //         email: newform.getFieldValue("email"),
  //         mobile: newform.getFieldValue("mobile"),
  //         notificationPeriod: newform.getFieldValue("notificationPeriod"),
  //         yearsOfExperience: newform.getFieldValue("yearsOfExperience"),
  //         llmskills: newform.getFieldValue("llmskills"),
  //         applied_Role: jobTitlestate,
  //       })
  //       .then((res) => {
  //         console.log("Resposne after send Contact Form", res);
  //         success();
  //         newform.resetFields();
  //         setOpen(false);
  //       })
  //       .catch((err) => {
  //         message.error(`Error while submitting form${err}`);
  //       });
  //     newform.resetFields();
  //   };
  const onChange = (value) => {
    // value will be null if reCAPTCHA was not verified
    setVerified(value !== null);
  };
  useEffect(() => {
    AOS.init();
  });

  const onFinish = (values) => {
    if (verified) {
      // Proceed with form submission
      console.log("Success:", values);

      const formData = new FormData();
      const jsonData = {
        name: newform.getFieldValue("name"),
        email: newform.getFieldValue("email"),
        mobile: newform.getFieldValue("mobile"),
        notificationPeriod: newform.getFieldValue("notificationPeriod"),
        yearsOfExperience: newform.getFieldValue("yearsOfExperience"),
        llmskills: newform.getFieldValue("llmskills"),
        jobId_id: jobIdstate,
      };

      formData.append("json", JSON.stringify(jsonData));

      if (fileList.length > 0) {
        const file = fileList[0].originFileObj;
        formData.append("file", file);
      }
      setLoading(true);
      axios
        .post(
          "https://hireflowapi.focusrtech.com:90/hiring/entryLevel/applyJob",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log("Response after sending Contact Form", res);
          setLoading(false);
          success();
          newform.resetFields();
          setFileList([]);
          setFile(null);
          setOpen(false);
        })
        .catch((err) => {
          // error();
          setLoading(false);
          error(`Error while submitting form${err}`);
        });
    } else {
      message.error(`Please verify reCAPTCHA`);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    error();
  };

  useEffect(() => {
    axios
      .get(
        `https://hireflowapi.focusrtech.com:90/hiring/entryLevel/postJob`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("this is response data", res.data);
        setJobDetails(res.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);
  useEffect(() => {
    document.title = "About | Careers";
    AOS.init();
  });

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList.slice(-1));
  };

  const handleJobClick = (x) => {
    navigate(`/careers/${x.id}`, { state: { x } });
  };

  
  return (
    <div>
    <Navbar mode="dark"/>
      <ChatNew />
    <div style={{marginTop:"0px"}}>
      
      <div className="custome-first-page-wrapper-company">
        
        <div style={{height:"fit-content"}}>
        <div className="custome-inner-page-left-product-dealerportal">
          
          <br />
          <div class="custome-futuRred1">
            <h1 class="custome-futuRred" data-aos="fade-right" data-aos-delay="200">
              Join us in creating Futu<span style={{color:"red"}}>R</span>e
            </h1>
          </div>
          <br />
          <p
            style={{
              color: "#000",
              width: "100%",
              textAlign: "center",
              marginTop: "0.6%",
            }}
            data-aos="fade-right"
            data-aos-delay="400"
          >
            At FocusR, you will find trusted colleagues, <br />
          </p>
          <p
            style={{
              color: "#000",
              width: "100%",
              textAlign: "center",
              marginTop: "0.6%",
            }}
            data-aos="fade-right"
            data-aos-delay="400"
          >
            committed senior management and a culture <br />
          </p>
          <p
            style={{
              color: "#000",
              width: "100%",
              textAlign: "center",
              marginTop: "0.6%",
            }}
            data-aos="fade-right"
            data-aos-delay="400"
          >
            that thrives on innovation and cross-collaboration
          </p>
          <br />
          <br />
          <div className="custome-button-container">
            <a
              className="custome-primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F60014", marginTop: "0.4em" }}
            >
              Grow with us
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: isMobile?"100px":"50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={CareersImf} alt="left-illustration" width="80%" />
      </div>
      </div>

  <div className="custome-our-values" id="careers" >
    <div className="custome-values-wrapper">
    <h1
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          fontWeight: "normal",
          textAlign: "center",
        }}
      >
        Open Vacancies
      </h1>
      <div className="custome-values-group">
        {jobDetails.map((x, i) => {
          const cleanDescription = x.jobDescription.replace(/•/g, '');
          return (
            <div className="custome-value-card" key={i} onClick={() => handleJobClick(x)}>
              <h2
                style={{
                  fontWeight: "normal",
                  marginTop: "-14%",
                  marginLeft: "-7%",
                  color: "#056CB8",
                  fontSize: "23px",
                }}
                className="custome-h2-values"
              >
                {x.jobTitle}
              </h2>
              <p className="custome-p-values" style={{ marginTop: "-5%" }}>
                {cleanDescription}
              </p>
              <span
                style={{
                  marginTop: '1em',
                  display: "flex",
                  justifyContent: "flex-start",
                  // color: "red",
                  fontSize: "14px",
                }}
              >
                <MdBadge className="custome-job-icon" style={{ marginRight: '0.5em' }} /> {x.experience} Years
              </span>
            </div>
          );
        })}
      </div>
    </div>
  </div>


            
      

      {/* {isMobile?(<section className="custome-careers" id="careers">
        <div className="custome-careers-top">
          <h1
            style={{
              fontSize: "300%",
              width: "100%",
              textAlign: "center",
              fontWeight: "normal",
            }}
          >
            Open Vacancies
          </h1>
          <br />
          
        </div>
        <br />
        <br />
        {jobDetails.map((x, i) => {
          return (
          <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
            <div className="custome-career-item">
              <div className="custome-career-item-left">
                <h1 style={{ fontWeight: "normal" }}>{x.jobTitle}</h1>
                <div className="custome-career-item-left-inner">
                <div style={{display:"flex", justifyContent:"center"}}>
                  <p style={{display:"flex", flexDirection:"row", marginRight:"15px"}}>
                    <MdLocationOn className="custome-job-icon" />
                    {x.jobLocation}
                  </p>
                  <p style={{display:"flex", flexDirection:"row", marginLeft:"15px"}}>
                    <MdBadge className="custome-job-icon" /> {x.experience} Years
                  </p>
                  </div>
                </div>
              </div>
              <div className="custome-career-item-right" style={{ maxWidth: "600px" }}>
                <p style={{ whiteSpace: "pre-wrap", lineHeight: "1.6em", width:"300px" , textAlign:"left"}}>
                  {x.jobDescription}
                </p>
                <button
                  className="custome-lite-btn"
                  onClick={() => perjob(x.jobTitle, x.id)}
                >
                  Apply Now
                </button>
              </div>
            </div>
            </div>
          );
        })}
      </section>):(<section className="custome-careers" id="careers">
        <div className="custome-careers-top">
          <h1
            style={{
              fontSize: "300%",
              width: "100%",
              textAlign: "center",
              fontWeight: "normal",
            }}
          >
            Open Vacancies
          </h1>
          <br />
          
        </div>
        <br />
        <br />
        {jobDetails.map((x, i) => {
          return (
          
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
  <div className="custome-career-item" style={{ maxWidth: "1200px" }}>
    <div className="custome-career-item-left">
      <h1 style={{ fontWeight: "normal" }}>{x.jobTitle}</h1>
      <div className="custome-career-item-left-inner">
        <p>
          <MdLocationOn className="custome-job-icon" />
          {x.jobLocation}
        </p>
        <p>
          <MdBadge className="custome-job-icon" /> {x.experience} Years
        </p>
      </div>
    </div>
    <div className="custome-career-item-right" style={{ maxWidth: "600px" }}>
      <p style={{ whiteSpace: "pre-wrap", lineHeight: "1.6em", width:"400px" , textAlign:"justify"}}>
        {x.jobDescription}
      </p>
      <button
        className="custome-lite-btn"
        onClick={() => perjob(x.jobTitle, x.id)}
      >
        Apply Now
      </button>
    </div>
  </div>
</div> */}



          {/* );
        })}
      </section>)} */}
      
      <Modal
        open={open}
        title={jobTitlestate}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="custome-apply-now-bn"
      >
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            minWidth: "100%",
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={newform}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
              {
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name="mobile"
            rules={[
              {
                required: true,
                message: "Please input your Mobile No!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Notice Period"
            name="notificationPeriod"
            rules={[
              {
                required: true,
                message: "Please input your Notice Period!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Years of Experience"
            name="yearsOfExperience"
            rules={[
              {
                required: true,
                message: "Please input your Experience!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Skills"
            name="llmskills"

rules={[
  {
    required: true,
    message: "Please input your skills!",
  },
]}>
  <Input />
</Form.Item>
<Form.Item
  label="Upload Resume"
  name="resume"
  rules={[
    {
      required: true,
      message: "Please upload your resume!",
    },
  ]}
>
  <Upload
    beforeUpload={() => false}
    fileList={fileList}
    onChange={handleFileChange}
  >
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  </Upload>
</Form.Item>
<Form.Item
  wrapperCol={{
    offset: 8,
    span: 16,
  }}
>
  <ReCAPTCHA
    sitekey="6LdWF7ApAAAAABLvVOKY0om3CK-cJwmSqbJqviPv"
    onChange={onChange}
  />
</Form.Item>
<Form.Item
  wrapperCol={{
    offset: 8,
    span: 16,
  }}
>
  <Button
    type="primary"
    htmlType="submit"
    loading={loading}
  >
    Submit Application
  </Button>
</Form.Item>
</Form>
</Modal>
</div>
<Footer />
</div>
);
};

export default Company;