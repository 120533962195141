import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import './PrivacyPolicy.css'

const Terms = () => {
  return (
    <>
      <Navbar mode="dark" />
      <div className="Privacy-header">
        <h1 style={{ fontWeight: "normal", marginBottom: "1em" }}>Terms</h1>
        <hr />
      </div>
      <div className="Privacy-overall-container">
        <div className="Privacy-container-1" style={{ marginTop: "1em" }}>
          <h3>Terms </h3>

          <p>
            By accessing this web site, you are agreeing to be bound by these
            web site Terms and Conditions of Use, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this site. The
            materials contained in this web site are protected by applicable
            copyright and trade mark law.{" "}
          </p>
        </div>
        <div className="Privacy-container-2" style={{ marginTop: "1em" }}>
          <h3>Use License</h3>

          <p>
            a) Permission is granted to temporarily download one copy of the
            materials (information or software) on focusrtech web site for
            personal, non-commercial transitory viewing only. This is the grant
            of a license, not a transfer of title, and under this license you
            may not:{" "}
          </p>
          <ol>
            <p>1.Modify or copy the materials; </p>
            <p>
              2.Use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);{" "}
            </p>
            <p>
              3.Attempt to decompile or reverse engineer any software contained on
              focusrtech web site;{" "}
            </p>
            <p>
              4.Remove any copyright or other proprietary notations from the
              materials; or{" "}
            </p>
            <p>
              5.Transfer the materials to another person or "mirror" the materials
              on any other server.{" "}
            </p>
          </ol>
          <p>
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by focusrtech at any time.
            Upon terminating your viewing of these materials or upon the
            termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed
            format.{" "}
          </p>
        </div>
        <div className="Privacy-container-3" style={{ marginTop: "1em" }}>
          <h3>Disclaimer</h3>
          <p>
            The materials on focusrtech web site are provided "as is".
            focusrtech makes no warranties, expressed or implied, and hereby
            disclaims and negates all other warranties, including without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights. Further,
            focusrtech does not warrant or make any representations concerning
            the accuracy, likely results, or reliability of the use of the
            materials on its Internet web site or otherwise relating to such
            materials or on any sites linked to this site.
          </p>
        </div>
        <div className="Privacy-container-4" style={{ marginTop: "1em" }}>
          <h3>Limitations </h3>
          <p>
            In no event shall focusrtech or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption,) arising out of the use or
            inability to use the materials on focusrtech Internet site, even if
            a focusrtech authorized representative has been notified orally or
            in writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you.{" "}
          </p>
        </div>
        <div className="Privacy-container-5" style={{ marginTop: "1em" }}>
          <h3>Revisions and Errata </h3>
          <p>
            The materials appearing on focusrtech web site could include
            technical, typographical, or photographic errors. FocusR Consultancy
            and Technologies Private Limited does not warrant that any of the
            materials on its web site are accurate, complete, or current.
            focusrtech may make changes to the materials contained on its web
            site at any time without notice. focusrtech does not, however, make
            any commitment to update the materials.{" "}
          </p>
        </div>
        <div className="Privacy-container-6">
          <h3>Links </h3>
          <p>
            Focusrtech has not reviewed all of the sites linked to its Internet
            web site and is not responsible for the contents of any such linked
            site. The inclusion of any link does not imply endorsement by
            focusrtech of the site. Use of any such linked web site is at the
            user's own risk.{" "}
          </p>
        </div>
        <div className="Privacy-container-7" style={{ marginTop: "1em" }}>
          <h3>Site Terms of Use Modifications </h3>
          <p>
            Focusrtech may revise these terms of use for its web site at any
            time without notice. By using this web site you are agreeing to be
            bound by the then current version of these Terms and Conditions of
            Use.{" "}
          </p>
        </div>
        <div className="Privacy-container-8" style={{ marginTop: "1em", width: '100%' }} >
          <h3>Governing Law </h3>
          <p>
            Any claim relating to focusrtech web site shall be governed by the
            Laws of India without regard to its conflict of law provisions.{" "}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
