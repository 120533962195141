import React, { useEffect, useState } from 'react';
import techstephubMockup from '../../../assets/techstephub-mockup.svg';
import templateTechstepHub from '../../../assets/benefits-template-techstephub.svg';
import { Rate, FloatButton, Modal } from 'antd';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiPlay, BiSolidBot, BiVideo } from 'react-icons/bi';
import ReactPlayer from 'react-player';
import { useProductState } from '../../../Context/ProductLogicProvider';
//import ProductLogicProvider from '../../../Context/ProductLogicProvider';


const Products = (props) => {
  const [open, setOpen] = useState(false);
  const [userInput, setuserInput] = useState('');
  const [userInput2, setuserInput2] = useState('');
  const [show, setshow] = useState(false);
  const [moreBtnState, setmoreBtnState] = useState(false);
  const {isProductOpen, setIsProductOpen} = useProductState()
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (e.isTrusted && window.scrollY > 0) {
        setmoreBtnState(true);
      } else {
        setmoreBtnState(false);
      }
    });
   
  }, [window.scrollY]);
  return (
    <>
      <div className="first-page-wrapper-product-TechstepHub">
        <div className="inner-page-left-product-TechstepHub">
          <br />
          <h1  data-aos="fade-top">{props.title}</h1>
          {/* <br /> */}
        </div>
      </div>
      <img src={props.mockupSrc} alt={props.mockupAlt} width={props.width == "" || undefined ? "100%" : props.width} />
      <div className="product-detail-row">
        <ul>
          <li>
            <h2 data-aos="fade-right" className='product-detail-row-h2'>Technologies Used</h2>
          </li>
          <ul className="technology-product-list">
            {props.technologyUsed.map((x, i) => {
              return (
                <li
                  data-aos="fade-left"
                  style={{
                    color: '#0055cc'
                  }}
                  className='technology-product-list-li'
                >
                  {x}&nbsp;&nbsp;
                </li>
                
              );
            })}
          </ul>
        </ul>
        <div className="customer-rating" data-aos="fade-left">
          <h2 data-aos="fade-right">Clients Rating</h2>
          <Rate className='customer-rating-star' disabled defaultValue={props.ratingCount} />({props.reviewCount} + Reviews)
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      {/* list ends here and description starts here */}
      <div className="what-we-offer-page">
        <h1
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#fff',
            fontWeight: 'bolder'
          }}
          data-aos="fade-right"
        >
          {props.benefitTitle}
        </h1>

        <div className="we-offer-wrapper">
          <div className="we-offer-wrapper-1">
            {props.benefitArray1.map((x, i) => {
              return (
                <div className="num-left-inner2" data-aos="fade-right" data-aos-delay="300">
                  <div className="num-left-inner-icon2">{x.icon}</div>
                  <div className="num-left-inner-text2">
                    <strong>{x.strongText}</strong>
                    <p>{x.pText}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="we-offer-wrapper-2">
            {props.benefitArray2.map((x, i) => {
              return (
                <div className="num-left-inner2" data-aos="fade-right" data-aos-delay="300">
                  <div className="num-left-inner-icon2">{x.icon}</div>
                  <div className="num-left-inner-text2">
                    <strong>{x.strongText}</strong>
                    <p>{x.pText}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>


      {/* new components render here */}
      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        <FloatButton
          tooltip="Demo Video"
          // description='Demo'
          icon={
            <BiVideo
              style={{
                color: 'blue'
              }}
            />
          }
          onClick={() => setOpen(true)}
        />
        {/* <FloatButton
          tooltip="Chat Bot"
          icon={
            <BiSolidBot
              style={{
                color: 'blue'
              }}
            />
          }
          onClick={() => setshow(true)}
        /> */}
        <FloatButton.BackTop />
        {/* {moreBtnState ? <button className="more-prod" onClick={() => setIsProductOpen(!isProductOpen)}>More Products ?</button> : null} */}
      </FloatButton.Group>
      <Modal centered open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width="100%">
        <div
          style={{
            width: '100%',
            height: '90vh'
          }}
        >
          <ReactPlayer
            url={props.videoUrl}
            playIcon={<BiPlay />}
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      </Modal>
    </>
  );
};

export default Products;
