import Footer from "../Components/Footer";
import './EprocSales.css'
import {
  MdOutlineWork
} from "react-icons/md";
import {
  message,
  Form,
  Modal
} from "antd";
import React, { useEffect, useState } from "react";
import { HiOutlineTicket } from "react-icons/hi";
import { FaExchangeAlt } from "react-icons/fa";
import { GrOracle, GrUserManager } from "react-icons/gr";
import {
  AiFillBank,
  AiFillCloud,
  AiFillDatabase,
  AiFillAlipaySquare,
} from "react-icons/ai";
import {
  MdPublic,
  MdPrecisionManufacturing,
  MdPermMedia,
  MdOutlineAssessment,
  MdApproval,
} from "react-icons/md";
import { RiCodeView, RiCustomerServiceFill } from "react-icons/ri";
import { GiHealthNormal, GiHeartPlus } from "react-icons/gi";
import { BiCode, BiSolidLandscape } from "react-icons/bi";
import { HiShoppingCart } from "react-icons/hi";
import { BsFillLightningChargeFill, BsMailbox2, BsRobot } from "react-icons/bs";
import { SiSap } from "react-icons/si";
import {
  FaShuttleSpace,
  FaRobot,
  FaTruckFront,
  FaTruckRampBox,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import { IoMdBarcode } from "react-icons/io";
import { TbTruckDelivery } from "react-icons/tb";
import { IoPersonAddSharp } from "react-icons/io5";
import { useProductState } from "../Context/ProductLogicProvider";
// Eproc Home Page
import Home1 from "../assets/Eproc/Home1.png";
import ModalImg from "../assets/Eproc/sharedimage.jfif";
import Home2 from "../assets/Eproc/Home2.png";
import Home3 from "../assets/Eproc/Home3.png";
import Home4 from "../assets/Eproc/Home4.png";
import Home5 from "../assets/Eproc/sharingevoice.png";
import Home6 from "../assets/Eproc/Rejectionandcancel.png";
import Home7 from "../assets/Eproc/Home7.jpg";
import Home8 from "../assets/Eproc/Home8.jpg";
import Home9 from "../assets/Eproc/Home9.jpg";
import { CiMail } from "react-icons/ci";
import "aos/dist/aos.css";
import Navbar from "../Components/Navbar";
import axios from "axios";

const Home = (props) => {
  console.log("Props", props.mode);
  const [navbarStatus, setnavbarStatus] = useState(false);
  const [navBarMonitor, setnavBarMonitor] = useState(false);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [highlightCompany, setHighlightCompany] = useState(false);
  const [highlightHome, sethighlightHome] = useState(false);
  const [hightlightService, sethightlightService] = useState(false);
  const [highlightProducts, sethighlightProducts] = useState(false);
  const [highlightsolutions, sethighlightsolutions] = useState(false);
  const [highlightPlatforms, sethighlightPlatforms] = useState(false);
  const [highlightVertical, sethighlightVertical] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNavProd, setopenNavProd] = useState(false);
  const navigate = useNavigate();
  const { isProductOpen, setIsProductOpen } = useProductState();
  const [highlightProducts2, setHighlightProducts2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [accessGranted, setAccessGranted] = useState(false);
  const [form] = Form.useForm();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 500 && window.innerWidth <= 900);
  const [isModalVisible, setIsModalVisible] = useState(false);
 
useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
      setIsTablet(window.innerWidth > 500 && window.innerWidth <= 900)
    };
 
    window.addEventListener('resize', handleResize);
 
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const checkLocation = async () => {
  //     try {
  //       const response = await axios.get('https://ipapi.co/json/');
  //       if (response.data.country_code === 'MY') {
  //         setAccessGranted(true);
  //         setIsModalVisible(true);
  //       } else {
  //         message.error("Access Denied: This page is only accessible in Malaysia.");
  //         navigate('/not-authorized'); // Redirect to a "not authorized" page or homepage
  //       }
  //     } catch (error) {
  //       console.error("Error fetching location data:", error);
  //       message.error("Could not determine location. Please try again later.");
  //     }
  //   };

  //   checkLocation();
  // }, [navigate]);

  

  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    const email = values.email;
    const full_Name = values.full_Name;
    const about = values.about;

    axios
      .post("https://focusrapi.focusrtech.com:83/post/enquery", {
        email: email,
        full_Name: full_Name,
        about: about,
        type: "EprocSales",
      })
      .then((res) => {
        console.log("Response after sending Contact Form", res);
        message.success("Success!");
        form.resetFields();
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error while submitting", err);
        // message.error("Error while submitting. Please try again.");
        setLoading(false);
      });
  };
  const onFinishFailed = () => {
    message.error("Please Fill Required Fields :-(");
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (e.isTrusted && window.scrollY > 0) {
        setnavbarStatus(true);
        setHover(true);
      } else {
        setnavbarStatus(false);
        setHover(false);
      }
    });
    console.log("Window y position", window.scrollY);
    console.log("windowWidth", window.innerWidth);
    //"1px 1px 50px 1px #fff"
    if (window.innerWidth < 1150) {
      setnavBarMonitor(true);
    } else {
      setnavBarMonitor(false);
    }
    window.addEventListener("resize", (e) => {
      e.isTrusted && window.innerWidth < 1150
        ? setnavBarMonitor(true)
        : setnavBarMonitor(false);
    });
  }, [window.innerWidth]);


  useEffect(() => {
    console.log("first", document.location.pathname);
    const a = document.location.pathname;
    if (String(a).includes("careers")) {
      setHighlightCompany(true);
    } else if (String(a).includes("platforms")) {
      sethighlightPlatforms(true);
    } else if (String(a).includes("products")) {
      sethighlightProducts(true);
    } else if (String(a).includes("verticals")) {
      sethighlightVertical(true);
    } else if (String(a).includes("solutions")) {
      sethighlightsolutions(true);
    } else if (String(a).includes("services")) {
      sethightlightService(true);
    } else {
      setHighlightCompany(false);
      sethighlightHome(false);
      sethighlightPlatforms(false);
      sethighlightProducts(false);
      sethighlightVertical(false);
      sethighlightsolutions(false);
      sethightlightService(false);
    }
  }, [document.location.pathname]);

  // if (!accessGranted) {
  //   return null; 
  // }

  return (
    <>
      <div>
        <Navbar />
      </div>

      <Modal
        visible={isModalVisible}
        footer={null}
        width={700}
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        onCancel={handleModalClose}
        onOk={handleModalClose}
      >
        <img src={ModalImg} alt="Home Image" style={{ width: '90%', height: 'auto' }} />
      </Modal>

      <div className="Eproc-Home-container">
        <div className="Eproc-left-container">
          <div
            className="Eproc-Home-first-left-top"
            style={{ marginTop: "4em" }}
          >
            <p style={{ fontWeight: "12rem" }}>
              Can provide businesses a range of electronic invoicing <br />
              options. We are prepared to help your company get
              <br /> ready for the eInvoicing deployment, since LHDNM has
              <br /> set a target timeline.
            </p>
          </div>

          <div className="Eproc-Home-first-left-bottom">
            <img src={Home1} className="Home1" />
          </div>
        </div>
        <div className="eproc-first-right">
          <Form
            className="eproc-form"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <div className="form-group">
              <label htmlFor="name">Name*</label>
              <Form.Item name="full_Name">
                <input type="text" id="name" placeholder="Name" />
              </Form.Item>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <Form.Item name="email">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <label htmlFor="mobile">Mobile*</label>
              <Form.Item name="about">
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  placeholder="Mobile"
                />
              </Form.Item>
            </div>
            <Form.Item>
              <button
                type="submit"
                className="form-submit-btn"
                loading={loading}
              >
                {loading ? "Loading" : "Get Your Free Demo"} <CiMail />
              </button>
            </Form.Item>
          </Form>
          {/* </div> */}
        </div>
      </div>

      {/* second part of home page */}
      <div className="Eproc-second-container">
        <div className="eproc-second-left">
          <img src={Home2} alt="Home2" className="Home2" />
        </div>
        <div className="eproc-second-right">
          <ul>
            <div className="eproc-list">
              <li>
                <p>
                  Our business plans are designed to give companies of all sizes
                  a comprehensive and reasonably priced package that will save
                  money and, ultimately, result in a more successful business
                  solution for our partners.
                </p>
              </li>
            </div>
            <div className="eproc-list">
              <li>
                <p>
                  In order to ensure a successful endeavour, we work with our
                  clients to develop business solutions that will benefit all
                  parties.
                </p>
              </li>
            </div>
            <div className="eproc-list">
              <li>
                <p>
                  E-Invoice by FocusR : Our solution is a flexible platform that
                  guarantees flexibility and agility in fulfilling your business
                  requirements.
                </p>
              </li>
            </div>

            <div className="eproc-list">
              <li>
                <p>
                  As a platform for regulatory technology, we provide
                  all-inclusive solutions for managing calculation rules,
                  validation, integration, and transformation.
                </p>
              </li>
            </div>

            <div className="eproc-list">
              <li>
                <p>
                  E-Invoice by FocusR : Ensures compliance with eInvoicing
                  standards in numerous countries by offering end-to-end
                  automation for managing multi-source inputs.
                </p>
              </li>
            </div>
            <div className="eproc-list">
              <li>
                <p>
                  Global Presence : We service clients globally and have a
                  strong global footprint, we guarantee safe and consistent
                  e-invoicing.
                </p>
              </li>
            </div>

            <div className="eproc-list">
              <li>
                <p>
                  We have gained the confidence of current clients in Southeast
                  Asia forEnabling end-to-end processes for SAP ECC or
                  S/4,Oracle EBS Suite & Oracle Fusion.
                </p>
              </li>
            </div>
          </ul>
        </div>
      </div>
      {/* Large container */}

      {/* Eproc third section */}
      <div className="Eproc-third-container">
        <div className="Eproc-third-top">
          <h1
            style={{
              fontWeight: "normal",
              textAlign: "center",
              borderRadius: "10px",
            }}
          >
            Integration Overview
          </h1>
        </div>

        <div className="Eproc-third-Bottom">
          <img src={Home3} alt="Home3" className="Home1" />
        </div>
      </div>
      {/* Eproc fourth content */}

      <div className="Eproc-fourth-container">
        <div className="Eproc-fourth-top">
          <h1
            style={{
              fontWeight: "normal",
              marginBottom: "1em",
              textAlign: "justify",
              color: "white",
              backgroundColor: "red",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            E-Invoice Validation
          </h1>
          <p style={{ lineHeight: "1.5em", fontSize: isMobile ? '20.8px' : '16px' }}>
            Once validated by the System, the Supplier or technology provider
            will receive an API response which contains the following:
            <br />
            (a) the IRBM Unique Identifier Number from IRBM;
            <br />
            (b) date and time of validation; and
            <br />
            (c) information to form validation link via API.
            <br />
            The IRBM Unique Identifier Number will allow traceability by IRBM
            and will reduce instances of tampering with the e-Invoice
            <br />
            If errors are detected during validation, an API error response will
            be shown.
            <br /> Accordingly, API response will be provided upon successful
            validation.
            <br /> Once the e-Invoice is successfully validated, notification
            will be sent to the Supplier and Buyer
          </p>
        </div>
        <div className="Eproc-fourth-bottom">
          <img src={Home4} alt="4th home image" className="Home4" />
        </div>
      </div>

      {/* fifth container */}
      <div className="Eproc-fifth-container">
        <div className="eproc-fifth-left">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "normal",
              marginBottom: "1em",
              color: "white",
              backgroundColor: "red",
              padding: "10px",
              borderRadius: "10px",
              marginTop: "0.2em",
            }}
          >
            Sharing of E-Invoice
          </h1>
          <p
            style={{
              lineHeight: "1.5em",
              textAlign: "justify",
               fontSize: isMobile ? '20.8px' : '16px'
            }}
          >
            Upon validation of the e-Invoice, the Supplier is obliged to share
            the validated e-Invoice with the Buyer. In the event the Supplier
            shares the visual representation of the e-Invoice to the Buyer, the
            Supplier is required to ensure that the QR code is embedded
            accordingly, which the QR code can be used to validate the existence
            and status of the e-Invoice via Portal
          </p>
        </div>
        <div className="eproc-fifth-right">
          <img src={Home5} alt="Home5" className="Home1" />
        </div>
      </div>

      {/* Sixth container */}

      <div className="Eproc-sixth-container">
        <div className="eproc-sixth-left">
          <h1
            style={{
              textAlign: "center",
              width: '100%',
              fontWeight: "normal",
              marginBottom: "1em",
              color: "white",
              backgroundColor: "red",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            Rejection and Cancellation
          </h1>
            <p
              style={{
                // marginTop: "-6em",
                lineHeight: "1.5em",
                textAlign: "justify",
                paddingBottom: "2em",
                 fontSize: isMobile ? '20.8px' : '16px'
              }}
            >
              Once the e-Invoice has been validated by IRBM, Supplier and Buyer
              are allowed to cancel or reject the said e-Invoice, within a
              stipulated time.
              <ol>
                1. Buyer to request rejection of the e-Invoice via API<br/>
                2. Supplier to perform cancellation of e-Invoice via API<br/>
              </ol>
              <p style={{ lineHeight: "1.5em" }}>
                If the e-Invoice is not rejected or cancelled within 72 hours,
                no cancellation would be allowed.
              </p>
            </p>
        </div>
        <div className="eproc-sixth-right">
          <img src={Home6} alt="Home6" className="Home12" />
        </div>
      </div>

      {/* seventh container*/}
      <div className="Eproc-seventh-container">
        <div className="Eproc-seventh-bottom">
          <h1
            style={{
              fontWeight: "normal",
              textAlign: "center",
              marginBottom: "1em",
              color: "white",
              backgroundColor: "red",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            Storing e-Invoice
          </h1>
          <p
            style={{ lineHeight: "1.5em", textAlign: "justify",  fontSize: isMobile ? '20.8px' : '16px' }}
            className="Eproc-seventh-bottom-para"
          >
            All validated e-Invoices will be stored in IRBM's database
            Notwithstanding the storage of the e-Invoice taxpayers are reminded
            to retain sufficient records and documentation in relation to the
            transaction
          </p>
        </div>

        <div className="Eproc-seventh-top">
          <img src={Home7} alt="Home7" className="Home7" />
        </div>
      </div>

      <div className="eproc-eight-container">
        <div className="eproc-eight-top">
          <h1
            style={{
              fontWeight: "normal",
              marginBottom: "1em",
              color: "white",
              backgroundColor: "red",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            Reporting and Dashboards Services for Taxpayer
          </h1>
          <p
            style={{
              lineHeight: "1.5em",
              textAlign: "justify",
               fontSize: isMobile ? '20.8px' : '16px'
            }}
          >
            Through API integration, both Supplier and Buyer will have the
            option to request and retrieve e-Invoice, which can be <br />
            seamlessly displayed on their respective systems. The API
            integration enables access to essential e-Invoice details
            <br /> such as the invoice date, amount, invoice status, and other
            relevant information submitted to IRBM, in the format of: <br />
            1. XML/JSON, either one-by-one or in package
            <br />
            2. Metadata
            <br />
            Below figure depicts the integration to enable Suppliers and Buyers
            to efficiently retrieve and utilize e-Invoice within
            <br /> their own systems, thus streamlining processes and enhancing
            transparency in e-Invoice management
          </p>
        </div>
        <div className="eproc-eight-bottom">
          <img src={Home8} alt="Home8" className="Home8" />
        </div>
      </div>

      <div className="eproc-ninth-container">
        <div className="eproc-ninth-top">
          <h1
            style={{
              fontWeight: "normal",
              color: "white",
              backgroundColor: "red",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            e-Invoicing Overview Workflow
          </h1>
        </div>
        <div className="eproc-ninth-bottom">
          <img src={Home9} alt="home9" className="home9" />
        </div>
      </div>
      <div>
        <Footer />
        {/* normal navigatoion closed */}
      </div>
    </>
  );
};
export default Home;
