/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import Products from './Components/Products';
import techstephubMockup from '../../assets/scanlogimockup.svg';
import Chat from '../../Components/Chat';
import { Collapse } from 'antd';
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled
} from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';
import modelSrc from '../../assets/3D-Models/earth00.obj';
import ModelViewer from '../../Components/ModelViewer';
const BarcodeApplication = () => {
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    document.title = 'Product | ScanlogiX';

    AOS.init();
  });

  const scanlogiXFAQ = [
    {
      key: '1',
      label: <h3>1. What is the purpose of the ScanlogiX application?</h3>,
      children: (
        <p>
          The ScanlogiX application is designed to seamlessly integrate barcode creation, extraction of labels from
          connected machines, and efficient storage of data in Oracle, streamlining your workflow.
        </p>
      )
    },
    {
      key: '2',
      label: <h3>2. How do I get started with the ScanlogiX application?</h3>,
      children: (
        <p>
          To get started, download the ScanlogiX app from the App Store/Google Play Store or visit our website. Set up
          your Oracle connection, configure barcode settings, and start utilizing the integrated features.
        </p>
      )
    },
    {
      key: '3',
      label: <h3>3. What platforms does the ScanlogiX application support?</h3>,
      children: (
        <p>
          The ScanlogiX application is available for download on iOS and Android devices. Additionally, it may be
          accessed through web browsers for enhanced flexibility.
        </p>
      )
    },
    {
      key: '4',
      label: <h3>4. Can I use ScanlogiX for both barcode creation and label extraction?</h3>,
      children: (
        <p>
          Yes, ScanlogiX is a versatile tool that enables both barcode creation and label extraction from connected
          machines, providing a comprehensive solution for your business needs.
        </p>
      )
    },
    {
      key: '5',
      label: <h3>5. How does ScanlogiX ensure data security?</h3>,
      children: (
        <p>
          ScanlogiX prioritizes data security. All information is encrypted, and the application follows
          industry-standard security practices, ensuring your data is stored securely and accessible only to authorized
          users.
        </p>
      )
    },
    {
      key: '6',
      label: <h3>6. Can I export data from ScanlogiX to Oracle?</h3>,
      children: (
        <p>
          Absolutely. ScanlogiX facilitates seamless data export to Oracle, ensuring that your barcode and label
          information is efficiently stored in your Oracle database.
        </p>
      )
    },
    {
      key: '7',
      label: <h3>7. Is customer support available for ScanlogiX?</h3>,
      children: (
        <p>
          Yes, we offer dedicated customer support to assist you with any issues or questions you may have. Our support
          team is accessible through the app or our website.
        </p>
      )
    },
    {
      key: '8',
      label: <h3>8. Can ScanlogiX be used in offline mode?</h3>,
      children: (
        <p>
          Yes, ScanlogiX features offline functionality. You can continue creating barcodes and extracting labels even
          when not connected to the internet, and the data will be synchronized once you're back online.
        </p>
      )
    },
    {
      key: '9',
      label: <h3>9. How often are updates released for ScanlogiX?</h3>,
      children: (
        <p>
          We are committed to regular improvements. Updates with new features, enhancements, and bug fixes are released
          periodically to ensure ScanlogiX remains efficient and up-to-date.
        </p>
      )
    },
    {
      key: '10',
      label: <h3>10. Are there any specific hardware requirements for ScanlogiX?</h3>,
      children: (
        <p>
          ScanlogiX is designed to be compatible with standard hardware. Detailed hardware requirements can be found in
          the application documentation to ensure optimal performance.
        </p>
      )
    },
    {
      key: '11',
      label: <h3>11. Can I customize barcode settings in ScanlogiX?</h3>,
      children: (
        <p>
          Absolutely. ScanlogiX provides options to customize barcode settings, allowing you to tailor the application
          to meet your specific barcode creation requirements.
        </p>
      )
    },
    {
      key: '12',
      label: <h3>12. Does ScanlogiX support integration with other systems?</h3>,
      children: (
        <p>
          Yes, ScanlogiX supports integration with various systems, ensuring compatibility with your existing
          infrastructure and facilitating a seamless workflow.
        </p>
      )
    }
  ];

  return (
    <div>
      <Navbar mode="dark" />
      <Chat />
      <Products
        title="ScanlogiX"
        mockupSrc={techstephubMockup}
        mockupAlt="TechstepHub-Mockup"
        technologyUsed={['ReactNative', 'Node.js', 'Oracle']}
        ratingCount={4}
        reviewCount="120"
        benefitTitle="Benefits of ScanlogiX"
        videoUrl="https://www.youtube.com/watch?v=q3n24u5X9Vc"
        width="100%"
        benefitArray1={[
          {
            icon: <TbSquareRoundedNumber1Filled className="num-icon" />,
            strongText: 'Accurate Billing',
            pText:
              'Ensure precise billing with detailed and error-free data from barcode creation and label extraction.'
          },
          {
            icon: <TbSquareRoundedNumber2Filled className="num-icon" />,
            strongText: 'Resource Optimization',
            pText:
              'Optimize resource allocation with insights gained from efficient barcode creation and label extraction processes.'
          },
          {
            icon: <TbSquareRoundedNumber3Filled className="num-icon" />,
            strongText: 'Project Visibility',
            pText:
              'Enhance project visibility by utilizing real-time progress tracking and reporting enabled by barcode and label data.'
          }
          // Add more benefits following the same structure
        ]}
        benefitArray2={[
          {
            icon: <TbSquareRoundedNumber4Filled className="num-icon" />,
            strongText: 'Inventory Management',
            pText:
              'Streamline inventory processes by associating barcodes with products and efficiently tracking stock levels.'
          },
          {
            icon: <TbSquareRoundedNumber5Filled className="num-icon" />,
            strongText: 'Error Reduction',
            pText:
              'Minimize errors in data entry and labeling with the automated and accurate processes of the application.'
          },
          {
            icon: <TbSquareRoundedNumber6Filled className="num-icon" />,
            strongText: 'Enhanced Compliance',
            pText:
              'Ensure compliance with labeling standards and regulatory requirements through accurate barcode creation.'
          }
        ]}
      />
<br />
      <br />
      <h1
        style={{
          textAlign: 'center'
        }}
      >
        FAQ
      </h1>
      <br />
      <Collapse
        style={{
          width: '90%',
          position: 'relative',
          left: '5%',
          backgroundColor: '#fff',
          marginBottom: '5em',
          color: '#343F52'
        }}
        items={scanlogiXFAQ}
        onChange={onChange}
        defaultActiveKey={['1']}
      />
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      {/* Modal Viewer Sample is Here */}
      {/* <ModelViewer modelPath={modelSrc} /> */}
      {/* Ends Here */}
      <Footer />
    </div>
  );
};

export default BarcodeApplication;
