/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import { AiFillControl, AiOutlineApi, AiOutlineBranches, AiOutlineSetting, AiOutlineArrowRight } from 'react-icons/ai';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import { useNavigate } from 'react-router-dom';
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled
} from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contactform from '../../Components/Contactform';
import { useWindupString } from 'windups';
import ChatNew from '../../Components/Chat2';
import aboutIllustration from '../../assets/about-page-bg.png';
import { Progress, Collapse } from 'antd';
import { FaGears, FaWandMagicSparkles } from 'react-icons/fa6';
import { SiCodeclimate } from 'react-icons/si';
import { BsGlobeCentralSouthAsia } from 'react-icons/bs';
import { FaHandshake } from 'react-icons/fa';
import { TiLightbulb } from 'react-icons/ti';
import { GoLightBulb } from 'react-icons/go';
import { BiSolidPieChartAlt2 } from 'react-icons/bi';
import { PiChartPieSliceDuotone, PiMagicWandLight } from 'react-icons/pi';
import { CaretRightOutlined } from '@ant-design/icons';
import { TbNumber12Small } from 'react-icons/tb';

const OverallVerticals = () => {
  const navigate = useNavigate();
  const [innerHeight, setinnerHeight] = useState('');
  const [innerWidth, setinnerWidth] = useState('');
  useEffect(() => {
    document.title = 'FocusR Technologies | Verticals';
    setinnerHeight(screen.height);
    setinnerWidth(screen.width);

    AOS.init();
    console.log('innerheight', screen.height);
    console.log('innerwidth', screen.width);
  }, []);

  const benefitArray1 = [
    {
      icon: <TbNumber1Small className="num-icon" />,
      strongText: 'Aerospace & Defense',
      pText: 'Optimizing Operation Solutions for Aerospace and Defense.'
    },
    {
      icon: <TbNumber2Small className="num-icon" />,
      strongText: 'Automative',
      pText: 'Accelerating progress, forging the path to automotive brilliance.'
    },
    {
      icon: <TbNumber3Small className="num-icon" />,
      strongText: 'Banking & Capital Market',
      pText: 'Driving Financial Futures Solutions for Banking and Capital Markets.'
    },
    {
      icon: <TbNumber4Small className="num-icon" />,
      strongText: 'Energy & Utilities',
      pText: 'Revolutionizing energy delivery and utility services for sustainable futures globally.'
    },
    {
      icon: <TbNumber5Small className="num-icon" />,
      strongText: 'Healthcare',
      pText: 'Healthcare through innovation, accessibility & patient-centered solutions.'
    },
    {
      icon: <TbNumber6Small className="num-icon" />,
      strongText: 'Hospitality & Travel',
      pText: 'Healthier lives with advanced medical tech and compassionate care.'
    }
    // Add more benefits following the same structure
  ];
  const benefitArray2 = [
    {
      icon: <TbNumber7Small className="num-icon" />,
      strongText: 'Insurance',
      pText: 'Insurance industry innovates with advanced risk management solutions.'
    },
    {
      icon: <TbNumber8Small className="num-icon" />,
      strongText: 'Manufacturing',
      pText: 'Manufacturing is centered on long term value creation'
    },
    {
      icon: <TbNumber9Small className="num-icon" />,
      strongText: 'Media & Entertainment',
      pText: 'An ever-evolving world of creativity, innovation, and fun.'
    },
    {
      icon: <TbNumber10Small className="num-icon" />,
      strongText: 'Public Sector',
      pText: 'The eyes and ears on the ground for the communities they serve'
    },
    {
      icon: <TbNumber11Small className="num-icon" />,
      strongText: 'Realestate',
      pText: 'Revolutionizing real estate with innovative industry solutions.'
    },
    {
      icon: <TbNumber12Small className="num-icon" />,
      strongText: 'Retail',
      pText: 'Thriving marketplace shaping consumer experiences worldwide.'
    }
  ];

  return (
    <div>
      <Navbar mode="dark" />
      <ChatNew />
      <div className="welcome-page-22">
        <center>
          <h1
            style={{
              color: '#343F52',
              textAlign:'center',
              width:'100%'
            }}
          >
            FocusR's Versatile Industry Proficiency
          </h1>
          <p
            style={{
              color: '#343F52'
            }}
            className='p-values2-in-vertical'
          >
            With a diverse professional background, FocusR has demonstrated its versatility by successfully working
            through various industries. Its expertise spans different fields, showcasing a dynamic and adaptable
            approach to challenges. Whether in technology, finance, or other sectors, FocusR has consistently proven its
            proficiency and effectiveness in delivering results across a broad spectrum of industries.
          </p>
        </center>
      </div>
      {/* about us starts here */}

      <center>
        <section className="vertical-box"></section>
      </center>
      <section>
        <center>
          <h1
            style={{
              color: '#343F52',
              fontSize: '4em',
              width: '60%',
              marginBottom: '2em',
              marginTop: '1em',
              textAlign:'center'
            }}
            className="dummy-h1-vertical"
          >
            Unlocking New Horizons: FocusR's Versatile Industry Expertise
          </h1>
        </center>
      </section>
      <div className="what-we-offer-page">
        <h1
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#fff',
            fontWeight: 'bolder'
          }}
        >
          Verticals
        </h1>

        <div className="we-offer-wrapper">
          <div className="we-offer-wrapper-1">
            {benefitArray1.map((x, i) => {
              return (
                <div className="num-left-inner2" key={i}>
                  <div className="num-left-inner-icon2">{x.icon}</div>
                  <div className="num-left-inner-text2">
                    <strong>{x.strongText}</strong>
                    <p>{x.pText}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="we-offer-wrapper-2">
            {benefitArray2.map((x, i) => {
              return (
                <div className="num-left-inner2" key={i}>
                  <div className="num-left-inner-icon2">{x.icon}</div>
                  <div className="num-left-inner-text2">
                    <strong>{x.strongText}</strong>
                    <p>{x.pText}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* about us ends here */}
      {/* what we offer page ends here */}
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};
export default OverallVerticals;

/*

*/
