import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const OracleSupport = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Troubleshooting and Issue Resolution ",
      description:
        "Our team is proficient in Oracle E-Business Suite (EBS) and Oracle Fusion, utilizing advanced techniques to diagnose and resolve issues quickly to maintain system integrity and performance",

      details: [
        "Root Cause Analysis: Employing Oracle Trace files, Diagnostic Framework, and Core Dump analysis to identify the fundamental issues in your Oracle environment. Tools like SQL Trace, TKPROF and Oracle Diagnostic Logging (ODL) are used to decode complex problems.",
        "Incident Managementn: Utilizing ITIL-based incident management systems such as ServiceNow for ticket management, we ensure that issues are categorized, prioritized, and resolved according to predefined service level agreements (SLAs). Service requests (SRs) are managed in Oracle Support to coordinate with Oracle for unresolved issues.",
        "Bug Fixes and Patches: Deploying cumulative and one-off patches using Oracle's AutoPatch utility, and testing patches in a cloned instance before production deployment to ensure zero downtime",
        "Database Recovery: Leveraging Oracle RMAN (Recovery Manager) for efficient backup and restoration processes, including point-in-time recovery (PITR) and tablespace-specific recoverability",
        "24/7 Support: Integrating with Oracle Advanced Customer Support (ACS) for critical issues, providing immediate escalations and round-the-clock support coverage for priority incidents",
      ],
    },
    {
      title: "Performance Tuning ",
      description:
        "Optimizing Oracle EBS and Fusion Applications requires a thorough understanding of the intricate dependencies and performance metrics.",
      details: [
        "SQL and PL/SQL Optimization: : Conducting thorough SQL profiling using Oracle SQL Tuning Advisor and SQL Access Advisor, rewriting inefficient SQL statements, and indexing strategies to enhance query performance",
        "Application Tuning: : Adjusting initialization parameters such as SGA_TARGET, PGA_AGGREGATE_TARGET, and DB_CACHE_SIZE for Oracle EBS, and leveraging Oracle Fusion Middleware Performance Analysis Framework for application-level tuning.",
        "System Resource Management: Utilizing Oracle Resource Manager to allocate CPU resources among competing workloads, ensuring optimal usage of system resources.",
        "Concurrent Processing Optimization: Configuring and optimizing Oracle Concurrent Manager settings such as Concurrent: Program Concurrent Request Limit and tuning concurrent jobs using the Concurrent Processing Diagnostic Tool.",
        "AWR and ADDM Reports: Regularly analyzing Automated Workload Repository (AWR) snapshots and Automatic Database Diagnostic Monitor (ADDM) findings to detect performance bottlenecks and recommend actionable insights.",
      ],
    },
    {
      title: " Proactive Monitoring ",
      description: "Proactive Monitoring",
      details: [
        "Real-Time Monitoring: Implementing Oracle Enterprise Manager (OEM) Cloud Control to monitor real-time performance metrics and thresholds, with comprehensive dashboards for quick status overview of Oracle environments.",
        "Threshold Alerts:  Configuring metric-based alerting in OEM for critical parameters such as CPU utilization, tablespace usage, and data file I/O to trigger alerts and automate responses",
        "Health Checks: Conducting regular full-stack health checks using Oracle Best Practice guidelines to evaluate system components, configurations, and operational practices",
        "Capacity Planning: Utilizing Oracle Capacity Planning Advisor to model and forecast future resource needs based on historical data and business growth projections.",
        "Security Monitoring: Integrating Oracle Audit Vault and Database Firewall to monitor and analyze database activities, ensuring compliance with security policies and regulations such as GDPR and HIPAA.",
      ],
    },
    {
      title: "System Upgrades and Patching",
      description:
        "Maintaining up-to-date Oracle systems involves strategic planning and precise execution. ",
      details: [
        "Release Upgrades: Managing end-to-end Oracle EBS upgrades using adpatch and Oracle Fusion Middleware upgrades via OPatch, ensuring compatibility and smooth transitions between versions",
        "Patch Management: Deploying critical patches using Oracle's Enterprise Manager Patching and Provisioning Module, conducting pre-installation impact analysis, test cloning, and regression testing before applying patches to production environments.",
        "Impact Analysis:  Utilizing Oracle Change Management Pack to analyze and simulate the impact of patches and upgrades on your current system, identifying potential disruptions and sequencing the updates to mitigate risks",
        "Downtime Minimization: Employing techniques such as Oracle Data Guard for zero-downtime upgrades and Oracle GoldenGate for real-time data replication to ensure business continuity during system maintenance.",
        "Post-Upgrade Validation: Conducting thorough application and database validation using Oracle Application Testing Suite (OATS) to ensure all functionalities and performance benchmarks are met post-upgrade.",
      ],
    },

    {
      title: "Configuration and Customization Support ",
      description:
        "Tailoring Oracle EBS and Fusion to meet specific business needs involves sophisticated customization and configuration capabilities.",
      details: [
        "Custom Workflow Development: Developing custom workflows using Oracle Workflow Builder and Oracle Business Process Management (BPM) Suite to automate complex business processes while ensuring integration with core modules",
        "Personalization and Extensions: Utilizing Oracle Application Framework (OAF) for EBS and Oracle Visual Builder for Fusion to create and manage personalizations and extensions. Ensuring upgradability by adhering to the Oracle standards for extending screens and application forms.",
        "API and Web Services Integration: Developing and maintaining integrations using Oracle SOA Suite and Oracle Integration Cloud for seamless data interchange between Oracle and third-party systems via REST and SOAP services.",
        "Configuration Management: Implementing Oracle Change Management Framework to track and manage configuration changes, ensuring precise documentation and compliance with deployment standards.",
        "Template and Report Customization: Utilizing Oracle BI Publisher to design and customize data templates and reports, creating pixel-perfect documents tailored to specific business requirements, and embedding analytics for enhanced decision-making.",
      ],
    },
    {
      title: "Configuration Management and Change Control",
      description:
        "Effective configuration management and change control are crucial for maintaining system stability and ensuring smooth transitions during updates or customizations.",
      details: [
        "Version Control: Utilizing tools like Git and Oracle Development tools (ODT) integrated with Oracle Developers Cloud Service (ODCS) for managing code versions and tracking changes across development, testing, and production environments. ",
        "Change Management: Implementing ITIL-based Change Management processes using tools such as ServiceNow to request, approve, and document changes in Oracle EBS and Oracle Fusion environments. Detailed change logs and rollback plans are maintained for every change.",
        "Configuration Audits: Conducting routine configuration audits using Oracle Configuration and Compliance Manager to ensure compliance with defined standards and identifying unauthorized changes that may impact system performance or security.",
        "Baseline Configuration Snapshots: Regularly capturing baseline snapshots of configurations using Oracle Enterprise Manager to compare and restore settings if deviations occur, ensuring consistent performance across environments.",
        "Drift Management: Leveraging Oracle Management Cloud (OMC) for continuous monitoring of configuration drifts and automating the remediation process to align all instances with the approved baseline configuration.",
      ],
    },
    {
      title: "Custom Developments and Enhancements  ",
      description:
        "Customized solutions and enhancements are essential to extend the functionality of Oracle applications to meet unique business requirements.",
      details: [
        "Bespoke Application Development: Utilizing Oracle Application Express (APEX) and Oracle Fusion Middleware (ADF) for developing custom applications that seamlessly integrate with core Oracle EBS and Fusion modules. ",
        "Enhancements and Extensions: Developing enhancements using Oracle Extension Framework for market-specific and process-specific needs without impacting the upgrade path of the core Oracle Environment.",
        "Integration with External Systems: Building bespoke integrations via Oracle SOA Suite or Oracle Integration Cloud Service (ICS), ensuring secure and efficient data exchange with third-party applications.",
        "Oracle Workflow and Approvals Management: Extending Oracle workflows and Approval Management Engine (AME) for customized approval routing and business process automation.",
        "User Interface Customization: Tailoring user interfaces with Oracle Visual Builder for Fusion applications and Oracle OAF for EBS, enhancing user experience through customized layouts and functionalities.",
      ],
    },
    {
      title: " Data Management and Optimization",
      description:
        "Efficient data management practices ensure the integrity, security, and performance of Oracle databases",
      details: [
        "Data Archiving and Purging: Implementing Oracle ILM (Information Lifecycle Management) for developing data archiving and purging strategies, ensuring optimal database performance and compliance with data retention policies.",
        "Data Quality Management: Using Oracle Enterprise Data Quality (EDQ) tools for continuously monitoring and enforcing data quality to ensure accurate and reliable information across all systems.",
        "Storage Optimization: Optimizing storage with Oracle Automatic Storage Management (ASM) and implementing Oracle Advanced Compression to reduce database size without compromising data integrity.",
        "Data Replication: Utilizing Oracle GoldenGate for real-time data replication across different environments, supporting high availability and disaster recovery strategies.",
        "Master Data Management: Managing master data with Oracle DRM (Data Relationship Management) to ensure consistency, accuracy, and governance across the enterprise.",
      ],
    },
    {
      title: "Backup and Disaster Recovery Planning ",
      description:
        "Robust backup and disaster recovery plans are essential to safeguard critical data and ensure business continuity.",
      details: [
        "Regular Backup Schedules: Implementing automated backup schedules using Oracle RMAN to run incremental, differential, and full backups of Oracle databases, ensuring data recoverability.",
        "Disaster Recovery Solutions: Configuring Oracle Data Guard for creating, maintaining, and monitoring standby databases, enabling seamless failover and switchover in case of primary database failure.",
        "Data Recovery Testing: Periodically conducting recovery drills to validate backup integrity and verify the effectiveness of the disaster recovery plan, ensuring the organization is prepared for real-time incidents.",
        "Offsite Storage Strategies: Implementing offsite storage solutions for backups, utilizing Oracle Cloud Infrastructure Object Storage for secure and reliable offsite data storage.",
        "Business Continuity Planning: Developing comprehensive business continuity plans, including scenario planning, defining RTO (Recovery Time Objective) and RPO (Recovery Point Objective) targets, and establishing clear protocols for disaster recovery events.",
      ],
    },
    {
      title: "Rollouts and Deployment Management ",
      description:
        "Managing rollouts and deployments effectively ensure that new functionalities and updates are deployed with minimal disruption to business operations.",
      details: [
        "Phased Rollout Strategies: Designing and implementing phased rollout strategies to introduce new modules or updates incrementally, reducing the risk of widespread issues.",
        "Pilot Testing: Conducting pilot rollouts in controlled environments to validate new functionalities, gather feedback, and make refinements before wide-scale deployment.",
        "Automated Deployment: Utilizing Oracle's Enterprise Manager, Ansible, and Jenkins for automated deployment processes, ensuring consistency and reducing human errors during deployments.",
        "User Training and Adoption: Providing comprehensive training sessions and hands-on workshops to ensure users are well-prepared and can effectively utilize new features post-rollout.",
        "Post-Rollout Support: Offering extensive post-rollout support, including monitoring system performance and user feedback to address any issues promptly, ensuring a smooth transition and high user satisfaction",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              //   fontSize: "35px",
            }}
          >
            Oracle Support and Maintenance
          </h1>
          <p style={{ fontSize: "18px" }}>
            At FocusR, we deliver comprehensive support and maintenance services
            for Oracle EBS and Oracle Fusion applications, ensuring optimal
            performance, security, and scalability. Our expertise encompasses
            rapid issue resolution, advanced performance tuning, proactive
            monitoring, strategic upgrades, and efficient patching. We manage
            configurations meticulously, develop custom enhancements, and
            optimize data management. Our robust backup and disaster recovery
            plans protect critical data, while our structured rollout and
            deployment strategies ensure seamless transitions. With FocusR, your
            Oracle environments are in expert hands, freeing you to focus on
            your core business objectives
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "16px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default OracleSupport;
