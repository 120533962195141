import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import { useWindupString } from "windups";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const CloudMigration = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Cloud Readiness Assessments",
      description:
        "Our Cloud Readiness Assessments provide a comprehensive evaluation of your current Oracle EBS (E-Business Suite) and Fusion environments to determine their suitability for cloud migration. The assessment involves",

      details: [
        "Infrastructure Analysis: Detailed examination of your on-premises hardware and software architecture to identify any compatibility issues with Oracle Cloud Infrastructure (OCI).",
        "Workload Profiling: Analysis of your current workload characteristics, including performance metrics, resource utilization, and peak usage patterns, to optimize cloud resource allocation.",
        "Security and Compliance Review: Rigorous assessment of your existing security controls and compliance requirements to ensure a seamless migration to a secure cloud environment.",
        "Cost-Benefit Analysis: Evaluation of potential cost savings and benefits, including TCO (Total Cost of Ownership) and ROI (Return on Investment) of migrating to Oracle Cloud.",
        "Migration Strategy Development: Customized migration roadmap and strategy tailored to your specific business needs and technical requirements.",
      ],
    },
    {
      title: "Migration Planning ",
      description:
        "Our Migration Planning services are designed to ensure a seamless transition of your Oracle EBS and Fusion applications to the cloud. We offer:",
      details: [
        "Detailed Project Planning: Comprehensive project plan outlining key milestones, timelines, and resource allocation for a successful migration.",
        "Risk Management: Identification and mitigation of potential risks and challenges associated with the migration process to minimize disruptions.",
        "Data Mapping and Schema Conversion: Detailed mapping of your existing database schemas and data transformations to align with Oracle Cloud infrastructure.",
        "Application Dependency Mapping: Analysis of application interdependencies to ensure all connected systems are addressed during the migration.",
        "Testing and Validation: Rigorous testing protocols, including unit testing, integration testing, and user acceptance testing (UAT), to validate the migration process and ensure system integrity.",
      ],
    },
    {
      title: " Workload Migration",
      description:
        "The Workload Migration services focus on the actual migration of your Oracle EBS and Fusion workloads to Oracle Cloud. This includes:",
      details: [
        "Data Migration: Seamless transfer of structured and unstructured data from on-premises databases to Oracle Autonomous Database or Oracle Database Cloud Service, ensuring data integrity and consistency.",
        "Application Rehosting: Rehosting your Oracle EBS and Fusion applications on Oracle Cloud Infrastructure (OCI) with minimal reconfiguration and downtime.",
        "Middleware Integration: Migrating and configuring Oracle Middleware components, such as Oracle SOA Suite and Oracle WebLogic Server, to ensure continuous application functionality.",
        "Backup and Recovery: Implementation of robust backup and disaster recovery plans to safeguard your data and applications during and after the migration.",
        "Performance Tuning: Post-migration performance tuning to optimize the efficiency and responsiveness of your Oracle applications in the cloud environment.",
      ],
    },
    {
      title: "Ongoing Cloud Management",
      description:
        "Our Ongoing Cloud Management services provide continuous support and management of your Oracle EBS and Fusion environments in the cloud. Key aspects include:",
      details: [
        "Monitoring and Alerting: Real-time monitoring and proactive alerting for performance issues, security threats, and compliance breaches using Oracle Management Cloud tools.",
        "Patch Management: Regular application and database patching to ensure that you are always running on the latest, most secure versions.",
        "Capacity Planning: Dynamic scaling and resource optimization to meet changing business demands and ensure high availability and optimal performance.",
        "Security Management: Ongoing management of cloud security policies, identity and access management (IAM), and data encryption to protect sensitive information.",
        "Compliance Monitoring: Continuous monitoring and auditing to ensure compliance with industry regulations and corporate policies",
      ],
    },

    {
      title: "Cloud Optimization",
      description:
        "Our Cloud Optimization services ensure that your Oracle EBS and Fusion applications run efficiently and cost-effectively in the Oracle Cloud. This involves:",
      details: [
        "Performance Optimization: Advanced techniques for tuning database and application performance, leveraging Oracle Exadata and Autonomous Database features.",
        "Cost Management: Optimization of cloud resource usage to reduce costs, including rightsizing instances and utilizing Oracle Cloud Cost Management tools.",
        "High Availability and Disaster Recovery: Implementing and maintaining high availability (HA) and disaster recovery (DR) configurations using Oracle Data Guard and Oracle Real Application Clusters (RAC).",
        "Application and Database Upgrades: Applying the latest upgrades and features to your Oracle EBS and Fusion applications to take advantage of new capabilities and improved performance.",
        "Continuous Improvement: Leveraging Oracle Cloud’s continuous improvement framework to implement best practices and streamline operations for long-term success",
      ],
    },
    {
      title: "Hybrid Cloud Solutions",
      description:
        "Our Hybrid Cloud Solutions service bridges your on-premises Oracle environments with Oracle Cloud to deliver seamless hybrid cloud architectures. This includes:",
      details: [
        "Integration Architecture Design: Designing a robust integration architecture that connects your on-premises Oracle EBS/Fusion applications with Oracle Cloud Infrastructure (OCI), leveraging Oracle Integration Cloud.",
        "Data Synchronization: Implementing data synchronization solutions to ensure data consistency and integrity between on-premises and cloud environments, using Oracle GoldenGate.",
        "Identity and Access Management: Implementing hybrid identity management strategies using Oracle Identity Cloud Service (IDCS) to provide secure, unified access across environments.",
        "Hybrid Disaster Recovery: Establishing hybrid DR solutions that use a combination of on-premises and cloud resources to safeguard your data and applications.",
        "Cost and Performance Optimization: Balancing workloads between on-premises and cloud environments to optimize cost and performance dynamically.",
      ],
    },
    {
      title: "Custom Developments and Enhancements  ",
      description:
        "Customized solutions and enhancements are essential to extend the functionality of Oracle applications to meet unique business requirements.",
      details: [
        "Bespoke Application Development: Utilizing Oracle Application Express (APEX) and Oracle Fusion Middleware (ADF) for developing custom applications that seamlessly integrate with core Oracle EBS and Fusion modules. ",
        "Enhancements and Extensions: Developing enhancements using Oracle Extension Framework for market-specific and process-specific needs without impacting the upgrade path of the core Oracle Environment.",
        "Integration with External Systems: Building bespoke integrations via Oracle SOA Suite or Oracle Integration Cloud Service (ICS), ensuring secure and efficient data exchange with third-party applications.",
        "Oracle Workflow and Approvals Management: Extending Oracle workflows and Approval Management Engine (AME) for customized approval routing and business process automation.",
        "User Interface Customization: Tailoring user interfaces with Oracle Visual Builder for Fusion applications and Oracle OAF for EBS, enhancing user experience through customized layouts and functionalities.",
      ],
    },
    {
      title: " DevOps and Continuous Integration/Continuous Deployment (CI/CD)",
      description:
        "Focused on streamlining your development and operational efforts, our DevOps and CI/CD services for Oracle Cloud include:",
      details: [
        "CI/CD Pipeline Setup: Setting up automated CI/CD pipelines using Oracle Developer Cloud Service to accelerate application development and deployment processes.",
        "Infrastructure as Code (IaC): Implementing IaC practices with tools like Terraform and Oracle Resource Manager to automate the provisioning and management of cloud resources.",
        "Automated Testing: Integrating automated testing frameworks to validate changes quickly and ensure robust, error-free deployments in Oracle Cloud.",
        "Containerization and Orchestration: Deploying Oracle EBS and Fusion applications in containerized environments using Docker and Kubernetes for improved scalability and manageability.",
        "Monitoring and Logging: Implementing comprehensive monitoring and logging solutions to track the performance and health of your DevOps pipelines, utilizing Oracle Management Cloud.",
      ],
    },
    {
      title: "Application Modernization",
      description:
        "Modernizing legacy Oracle applications to take advantage of Oracle Cloud's advanced features and capabilities includes",
      details: [
        "Refactoring: Analyzing and refactoring legacy Oracle EBS/Fusion applications to leverage modern cloud-native architecture principles, such as microservices.",
        "Replatforming: Migrating and replatforming applications to Oracle Cloud Infrastructure (OCI) to enhance performance, scalability, and resilience.",
        "API Management: Developing and managing APIs to enhance interoperability between legacy systems and modern cloud-based applications using Oracle API Gateway.",
        "Low-Code Development: Utilizing Oracle Application Express (APEX) and Oracle Visual Builder to accelerate the development of modern, cloud-native applications.",
        "Integration with SaaS Applications: Extending the functionality of Oracle EBS/Fusion applications by integrating them with Oracle SaaS solutions like Oracle ERP Cloud and HCM Cloud.",
      ],
    },
    {
      title: "Big Data and Analytics in the Cloud ",
      description:
        "Providing advanced analytics and big data capabilities for your Oracle environments in the cloud:",
      details: [
        "Data Warehouse Cloud Service (DWCS): Migrating existing data warehouses to Oracle Autonomous Data Warehouse (ADW) for highly scalable, self-managing analytics capabilities.",
        "Big Data Integration: Integrating Oracle Big Data Service with Oracle EBS/Fusion data to provide comprehensive big data analytics, leveraging tools like Oracle Data Integrator.",
        "Advanced Analytics: Implementing advanced analytics and machine learning models using Oracle Analytics Cloud to derive actionable insights from your data.",
        "Real-Time Analytics: Enabling real-time analytics with Oracle Stream Analytics for immediate insights into your Oracle EBS and Fusion data.",
        "Visualization and Reporting: Developing advanced dashboards and reports using Oracle BI Publisher and Oracle Analytics Cloud, tailored to meet your specific business intelligence needs.",
      ],
    },
    {
      title: "Comprehensive Disaster Recovery Solutions",
      description:
        "Ensuring business continuity with robust disaster recovery strategies for Oracle Cloud environments:",
      details: [
        "Replication Strategies: Implementing data replication strategies using Oracle Active Data Guard and Oracle GoldenGate to ensure data availability and integrity.",
        "DR Orchestration: Automating DR processes with Oracle Cloud Infrastructure (OCI) DR orchestration tools to minimize recovery time objectives (RTO) and recovery point objectives (RPO)",
        "Backup Solutions: Designing and managing comprehensive backup solutions using Oracle Cloud Infrastructure (OCI) Backup and Recovery services.",
        "Failover and Failback Procedures: Creating and testing failover and failback procedures to ensure seamless business operations during unexpected outages.",
        "Compliance with DR Regulations: Ensuring that your DR solutions meet regulatory compliance requirements and best practices for data protection and business continuity.",
      ],
    },
  ];
  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
            }}
          >
            Oracle Cloud Migration and Management Services
          </h1>
          <p style={{ fontSize: "17px" }}>
            At FocusR, we specialize in delivering a full spectrum of Oracle
            Cloud Migration and Management services tailored to Oracle EBS and
            Fusion environments. Our expertise encompasses every stage of the
            migration journey—from initial cloud readiness assessments and
            meticulous migration planning to seamless workload transitions and
            continuous, proactive management. We also provide specialized
            services in hybrid cloud solutions, DevOps, application
            modernization, advanced analytics, and robust disaster recovery. Our
            highly technical approach ensures that your Oracle applications and
            infrastructure are optimized for performance, scalability, and
            security, enabling you to drive business innovation and growth in
            the cloud{" "}
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CloudMigration;
