import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const CustomDevelopment = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Implementation Services",
      description:
        "Our certified Oracle professionals are highly skilled in deploying Oracle E-Business Suite (EBS) and Oracle Fusion applications. Our thorough implementation process includes:",

      details: [
        "Business Requirements Gathering: Conducting stakeholder interviews, workshops, and JAD sessions to capture precise business requirements. Using Oracle AIM (Application Implementation Methodology) or OUM (Oracle Unified Method) frameworks to structure the requirement documentation process.",
        "Technical System Architecture Design: Architecting scalable and secure environments tailored for Oracle EBS and Oracle Fusion, leveraging Oracle Cloud Infrastructure (OCI) or on-premise setups. This includes configuring Oracle ASM (Automatic Storage Management), RAC (Real Application Clusters), and Data Guard for high availability and disaster recovery.",
        "Instance Planning and Environment Setup: Setting up multiple instances such as CRP (Conference Room Pilot), SIT (System Integration Testing), UAT (User Acceptance Testing), and Production environments. Implementing Oracle EBS R12.x and Oracle Fusion SaaS with proper cloning, patching, and backup strategy.",
        "Data Migration Strategy: Utilizing Oracle Data Integrator (ODI), SQL*Loader, and custom scripts for data migration. Ensuring accurate data mapping between legacy systems and Oracle EBS/Fusion. Performing extensive data validation using SQL, PL/SQL scripts, and Oracle Data Profiling tools.",
        "User Training and Documentation: Developing comprehensive user manuals, online help guides, and conducting role-based training sessions. Utilizing Oracle UPK (User Productivity Kit) for creating interactive training materials and tutorials. Monitoring user competency through post-training assessments.",
      ],
    },
    {
      title: "Roll-Out Services",
      description:
        "Ensuring a streamlined roll-out of Oracle solutions across your organization involves:",
      details: [
        "Phased and Agile Deployment Approach: Implementing Oracle EBS modules (such as Financials, SCM, HCM) and Oracle Fusion applications in phases using agile methodologies. Employing iterative roll-out strategies to ensure minimal disruption and faster realization of benefits.",
        "Organizational Change Management (OCM): Crafting change management plans including stakeholder engagement, readiness assessments, and resistance management. Leveraging tools like Oracle OCM Suite to facilitate and monitor change activities.",
        "Regulatory and Compliance Configuration: Customizing Oracle EBS and Fusion applications to meet industry regulations such as SOX (Sarbanes-Oxley Act), GDPR, and HIPAA. Implementing Oracle GRC (Governance, Risk, and Compliance) modules to ensure auditability and compliance.",
        "Localization and Globalization: Configuring Oracle EBS using localizations specific for different geographies, such as local tax codes, legal reporting requirements, multi-currency, and multi-language support. Utilizing Oracle Fusion's Global Human Resources (HR) module for managing a global workforce.",
        "Proactive Performance Monitoring: Setting up Oracle Enterprise Manager (OEM) to monitor performance metrics, system health, and user activity. Generating AWR (Automatic Workload Repository) and ADDM (Automatic Database Diagnostic Monitor) reports to ensure optimal performance during the roll-out phase.",
      ],
    },
    {
      title: "Upgrade Services",
      description:
        "Providing seamless upgrades to Oracle EBS and Fusion platforms to leverage enhanced capabilities:",
      details: [
        "Comprehensive Upgrade Assessment: Conducting FIT-GAP analysis to identify customizations, extensions, and potential conflicts. Using Oracle Upgrade Advisor and compatibility guides to plan the upgrade path from EBS 11i or R12.x to R12.2.x or from Oracle Fusion applications to newer releases.",
        "Strategic Upgrade Execution: Utilizing proven upgrade methodologies such as the Oracle EBS Online Patching ADOP (Application DBA Online Patching) for minimal downtime. Conducting backups, cloning, and environment refreshes before upgrades.",
        "Data Integrity and Compatibility Checks: Performing pre-upgrade and post-upgrade data validation using SQL scripts, Oracle GoldenGate for migration and replication tasks, and Oracle Flashback technology for data recovery.",
        "Comprehensive Testing Procedures: Utilizing automated testing tools like Oracle Application Testing Suite (OATS) for regression testing, load testing, and UAT. Ensuring all functional and technical aspects meet the organization's needs post-upgrade.",
        "End-User Training on New Features: Conducting training sessions on new features and enhancements using detailed instructional materials. Utilizing Oracle Learning Management for tracking and managing learning paths.",
      ],
    },
    {
      title: "Application Management Services (ASM)",
      description:
        "Ensuring the optimal and secure operation of Oracle EBS and Fusion applications:",
      details: [
        "Advanced Proactive Monitoring: Implementing Oracle Enterprise Manager (OEM) with custom scripts for comprehensive server, application, and database monitoring. Setting up thresholds and alerts for proactive issue detection and resolution.",
        "Incident, Problem & Change Management: Utilizing Oracle IT Service Management Suite for tracking incidents, analyzing problem root causes, and managing change requests. Integrating with ITIL-based service management and Oracle Support for seamless issue resolution.",
        "Patch and Security Management: Deploying quarterly Critical Patch Updates (CPU) and Patch Set Updates (PSU) from Oracle. Utilizing Oracle OPatch and Oracle Fusion Middleware Patch Application Utility (PATCH) for patch management.",
        "Performance Tuning and Optimization: Conducting regular database and application performance assessments. Implementing SQL tuning, indexing strategies, Oracle Exadata performance tuning techniques, and database partitioning to improve system performance.",
        "Business Continuity and Disaster Recovery (BCDR): Implementing high-availability solutions like Oracle Data Guard, Oracle RAC, and Oracle GoldenGate for disaster recovery. Developing and testing DR plans to ensure minimal downtime and data loss.",
      ],
    },

    {
      title: "Integration and Customization",
      description:
        "Providing specialized services to tailor Oracle EBS and Fusion to your business processes:",
      details: [
        "API and Web Services Development: Developing and managing REST and SOAP API endpoints using Oracle Integration Cloud (OIC) for secure data exchange across disparate systems. Implementing OAuth2 and other security protocols for API security.",
        "Custom Development (RICEFW): Developing custom Reports, Interfaces, Conversions, Extensions, Forms, and Workflows using Oracle ADF, Oracle Forms, and Oracle Reports. Ensuring seamless integration with Oracle EBS and Fusion environments using OAF and custom PL/SQL packages.",
        "Enterprise Service Bus (ESB) Integration: Utilizing Oracle Service Bus (OSB) and Oracle SOA Suite for robust middleware solutions to integrate heterogeneous applications. Implementing messaging queues, service orchestration, and business process execution.",
        "Workflow Automation and BPM: Designing custom workflows using Oracle Business Process Management Suite (BPM) and Oracle Workflow for EBS. Streamlining and automating complex business processes using Oracle Fusion's embedded process management tools.",
        "Real-Time Data Synchronization and BI Analytics: Ensuring real-time data synchronization using Oracle GoldenGate for data replication. Integrating Oracle BI Publisher, Oracle Analytics Cloud (OAC), and OTBI (Oracle Transactional Business Intelligence) for enhanced reporting and data analytics.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "40px",
            }}
          >
            Implement / Roll-Out / Upgrade / ASM
          </h1>
          <p style={{ fontSize: "158x" }}>
            At Focusr, we specialize in deploying and optimizing Oracle
            E-Business Suite (EBS) and Oracle Fusion applications. From seamless
            implementations and strategic roll-outs to comprehensive upgrades
            and application management services (ASM), our highly technical
            approach ensures you maximize the value of your Oracle investments.
            Leveraging advanced methodologies and tools, we offer tailored
            solutions that drive efficiency, enhance performance, and support
            strategic decision-making, making Oracle a perfect fit for your
            business requirements.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
        <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CustomDevelopment;
