import { useEffect, useRef, useState } from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { Link, Element, scroller } from "react-scroll";
import { HiMenuAlt1 } from "react-icons/hi";
import {
  Button,
  Drawer,
  Dropdown,
  Popover,
  Space,
  message,
  Tooltip,
} from "antd";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import focusRlogoRight from "../../assets/FocusR_100px.png";
import Footer from "../../Components/Footer";
import FocusrAIArrow from '../../assets/focusrarrowai.png'
import IntelligenceIcon from '../../assets/intelligence (1).svg'
import FeatureDots from '../../assets/smb_repeat_circle_bg.svg'
import { MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import { useWindupString } from "windups";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import AzureML from '../../assets/Azure Machine Learning Service.png'
import AzureCognitive from '../../assets/cognative-services.png'
import AzureOpenAI from '../../assets/Azure OpenAI.jpg'
import AzureSynapse from '../../assets/Azure Synapse Analytics.png'
import VertexAI from '../../assets/vertexai.png'
import AutoML from '../../assets/AutoML.png'
import AIHub from '../../assets/aihub.png'
import TensorFlow from '../../assets/TensorFlow New.png'
import NLApi from '../../assets/naturalapi.png'
import VisionAPI from '../../assets/Cloud Vision API.png'
import Sagemaker from '../../assets/SageMaker.png'
import Rekognition from '../../assets/Rekognition.png'
import Polly from '../../assets/Polly.png'
import Lex from '../../assets/Lex.png'
import Comprehend from '../../assets/Comprehend.png'
import './aiServices.css'

const CloudAI = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const contentRefs = useRef([]);
  const [navbarStatus, setnavbarStatus] = useState(false);
  const [navBarMonitor, setnavBarMonitor] = useState(false);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const showDrawer = () => {
    setOpen(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (e.isTrusted && window.scrollY > 0) {
        setnavbarStatus(true);
        setHover(true);
      } else {
        setnavbarStatus(false);
        setHover(false);
      }
    });
    console.log("Window y position", window.scrollY);
    console.log("windowWidth", window.innerWidth);
    //"1px 1px 50px 1px #fff"
    if (window.innerWidth < 1150) {
      setnavBarMonitor(true);
    } else {
      setnavBarMonitor(false);
    }
    window.addEventListener("resize", (e) => {
      e.isTrusted && window.innerWidth < 1150
        ? setnavBarMonitor(true)
        : setnavBarMonitor(false);
    });
  }, [window.innerWidth]);

  const isMobile = window.innerWidth < 768; // Example mobile check
  const isTablet = window.innerWidth <= 1024 && window.innerWidth >= 768; // Example tablet check

  window.onload = function () {
    const elements = document.querySelectorAll('.framework-icons');
    elements.forEach(el => {
      el.classList.add('anim-init');
    });
  };
  const onClose = () => {
    setOpen(false);
  };

  const items2 = [
    {
      key: "1",
      label: <span className="drawer-antd-btn1">Gen AI</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/llmframework");
              onClose();
            }}
          >
            LLM Framework
          </li>
          <li
            onClick={() => {
              navigate("/finetuning");
              onClose();
            }}
          >
            Fine-Tuning
          </li>
        </ul>
      ),
    },
    {
      key: "3",
      label: <span className="drawer-antd-btn1">ERP</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/seamlessintegration");
              onClose();
            }}
          >
            Integration
          </li>
          <li
            onClick={() => {
              navigate("/dataprotection");
              onClose();
            }}
          >
            Data Protection
          </li>
          <li
            onClick={() => {
              navigate("/unstructureddata");
              onClose();
            }}
          >
            Data Processing
          </li>
          <li
            onClick={() => {
              navigate("/cloudai");
              onClose();
            }}
          >
            Cloud AI
          </li>
          <li
            onClick={() => {
              navigate("/rpaeuc");
              onClose();
            }}
          >
            RPA
          </li>
        </ul>
      ),
    },
    {
      key: "4",
      label: <span className="drawer-antd-btn1">AI APP</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/products/HireCentral");
              onClose();
            }}
          >
            Hireflow
          </li>
        </ul>
      ),
    },
    {
      key: "5",
      label: <span className="drawer-antd-btn1">Analytics</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/powerbianalytics");
              onClose();
            }}
          >
            Power BI
          </li>
          <li
            onClick={() => {
              navigate("/oraclesapanalytics");
              onClose();
            }}
          >
            Oracle and SAP
          </li>
          <li
            onClick={() => {
              navigate("/googleanalytics");
              onClose();
            }}
          >
            Google
          </li>
        </ul>
      ),
    },
  ];

  const sections = [
    {
      title: "End-to-End AI Infrastructure Solutions",
      description: "FocusR manages the entire process—from initial setup to ongoing support—so your systems can operate efficiently without downtime. Whether you need AI models hosted in the cloud, data pipelines set up for machine learning, or real-time analytics solutions, we handle it all.",
    },
    {
      title: "Azure AI Services",
      images: [
        {
          imageUrl: AzureML,
          alt: "Azure Machine Learning"
        },
        {
          imageUrl: AzureCognitive,
          alt: "Azure Cognitive Services"
        },
        {
          imageUrl: AzureOpenAI,
          alt: "Azure OpenAI Services"
        },
        {
          imageUrl: AzureSynapse,
          alt: "Azure Synapse Analytics"
        }
      ],
      description: "With Azure AI, FocusR offers a variety of solutions that integrate seamlessly with Microsoft Azure’s cloud ecosystem:",
      details: [
        "Azure Machine Learning (AML):  Build, train, and deploy machine learning models at scale with AML. We help configure the infrastructure, integrate with your existing systems, and ensure your models are optimized.",
        "Azure Cognitive Services: Unlock advanced capabilities like speech recognition, language understanding, and computer vision. FocusR customizes these services to meet your business needs.",
        "Azure OpenAI Service:  Deploy state-of-the-art generative AI models, like GPT, using Azure’s secure and scalable cloud infrastructure, fine-tuned for your specific use cases.",
        "Azure Synapse Analytics:  Combine big data analytics with AI, providing a unified platform for data integration, warehousing, and AI model deployment.",
      ],
    },
    {
      title: "GCP AI Services",
      images: [
        {
          imageUrl: VertexAI,
          alt: "VertexAI"
        },
        {
          imageUrl: AutoML,
          alt: "AutoML"
        },
        {
          imageUrl: AIHub,
          alt: "AI Hub"
        },
        {
          imageUrl: TensorFlow,
          alt: "TensorFlow"
        },
        {
          imageUrl: NLApi,
          alt: "Natural Language API"
        },
        {
          imageUrl: VisionAPI,
          alt: "Vision API"
        }
      ],
      description: "With Google Cloud AI, FocusR helps you take advantage of Google's extensive AI capabilities:",
      details: [
        "Vertex AI:  A unified machine learning platform that simplifies the process of building, deploying, and scaling ML models. We help you integrate Vertex AI into your cloud environment to automate workflows.",
        "AutoML:  Enable automatic model training and deployment with minimal coding. FocusR supports AutoML solutions to fast-track your AI initiatives.",
        "AI Hub and TensorFlow:   Utilize Google’s open-source machine learning framework, TensorFlow, to build custom AI models. We also offer access to Google’s AI Hub, which provides pre-trained models for immediate deployment.",
        "Natural Language API and Vision API:  Deploy these advanced APIs to add text analytics, language understanding, and image recognition capabilities to your applications.",
      ],
    },
    {
        title: "AWS AI Services",
        images: [
          {
            imageUrl: Sagemaker,
            alt: "Sagemaker"
          },
          {
            imageUrl: Rekognition,
            alt: 'Rekognition'
          },
          {
            imageUrl: Polly,
            alt: "Polly"
          },
          {
            imageUrl: Lex,
            alt: "Lex"
          },
          {
            imageUrl: Comprehend,
            alt: "Comprehend"
          }
        ],
        description: "Leverage AWS AI to build, scale, and deploy robust AI applications:",
        details: [
          "Amazon SageMaker:   Develop, train, and deploy machine learning models with Amazon SageMaker. FocusR ensures your models are optimized for performance and integrated with your AWS environment.",
          "Amazon Rekognition:  Add powerful image and video analysis capabilities to your applications. From facial recognition to object detection, we help implement Rekognition in your AI workflows.",
          "Amazon Polly and Lex:  Implement voice-enabled AI solutions using Polly for text-to-speech and Lex for natural language processing, delivering smarter customer experiences.",
          "Amazon Comprehend:  Extract key insights from unstructured text data using AWS's Comprehend, enabling your business to analyze customer feedback, emails, and social media data.",
        ],
      },
      {
        title: "Ongoing AI Support and Maintenance",
        description: "Beyond initial implementation, FocusR provides ongoing support to ensure your AI infrastructure remains efficient and secure:",
        details: [
          "24/7 Monitoring:   We monitor your AI systems in real time to identify and resolve issues quickly.",
          "Performance Optimization:  FocusR continually evaluates your infrastructure to ensure scalability, speed, and reliability as your business grows.",
          "Security and Compliance:   Our services adhere to industry best practices and regulations, ensuring your AI systems are secure and compliant with data protection standards.",
          "Regular Updates:   As cloud AI services evolve, we ensure your systems stay up-to-date with the latest features and improvements from Azure, GCP, and AWS.",
        ],
      },
    {
      title: "Key Benefits of FocusR’s Cloud AI Infrastructure Services:",
      details: [
        "Scalability:   Our cloud AI solutions grow with your business, ensuring that your infrastructure can handle increasing demand.",
        "Multi-Cloud Expertise:   Whether you’re using Azure, GCP, AWS, or a hybrid setup, FocusR ensures smooth integration and optimal performance.",
        "Cost Efficiency:   We help you select the right cloud services and configurations to maximize your ROI and minimize operational costs.",
        "Customized Support:    From setup to ongoing maintenance, our services are tailored to meet your specific business and technical requirements.",
      ],
    },
    {
        title: "Transform Your Business with Scalable Cloud AI",
        description: "With FocusR’s cloud AI infrastructure services, you can harness the power of AI across leading platforms like Azure AI, GCP AI, and AWS AI—all while ensuring your systems are efficient, scalable, and secure."
    }
  ];
  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);

    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      {/* <Navbar mode="dark" /> */}
      {navBarMonitor ? (
          <div className="navbar2" style={{ backgroundColor: "#fff" }}>
            <button className="hamburger-cus" onClick={showDrawer}>
              <HiMenuAlt1 style={{ width: "3em", height: "3em" }} />
            </button>
            <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
          </div>
        ) : (
          <Navbar mode="dark" />
        )}
        <Drawer
          title={
            <div className="logo-small">
              <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
            </div>
          }
          placement="top"
          onClose={onClose}
          open={open}
          height="100%"
        >
          <button
            className="drawer-antd-btn"
            onClick={() => {
              navigate("/");
              onClose();
            }}
          >
            Home
          </button>
          <Collapse ghost items={items2} expandIconPosition="right" />
        </Drawer>
      {/* <ChatNew /> */}
      <div className="welcome-page-23-llm">
        <center>
        <div className='welcome-ai-page-2-header' style={{marginBottom: '3em'}}>
            <div className='welcome-ai-page-2-wrap' style={{width: isMobile ? "": isTablet ? '': "90%"}}>
              <div className='welcome-ai-page-2-title' onClick={() => navigate('/services/AI')} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <img src={FocusrAIArrow} width="auto" height={ isMobile? "30px" : "45px"}/>
                <img src={IntelligenceIcon} width={ isMobile? "100px" : "150px"} style={{marginLeft: '15px'}} />
              </div>
              <div className='welcome-ai-page-2-navlinks'>
                <div className='welcome-ai-page-2-menu' style={{height: 'auto', top: 'auto', display: 'block'}}>
                <span className='menu-item'>Gen AI<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/llmframework')} className="dropdown-item">LLM Framework</Link>
      <Link onClick={() => navigate('/finetuning')} className="dropdown-item">Fine-tuning</Link>
    </div>
  </span>
  <span className='menu-item'>ERP<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/seamlessintegration')} className="dropdown-item">Integration</Link>
      <Link onClick={() => navigate('/dataprotection')} className="dropdown-item">Data Protection</Link>
      <Link onClick={() => navigate('/unstructureddata')} className="dropdown-item">Data Processing</Link>
      <Link onClick={() => navigate('/cloudai')} className="dropdown-item">Cloud AI</Link>
      <Link onClick={() => navigate('/rpaeuc')} className="dropdown-item">RPA</Link>
    </div>
  </span>
  <span className='menu-item'>AI APP<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/products/HireCentral')} className="dropdown-item">Hireflow</Link>
    </div>
  </span>
  <span className='menu-item'>Analytics<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/powerbianalytics')} className="dropdown-item">Power BI</Link>
      <Link onClick={() => navigate('/oraclesapanalytics')} className="dropdown-item">Oracle and SAP</Link>
      <Link onClick={() => navigate('/googleanalytics')} className="dropdown-item">Google</Link>
    </div>
  </span>

                </div>
                {/* <a href='#aiform' style={{display : isMobile? 'none' : 'flex', backgroundColor: '#E42527', minWidth: '100px',marginLeft: '10px', justifyContent: 'center', borderRadius: '30px', padding: '7px 20px', color: 'white'}}>
                  Get Started
                </a> */}
              </div>
            </div>
          </div>
          <h1
            style={{
              color: "#0067FF",
              textAlign: "center",
              width: "90%",
              fontSize: isMobile? '35px': '46px',
              fontWeight: '800',
              lineHeight: '52px',
              marginBottom: '0.5em'
            }}
          >
            Cloud AI Infrastructure Implementation and Support
          </h1>
          <p style={{ fontSize: "18px", letterSpacing: '1px' }}>
          At FocusR, we offer comprehensive, scalable cloud AI infrastructure services designed to empower your business with the latest advancements in artificial intelligence. Our expertise covers the full range of services from Azure AI, Google Cloud Platform (GCP) AI, and AWS AI, ensuring your cloud-based AI systems are set up for optimal performance, reliability, and growth.{" "}
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/contact");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      {/* <div className="llmframework-feature1">
        <div className="anim-init" style={{ color: '#0067ff', textAlign: 'center', fontSize: '60px' }} data-aos="fade-right"
        data-aos-delay="200">
          LLMS we work with
        </div>
        <div style={{ display: 'flex', paddingTop: '35px', height: 'fit-content', justifyContent: 'space-evenly', width: '100%' }} >
          <div className="framework-icon-wrapper" data-aos="fade-right"
        data-aos-delay="300">
            <span className="framework-icons anim-init">
                <img width={150} src={GeminiIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={200} src={OpenAIIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={180} src={ClaudeIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={80} style={{ paddingTop: '2em' }} src={LlamaIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={180} style={{ paddingTop: '2.5em' }} src={HuggingIcon} />
            </span>
          </div>
        </div>
      </div> */}
      {/* <div className="llmframework-feature1">
      <div className="anim-init" style={{ color: '#0067ff', textAlign: 'center', fontSize: isMobile? '35px': '60px' }}>
          LLMS we work with
        </div>
      <div className="new-logo-container" data-aos="fade-right"
        data-aos-delay="200">
          {arrayOfImages.map((x, i) => {
            return <img src={x.imageUrl} alt={x.alt} />;
          })}
        </div>
        </div>
        <div className="feature1-main" style={{maxWidth: '1140px', margin: '130px auto 0', position: 'relative'}}>
          <div className="feature1-dots"></div>
          <div style={{background: 'white'}}>
      <div style={{ color: '#0067ff', textAlign: 'center', fontSize:isMobile? '30px': '60px', marginBottom: '1em' }}>
        Other LLMs that can be integrated
      </div>
      <div className="llmframework-feature2">
        <div className="feature2-table">
          <ul className="summary_lst">
            <li style={{ minHeight: '60px' }}>
              <span>AI21 Jur</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Aleph Alpha Luminous</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Baichuan LLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>ChatGLM-6B</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>GigaChat</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>GPT4All</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>IBM Watsonx.ai</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>KoboldAI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Moonshot LLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>MosaicML</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>NLP Cloud</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>OctoAI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>OpenLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Petals</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>SambaNova Sambaverse</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>SparkLLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Symbl.ai Nebula</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Together AI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Tongyi Qwen</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>vLLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Volc Engine Maas</span>
            </li>
          </ul>
        </div>
      </div>
      </div>
      </div>
      <div className="features-vectorbg">
        <div style={{ color: '#0067ff', textAlign: 'center', fontSize: '60px', marginBottom: '1em', marginTop: '1.5em' }}>
          <span style={{position: 'relative', top: '30px', fontSize: isMobile ? '26px' : ''}}>Vector stores we can work with</span>
        </div>
        <div className="llmframework-feature2" style={{borderRadius: '30px'}}>
          <div className="feature2-table" >
            <ul className="summary_lst">
              <li style={{ minHeight: '60px' }}><span>Activeloop Deep Lake</span></li>
              <li style={{ minHeight: '60px' }}><span>Aerospike Vector Search (AVS)</span></li>
              <li style={{ minHeight: '60px' }}><span>Alibaba Cloud OpenSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>ApertureDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Astra DB Vector Store</span></li>
              <li style={{ minHeight: '60px' }}><span>Atlas</span></li>
              <li style={{ minHeight: '60px' }}><span>AwaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Azure AI Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Baidu Cloud ElasticSearch VectorSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Baidu VectorDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Chroma</span></li>
              <li style={{ minHeight: '60px' }}><span>Clarifai</span></li>
              <li style={{ minHeight: '60px' }}><span>DashVector</span></li>
              <li style={{ minHeight: '60px' }}><span>Databricks Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>DingoDB</span></li>
              <li style={{ minHeight: '60px' }}><span>DocArray HnswSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>DocArray InMemorySearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Elasticsearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Epsilla</span></li>
              <li style={{ minHeight: '60px' }}><span>Faiss</span></li>
              <li style={{ minHeight: '60px' }}><span>Google BigQuery Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Google Vertex AI Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Hippo</span></li>
              <li style={{ minHeight: '60px' }}><span>Jaguar Vector Database</span></li>
              <li style={{ minHeight: '60px' }}><span>KDB.AI</span></li>
              <li style={{ minHeight: '60px' }}><span>Kinetica</span></li>
              <li style={{ minHeight: '60px' }}><span>LanceDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Lantern</span></li>
              <li style={{ minHeight: '60px' }}><span>ManticoreSearch VectorStore</span></li>
              <li style={{ minHeight: '60px' }}><span>Milvus</span></li>
              <li style={{ minHeight: '60px' }}><span>Momento Vector Index (MVI)</span></li>
              <li style={{ minHeight: '60px' }}><span>MongoDB Atlas Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Neo4j Vector Index</span></li>
              <li style={{ minHeight: '60px' }}><span>NucliaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>OpenSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Oracle AI Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>PGVector</span></li>
              <li style={{ minHeight: '60px' }}><span>Pinecone</span></li>
              <li style={{ minHeight: '60px' }}><span>Qdrant</span></li>
              <li style={{ minHeight: '60px' }}><span>Redis</span></li>
              <li style={{ minHeight: '60px' }}><span>SAP HANA Cloud Vector Engine</span></li>
              <li style={{ minHeight: '60px' }}><span>ScaNN</span></li>
              <li style={{ minHeight: '60px' }}><span>SemaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>SingleStoreDB</span></li>
              <li style={{ minHeight: '60px' }}><span>SQLite-VSS</span></li>
              <li style={{ minHeight: '60px' }}><span>SurrealDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Tencent Cloud VectorDB</span></li>
              <li style={{ minHeight: '60px' }}><span>ThirdAI NeuralDB</span></li>
              <li style={{ minHeight: '60px' }}><span>TiDB Vector</span></li>
              <li style={{ minHeight: '60px' }}><span>Timescale Vector (Postgres)</span></li>
              <li style={{ minHeight: '60px' }}><span>Upstash Vector</span></li>
              <li style={{ minHeight: '60px' }}><span>USearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Vald</span></li>
              <li style={{ minHeight: '60px' }}><span>Vearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Vectara</span></li>
              <li style={{ minHeight: '60px' }}><span>Vespa</span></li>
              <li style={{ minHeight: '60px' }}><span>viking DB</span></li>
              <li style={{ minHeight: '60px' }}><span>vlite</span></li>
              <li style={{ minHeight: '60px' }}><span>Weaviate</span></li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className="welcome-page-24" ref={welcomePageRef} style={{ marginTop: '5em' }}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${activeIndex === index ? "active" : ""
                      }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side" style={{paddingRight: '0'}}>
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              {Array.isArray(section.images) && section.images.length > 0 && (
      <div className="new-logo-container" style={{marginTop: '0', marginBottom: '2em', padding: '0',  marginLeft: '-20px',  marginRight: '-20px'}}>
        {section.images.map((img, imgIndex) => (
          <img key={imgIndex} src={img.imageUrl} alt={img.alt} />
        ))}
      </div>
    )}
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-ai-services">
        <h1 style={{fontSize: '25px', width: '80%'}}>Ready to elevate your AI infrastructure? <br/> Let FocusR design and manage a cloud AI environment that powers innovation and drives results for your business.</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/contact");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CloudAI;
