/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { BsFillWrenchAdjustableCircleFill } from 'react-icons/bs';
import chatbotLogo from '../assets/MeteorChat.svg';
import { Button, FloatButton, Input, Modal, Space } from 'antd';
import { RxVideo } from 'react-icons/rx';
import { BiVideo, BiSolidBot, BiPlay, BiSend } from 'react-icons/bi';
import ReactPlayer from 'react-player';
import { FiArrowRight } from 'react-icons/fi';
import { AiFillCloseCircle, AiOutlineClose } from 'react-icons/ai';

const ChatNew = (props) => {
  const [open, setOpen] = useState(false);
  const [userInput, setuserInput] = useState('');
  const [userInput2, setuserInput2] = useState('');
  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);
  const [show5, setshow5] = useState(false);
  const [show6, setshow6] = useState(false);
  const [response2, setResponse2] = useState('');
  const [request2, setRequest2] = useState('');
  const [request3, setRequest3] = useState('');
  // const [request, setRequest] = useState([]);
  const [responseState, setresponseState] = useState(false);
  const [requestState, setrequestState] = useState(false);
  const [Array, setArray] = useState([]);
 

  const request = [
    {
      id: '1a',
      data: 'In Oracle Related'
    },
    {
      id: '2a',
      data: 'In SAP Related'
    },
    {
      id: '3a',
      data: 'Is Development Related'
    }
  ];

  const response = ['How May I assist you', 'Oracle Related', 'SAP Related', 'Development Related', 'Out Sourcing'];

  const OracleResponse = [
    {
      id: '1_Oracle',
      data: 'Implementation'
    },
    {
      id: '2_Oracle',
      data: 'Upgrade'
    },
    {
      id: '3_Oracle',
      data: 'Support'
    },
    {
      id: '4_Oracle',
      data: 'Development'
    },
    {
      id: '5_Oracle',
      data: 'Business Process Re-Engineering'
    },
    {
      id: '6_Oracle',
      data: 'Process Automation'
    },
    {
      id: '7_Oracle',
      data: 'Testing & Audit'
    },
    {
      id: '8_Oracle',
      data: 'Training'
    }
  ];

  const SAPResponse = [
    {
      id: '1_SAP',
      data: 'Implementation'
    },
    {
      id: '2_SAP',
      data: 'Upgrade'
    },
    {
      id: '3_SAP',
      data: 'Support'
    },
    {
      id: '4_SAP',
      data: 'Development'
    },
    {
      id: '5_SAP',
      data: 'Business Process Re-Engineering'
    },
    {
      id: '6_SAP',
      data: 'Process Automation'
    },
    {
      id: '7_SAP',
      data: 'Testing & Audit'
    },
    {
      id: '8_SAP',
      data: 'Training'
    }
  ];

  const OutSourcing = [
    {
      id: '1_OutSourcing',
      data: 'React Developer'
    },
    {
      id: '2_OutSourcing',
      data: 'Java Developer'
    },
    {
      id: '3_OutSourcing',
      data: 'Angular Developer'
    },

    {
      id: '4_OutSourcing',
      data: 'React Native Developer'
    },
    {
      id: '5_OutSourcing',
      data: 'Spring Boot Developer'
    },
    {
      id: '6_OutSourcing',
      data: 'SAP Consultants'
    },
    {
      id: '7_OutSourcing',
      data: 'Oracle Functional Consultants'
    },
    {
      id: '8_OutSourcing',
      data: 'Oracle Technical Consultants'
    }
  ];

  useEffect(() => {
    setResponse2(response[0]);

  }, []);

  const oracleRelated = (x) => {
    console.log('XXXX', x);
    if (x.id === '3a') {
      setshow2(true);
      setRequest2('Please Choose Below OutSourcing Services');
      setTimeout(() => {
        setshow5(true);
      }, 1000);
    } else if (x.id === '2a') {
      setshow2(true);
      setRequest2('Please Choose Below SAP Services');
      setTimeout(() => {
        setshow4(true);
      }, 1000);
    } else if (x.id === '1a') {
      setshow2(true);
      setRequest2('Please Choose Below Oracle Services');
      setTimeout(() => {
        setshow3(true);
      }, 1000);
    }
  };

  const OracleSubRelated = (x) => {
    setshow6(true);
    console.log('xxxx111111', x);
    setRequest3('Here is The Related Data For \n' + x.data);
  };
  const SAPRelated = (x) => {
    console.log('xxxx22222', x);
  };
  return (
    <>
     {show ? (
        <div className="own-float">
          <div className="own-chat-head">
            <img src={chatbotLogo} width="40%" alt="chatbot logo" />
            <AiOutlineClose
              style={{
                cursor: 'pointer',
                color: '#000'
              }}
              onClick={() => setshow(false)}
            />
          </div>
          <div className="own-chat-body">
            <div className="left-chat">
              <button className="request-btn">
                {' '}
                <BiSolidBot
                  style={{
                    color: '#000'
                  }}
                />
                &nbsp; &nbsp; {response2}
              </button>
            </div>
            <div className="right-chat">
              <div className="right-chat-inner">
                {request.map((x, i) => {
                  return (
                    <button className="response-btn" onClick={() => oracleRelated(x)}>
                      {x.data} &nbsp; &nbsp;
                      <BsFillWrenchAdjustableCircleFill
                        style={{
                          color: '#fff'
                        }}
                      />
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="left-chat">
              {show2 ? (
                <button className="request-btn">
                  {' '}
                  <BiSolidBot
                    style={{
                      color: '#000'
                    }}
                  />
                  &nbsp; &nbsp;{request2}
                </button>
              ) : null}
            </div>
            {show3 ? (
              <div className="right-chat">
                <div className="right-chat-inner">
                  {OracleResponse.map((x, i) => {
                    return (
                      <button className="response-btn" onClick={() => OracleSubRelated(x)}>
                        {x.data} &nbsp; &nbsp;
                        <BsFillWrenchAdjustableCircleFill
                          style={{
                            color: '#fff'
                          }}
                        />
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {show4 ? (
              <div className="right-chat">
                <div className="right-chat-inner">
                  {SAPResponse.map((x, i) => {
                    return (
                      <button className="response-btn" onClick={() => SAPRelated(x)}>
                        {x.data}&nbsp; &nbsp;
                        <BsFillWrenchAdjustableCircleFill
                          style={{
                            color: '#fff'
                          }}
                        />
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {show5 ? (
              <div className="right-chat">
                <div className="right-chat-inner">
                  {OutSourcing.map((x, i) => {
                    return (
                      <button className="response-btn" onClick={() => SAPRelated(x)}>
                        {x.data}&nbsp; &nbsp;
                        <BsFillWrenchAdjustableCircleFill
                          style={{
                            color: '#fff'
                          }}
                        />
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div className="left-chat">
              {show6 ? (
                <button className="request-btn">
                  {' '}
                  <BiSolidBot
                    style={{
                      color: '#000'
                    }}
                  />
                  &nbsp; &nbsp;{request3}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        {/* <FloatButton
          tooltip="Demo Video"
          // description='Demo'
          icon={
            <BiVideo
              style={{
                color: 'blue'
              }}
            />
          }
          onClick={() => setOpen(true)}
        /> */}
        {/* <FloatButton
          tooltip="Chat Bot"
          icon={
            <BiSolidBot
              style={{
                color: 'blue'
              }}
            />
          }
          onClick={() => setshow(true)}
        /> */}
        <FloatButton.BackTop />
      </FloatButton.Group>
      <Modal centered open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width="100%">
        <div
          style={{
            width: '100%',
            height: '90vh'
          }}
        >
          <ReactPlayer
            url="https://www.youtube.com/watch?v=NkBTFXjJNgs"
            playIcon={<BiPlay />}
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      </Modal>
    </>
  )
}

export default ChatNew