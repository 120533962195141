/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Collapse,
  Drawer,
  Dropdown,
  Popover,
  Space,
  message,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { DownOutlined } from "@ant-design/icons";
import { HiOutlineTicket } from "react-icons/hi";
import { FaExchangeAlt } from "react-icons/fa";
import { GrOracle, GrUserManager } from "react-icons/gr";
import Footer from "../Components/Footer";
import { Helmet } from 'react-helmet';
import {
  AiFillApi,
  AiFillBank,
  AiFillCloud,
  AiFillDatabase,
  AiFillAppstore,
  AiFillAlipaySquare,
} from "react-icons/ai";
import {
  MdOutlineWork,
  MdPublic,
  MdPrecisionManufacturing,
  MdPermMedia,
  MdOutlineAssessment,
  MdApproval,
  MdKeyboardArrowDown,
  MdOutlineMoreHoriz,
} from "react-icons/md";
import { RiCodeView, RiCustomerServiceFill } from "react-icons/ri";
import { GiHealthNormal, GiHeartPlus } from "react-icons/gi";
import { BiCode, BiSolidLandscape } from "react-icons/bi";
import { HiShoppingCart } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { BsFillLightningChargeFill, BsMailbox2, BsRobot } from "react-icons/bs";
import { SiSap } from "react-icons/si";
import {
  FaShuttleSpace,
  FaRobot,
  FaTruckFront,
  FaTruckRampBox,
} from "react-icons/fa6";
import { useLocation,useParams,useNavigate } from "react-router-dom";
import officiallogo from "../assets/FocurR_arrow 1.svg";
import { CgNotes } from "react-icons/cg";
import { IoMdBarcode } from "react-icons/io";
import { TbTruckDelivery } from "react-icons/tb";
import { IoPersonAddSharp } from "react-icons/io5";
import focusRlogo from "../assets/logo.png";
import focusRlogoRight from "../assets/FocusR_100px.png";
// import  ProductState  from '../Context/ProductLogic';
//import ProductLogic from '../Context/ProductLogic';
import { useProductState } from "../Context/ProductLogicProvider";
import { motion } from "framer-motion";
// import leftImage from "../assets/blogss.jpg";
import { IoMdHome } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import './Blog.css'
import Lottie from 'lottie-react';
import loading_animation from '../assets/loading_anim.json'

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

const Navbar = (props) => {
  console.log("Props", props.mode);
  const [searchValue, setSearchValue] = useState("");
  const [navbarStatus, setnavbarStatus] = useState(false);
  const [navBarMonitor, setnavBarMonitor] = useState(false);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [highlightBlog, setHighlightBlog] = useState(false);
  const [highlightCompany, setHighlightCompany] = useState(false);
  const [highlightHome, sethighlightHome] = useState(false);
  const [hightlightService, sethightlightService] = useState(false);
  const [highlightProducts, sethighlightProducts] = useState(false);
  const [highlightsolutions, sethighlightsolutions] = useState(false);
  const [highlightPlatforms, sethighlightPlatforms] = useState(false);
  const [highlightVertical, sethighlightVertical] = useState(false);
  const [openNavProd, setopenNavProd] = useState(false);
  const { isProductOpen, setIsProductOpen } = useProductState();
  const [highlightProducts2, setHighlightProducts2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const [blogDetails, setBlogDetails] = useState([]);
  const [blogContent, setBlogContent] = useState([]);
  console.log("blogs", blogDetails);
  const [messageApi, contextHolder] = message.useMessage();
  const[loading, setLoading] = useState(true);
  const location = useLocation();
  // const { blog } = location.state;
  // console.log("first", blog);

  const { id } = useParams();

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://focusrblogsapi.focusrtech.com:91/blog/retrievepublishedposts/nothing`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("this is response data", res.data);
        setBlogDetails(res.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  // console.log("idd", blog.postId);

  useEffect(() => {
    console.log("params",id)
    let postId;
    if (location.state && location.state.blog && location.state.blog.postId) {
      postId = location.state.blog.postId;
    } else if (id) {
      postId = id;
    } else {
      console.error('No blog ID provided');
      setLoading(false);
      return; // Exit early if no ID is available
    }
    axios
      .get(
        `https://focusrblogsapi.focusrtech.com:91/blog/retrieveparticularpost/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("Responce Data", res.data);
        setBlogContent(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("Error", err);
        setLoading(false);
      });
  }, []);

  

  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const handleMouseEnter = () => {
    setIsProductOpen(true);
    setHighlightProducts2(true);
  };

  const handleMouseLeave = () => {
    setIsProductOpen(false);
    setHighlightProducts2(false);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const headLocalstyle = {
    fontWeight: "600",
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (e.isTrusted && window.scrollY > 0) {
        setnavbarStatus(true);
        setHover(true);
      } else {
        setnavbarStatus(false);
        setHover(false);
      }
    });
    console.log("Window y position", window.scrollY);
    console.log("windowWidth", window.innerWidth);
    //"1px 1px 50px 1px #fff"
    if (window.innerWidth < 1150) {
      setnavBarMonitor(true);
    } else {
      setnavBarMonitor(false);
    }
    window.addEventListener("resize", (e) => {
      e.isTrusted && window.innerWidth < 1150
        ? setnavBarMonitor(true)
        : setnavBarMonitor(false);
    });
  }, [window.innerWidth]);

  const extractImagesAndContent = (htmlContent) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;

    const images = Array.from(div.getElementsByTagName("img"));
    const imageHtml = images.map((img) => img.outerHTML).join("");
    images.forEach((img) => img.remove());

    return {
      images: imageHtml,
      content: div.innerHTML,
    };
  };
  const { images, content } = extractImagesAndContent(blogContent.content);

  useEffect(() => {
    console.log("first", document.location.pathname);
    const a = document.location.pathname;
    if (String(a).includes("careers")) {
      setHighlightCompany(true);
    } else if (String(a).includes("platforms")) {
      sethighlightPlatforms(true);
    } else if (String(a).includes("products")) {
      sethighlightProducts(true);
    } else if (String(a).includes("verticals")) {
      sethighlightVertical(true);
    } else if (String(a).includes("solutions")) {
      sethighlightsolutions(true);
    } else if (String(a).includes("services")) {
      sethightlightService(true);
    } else if (String(a).includes("blog")) {
      setHighlightBlog(true);
    } else {
      setHighlightCompany(false);
      sethighlightHome(false);
      sethighlightPlatforms(false);
      sethighlightProducts(false);
      sethighlightVertical(false);
      sethighlightsolutions(false);
      sethightlightService(false);
      setHighlightBlog(false);
    }
  }, [document.location.pathname]);

  useEffect(() => {
    const blogContentDiv = document.querySelector(".blog-content");
    if (blogContentDiv) {
      const links = blogContentDiv.getElementsByTagName("a");
      for (let link of links) {
        link.setAttribute("target", "_blank");
      }
    }
  }, [content]);

  function formatAuthorName(author) {
    return author ? author.toLowerCase().replace(/\s+/g, "-") : "";
  }

  if (loading) {
    return (
      <div className="loading-container">
        <Lottie style={{ width: 150, height: 150 }} animationData={loading_animation} loop={true} />
      </div>
    );
  }
  

  return (
    <>
    <Helmet>
      <title>{blogContent.title}</title>
      <meta property="og:title" content={`${blogContent.title}: ${blogContent.footer}`} />
      {/* <meta property="og:description" content={blogContent.description || 'Read our latest blog post'} /> */}
      {/* <meta property="og:image" content={blogContent.thumbnail} /> */}
      <meta property="og:url" content={`https://focusrtech.com/blogContent/${blogContent.postId}`} />
      <meta property="og:type" content="article" />
    </Helmet>
    <div>
      {navBarMonitor ? (
          <div className="" style={{ backgroundColor: "#fff" }}>
            <button className="hamburger-cus" onClick={showDrawer}>
              <HiMenuAlt1 style={{ width: "3em", height: "3em" }} />
            </button>
            <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
          </div>
        ) : (
          <div
            className="navbar-blog"
            style={{
              boxShadow: hover ? "1px 1px 10px 1px rgb(227, 218, 218)" : "none",
            }}
          >
            <div className="navbar-wrap">
            {/* <ul className="left-nav"> */}
              <li
                className="logo"
                // style={{ color: "#423E3B" }}
                onClick={() => navigate("/")}
              >
                <img
                  src={focusRlogo}
                  width="90em"
                  alt="logo"
                  style={{
                    // marginTop: "0.1em",
                    // position: "absolute",
                    // left: "1.5em",
                    cursor: "pointer",
                    backgroundSize: "100%",
                    textIndent: "-99999px",
                    backgroundPosition: "1px 5px",
                  }}
                />
              </li>
              <ul className="left-nav">
              <li className="handwritten-font"
              onClick={() => navigate("/blog")}
                style={{
                  // marginLeft: "90%",
                  // marginTop: "0.4em",
                  // fontWeight: "normal",
                  // fontSize: "22px",
                  float: "left",
                  // opacity: "1",
                  padding: "5px 0 0 15px",
                  flex: "1",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "normal",
                  cursor: "pointer",
                  fontSize: "25px",
                  letterSpacing: "1px"
                }}
              >
                Blog
              </li>
            </ul>
            <ul
              className="right-nav"
              style={{ display: "flex", alignItems: "center" }}
            >
              <li
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#423E3B",
                  cursor: "pointer",
                  position: "relative",
                  marginLeft: "90%",
                }}
              >
                <FaSearch onClick={() => setShowSearch(!showSearch)} />
                <div
                  ref={searchRef}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: showSearch ? "-265px" : "10px",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    padding: "0.5em",
                    display: "flex",
                    alignItems: "center",
                    transition: "left 0.4s, opacity 0.1s",
                    opacity: showSearch ? 1 : 0,
                    pointerEvents: showSearch ? "auto" : "none",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search..."
                    style={{
                      border: "none",
                      outline: "none",
                      padding: "0.5em",
                      width: "230px",
                    }}
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                  />
                </div>
              </li>
              <li
                onClick={() => navigate("/")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#423E3B",
                  marginLeft: "0em",
                }}
              >
                <IoMdHome />
                <span style={{ marginLeft: "0.1em", marginTop: "2%" }}>
                  Home
                </span>
                {highlightHome ? (
                  <span
                    style={{
                      width: "2em",
                      height: "3px",
                      display: "inline-block",
                      backgroundColor: "red",
                      transition: "all 400ms",
                    }}
                  ></span>
                ) : null}
              </li>
            </ul>
            </div>
          </div>
        )}
        <Drawer
          title={
            <div className="logo-small">
              <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
            </div>
          }
          placement="top"
          onClose={onClose}
          open={open}
          height="100%"
        >
          <button
            className="drawer-antd-btn"
            onClick={() => {
              navigate("/");
              onClose();
            }}
          >
            Home
          </button>
        </Drawer>
      </div>
      <div
        className="blog-content-container"
        style={{ fontFamily: "'Open Sans', sans-serif" }}
      >
        <div className="content-wrapper">
          <h1 style={{ textAlign: "left", fontWeight: "normal" }}>
            {blogContent.title}: {blogContent.footer}
          </h1>
          <p style={{ marginTop: "1.5em" }}>
            <span className="posted-info">
              Posted on {formatDate(blogContent.publishedDate)}
            </span>{" "}
            By{" "}
            <Link
                    to={`/blog/author/${formatAuthorName(blogContent.author)}`}
                    style={{ color: "#03A9F5" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {blogContent.author}{" "}
                  </Link>
          </p>
          {/* <div
            className="blog-images"
            dangerouslySetInnerHTML={{ __html: images }}
          /> */}
          <div
            className="blog-content"
            dangerouslySetInnerHTML={{ __html: blogContent.content }}
          />
        </div>
      </div>
      <Footer />
      {contextHolder}
    </>
  );
};

export default Navbar;
