/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import Products from './Components/Products';
import techstephubMockup from '../../assets/eprocurement-mockup.svg';
import Chat from '../../Components/Chat';
import { Collapse } from 'antd';
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled
} from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';

const EProcurement = () => {
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    document.title = 'Product | E-Proc';

    AOS.init();
  });

  const eProcFAQ = [
    {
      key: '1',
      label: <h3>1. What is the purpose of the E-Proc application?</h3>,
      children: (
        <p>
          The E-Proc application is designed to streamline the procurement process by providing an efficient
          E-Procurement solution. It facilitates the creation, approval, and management of procurement requests and orders.
        </p>
      ),
    },
    {
      key: '2',
      label: <h3>2. How do I get started with the E-Proc application?</h3>,
      children: (
        <p>
          To get started, access the E-Proc app through the App Store/Google Play Store or visit our website. Create an
          account, and you can begin creating, submitting, and tracking procurement requests.
        </p>
      ),
    },
    {
      key: '3',
      label: <h3>3. What platforms does the E-Proc application support?</h3>,
      children: (
        <p>
          The E-Proc application is available for download on iOS and Android devices. Additionally, it can be accessed
          through modern web browsers for increased accessibility.
        </p>
      ),
    },
    {
      key: '4',
      label: <h3>4. Can I create and submit procurement requests using E-Proc?</h3>,
      children: (
        <p>
          Yes, E-Proc allows users to create and submit procurement requests seamlessly. The application guides you through
          the process, ensuring accurate and organized submissions.
        </p>
      ),
    },
    {
      key: '5',
      label: <h3>5. How does the approval process work in E-Proc?</h3>,
      children: (
        <p>
          E-Proc incorporates a structured approval process for procurement requests. Requests move through defined stages of
          approval, ensuring thorough scrutiny and adherence to organizational protocols.
        </p>
      ),
    },
    {
      key: '6',
      label: <h3>6. Is E-Proc integrated with any ERP systems?</h3>,
      children: (
        <p>
          Yes, E-Proc is designed to integrate seamlessly with various ERP systems, enhancing the synchronization of
          procurement data and improving overall workflow efficiency.
        </p>
      ),
    },
    {
      key: '7',
      label: <h3>7. Is customer support available for E-Proc?</h3>,
      children: (
        <p>
          Certainly. We offer dedicated customer support to assist you with any issues or questions you may have. You can
          reach out to our support team through the app or our website.
        </p>
      ),
    },
    {
      key: '8',
      label: <h3>8. Can E-Proc be used in offline mode?</h3>,
      children: (
        <p>
          Yes, E-Proc features offline functionality. You can continue creating and submitting procurement requests even
          without an internet connection, and the data will be synchronized when you are back online.
        </p>
      ),
    },
    {
      key: '9',
      label: <h3>9. How often are updates released for E-Proc?</h3>,
      children: (
        <p>
          We are committed to regular improvements. Updates with new features, enhancements, and bug fixes are released
          periodically to ensure E-Proc remains efficient and up-to-date.
        </p>
      ),
    },
    {
      key: '10',
      label: <h3>10. Can I customize approval workflows in E-Proc?</h3>,
      children: (
        <p>
          Yes, E-Proc provides flexibility in customizing approval workflows. You can configure the approval stages and
          criteria based on your organization's specific procurement processes.
        </p>
      ),
    },
    {
      key: '11',
      label: <h3>11. Are there any security measures in place for E-Proc?</h3>,
      children: (
        <p>
          E-Proc prioritizes data security. All information is encrypted, and the application follows industry-standard
          security practices, ensuring your procurement data is stored securely and accessible only to authorized users.
        </p>
      ),
    },
    {
      key: '12',
      label: <h3>12. Can E-Proc be used for tracking order status?</h3>,
      children: (
        <p>
          Absolutely. E-Proc provides real-time tracking of procurement requests and order status, allowing users to
          monitor progress and make informed decisions.
        </p>
      ),
    },
  ];
  
  
  

  return (
    <div>
      <Navbar mode="dark" />
      <Chat />
      <Products
        title="E-Proc"
        mockupSrc={techstephubMockup}
        videoUrl="https://www.youtube.com/watch?v=EhylxMKwgZI"
        mockupAlt="TechstepHub-Mockup"
        technologyUsed={['React ', 'Java ', 'Oracle ','Spring-Boot ']}
        ratingCount={5}
        reviewCount="131"
        benefitTitle="Benefits of E-Proc"
        width="100%"
        benefitArray1={[
          {
            icon: <TbSquareRoundedNumber1Filled className="num-icon" />,
            strongText: 'Seamless Oracle Integration',
            pText: "Effortlessly sync and manage procurement data with Oracle systems.",
          },
          {
            icon: <TbSquareRoundedNumber2Filled className="num-icon" />,
            strongText: 'Cost Optimization',
            pText: "Maximize cost savings through efficient procurement processes and vendor management.",
          },
          {
            icon: <TbSquareRoundedNumber3Filled className="num-icon" />,
            strongText: 'Real-time Tracking',
            pText: "Gain insights with real-time tracking of procurement activities and order statuses.",
          }
          // Add more benefits following the same structure
        ]}
        benefitArray2={[
          {
            icon: <TbSquareRoundedNumber4Filled className="num-icon" />,
            strongText: 'Compliance Assurance',
            pText: "Ensure regulatory compliance and policy adherence in procurement transactions.",
          },
          {
            icon: <TbSquareRoundedNumber5Filled className="num-icon" />,
            strongText: 'Vendor Collaboration',
            pText: "Enhance collaboration with vendors through integrated communication and document sharing.",
          },
          {
            icon: <TbSquareRoundedNumber6Filled className="num-icon" />,
            strongText: 'Enhanced Data Security',
            pText: "Protect sensitive procurement data with robust security measures integrated with Oracle systems.",
          }
        ]}
      />
      <br />
      <br />
      <h1
        style={{
          textAlign: 'center'
        }}
      >
        FAQ
      </h1>
      <br />
      <Collapse
        style={{
          width: '90%',
          position: 'relative',
          left: '5%',
          backgroundColor: '#fff',
          marginBottom: '5em',
          color:"#343F52"
        }}
        items={eProcFAQ}
        onChange={onChange}
        defaultActiveKey={['1']}
      />
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default EProcurement;
