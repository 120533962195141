import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const CustomDevelopment = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Custom Reports Development",
      description:
        "Providing detailed and customized reporting solutions that enhance data visibility and decision-making:",

      details: [
        "Oracle BI Publisher: Implement Oracle BI Publisher to design and deliver highly customized, pixel-perfect reports. Utilize templates and data models to generate complex financial, operational, and regulatory reports, drawing data from both Oracle E-Business Suite (EBS) and Fusion applications.",
        "Interactive Dashboards and Ad-hoc Reporting: Leverage Oracle Analytics Cloud (OAC) to create interactive dashboards and enable ad-hoc reporting capabilities, allowing users to drill down into data for deeper insights. This integration ensures smooth data flow between Fusion applications and BI tools.",
        "Custom PL/SQL Reports: Develop custom PL/SQL reports for EBS, enabling advanced calculations and data manipulations. These reports can be integrated with Oracle Fusion using Oracle Integration Cloud (OIC) for comprehensive reporting solutions.",
        "Oracle Smart View: Use Oracle Smart View for Office to integrate Excel-based reporting, providing users with familiar tools to analyze data. This approach ensures that data from Oracle ERP and Fusion systems can be seamlessly used in Excel for detailed analysis.",
        "Automated Reporting Solutions: Implement automated reporting workflows using Oracle Workflow and Oracle Integration Cloud (OIC) to schedule and deliver reports at predefined intervals, ensuring timely access to critical business data.",
      ],
    },
    {
      title: "Custom Interfaces Development",
      description:
        "Building robust and efficient interfaces for seamless connectivity between systems:",
      details: [
        "Oracle SOA Suite: Utilize Oracle SOA Suite to build and manage complex integration workflows that connect Oracle E-Business Suite and Fusion applications with third-party systems. This includes developing SOAP and RESTful web services for real-time data exchange.",
        "ODI for ETL Processes: Implement Oracle Data Integrator (ODI) to develop custom ETL processes that extract, transform, and load data between various Oracle and non-Oracle systems. This ensures data consistency and integrity across the enterprise.",
        "API Development: Develop custom APIs using Oracle API Gateway and Oracle Integration Cloud (OIC), enabling seamless interaction between Oracle Fusion applications and other enterprise systems such as CRM, SCM, and HCM.",
        "EDI Integration: Configure and deploy Electronic Data Interchange (EDI) interfaces to facilitate efficient B2B communication, integrating Oracle EBS and Fusion with trading partners and external systems.",
        "Real-time Data Sync: Implement Oracle GoldenGate for real-time data synchronization between Oracle EBS, Fusion applications, and other databases, ensuring that data is always up-to-date and accurate for business operations.",
      ],
    },
    {
      title: "Data Conversions ",
      description:
        "Expertly handling data migration and conversion processes to ensure smooth transitions:",
      details: [
        "Legacy Data Migration: Utilize Oracle Data Integrator (ODI) and Oracle SQL*Loader to migrate data from legacy systems into Oracle EBS and Fusion applications. This includes mapping, cleaning, and transforming data to fit Oracle’s data models.",
        "Data Transformation and Validation: Develop custom data transformation scripts using PL/SQL and ODI to ensure data accuracy and completeness during the conversion process. These scripts validate and cleanse data, eliminating inconsistencies before loading into target systems.",
        "Cutover Strategies: Plan and execute carefully designed cutover strategies that minimize downtime and ensure a seamless transition to new systems. This includes detailed planning for data extraction, staging, transformation, and loading activities.",
        "Automated Data Conversion Tools: Implement automated data conversion tools and frameworks to streamline the migration processes, reducing manual intervention and enhancing data accuracy. These tools are configured to support specific requirements of both Oracle EBS and Fusion environments.",
        "Post-conversion Reconciliation: Conduct thorough post-conversion reconciliation and validation processes to verify data integrity and correctness. This involves comparing source and target data to ensure all records have been accurately transferred and any discrepancies are addressed.",
      ],
    },
    {
      title: "Custom Extensions",
      description:
        "Developing extensions to enhance the functionality of Oracle Applications:",
      details: [
        "Oracle ADF Development: Use Oracle Application Development Framework (ADF) to build custom applications and extensions for Oracle Fusion. ADF enables the creation of rich, interactive web applications that seamlessly integrate with standard Fusion modules.",
        "OA Framework Customization: Customize Oracle Applications Framework (OAF) components in Oracle EBS, enhancing the user interface and adding new functionalities. This includes modifying existing pages and creating new OAF regions.",
        "PaaS Extensions: Develop Platform-as-a-Service (PaaS) extensions using Oracle Cloud Infrastructure (OCI) that extend the functionalities of Fusion applications. These extensions are hosted on OCI and integrate with Fusion via web services and REST APIs.",
        "Oracle Visual Builder: Utilize Oracle Visual Builder to rapidly create web and mobile applications that extend Oracle Fusion applications. Visual Builder’s low-code environment accelerates development and enables easy deployment of custom extensions.",
        "JDeveloper Customizations: Leveraging Oracle JDeveloper to create tailored business components and applications, enhancing Oracle Fusion’s capabilities. These customizations ensure that the extended functionalities remain compatible with future upgrades and patches",
      ],
    },

    {
      title: "Workflow and Automation",
      description:
        "Implementing advanced workflows and automation to streamline business processes:",
      details: [
        "•	Oracle BPM Suite: Deploy Oracle Business Process Management (BPM) Suite to design, simulate, and implement business processes that integrate with Oracle Fusion applications. BPM Suite helps automate complex workflows, improving efficiency and reducing manual tasks.",
        "Fusion Approval Workflows: Configure and customize approval workflows within Oracle Fusion applications using Oracle Workflow and BPM tools. This includes setting up multi-level approval hierarchies and defining custom approval rules.",
        "Robotic Process Automation (RPA): Implement RPA solutions to automate repetitive tasks within Oracle EBS and Fusion environments. RPA tools such as UiPath and Automation Anywhere can be integrated to handle routine data entry, report generation, and other manual processes.",
        "Job Scheduling: Utilize Oracle Enterprise Scheduler (ESS) and Oracle E-Business Suite Concurrent Programs to schedule and automate recurring tasks and batch processes, ensuring timely execution and consistency.",
        "Event-Driven Automation: Implement Oracle Event Processing to develop event-driven architectures that trigger automated workflows based on specific business even",
      ],
    },
    {
      title: "Advanced Data Security and User Management",
      description:
        "Ensuring robust data security and effective user management in custom Oracle solutions:",
      details: [
        "•	RBAC and Data Segmentation: Implement Role-Based Access Control (RBAC) in Oracle Fusion and EBS customizations to enforce data security and control access based on user roles. This ensures sensitive data is only accessible to authorized personnel.",
        "Data Encryption and Masking: Utilize Oracle Advanced Security options such as Transparent Data Encryption (TDE) and Oracle Data Masking and Subsetting Pack to protect sensitive data in custom applications, both in transit and at rest.",
        "Custom User Interfaces for Security Management: Develop custom user interfaces within Oracle Fusion using Oracle ADF for managing users, roles, and permissions. This streamlines the administration of security policies and compliance requirements.",
        "Auditing and Compliance: Configure Oracle Audit Vault and Database Firewall (AVDF) to monitor and log activities within custom developed applications. This ensures that all actions are auditable and compliant with regulatory standards like GDPR, SOX, and HIPAA.",
        "Federated Identity Management: Implement Oracle Identity Cloud Service (IDCS) for federated identity management, enabling Single Sign-On (SSO) and streamlined user authentication across Oracle EBS, Fusion, and third-party applications.",
      ],
    },
    {
      title: "Tailored Business Intelligence and Analytics",
      description:
        "Creating custom BI solutions to deliver tailored insights specific to business needs:",
      details: [
        "Oracle BI Publisher Custom Templates: Design and deploy custom templates in Oracle BI Publisher for generating highly formatted reports that cater to specific business requirements, integrating data from both Fusion and Oracle EBS.",
        "Custom BI Dashboards: Develop custom dashboards within Oracle Analytics Cloud that pull data from tailored sources, ensuring that key stakeholders have access to business-critical insights that align with their specific needs.",
        "Integrative Data Models: Build custom data models that integrate data from Oracle EBS, Fusion, and other enterprise systems using Oracle Data Integrator (ODI) and Oracle Enterprise Data Quality (EDQ). These models support bespoke analytics and reporting.",
        "Analytic Workspaces with Oracle OLAP: Utilize Oracle OLAP (Online Analytical Processing) to create analytic workspaces that allow in-depth, multidimensional analysis of business data tailored specifically to organizational questions and KPIs.",
        "Advanced Statistical Analysis: Integrate Oracle R Enterprise (ORE) to perform advanced statistical analysis and machine learning on data sourced from Oracle EBS and Fusion, providing predictive insights and advanced data trends.",
      ],
    },
    {
      title: "Enhanced Financial Management",
      description:
        "Custom solutions to improve financial operations and management within Oracle environments",
      details: [
        "Custom Financial Reports: Develop sophisticated financial reports using Oracle Financial Reporting Studio (FRS) that can integrate data from both Oracle Fusion Financials and EBS Financial modules. These reports can be customized to meet specific financial disclosure and compliance requirements.",
        "Automated Financial Processes: Implement workflow automations in Oracle Fusion to streamline financial processes such as Consolidation, Intercompany Transfers, and Automated Journal Entries, ensuring accuracy and reducing cycle times.",
        "Financial Data Integration: Employ Oracle Data Integrator (ODI) to seamlessly integrate financial data from disparate systems into Oracle EBS and Fusion Financials, ensuring a unified view of financial performance.",
        "Budgeting and Forecasting Customizations: Customize Oracle Hyperion Planning and Budgeting Cloud Service (PBCS) to improve budgeting, forecasting, and scenario planning processes, allowing for integrated financial planning and analysis.",
        "Customized Payment Gateway Integrations: Develop integrations with custom payment gateways, ensuring secure and compliant payment processing within Oracle EBS and Fusion ERP systems.",
      ],
    },
    {
      title: "Custom Supply Chain Solutions",
      description:
        "Building tailored solutions to optimize supply chain processes and operations:",
      details: [
        "Advanced Inventory Management: Develop custom extensions and interfaces within Oracle Fusion Supply Chain Management (SCM) to enhance inventory tracking, warehouse management, and order fulfillment processes.",
        "Procurement Customizations: Create custom workflows and integrations for Oracle Fusion Procurement, to streamline procurement processes and enhance supplier collaboration. This includes electronic purchase orders, supplier portals, and automated approvals.",
        "Demand Planning and Forecasting: Utilize Oracle Demantra to customize demand planning and forecasting models that integrate with Oracle EBS and Fusion Supply Chain modules, improving accuracy and responsiveness.",
        "Logistics and Transportation Integration: Implement Oracle Transportation Management (OTM) customizations to integrate logistics and transportation data, optimizing shipping routes, reducing costs, and enhancing delivery times.",
        "Supplier Performance Monitor: Build dashboards and reports using Oracle BI to monitor supplier performance metrics, leveraging data from Oracle EBS and Fusion to identify trends and improve supplier management strategies.",
      ],
    },
    {
      title: "Human Resources Management Enhancements",
      description:
        "Developing custom solutions to enhance Human Resources (HR) functionalities",
      details: [
        "Customized Talent Management: Configure and extend Oracle Fusion HCM modules to create tailored talent management processes, including custom performance review cycles, employee feedback mechanisms, and career development plans.",
        "Employee Self-Service Interfaces: Using Oracle ADF, develop custom self-service interfaces for employee onboarding, leave management, and benefits enrollment within Oracle EBS and Fusion HCM.",
        "HR Analytics: Implement Oracle Analytics Cloud to create custom HR analytics dashboards that provide insights into workforce metrics, including headcount, attrition rates, and employee engagement, integrating data from both EBS and Fusion HCM.",
        "Automated Compliance Reporting: Design automated compliance reporting tools that ensure adherence to labor laws and regulations, leveraging Oracle BI Publisher and integrating with both Fusion and EBS HR data.",
        "Learning and Development Customizations: Extending Oracle Learning Cloud to create tailored learning paths and training modules that address specific skill development needs, providing comprehensive employee development solutions.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "34px",
            }}
          >
            Custom Development RICE: Tailored Oracle Solutions for Enhanced
            Business Performance
          </h1>
          <p style={{ fontSize: "15px" }}>
            At FocusR, we specialize in delivering bespoke development services
            for Oracle applications, covering Reports, Interfaces, Conversions,
            and Extensions (RICE) to meet the unique needs of your business. Our
            offerings encompass advanced custom reports, seamless interface
            integrations, efficient data conversions, bespoke extensions,
            workflow automations, enhanced data security, tailored business
            intelligence, financial management solutions, optimized supply chain
            processes, and HR management enhancements. By leveraging the
            capabilities of Oracle E-Business Suite (EBS) and Oracle Fusion, our
            highly technical solutions ensure improved functionality, usability,
            and performance, empowering your organization to achieve operational
            excellence and strategic goals
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CustomDevelopment;
