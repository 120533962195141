import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const GreenField = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Requirements Gathering and Analysis",
      descriptions: [
        {
          text: "Stakeholder Analysis and Interviews: We engage in exhaustive stakeholder analysis to pinpoint crucial business and technical requirements. Techniques include:",
          details: [
            "Critical Requirement Workshops: Facilitating workshops with cross-functional teams to derive explicit functional and non-functional requirements.",
            "Use Case Identification: Creating detailed use case scenarios using Oracle Business Process Composer to capture real-world business activities and exceptions.",
          ],
        },
        {
          text: "Business Process Mapping: Leveraging Oracle’s BPM suite, we perform:",
          details: [
            "Current and Future State Modelling: Using Oracle Business Process Management to digitally model existing business processes and envision future states, ensuring alignment with Oracle functionalities.",
            "Gap Analysis: Evaluating performance gaps within current processes and proposing Oracle EBS and Fusion modules that introduce efficiencies and optimizations.",
          ],
        },
        {
          text: "Technical Evaluation: A meticulous technical assessment is conducted, encompassing:",
          details: [
            "Legacy System Analysis: Utilizing Oracle Data Profiling to understand legacy data structures and prepare for migration.",
            "Infrastructure Capability Assessment: Ensuring the existing IT landscape can support Oracle’s system needs, running scalability assessments with Oracle Real Application Testing (RAT).",
          ],
        },
        {
          text: "Regulatory and Compliance Alignment: Ensuring every requirement meets stringent compliance measures by:",
          details: [
            "Pre-Configured Regulatory Packages: Using Oracle’s regulatory compliance setups for industries such as healthcare and finance to ensure adherence to local and international laws.",
            "Audit Trails and Reporting: Establishing robust audit trails within Oracle Fusion Cloud and implementing pre-built reports for compliance verification.",
          ],
        },
      ],
    },
    {
        title: "Solution Design",
        descriptions: [
          {
            text: "System Architecture Design: Developing a resilient architecture through:",
            details: [
              "Oracle SOA Suite: Designing service-oriented architectures that leverage Oracle SOA Suite for orchestration and management of web services.",
              "High Availability Clusters: Setting up Oracle Real Application Clusters (RAC) for load balancing and continuous availability.",
            ],
          },
          {
            text: "Database Design: Focusing on a solid data foundation via:",
            details: [
              "Oracle Autonomous Database: Configuring Oracle Autonomous Data Warehouse for high availability, automated tuning, and superior performance.",
              "Partitioning and Data Segmentation: Implementing data partitioning methodologies for optimized query performance and management, using Oracle Partitioning.",
            ],
          },
          {
            text: "Application Modules Customization: Customizations involve:",
            details: [
              "Custom Object Libraries: Creating bespoke object libraries with Oracle Application Framework (OAF) to tailor modules like Financial Management and Human Resources.",
              "Workflow Management: Utilizing Oracle Workflow Builder to automate and customize business processes, ensuring alignment with unique business needs.",
            ],
          },
          {
            text: "Security Framework: Ensuring security by:",
            details: [
              "Multi-Layer Security Introductions: Integrating Oracle Identity Management (OIM) with multi-factor authentication to safeguard critical information.",
              "Data Encryption: Implementing Oracle Advanced Security to encrypt sensitive data both at rest and in transit.",
            ],
          },
        ],
      },
      {
        title: "Configuration and Setup",
        descriptions: [
          {
            text: "Module Configuration: Focused module configurations include:",
            details: [
              "Oracle Financials Cloud: Setting up financials, including GL, AP, and AR modules, with specific customizations for tax rules, automated invoicing, and compliance reporting.",
              "SCM Module Setup: Configuring Oracle Supply Chain Management, including Inventory, Procurement, and Order Management, to streamline end-to-end supply chain operations.",
            ],
          },
          {
            text: "Data Migration Strategy: A multi-faceted migration involves:",
            details: [
              "ETL Processes: Employing Oracle Data Integrator (ODI) for seamless extraction, transformation, and loading of data into the new system.",
              "Data Cleansing: Utilizing Oracle Enterprise Data Quality (EDQ) to cleanse and standardize data before migration.",
            ],
          },
          {
            text: "Integration Framework: Establishing integrations through:",
            details: [
              "Oracle Integration Cloud (OIC): Building and deploying complex integrations using OIC, ensuring real-time data exchange and process automation across various enterprise applications.",
              "API Management: Leveraging Oracle API Gateway for secure and scalable API management, facilitating seamless integration across diverse systems.",
            ],
          },
          {
            text: "Environment Setup: Ensuring stability and scalability with:",
            details: [
              "Parallel Environment Creation: Creating parallel environments to ensure uninterrupted operations during transitions using Oracle Multitenant for efficient consolidation.",
              "Continuous Deployment Pipelines: Implementing CI/CD pipelines using Oracle Developer Cloud Service for automated and error-free code deployments.",
            ],
          },
        ],
      },
      {
        title: "Testing and Validation",
        descriptions: [
          {
            text: "Unit and System Testing: Attention to detail in testing includes:",
            details: [
              "Automated Testing: Using Oracle Application Testing Suite (OATS) to automate regression tests, ensuring that all functionalities perform as expected.",
              "End-to-End Testing: Conducting comprehensive end-to-end testing scenarios to validate integrated business processes and data flows.",
            ],
          },
          {
            text: "User Acceptance Testing (UAT): Ensuring user preparedness with:",
            details: [
              "Scripted UAT: Providing detailed UAT scripts and scenarios aligned with real-world use cases for thorough validation.",
              "Feedback and Iteration: Utilizing Oracle Collaboration Suite for continuous feedback and iterative improvements based on user inputs.",
            ],
          },
          {
            text: "Performance Testing: Performance assessments are performed using:",
            details: [
              "Load Testing: Employing Oracle Load Testing to simulate high user loads and assess response times and system throughput.",
              "Scalability Analysis: Running scalability tests with Oracle Real Application Testing to ensure the system can handle future growth demands.",
            ],
          },
          {
            text: "Validation and Verification: Ongoing validation involves:",
            details: [
              "Data Consistency Checks: Using Oracle Data Integrator to perform thorough data validation and consistency checks post-migration.",
              "Configuration Audits: Automated configuration audits using Oracle Enterprise Manager to ensure compliance with predefined setups.",
            ],
          },
        ],
      },
      {
        title: "Deployment and Post-Implementation Support",
        descriptions: [
          {
            text: "Deployment Strategy: A robust deployment strategy includes:",
            details: [
              "Zero-Downtime Deployment: Utilizing Oracle GoldenGate for real-time data replication, ensuring zero downtime during the cutover.",
              "Rollback Mechanisms: Establishing reliable rollback procedures using Oracle Flashback Technology to recover systems to prior states if needed.",
            ],
          },
          {
            text: "Training and Documentation: Comprehensive training programs entail:",
            details: [
              "Structured Training Modules: Developing detailed training curricula using Oracle Learning Library and Interactive tutorials.",
              "Knowledge Transfer: Conducting hands-on training sessions and workshops, ensuring knowledge transfer to internal teams using Oracle User Productivity Kit (UPK).",
            ],
          },
          {
            text: "Post-Go-Live Support: Ongoing assistance includes:",
            details: [
              "24/7 Support Desk: Offering round-the-clock support services with dedicated Oracle-certified professionals for immediate issue resolution.",
              "Patch Management: Regularly applying Oracle patches and updates using Oracle Enterprise Manager to maintain system security and performance.",
            ],
          },
          {
            text: "Performance Monitoring: Post-implementation performance is monitored via:",
            details: [
              "Oracle Management Cloud Analytics: Utilizing advanced analytics and reporting from Oracle Management Cloud to monitor performance and identify optimization opportunities.",
              "Proactive Health Checks: Implementing regular health checks and system audits to foresee potential issues and apply corrective measures proactively.",
            ],
          },
        ],
      },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "40px",
            }}
          >
            Oracle Green-Field Implementation
          </h1>
          <p style={{ fontSize: "158x" }}>
            We at Focusr provide comprehensive expertise in green-field
            implementations of Oracle solutions. Our approach involves a
            meticulously planned and executed lifecycle that ensures new Oracle
            environments are established efficiently and effectively.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              {section.descriptions.map((description, descIndex) => (
                <div key={descIndex}>
                  <p style={{ marginLeft: "1.6em" }}>
                    {description.text.includes(":") ? (
                      <span>
                        <strong>{description.text.split(":")[0]}</strong>:{" "}
                        {description.text.split(":")[1]}
                      </span>
                    ) : (
                      description.text
                    )}
                  </p>
                  {Array.isArray(description.details) &&
                    description.details.length > 0 && (
                      <ul
                        style={{
                          listStyle: "square",
                          padding: "1.5em 0 2em 6em",
                        }}
                      >
                        {description.details.map((detail, detailIndex) => (
                          <li key={detailIndex} style={{ padding: "0.5em" }}>
                            {detail.includes(":") ? (
                              <span>
                                <strong>{detail.split(":")[0]}</strong>:{" "}
                                {detail.split(":")[1]}
                              </span>
                            ) : (
                              detail
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default GreenField;
