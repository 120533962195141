/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Collapse,
  Drawer,
  Dropdown,
  Popover,
  Space,
  message,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { DownOutlined } from "@ant-design/icons";
import { HiOutlineTicket } from "react-icons/hi";
import { FaExchangeAlt } from "react-icons/fa";
import { GrOracle, GrUserManager } from "react-icons/gr";
import Footer from "../Components/Footer";
import {
  AiFillApi,
  AiFillBank,
  AiFillCloud,
  AiFillDatabase,
  AiFillAppstore,
  AiFillAlipaySquare,
} from "react-icons/ai";
import {
  MdOutlineWork,
  MdPublic,
  MdPrecisionManufacturing,
  MdPermMedia,
  MdOutlineAssessment,
  MdApproval,
  MdKeyboardArrowDown,
  MdOutlineMoreHoriz,
} from "react-icons/md";
import { RiCodeView, RiCustomerServiceFill } from "react-icons/ri";
import { GiHealthNormal, GiHeartPlus } from "react-icons/gi";
import { BiCode, BiSolidLandscape } from "react-icons/bi";
import { HiShoppingCart } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { BsFillLightningChargeFill, BsMailbox2, BsRobot } from "react-icons/bs";
import { SiSap } from "react-icons/si";
import {
  FaShuttleSpace,
  FaRobot,
  FaTruckFront,
  FaTruckRampBox,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import officiallogo from "../assets/FocurR_arrow 1.svg";
import { CgNotes } from "react-icons/cg";
import { IoMdBarcode } from "react-icons/io";
import { TbTruckDelivery } from "react-icons/tb";
import { IoPersonAddSharp } from "react-icons/io5";
import focusRlogo from "../assets/logo.png";
import focusRlogoRight from "../assets/FocusR_100px.png";
// import  ProductState  from '../Context/ProductLogic';
//import ProductLogic from '../Context/ProductLogic';
import { useProductState } from "../Context/ProductLogicProvider";
import { motion } from "framer-motion";
import { IoMdHome } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import SaleSyncx from "../assets/SaleSynx.png";
import Techzard from "../assets/techzard.png";
import EprocLogo from "../assets/eproc.png";
import Truck from "../assets/homeSaleSynx.png";
import Ticket from "../assets/hometechzard.png";
import Proc from "../assets/homeeproc.png";
import './Blog.css'

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

const Navbar = (props) => {
  console.log("Props", props.mode);
  const [navbarStatus, setnavbarStatus] = useState(false);
  const [navBarMonitor, setnavBarMonitor] = useState(false);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [highlightBlog, setHighlightBlog] = useState(false);
  const [highlightCompany, setHighlightCompany] = useState(false);
  const [highlightHome, sethighlightHome] = useState(false);
  const [hightlightService, sethightlightService] = useState(false);
  const [highlightProducts, sethighlightProducts] = useState(false);
  const [highlightsolutions, sethighlightsolutions] = useState(false);
  const [highlightPlatforms, sethighlightPlatforms] = useState(false);
  const [highlightVertical, sethighlightVertical] = useState(false);
  const [openNavProd, setopenNavProd] = useState(false);
  const { isProductOpen, setIsProductOpen } = useProductState();
  const [highlightProducts2, setHighlightProducts2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const [blogDetails, setBlogDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;
  console.log("blogs", blogDetails);
  const [messageApi, contextHolder] = message.useMessage();
  const [searchValue, setSearchValue] = useState("");

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSearch(false);
    }
  };

  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  const [phone,setPhone] = useState("")
  const handleSubmit = () => {
    if (!name || !email || !phone) {
      message.error("All fields are required!");
      return;
    }
    const payload = {
      full_Name: name,
      email: email,
      phone: phone,
      type: "HireFlow",
    };
    axios
      .post("https://focusrapi.focusrtech.com:83/post/enquery", payload)
      .then((response) => {
        console.log(response.data);
        message.success("Submitted Successfully!");
        setName("");
        setEmail("");
        setPhone("");
      })
      .catch((error) => {
        console.error("There was an error making the request!", error);
        message.error("Failed to Submit");
      });
  };
<button
              className="submit"
              onClick={() => {handleSubmit(); navigate("/messageHireflow")}}
            >
              START
            </button>
            
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://focusrblogsapi.focusrtech.com:91/blog/retrievepublishedposts/nothing`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("this is response data", res.data);
        setBlogDetails(res.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };


const filteredBlogs = blogDetails.filter((data) => {
    const searchValueLower = searchValue.toLowerCase();
    const titleIncludes = data.title.toLowerCase().includes(searchValueLower);
    const footerIncludes = data.footer?.toLowerCase().includes(searchValueLower) || false;
   
    return titleIncludes || footerIncludes;
  });

  const indexOfLastBlog = currentPage * blogsPerPage;
const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

const paginate = (pageNumber) => setCurrentPage(pageNumber);
 

  const handleBlogClick = (blog) => {
    navigate(`/blogContent/${blog.postId}`, { state: { blog } });
  };

  const handleMouseEnter = () => {
    setIsProductOpen(true);
    setHighlightProducts2(true);
  };

  const handleMouseLeave = () => {
    setIsProductOpen(false);
    setHighlightProducts2(false);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const headLocalstyle = {
    fontWeight: "600",
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (e.isTrusted && window.scrollY > 0) {
        setnavbarStatus(true);
        setHover(true);
      } else {
        setnavbarStatus(false);
        setHover(false);
      }
    });
    console.log("Window y position", window.scrollY);
    console.log("windowWidth", window.innerWidth);
    //"1px 1px 50px 1px #fff"
    if (window.innerWidth < 1150) {
      setnavBarMonitor(true);
    } else {
      setnavBarMonitor(false);
    }
    window.addEventListener("resize", (e) => {
      e.isTrusted && window.innerWidth < 1150
        ? setnavBarMonitor(true)
        : setnavBarMonitor(false);
    });
  }, [window.innerWidth]);

  const onClick = ({ key }) => {
    message.info(`Click on item ${key}`);
  };
  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
    },
  ];

  useEffect(() => {
    console.log("first", document.location.pathname);
    const a = document.location.pathname;
    if (String(a).includes("careers")) {
      setHighlightCompany(true);
    } else if (String(a).includes("platforms")) {
      sethighlightPlatforms(true);
    } else if (String(a).includes("products")) {
      sethighlightProducts(true);
    } else if (String(a).includes("verticals")) {
      sethighlightVertical(true);
    } else if (String(a).includes("solutions")) {
      sethighlightsolutions(true);
    } else if (String(a).includes("services")) {
      sethightlightService(true);
    } else if (String(a).includes("blog")) {
      setHighlightBlog(true);
    } else {
      setHighlightCompany(false);
      sethighlightHome(false);
      sethighlightPlatforms(false);
      sethighlightProducts(false);
      sethighlightVertical(false);
      sethighlightsolutions(false);
      sethightlightService(false);
      setHighlightBlog(false);
    }
  }, [document.location.pathname]);

  function formatAuthorName(author) {
    return author.toLowerCase().replace(/\s+/g, "-");
  }

  return (
    <>
      <div>
      {navBarMonitor ? (
          <div className="" style={{ backgroundColor: "#fff" }}>
            <button className="hamburger-cus" onClick={showDrawer}>
              <HiMenuAlt1 style={{ width: "3em", height: "3em" }} />
            </button>
            <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
          </div>
        ) : (
          <div
            className="navbar-blog"
            style={{
              boxShadow: hover ? "1px 1px 10px 1px rgb(227, 218, 218)" : "none",
            }}
          >
            <div className="navbar-wrap">
            {/* <ul className="left-nav"> */}
              <li
                className="logo"
                // style={{ color: "#423E3B" }}
                onClick={() => navigate("/")}
              >
                <img
                  src={focusRlogo}
                  width="90em"
                  alt="logo"
                  style={{
                    // marginTop: "0.1em",
                    // position: "absolute",
                    // left: "1.5em",
                    cursor: "pointer",
                    backgroundSize: "100%",
                    textIndent: "-99999px",
                    backgroundPosition: "1px 5px",
                  }}
                />
              </li>
              <ul className="left-nav">
              <li className="handwritten-font"
                style={{
                  // marginLeft: "90%",
                  // marginTop: "0.4em",
                  // fontWeight: "normal",
                  // fontSize: "22px",
                  float: "left",
                  // opacity: "1",
                  padding: "5px 0 0 15px",
                  flex: "1",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "normal",
                  cursor: "pointer",
                  fontSize: "25px",
                  letterSpacing: "1px"
                }}
              >
                Blog
              </li>
            </ul>
            <ul
              className="right-nav"
              style={{ display: "flex", alignItems: "center" }}
            >
              <li
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#423E3B",
                  cursor: "pointer",
                  position: "relative",
                  marginLeft: "90%",
                }}
              >
                <FaSearch onClick={() => setShowSearch(!showSearch)} />
                <div
                  ref={searchRef}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: showSearch ? "-265px" : "10px",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    padding: "0.5em",
                    display: "flex",
                    alignItems: "center",
                    transition: "left 0.4s, opacity 0.1s",
                    opacity: showSearch ? 1 : 0,
                    pointerEvents: showSearch ? "auto" : "none",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search..."
                    style={{
                      border: "none",
                      outline: "none",
                      padding: "0.5em",
                      width: "230px",
                    }}
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                  />
                </div>
              </li>
              <li
                onClick={() => navigate("/")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#423E3B",
                  marginLeft: "0em",
                }}
              >
                <IoMdHome />
                <span style={{ marginLeft: "0.1em", marginTop: "2%" }}>
                  Home
                </span>
                {highlightHome ? (
                  <span
                    style={{
                      width: "2em",
                      height: "3px",
                      display: "inline-block",
                      backgroundColor: "red",
                      transition: "all 400ms",
                    }}
                  ></span>
                ) : null}
              </li>
            </ul>
            </div>
          </div>
        )}
        <Drawer
          title={
            <div className="logo-small">
              <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
            </div>
          }
          placement="top"
          onClose={onClose}
          open={open}
          height="100%"
        >
          <button
            className="drawer-antd-btn"
            onClick={() => {
              navigate("/");
              onClose();
            }}
          >
            Home
          </button>
        </Drawer>
      </div>

      <div className="content-container">
        <div className="left-content">
          <h1>LATEST POSTS</h1>
          <hr
            style={{
              color: "#d3d3d3",
              marginLeft: "2.5em",
              marginTop: "1em",
              width: "88%",
              opacity: "0.2",
            }}
          />
          {currentBlogs.map((blog) => (
            <div
              key={blog.id}
              className="post"
              onClick={() => handleBlogClick(blog)}
            >
              <img src={blog.thumbnail} alt={`Post ${blog.id}`} />
              <h2 style={{ fontWeight: "bold", fontSize: "28px" }}>
                {blog.title}: {blog.footer}
              </h2>
              <div className="descrip-blogs">
                <p style={{ marginBottom: "10%", marginTop: "-3%" }}>
                  <span className="posted-info">
                    Posted on {formatDate(blog.publishedDate)}
                  </span>{" "}
                  By{" "}
                  <Link
                    to={`/blog/author/${formatAuthorName(blog.author)}`}
                    style={{ color: "#03A9F5" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {blog.author}{" "}
                  </Link>
                </p>
              </div>
              <hr
                className="blog-line"
                style={{
                  color: "#d3d3d3",
                  marginLeft: "2.5em",
                  marginTop: "-3em",
                  width: "88%",
                  opacity: 0.2,
                }}
              />
            </div>
          ))}
          <div className="pagination">
      {[...Array(Math.ceil(filteredBlogs.length / blogsPerPage)).keys()].map(number => (
        <button 
          key={number + 1} 
          onClick={() => paginate(number + 1)} 
          className={`page-number ${currentPage === number + 1 ? 'active' : ''}`}
        >
          {number + 1}
        </button>
      ))}
    </div>
        </div>
        <div className="right-content">
          <div
            className="feature-item"
            style={{
              borderColor: "#E6F7FF",
              borderWidth: "2px",
              borderStyle: "solid",
              backgroundColor: "white",
              marginTop: "50px",
            }}
          >
            <div>
              <img
                src={Truck}
                alt="truck"
                style={{ width: "40px", height: "40px" }}
              ></img>
            </div>
            <h3>
              SalesSyncX &nbsp;
              {/* <FaTruckFront
                className="inner-menu-icon"
                style={{
                  position: "relative",
                  top: "0.3em",
                  fontSize: "2em",
                  maxWidth: "12%",
                }}
              /> */}
            </h3>
            <div className="feature1">
              <p>
                SalesSyncX streamlines dealer portal operations, integrating
                seamlessly with Oracle ERP for purchase orders and approvals.
              </p>
            </div>
            <div className="logo-container">
              <img
                src={SaleSyncx}
                alt="SaleSyncx Logo"
                className="blogpage-logo"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/products/SaleSyncX")}
              ></img>
            </div>
          </div>

          <div
            className="feature-item"
            style={{
              borderColor: "#FFF0F0",
              borderWidth: "2px",
              borderStyle: "solid",
              backgroundColor: "white",
            }}
          >
            <div>
              <img
                src={Ticket}
                alt="ticket"
                style={{ width: "50px", height: "50px" }}
              ></img>
            </div>
            <h3>
              Techzard &nbsp;
              {/* <HiOutlineTicket
                className="inner-menu-icon"
                style={{
                  position: "relative",
                  top: "0.3em",
                  fontSize: "2em",
                  maxWidth: "13%",
                }}
              /> */}
            </h3>
            <div className="feature2">
              <p>
                Streamline ticket management with Techzard for efficient issue
                tracking, prioritization, and resolution.
              </p>
            </div>
            <div className="logo-container">
              <img
                src={Techzard}
                alt="techzard logo"
                className="blogpage-logo"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/products/Techzard")}
              ></img>
            </div>
          </div>

          <div
            className="feature-item"
            style={{
              borderColor: "#FEF2CC",
              borderWidth: "2px",
              borderStyle: "solid",
              backgroundColor: "white",
            }}
          >
            <div>
              <img
                src={Proc}
                alt="procurement"
                style={{ width: "40px", height: "40px" }}
              ></img>
            </div>
            <h3>
              E-proc &nbsp;
              {/* <MdApproval
                className="inner-menu-icon"
                style={{
                  position: "relative",
                  top: "0.3em",
                  fontSize: "2em",
                  maxWidth: "12%",
                }}
              /> */}
            </h3>
            <div className="feature3">
              <p>
                The E-Proc application streamlines procurement with efficient
                request, approval, and order management.
              </p>
            </div>
            <div className="logo-container">
              <img
                src={EprocLogo}
                alt="Eproc Logo"
                className="blogpage-logo"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/products/E-Proc")}
              ></img>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {contextHolder}
    </>
  );
};

export default Navbar;
