import { useEffect, useRef, useState } from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { Link, Element, scroller } from "react-scroll";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import FocusrAIArrow from '../../assets/focusrarrowai.png'
import IntelligenceIcon from '../../assets/intelligence (1).svg'
import FeatureDots from '../../assets/smb_repeat_circle_bg.svg'
import { MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import { useWindupString } from "windups";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import GeminiIcon from '../../assets/gemini-icon-new.png'
import OpenAIIcon from '../../assets/OpenAI-new.png'
import ClaudeIcon from '../../assets/Claude Ai.png'
import LlamaIcon from '../../assets/Facebook Meta Icon.png'
import HuggingIcon from '../../assets/hugging-face.png'
import focusRlogoRight from "../../assets/FocusR_100px.png";
import { HiMenuAlt1 } from "react-icons/hi";
import {
    Button,
    Drawer,
    Dropdown,
    Popover,
    Space,
    message,
    Tooltip,
  } from "antd";
import './aiServices.css'

const LLMFramework = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);
  const [open, setOpen] = useState(false);
    const [navbarStatus, setnavbarStatus] = useState(false);
  const [hover, setHover] = useState(false);
    const [navBarMonitor, setnavBarMonitor] = useState(false);

    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      window.addEventListener("scroll", (e) => {
        if (e.isTrusted && window.scrollY > 0) {
          setnavbarStatus(true);
          setHover(true);
        } else {
          setnavbarStatus(false);
          setHover(false);
        }
      });
      console.log("Window y position", window.scrollY);
      console.log("windowWidth", window.innerWidth);
      //"1px 1px 50px 1px #fff"
      if (window.innerWidth < 1150) {
        setnavBarMonitor(true);
      } else {
        setnavBarMonitor(false);
      }
      window.addEventListener("resize", (e) => {
        e.isTrusted && window.innerWidth < 1150
          ? setnavBarMonitor(true)
          : setnavBarMonitor(false);
      });
    }, [window.innerWidth]);

    const items2 = [
      {
        key: "1",
        label: <span className="drawer-antd-btn1">Gen AI</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/llmframework");
                onClose();
              }}
            >
              LLM Framework
            </li>
            <li
              onClick={() => {
                navigate("/finetuning");
                onClose();
              }}
            >
              Fine-Tuning
            </li>
          </ul>
        ),
      },
      {
        key: "3",
        label: <span className="drawer-antd-btn1">ERP</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/seamlessintegration");
                onClose();
              }}
            >
              Integration
            </li>
            <li
              onClick={() => {
                navigate("/dataprotection");
                onClose();
              }}
            >
              Data Protection
            </li>
            <li
              onClick={() => {
                navigate("/unstructureddata");
                onClose();
              }}
            >
              Data Processing
            </li>
            <li
              onClick={() => {
                navigate("/cloudai");
                onClose();
              }}
            >
              Cloud AI
            </li>
            <li
              onClick={() => {
                navigate("/rpaeuc");
                onClose();
              }}
            >
              RPA
            </li>
          </ul>
        ),
      },
      {
        key: "4",
        label: <span className="drawer-antd-btn1">AI APP</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/products/HireCentral");
                onClose();
              }}
            >
              Hireflow
            </li>
          </ul>
        ),
      },
      {
        key: "5",
        label: <span className="drawer-antd-btn1">Analytics</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/powerbianalytics");
                onClose();
              }}
            >
              Power BI
            </li>
            <li
              onClick={() => {
                navigate("/oraclesapanalytics");
                onClose();
              }}
            >
              Oracle and SAP
            </li>
            <li
              onClick={() => {
                navigate("/googleanalytics");
                onClose();
              }}
            >
              Google
            </li>
          </ul>
        ),
      },
    ];

  const isMobile = window.innerWidth < 768; // Example mobile check
  const isTablet = window.innerWidth <= 1024 && window.innerWidth >= 768; // Example tablet check

  const arrayOfImages = [
    {
      imageUrl: GeminiIcon,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: OpenAIIcon,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: ClaudeIcon,
      alt: 'Logo-A-Series'
    },

    {
      imageUrl: LlamaIcon,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: HuggingIcon,
      alt: 'Logo-A-Series'
    },
  ];

  window.onload = function () {
    const elements = document.querySelectorAll('.framework-icons');
    elements.forEach(el => {
      el.classList.add('anim-init');
    });
  };

  const sections = [
    {
      title: "Why FocusR’s Customized LLM Framework Design?",
      details: [
        "Business-Specific AI Solutions: We don't believe in cookie-cutter AI. Our team of experts collaborates with you to understand your business's specific pain points and strategic objectives. We then craft a custom LLM framework that directly addresses your operational needs and accelerates your business goals.",
        "Deep Industry Expertise: With extensive experience across industries—ranging from finance and healthcare to retail and logistics—we understand the nuances and requirements of different sectors. Our customized LLMs are trained using industry-relevant data, enabling better accuracy, performance, and contextual understanding of your business processes.",
        "End-to-End Customization: We offer full-spectrum AI customization, starting from data collection and model architecture to training and deployment. This end-to-end approach ensures that every layer of your AI solution is aligned with your business and IT environment, delivering maximum ROI.",
        "Advanced AI Capabilities: Whether it's natural language processing (NLP), predictive analytics, or automated decision-making, FocusR’s LLMs integrate cutting-edge AI techniques. We leverage the latest advancements in AI research to design models that offer deep insights, enhanced automation, and improved customer engagement.",
        "Future-Proof Frameworks: The AI world is always evolving, and so are the needs of your business. Our LLM frameworks are built to be scalable and flexible, ensuring that your AI solutions can grow and adapt as new technologies and business requirements emerge. Whether it’s adding new features or scaling up processing power, your LLM will be ready for future expansions.",
      ],
    },
    {
      title: "Key Features of FocusR’s LLM Frameworks:",
      details: [
        "Data-Driven Customization: We develop models using your proprietary data to ensure that your LLM understands the intricacies of your business and responds intelligently to real-world challenges.",
        "High-Performance AI Models: By leveraging advanced training methods and optimizations, our LLMs deliver fast, reliable, and highly accurate results—empowering your team to make informed decisions based on real-time insights.",
        "Seamless Integration: Our customized LLM frameworks are designed to integrate smoothly into your existing systems and infrastructure. Whether you use cloud platforms, on-premises solutions, or hybrid systems, FocusR ensures that the AI seamlessly connects to your data pipelines, workflows, and operational systems.",
        "Enhanced Security and Compliance: FocusR understands that data security is paramount. We embed strong data protection mechanisms—from encryption to compliance with industry standards like GDPR and HIPAA—ensuring that your LLM operates within a secure environment, safeguarding sensitive business data.",
        "Continuous Improvement: Once deployed, our support doesn’t end. We offer ongoing monitoring and model tuning services, allowing your LLM to evolve and improve over time. This ensures sustained performance improvements, adapting to your changing business environment.",
      ],
    },
    {
      title: "How FocusR’s Customized LLMs Can Benefit Your Business",
      details: [
        "Increase Operational Efficiency: Automate repetitive tasks, optimize workflows, and improve decision-making accuracy, reducing operational costs and boosting productivity.",
        "Unlock Hidden Insights:  Analyze vast amounts of unstructured data, uncovering trends, patterns, and insights that would be otherwise missed.",
        "Enhance Customer Experience:  Deliver personalized experiences and faster response times with AI-driven customer service solutions, chatbots, and virtual assistants.",
        "Enable Smarter Automation:  Leverage AI-powered automation in areas like data processing, inventory management, and predictive maintenance, allowing your team to focus on high-value tasks.",
        "Improve Decision-Making:  With access to real-time data and AI-driven forecasts, make smarter, more informed decisions that lead to better business outcomes.",
      ],
    },
    {
      title: "Ready to Build a Customized LLM Framework?",
      description:
        "FocusR is committed to driving innovation through personalized AI solutions. Our team of AI specialists and data scientists work hand-in-hand with your business to design, build, and deploy LLM frameworks that deliver tangible results. From accelerating growth to optimizing operations, our customized LLM frameworks are built to empower your business.",
    },
  ];
  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);

    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      {/* <Navbar mode="dark" /> */}
      {navBarMonitor ? (
          <div className="navbar2" style={{ backgroundColor: "#fff" }}>
            <button className="hamburger-cus" onClick={showDrawer}>
              <HiMenuAlt1 style={{ width: "3em", height: "3em" }} />
            </button>
            <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
          </div>
        ) : (
          <Navbar mode="dark" />
        )}
        <Drawer
          title={
            <div className="logo-small">
              <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
            </div>
          }
          placement="top"
          onClose={onClose}
          open={open}
          height="100%"
        >
          <button
            className="drawer-antd-btn"
            onClick={() => {
              navigate("/");
              onClose();
            }}
          >
            Home
          </button>
          <Collapse ghost items={items2} expandIconPosition="right" />
        </Drawer>
      {/* <ChatNew /> */}
      <div className="welcome-page-23-llm">
        <center>
        <div className='welcome-ai-page-2-header' style={{marginBottom: '3em'}}>
            <div className='welcome-ai-page-2-wrap' style={{width: isMobile ? "": isTablet ? '': "90%"}}>
              <div className='welcome-ai-page-2-title' onClick={() => navigate('/services/AI')} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <img src={FocusrAIArrow} width="auto" height={ isMobile? "30px" : "45px"}/>
                <img src={IntelligenceIcon} width={ isMobile? "100px" : "150px"} style={{marginLeft: '15px'}} />
              </div>
              <div className='welcome-ai-page-2-navlinks'>
              <div className='welcome-ai-page-2-menu' style={{height: 'auto', top: 'auto', display: 'block'}}>
                <span className='menu-item'>Gen AI<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/llmframework')} className="dropdown-item">LLM Framework</Link>
      <Link onClick={() => navigate('/finetuning')} className="dropdown-item">Fine-tuning</Link>
    </div>
  </span>
  <span className='menu-item'>ERP<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/seamlessintegration')} className="dropdown-item">Integration</Link>
      <Link onClick={() => navigate('/dataprotection')} className="dropdown-item">Data Protection</Link>
      <Link onClick={() => navigate('/unstructureddata')} className="dropdown-item">Data Processing</Link>
      <Link onClick={() => navigate('/cloudai')} className="dropdown-item">Cloud AI</Link>
      <Link onClick={() => navigate('/rpaeuc')} className="dropdown-item">RPA</Link>
    </div>
  </span>
  <span className='menu-item'>AI APP<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/products/HireCentral')} className="dropdown-item">Hireflow</Link>
    </div>
  </span>
  <span className='menu-item'>Analytics<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/powerbianalytics')} className="dropdown-item">Power BI</Link>
      <Link onClick={() => navigate('/oraclesapanalytics')} className="dropdown-item">Oracle and SAP</Link>
      <Link onClick={() => navigate('/googleanalytics')} className="dropdown-item">Google</Link>
    </div>
  </span>
                  {/* <span>Pricing</span>
                  <span>Solutions <MdKeyboardArrowDown/></span>
                  <span>Customers</span>
                  <span>Resources</span> */}
                </div>
                {/* <a href='#aiform' style={{display : isMobile? 'none': 'flex', backgroundColor: '#E42527', minWidth: '100px',marginLeft: '10px', justifyContent: 'center', borderRadius: '30px', padding: '7px 20px', color: 'white'}}>
                  Get Started
                </a> */}
              </div>
            </div>
          </div>
          <h1
            style={{
              color: "#0067FF",
              textAlign: "center",
              width: "100%",
              fontSize: '46px',
              fontWeight: '800',
              lineHeight: '52px',
              marginBottom: '0.5em'
            }}
          >
            Customized LLM Framework Design
          </h1>
          <p style={{ fontSize: "18px", letterSpacing: '1px' }}>
            At FocusR, we specialize in designing customized Language Learning Models (LLMs) tailored to your unique business needs. Our bespoke custom AI frameworks are crafted to align with your goals, ensuring maximum efficiency, scalability, and accuracy. With a focus on deep industry expertise and seamless integration, we deliver AI solutions that drive innovation and transform your business operations with adaptor to integrate with llms and vector stores.{" "}
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/contact");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      {/* <div className="llmframework-feature1">
        <div className="anim-init" style={{ color: '#0067ff', textAlign: 'center', fontSize: '60px' }} data-aos="fade-right"
        data-aos-delay="200">
          LLMS we work with
        </div>
        <div style={{ display: 'flex', paddingTop: '35px', height: 'fit-content', justifyContent: 'space-evenly', width: '100%' }} >
          <div className="framework-icon-wrapper" data-aos="fade-right"
        data-aos-delay="300">
            <span className="framework-icons anim-init">
                <img width={150} src={GeminiIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={200} src={OpenAIIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={180} src={ClaudeIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={80} style={{ paddingTop: '2em' }} src={LlamaIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={180} style={{ paddingTop: '2.5em' }} src={HuggingIcon} />
            </span>
          </div>
        </div>
      </div> */}
      <div className="llmframework-feature1">
      <div className="anim-init" style={{ color: '#0067ff', textAlign: 'center', fontSize: isMobile? '35px': '60px' }}>
          LLMS we work with
        </div>
      <div className="new-logo-container" data-aos="fade-right"
        data-aos-delay="200">
          {arrayOfImages.map((x, i) => {
            return <img src={x.imageUrl} alt={x.alt} />;
          })}
        </div>
        </div>
        <div className="feature1-main" style={{maxWidth: '1140px', margin: '130px auto 0', position: 'relative'}}>
          <div className="feature1-dots"></div>
          <div style={{background: 'white'}}>
      <div style={{ color: '#0067ff', textAlign: 'center', fontSize:isMobile? '30px': '60px', marginBottom: '1em' }}>
        Other LLMs that can be integrated
      </div>
      <div className="llmframework-feature2">
        <div className="feature2-table">
          <ul className="summary_lst">
            <li style={{ minHeight: '60px' }}>
              <span>AI21 Jur</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Aleph Alpha Luminous</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Baichuan LLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>ChatGLM-6B</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>GigaChat</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>GPT4All</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>IBM Watsonx.ai</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>KoboldAI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Moonshot LLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>MosaicML</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>NLP Cloud</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>OctoAI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>OpenLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Petals</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>SambaNova Sambaverse</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>SparkLLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Symbl.ai Nebula</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Together AI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Tongyi Qwen</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>vLLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Volc Engine Maas</span>
            </li>
          </ul>
        </div>
      </div>
      </div>
      </div>
      <div className="features-vectorbg">
        <div style={{ color: '#0067ff', textAlign: 'center', fontSize: '60px', marginBottom: '1em', marginTop: '1.5em' }}>
          <span style={{position: 'relative', top: '30px', fontSize: isMobile ? '26px' : ''}}>Vector stores we can work with</span>
        </div>
        <div className="llmframework-feature2" style={{borderRadius: '30px'}}>
          <div className="feature2-table" >
            <ul className="summary_lst">
              <li style={{ minHeight: '60px' }}><span>Activeloop Deep Lake</span></li>
              <li style={{ minHeight: '60px' }}><span>Aerospike Vector Search (AVS)</span></li>
              <li style={{ minHeight: '60px' }}><span>Alibaba Cloud OpenSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>ApertureDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Astra DB Vector Store</span></li>
              <li style={{ minHeight: '60px' }}><span>Atlas</span></li>
              <li style={{ minHeight: '60px' }}><span>AwaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Azure AI Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Baidu Cloud ElasticSearch VectorSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Baidu VectorDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Chroma</span></li>
              <li style={{ minHeight: '60px' }}><span>Clarifai</span></li>
              <li style={{ minHeight: '60px' }}><span>DashVector</span></li>
              <li style={{ minHeight: '60px' }}><span>Databricks Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>DingoDB</span></li>
              <li style={{ minHeight: '60px' }}><span>DocArray HnswSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>DocArray InMemorySearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Elasticsearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Epsilla</span></li>
              <li style={{ minHeight: '60px' }}><span>Faiss</span></li>
              <li style={{ minHeight: '60px' }}><span>Google BigQuery Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Google Vertex AI Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Hippo</span></li>
              <li style={{ minHeight: '60px' }}><span>Jaguar Vector Database</span></li>
              <li style={{ minHeight: '60px' }}><span>KDB.AI</span></li>
              <li style={{ minHeight: '60px' }}><span>Kinetica</span></li>
              <li style={{ minHeight: '60px' }}><span>LanceDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Lantern</span></li>
              <li style={{ minHeight: '60px' }}><span>ManticoreSearch VectorStore</span></li>
              <li style={{ minHeight: '60px' }}><span>Milvus</span></li>
              <li style={{ minHeight: '60px' }}><span>Momento Vector Index (MVI)</span></li>
              <li style={{ minHeight: '60px' }}><span>MongoDB Atlas Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Neo4j Vector Index</span></li>
              <li style={{ minHeight: '60px' }}><span>NucliaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>OpenSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Oracle AI Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>PGVector</span></li>
              <li style={{ minHeight: '60px' }}><span>Pinecone</span></li>
              <li style={{ minHeight: '60px' }}><span>Qdrant</span></li>
              <li style={{ minHeight: '60px' }}><span>Redis</span></li>
              <li style={{ minHeight: '60px' }}><span>SAP HANA Cloud Vector Engine</span></li>
              <li style={{ minHeight: '60px' }}><span>ScaNN</span></li>
              <li style={{ minHeight: '60px' }}><span>SemaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>SingleStoreDB</span></li>
              <li style={{ minHeight: '60px' }}><span>SQLite-VSS</span></li>
              <li style={{ minHeight: '60px' }}><span>SurrealDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Tencent Cloud VectorDB</span></li>
              <li style={{ minHeight: '60px' }}><span>ThirdAI NeuralDB</span></li>
              <li style={{ minHeight: '60px' }}><span>TiDB Vector</span></li>
              <li style={{ minHeight: '60px' }}><span>Timescale Vector (Postgres)</span></li>
              <li style={{ minHeight: '60px' }}><span>Upstash Vector</span></li>
              <li style={{ minHeight: '60px' }}><span>USearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Vald</span></li>
              <li style={{ minHeight: '60px' }}><span>Vearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Vectara</span></li>
              <li style={{ minHeight: '60px' }}><span>Vespa</span></li>
              <li style={{ minHeight: '60px' }}><span>viking DB</span></li>
              <li style={{ minHeight: '60px' }}><span>vlite</span></li>
              <li style={{ minHeight: '60px' }}><span>Weaviate</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef} style={{ marginTop: '5em' }}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${activeIndex === index ? "active" : ""
                      }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-ai-services">
        <h1 style={{fontSize: '25px', width: '80%'}}>Get in touch with us today to start your journey toward a more intelligent future with FocusR’s Customized LLM Framework Design.</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/contact");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default LLMFramework;
