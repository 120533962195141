import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
const CardCount = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      style={{
        marginTop: '2em'
      }}
    >
      <div className="corevalue2">
        <h1 data-aos="fade-right" data-aos-delay="500">
          120+ <br /> <p className="small-txt">Happy Clients</p>
        </h1>
        <h1 data-aos="fade-right" data-aos-delay="550">
          110+ <br /> <p className="small-txt">Finished Projects</p>
        </h1>
        <h1 data-aos="fade-right" data-aos-delay="600">
          185+ <br /> <p className="small-txt"> Ideas Realized</p>
        </h1>
        <h1 data-aos="fade-right" data-aos-delay="650">
          200+ <br /> <p className="small-txt">Work Force</p>
        </h1>
      </div>
    </div>
  );
};

export default CardCount;
