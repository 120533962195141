import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import { Col, FloatButton, Modal, Row, Tabs } from 'antd';
import { RxVideo } from 'react-icons/rx';
import { BiVideo, BiSolidBot, BiPlay } from 'react-icons/bi';
import ReactPlayer from 'react-player';
// import { MdOutlineSettings } from "react-icons/hi";
import BreadCrum from '../../Components/BreadCrum';
import GraphQlArchitecture from '../../assets/Query-Architecture.svg';
import RestService from '../../assets/Rest-service.svg';
import soap from '../../assets/soap.svg';
import { BsShieldFillCheck } from 'react-icons/bs';
import ChatNew from '../../Components/Chat2';
import CallToAction from '../../Components/CallToAction';
import ProductDev from './Components/ProductDev';

const Api = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.title = 'Services | Product Development';
  });

  return (
    <div>
      {/* navbar here */}
      <Navbar mode="dark" />
      <ChatNew />
      {/* First Page starts */}
      <ProductDev/>
      <br/>
      <br/>
      <br/>
      <CallToAction
        title="Innovate and Grow with Product Development"
        description="Transform your ideas into exceptional products with our comprehensive product development services. Our team of skilled professionals follows a structured approach, from ideation and prototyping to testing and market launch. We prioritize innovation, quality, and speed to market, ensuring that your products not only meet but exceed customer expectations. Whether you're a startup or an established enterprise, our product development services are designed to catalyze your growth and keep you ahead in a competitive market."
        buttonTitle="Start Product Development"
      />
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default Api;
