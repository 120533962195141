/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
import ChatNew from "../../Components/Chat2";
import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const Consulting = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Comprehensive Oracle EBS and Fusion Applications Management",
      description:
        "At FocusR Technologies, we provide end-to-end management of Oracle E-Business Suite (EBS) and Oracle Fusion Applications to ensure peak performance and alignment with your business goals. Our comprehensive services cover the entire application lifecycle",
      details: [
        "Lifecycle Management: We manage Oracle EBS and Fusion applications from initial deployment to decommissioning. Our team handles planning, installation, custom configurations, instance cloning, patching, and retirement of instances.",
        "Application Administration: Skilled DBAs and application administrators manage key tasks such as database backup and recovery, patch management (including CPU and PSU patches), and cloning environments for different purposes like dev/test.",
        "Functional Support: Our experts provide in-depth functional support across Oracle modules such as Financials (General Ledger, Payables, Receivables), SCM (Inventory, Order Management), and HCM (Core HR, Payroll, Talent Management). We ensure that processes are optimized and tailored to your business requirements.",
        "Customization and Enhancements: FocusR develops custom forms, reports, workflows, and extensions (using tools like Oracle JDeveloper and ADF) to tailor Oracle EBS and Fusion Applications to meet unique business needs.",
        "User Management: We implement robust user management systems using Oracle IAM and configure roles, responsibilities, and access controls while ensuring compliance with organizational security policies and SOX regulations.",
      ],
    },
    {
      title: "Proactive Monitoring, Performance Tuning, and Optimization",
      description:
        "Ensuring optimal performance and scaling of Oracle systems is vital for maintaining operational efficiency. Our highly technical approach includes:",
      details: [
        "Real-time Monitoring: Utilizing Oracle Enterprise Manager (OEM) and OCI Monitoring to continuously observe key performance metrics like CPU usage, IO throughput, and memory consumption. We configure monitoring for Oracle RAC, Dataguard and implement alerting for any deviations from norms.",
        "Performance Analysis: Conducting AWR (Automatic Workload Repository) and ASH (Active Session History) reports analysis for EBS and Fusion environments to pinpoint bottlenecks; tuning SQL queries and PL/SQL code for improved execution times.",
        "Capacity Planning: Leveraging historical data and trend analysis to provide detailed capacity planning, including advice on storage requirements, data archiving strategies, and hardware sizing.",
        "Load Balancing and High Availability: Implementing Oracle RAC for database high availability, load balancing application servers using Oracle Traffic Director, and configuring Oracle Data Guard for disaster recovery.",
        "Proactive Maintenance: Scheduling routine maintenance activities like index reorganization, tablespace analysis, and storage optimization. Implementing Oracle's Automatic Storage Management (ASM) to enhance database performance and storage management.",
      ],
    },
    {
      title: "Security Management and Compliance",
      description:
        "Protecting the security and compliance of Oracle environments is crucial. Our specialized methods include",
      details: [
        "Security Audits and Assessments: Regularly conducting security posture assessments and vulnerability scans using tools like Oracle Configuration Manager and Oracle Audit Vault.",
        "Data Encryption and Masking: Deploying Transparent Data Encryption (TDE) for database-level security and using Oracle Data Masking and Subsetting solutions to protect sensitive data during non-production activities.",
        "Access Control and Identity Management: Setting up and managing Oracle Identity and Access Management (IAM), including Oracle Access Manager (OAM) for Single Sign-On (SSO) and Oracle Identity Manager (OIM) for user provisioning.",
        "Compliance Management: Ensuring compliance with GDPR, HIPAA, and SOX by implementing Oracle’s Governance, Risk, and Compliance (GRC) suite; providing comprehensive audit trails and activity monitoring.",
        "Incident Response and Mitigation: Developing incident response protocols and implementing Oracle’s Real Application Security (RAS) to quickly identify and mitigate threats, supported by regular penetration testing.",
      ],
    },
    {
      title: "Advanced Troubleshooting and Incident Management",
      description:
        "Our highly technical troubleshooting and incident management process ensures quick diagnosis and resolution",
      details: [
        "Issue Tracking and Management: Utilizing Oracle Cloud Infrastructure (OCI) logging, Splunk, and OEM for incident tracking, escalation management, and KPI monitoring.",
        "Root Cause Analysis: Executing detailed root cause analysis involving extensive log reviews, STATSPACK reports, and SQL tracing to uncover and resolve the root issues of system disruptions.",
        "24/7 Support: Providing round-the-clock support through a dedicated NOC (Network Operations Center) with L1, L2, and L3 support tiers using ITIL-based frameworks.",
        "Knowledge Base and Documentation: Maintaining an extensive knowledge base and SOPs, documenting common issues, known bugs with Oracle EBS and Fusion, and their resolutions.",
        "Automated Alerting and Notifications: Configuring automated alert systems using Oracle Cloud Monitoring and Oracle Management Cloud to notify stakeholders of critical incidents and provide real-time operational insights.",
      ],
    },
    {
      title: "Strategic Planning and Advisory Services",
      description:
        "Beyond operational management, we offer strategic planning and advisory services tailored for long-term success",
      details: [
        "IT Roadmap Development: Crafting detailed IT roadmaps integrating Oracle EBS and Fusion Applications with your business strategies, utilizing tools like Oracle Primavera and Oracle Enterprise Architecture.",
        "Technology Adoption and Best Practices: Advising on the adoption of cutting-edge technologies like Oracle Autonomous Database, AI, ML, and Blockchain, and applying Oracle’s best practices for system architecture and development.",
        "Cost Management and Optimization: Conducting TCO analyses and providing iterative reviews to help you optimize your IT spend. Advising on cost-effective licensing options, and optimizing your Oracle Cloud resources.",
        "Change Management: Developing comprehensive change management policies and procedures, ensuring smooth upgrades to Oracle Fusion from Oracle EBS, and facilitating user adoption through specialized training programs.",
        "Continuous Improvement: Implementing continuous improvement methodologies such as ITIL, Kaizen, and Six Sigma to ensure that Oracle environments remain agile and responsive to evolving business and technological needs.",
      ],
    },
    {
      title: "Disaster Recovery and Business Continuity",
      description:
        "Ensuring business continuity and robust disaster recovery mechanisms for Oracle environments is paramount for uninterrupted operations. At FocusR Technologies, we provide comprehensive outsourcing services to manage these critical aspects",
      details: [
        "Disaster Recovery Planning: Developing and implementing comprehensive disaster recovery plans tailored for Oracle EBS and Fusion applications. This includes defining RTO (Recovery Time Objective) and RPO (Recovery Point Objective) requirements.",
        "Data Replication and Backup: Implementing data replication strategies using Oracle Data Guard, Oracle GoldenGate, and RMAN (Recovery Manager) for real-time data synchronization and regular backups.",
        "Periodic Testing: Conducting regular disaster recovery drills and failover tests to ensure preparedness, validate the DR environment, and fine-tune recovery procedures.",
        "High Availability Architectures: Designing and managing high availability architectures, leveraging Oracle RAC and Oracle Cloud Infrastructure's DRaaS (Disaster Recovery as a Service).",
        "Business Continuity Solutions: Ensuring business continuity by developing strategies for maintaining critical operations, including setting up secondary data centers and cold, warm, or hot sites.",
      ],
    },
    {
      title: "Database Administration and Management",
      description:
        "Our outsourced database administration (DBA) services ensure your Oracle databases are optimized, secure, and highly available",
      details: [
        "Database Provisioning: Handling the provisioning of Oracle databases, including installation, configuration, and clustering (using Oracle RAC) for high availability environments.",
        "Performance Monitoring: Continuous monitoring of database performance using Oracle Enterprise Manager (OEM), enabling proactive identification and resolution of issues.",
        "Backup and Recovery: Managing regular database backups using Oracle RMAN, and ensuring swift and reliable recovery procedures are in place to minimize data loss.",
        "Security Administration: Implementing and managing database security measures, including user authentication, role-based access control, and data encryption.",
        "Health Checks and Audits: Conducting periodic health checks and audits to ensure database integrity, performance, and compliance with best practices and regulatory requirements.",
      ],
    },
    {
      title: "Patch Management and Updates",
      description:
        "Keeping Oracle environments up-to-date with the latest patches and updates is crucial for security and functionality. FocusR Technologies offers comprehensive patch management services",
      details: [
        "Patch Analysis and Impact Assessment: Reviewing and analyzing Oracle Critical Patch Updates (CPUs), Patch Set Updates (PSUs), and security releases to assess their impact on your environment.",
        "Patch Deployment: Scheduling and deploying patches during maintenance windows to minimize business disruption. Utilizing Oracle’s OPatch utility for streamlined application.",
        "Testing and Validation: Implementing new patches in test environments first to validate and ensure compatibility with existing customizations and integrations.",
        "Documentation and Reporting: Maintaining comprehensive records of patch deployments, including documentation of changes and any observed impacts or improvements.",
        "Rollback Procedures: Developing and managing rollback procedures to ensure swift recovery to prior states in case of any issues with new patches.",
      ],
    },
    {
      title: "Regulatory Compliance and Audit Readiness",
      description:
        "FocusR Technologies assists in maintaining regulatory compliance and ensuring audit readiness for your Oracle environments",
      details: [
        "Compliance Frameworks: Implementing compliance frameworks aligned with key regulations such as GDPR, HIPAA, SOX, and PCI-DSS, using Oracle's Governance, Risk, and Compliance (GRC) tools.",
        "Audit Trails and Logging: Configuring and managing comprehensive audit trails and logging mechanisms using Oracle Audit Vault and Database Firewall to monitor and report on user activity.",
        "Compliance Audits: Preparing and assisting with compliance audits, providing necessary documentation, reports, and evidence to auditors.",
        "Security Controls Implementation: Deploying and managing security controls to protect sensitive data, including advanced encryption, access controls, and data masking techniques.",
        "Continuous Monitoring: Utilizing Oracle Management Cloud and SIEM (Security Information and Event Management) tools for continuous monitoring and alerting on potential compliance issues.",
      ],
    },
    {
      title: "Cost Optimization and Financial Management",
      description:
        "Optimizing costs and ensuring financial efficiency is a key component of our outsourcing services",
      details: [
        "Financial Audits: Conducting financial audits of Oracle EBS and Fusion environments to identify areas for cost savings and efficiency improvements.",
        "License Management: Managing Oracle licenses effectively to ensure compliance and avoid unnecessary costs, utilizing Oracle License Management Services (LMS) for audits and optimization.",
        "Cloud Cost Management: Optimizing Oracle Cloud Infrastructure (OCI) costs by leveraging OCI cost management tools to monitor usage, and allocate resources efficiently.",
        "Cost-Benefit Analysis: Performing detailed cost-benefit analyses for new projects, migrations, and upgrades to ensure financial viability and ROI (Return on Investment).",
        "Usage Optimization: Identifying and eliminating underutilized resources, automating scaling, and scheduling workloads to reduce overall operational costs.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      <ChatNew />
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "40px",
            }}
            // data-aos="fade-right"
            // data-aos-delay="200"
          >
            Outsourcing Services
          </h1>
          <p style={{ fontSize: "16px" }}>
            FocusR provides a full range of outsourcing services for Oracle
            E-Business Suite (EBS) and Oracle Fusion applications, ensuring
            optimal performance, security, and compliance. Our expert team
            handles everything from application administration and proactive
            monitoring to disaster recovery, custom development, cloud
            migration, and regulatory compliance. We enable your organization to
            focus on strategic goals while we take care of the technical
            complexities, ensuring cost efficiency and seamless operation of
            your Oracle environments.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to Know More
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Consulting;
