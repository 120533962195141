import React from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import DealerPortal from './Pages/Product/DealerPortal';
import AssesmentPortal from './Pages/Product/AssesmentPortal';
import BarcodeApplication from './Pages/Product/BarcodeApplication';
import DispatchPlanner from './Pages/Product/DispatchPlanner';
import EProcurement from './Pages/Product/EProcurement';
import HiringApplication from './Pages/Product/HiringApplication';
import SupplierPortal from './Pages/Product/SupplierPortal';
import TenderManagement from './Pages/Product/TenderManagement';
import TicketManagement from './Pages/Product/TicketManagement';
import TimesheetApplication from './Pages/Product/TimesheetApplication';
import ERP from './Pages/Services/ERP';
import Api from './Pages/Services/Api';
import Outsourcing from './Pages/Services/Outsourcing';
import Customercentric from './Pages/Services/Customercentric';
import DataQualityMdm from './Pages/Solutions/DataQualityMdm';
import Ecommerce from './Pages/Solutions/Ecommerce';
import ITmanagedService from './Pages/Solutions/ITmanagedService';
import ApplicationMigration from './Pages/Solutions/ApplicationMigration';
import Oracle from './Pages/Platforms/Oracle';
import ProductDevelopment from './Pages/Platforms/ProductDevelopment';
import Cloud from './Pages/Platforms/Cloud';
import SAP from './Pages/Platforms/SAP';
import Company from './Pages/Company';
import AI from './Pages/Solutions/AI';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import ScrollToTop from './ScrollToTop';
import OverallVerticals from './Pages/Verticals/OverallVerticals';
import ContactUs from './Pages/ContactUs';
import Terms from './Pages/Terms'

import Primavera from "./Pages/oracleservices/Primavera";
import SupportandMaintentance from "./Pages/oracleservices/SupportandMaintenance";
import AutomationSolution from "./Pages/oracleservices/AutomationSolution";
import BiwithFusion from "./Pages/oracleservices/BiwithFusion";
import CloudMigration from "./Pages/oracleservices/CloudMigration";
import Consulting from "./Pages/oracleservices/Consulting";
import CustomDevelopment from "./Pages/oracleservices/CustomDevelopment";
import CustomRice from "./Pages/oracleservices/CustomRice";
import EBStoFusion from "./Pages/oracleservices/EBStoFusion";
import GreenField from "./Pages/oracleservices/GreenField";
import GstVat from "./Pages/oracleservices/GstVat";
import ExtendedOracle from "./Pages/oracleservices/ExtendedOracle"
import OracleService from "./Pages/oracleservices/OracleService";
import Migration from "./Pages/oracleservices/Migration";
import EnterpriseIntegration from "./Pages/oracleservices/EnterpriseIntegration";
import Training from "./Pages/oracleservices/Training";
import OracleSupport from "./Pages/oracleservices/OracleSupport";
import Security from "./Pages/oracleservices/Security";
import FusionBI from "./Pages/oracleservices/FusionandBi";
import Implement from "./Pages/oracleservices/Implement";
import Optimization from "./Pages/oracleservices/Optimization";
import ServiceOutsourcing from "./Pages/oracleservices/ServiceOutsourcing";
import OnPremise from './Pages/oracleservices/OnPremise';
import Blog from "./Pages/Blog";
import BlogContent from "./Pages/BlogContent";
import AuthorPage from "./Pages/AuthorPage";
import EprocSales from "./Pages/EprocSales"
import CareerJobs from './Pages/CareerJobs'
import LLMFramework from './Pages/aiservices/llmframework'
import FineTuning from './Pages/aiservices/finetuning';
import SeamlessIntegration from './Pages/aiservices/seamlessIntegration';
import DataProtection from './Pages/aiservices/dataprotection';
import UnstructuredData from './Pages/aiservices/unstructureddata';
import CloudAI from './Pages/aiservices/cloudai';
import RPAEUC from './Pages/aiservices/RPAEUC';
import PowerBI from './Pages/analytics/PowerBI';
import OracleSAP from './Pages/analytics/OracleSAP';
import GoogleGCP from './Pages/analytics/GoogleGCP';

function App() {
  return (
    <BrowserRouter scrollRestoration="manual" basename="">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/SaleSyncX" element={<DealerPortal />} />
        <Route path="/products/Quizlink" element={<AssesmentPortal />} />
        <Route path="/products/ScanlogiX" element={<BarcodeApplication />} />
        <Route path="/products/Planify" element={<DispatchPlanner />} />
        <Route path="/products/E-Proc" element={<EProcurement />} />
        <Route path="/products/HireCentral" element={<HiringApplication />} />
        <Route path="/products/SupplySyncX" element={<SupplierPortal />} />
        <Route path="/products/BidConnectHub" element={<TenderManagement />} />
        <Route path="/products/Techzard" element={<TicketManagement />} />
        <Route path="/products/TechstepHub" element={<TimesheetApplication />} />
        <Route path="/services/ERP-Service" element={<ERP />} />
        <Route path="/services/Product-Development" element={<Api />} />
        <Route path="/services/Out-Sourcing" element={<Outsourcing />} />
        <Route path="/services/AI" element={<AI />} />
        <Route path="/llmframework" element={<LLMFramework/>} />
        <Route path='/finetuning' element={<FineTuning/>} />
        <Route path='/seamlessintegration' element={<SeamlessIntegration/>}/>
        <Route path='/dataprotection' element={<DataProtection/>} />
        <Route path='/unstructureddata' element={<UnstructuredData/>} /> 
        <Route path='/cloudai' element={<CloudAI/>} />
        <Route path='/rpaeuc' element={<RPAEUC/>} />
        <Route path='/powerbianalytics' element={<PowerBI/>} />
        <Route path='/oraclesapanalytics' element={<OracleSAP/>} />
        <Route path='/googleanalytics' element={<GoogleGCP/>} />
        <Route path="/solutions/Dataquality and MDM" element={<DataQualityMdm />} />
        <Route path="/solutions/ECommerce(B2B,B2C)" element={<Ecommerce />} />
        <Route path="/solutions/IT-Managed Services" element={<ITmanagedService />} />
        <Route path="/solutions/Application Migration" element={<ApplicationMigration />} />
        <Route path="/platforms/Oracle" element={<Oracle />} />
        <Route path="/platforms/SAP" element={<SAP />} />
        <Route path="/platforms/Cloud" element={<Cloud />} />
        <Route path="/platforms/Product Development" element={<ProductDevelopment />} />
        <Route path="/verticals" element={<OverallVerticals />} />
        <Route path="/careers" element={<Company />} />
        <Route path='/careers/:id' element={<CareerJobs/>} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        {/* <Route path='*' element="../public/index.html"/> */}
        <Route path="/terms" element={<Terms />} />

        <Route path="/oraclePrimavera" element={<Primavera />} />
        <Route path="/automationSolutions" element={<AutomationSolution />} />
        <Route path="/ebsFusion" element={<EBStoFusion />} />
        <Route path="/greenField" element={<GreenField />} />
        <Route path="/gstvat" element={<GstVat />} />
        <Route
          path="/supportandmaintenance"
          element={<SupportandMaintentance />}
        />
        <Route
          path="/oracleCustomDevelopment"
          element={<CustomDevelopment />}
        />
        <Route
          path="/enterpriseIntegration"
          element={<EnterpriseIntegration />}
        />
        <Route path="/oracleTraining" element={<Training />} />
        <Route path="/oraclesupport" element={<OracleSupport />} />
        <Route path="/oracleSecurity" element={<Security />} />
        <Route path="/serviceOutsourcing" element={<ServiceOutsourcing />} />
        <Route path="/oracleservice" element={<OracleService />} />
        <Route path="/optimization" element={<Optimization />} />
        <Route path="/onPremise" element={<OnPremise />} />
        <Route path="/implement" element={<Implement />} />
        <Route path="/extendedOracle" element={<ExtendedOracle />} />
        <Route path="/fusionBi" element={<FusionBI />} />
        <Route path="/customRice" element={<CustomRice />} />
        <Route path="/cloudmigration" element={<CloudMigration />} />
        <Route path="/bifusion" element={<BiwithFusion />} />
        <Route path="/oracleConsulting" element={<Consulting />} />
        <Route path="/migration" element={<Migration />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/focusrtech/eproc" element={<EprocSales/>} />
        <Route path="/blogContent/:id" element={<BlogContent />} />
        <Route path='/blog/author/:authorName' element={<AuthorPage/>}></Route>
        <Route path="*" element={<Navigate to="../public/index.html" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
