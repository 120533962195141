import { useEffect, useRef, useState } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Sticky from "react-stickynode";
import './OracleServices.css'

const GstVat = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Regulatory Compliance and Configuration",
      description:
        "Ensuring rigorous compliance with national and international tax regulations within Oracle environments requires precise configuration and continuous updates to align with legal changes.",
      headers: [
        {
          header: "Technical Aspects",
          subheaders: [
            {
              subheader: "EBS Setup",
              details: [
                "Define Tax Regimes: Use the Oracle E-Business Suite (EBS) Tax Manager to establish tax regimes, including the creation of tax authorities, jurisdictions, and tax types.",
                "Configure Tax Rules and Rates: Design rule-based tax determination using Oracle EBTax’s advanced features such as Prioritization and Applicability Rules. Create tax rates for different goods and services and integrate them within modules like Accounts Payable (AP) and Accounts Receivable (AR).",
                "Automated Endowments and Waivers: Develop procedures for automated tax exemptions and waivers using conditions and event triggers in EBTax.",
              ],
            },
            {
              subheader: "Fusion Configuration",
              details: [
                "Oracle Tax Cloud Setup: Utilize Oracle Tax Cloud to set up tax regimes, jurisdictions, tax rules, and exceptions. Configure opt-in or opt-out scenarios for optional tax treatments.",
                "Tax Regulation Engines: Leverage the in-built tax engines within Oracle Fusion Cloud Financials, integrating them to transactional modules like Oracle Procurement, Accounts Payable, Accounts Receivable, and Order Management to ensure transactions are tax-compliant upon creation.",
                "GDPR & Local Compliance: Ensure compliance with General Data Protection Regulation (GDPR) and other local regulations by configuring data privacy and protection settings within the Oracle environment.",
              ],
            },
            {
              subheader: "Cross-Application Validation",
              details: [
                "Configuration Validation Toolkit: Use Oracle's Configuration Validation Toolkit (CVT) to validate tax settings between EBS and Fusion, ensuring alignment of tax rules, rates, and policies.",
                "Automated Reconciliation: Develop reconciliation scripts and automated processes to cross-validate tax data entries between Oracle EBS and Fusion systems regularly.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Automated Tax Calculations",
      description:
        "Automated tax calculations in Oracle applications encompass the deployment of configurable tax engines ensuring real-time accuracy and compliance.",
      headers: [
        {
          header: "Technical Aspects",
          subheaders: [
            {
              subheader: "Rule-Based Calculations in EBS",
              details: [
                "Tax Code Definition: Define multiple tax codes within Oracle EBTax tailored to different tax authorities and compliance requirements.",
                "Integration with Subledgers: Integrate tax rules directly with Oracle subledgers such as AP, AR, and Order Management to ensure that each transaction automatically reflects accurate tax calculations.",
                "User-Defined Tax Rules: Implement custom PL/SQL scripts and user-defined tax rules to handle complex tax scenarios involving multiple jurisdictions and special conditions.",
              ],
            },
            {
              subheader: "Integration with Fusion Tax",
              details: [
                "Tax Configuration in Fusion: Utilize Oracle Fusion’s extensible tax framework to configure tax determinants, including party-specific, product-specific, and geography-specific tax determination rules.",
                "Transaction-Based Tax Calculation: Enable dynamic transaction-based tax calculation using Oracle Fusion Tax engine features such as Direct-Connect Tax Calculation for continuous transaction evaluation.",
                "Scalable Tax Processing: Ensure scalability in tax calculation processes by using Fusion’s parallel processing capabilities to handle large volumes of concurrent transactions.",
              ],
            },
            {
              subheader: "API Integrations",
              details: [
                "Develop Tax Calculation APIs: Create REST and SOAP APIs to enable real-time tax determination and calculation for transactions originating from external systems integrated with Oracle EBS or Oracle Fusion.",
                "Trigger-Based Workflows: Use Oracle BPM (Business Process Management) to create trigger-based tax workflows, ensuring that any tax classification changes are automatically reflected and recalculated in all affected transactions.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Advanced Reporting and Analytics",
      description:
        "Implementing sophisticated reporting and analytics tools within Oracle environments empowers organizations with actionable insights and ensures complete visibility into tax obligations.",
      headers: [
        {
          header: "Technical Aspects",
          subheaders: [
            {
              subheader: "BI Reports in EBS",
              details: [
                "Custom Report Builder: Leverage Oracle BI Publisher to design and deploy customized tax reports. Use XML Publisher to format complex reports that detail tax transactions at a granular level.",
                "Ad-Hoc Query Capabilities: Utilize Oracle Discoverer's ad-hoc querying capabilities to drill down into specific financial transactions, ensuring detailed analysis of tax data for compliance auditing.",
                "Subledger Transaction Analysis: Implement detailed subledger transaction analysis reports capturing AP, AR, and General Ledger entries to provide a comprehensive view of tax liabilities and credits.",
              ],
            },
            {
              subheader: "Fusion Analytics Warehouse",
              details: [
                "Data Mart Design: Design tax-specific data marts within Oracle Fusion Analytics Warehouse (FAW) to store aggregated tax data for performance-efficient querying and reporting.",
                "Interactive Dashboards: Develop interactive dashboards using Oracle Analytics Cloud to monitor key tax metrics such as GST/VAT collections, input tax credit reconciliation, and tax return filings.",
                "Predictive Analytics: Employ machine learning algorithms within Oracle Fusion Analytics to predict tax trends and potential compliance issues based on historical transaction data and current financial activity.",
              ],
            },
            {
              subheader: "Data Warehouse Integration",
              details: [
                "ETL Processes: Use Oracle Data Integrator (ODI) to construct extract, transform, and load (ETL) processes for consolidating tax data from various Oracle modules, ensuring data consistency and reliability.",
                "Unified Reporting Platform: Develop a unified reporting platform by integrating Oracle EBS and Fusion tax data into a single Oracle Data Warehouse, enabling cross-environment analytics and comprehensive compliance reports.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Tax Data Migration and Synchronization",
      description:
        "Seamlessly migrating and synchronizing tax data between Oracle EBS and Oracle Fusion environments involves intricate procedures to ensure data integrity and continuity.",
      headers: [
        {
          header: "Technical Aspects",
          subheaders: [
            {
              subheader: "Data Migration Strategy",
              details: [
                "Pre-Migration Assessment: Conduct a thorough pre-migration assessment using Oracle’s Diagnostic and Migration Workbench to identify all tax-related data elements that need to be transferred.",
                "Data Mapping: Create detailed data mapping schemas to translate tax data structures between Oracle EBS and Oracle Fusion ensuring all elements such as tax codes, tax rates, and tax rules are accurately migrated.",
                "Data Cleansing: Implement data cleansing routines to eliminate redundant and obsolete tax records prior to migration, utilizing Oracle Data Quality tools.",
              ],
            },
            {
              subheader: "Data Synchronization",
              details: [
                "Real-Time Data Replication: Employ Oracle GoldenGate to set up real-time data replication processes to ensure that tax data changes in Oracle EBS are immediately reflected in Oracle Fusion Cloud environments.",
                "Bidirectional Synchronization: Develop bidirectional data synchronization mechanisms to manage tax data consistency in hybrid environments where transactions may occur in both Oracle EBS and Fusion.",
                "Auditing and Logging: Integrate audit trails and logging mechanisms to track every data synchronization event, ensuring auditability and traceability of tax data movements.",
              ],
            },
            {
              subheader: "Validation Scripts",
              details: [
                "Validation Framework: Implement a robust validation framework using Oracle SQL and PL/SQL scripts to cross-verify the completeness and accuracy of migrated tax data.",
                "Compliance Testing: Conduct compliance testing using Oracle Application Testing Suite (OATS) to ensure that the migrated tax data conforms to regulatory and operational requirements.",
                "Parallel Runs: Perform parallel runs in non-production environments to compare outputs from EBS and Fusion tax systems, validating the integrity and reliability of the migration process.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Custom Development and Integrations",
      description:
        "Crafting bespoke development and integrations enhances flexibility and addresses unique business requirements within Oracle EBS and Fusion environments.",
      headers: [
        {
          header: "Technical Aspects",
          subheaders: [
            {
              subheader: "Custom Extensions in EBS",
              details: [
                "Custom Forms and Reports: Develop custom Oracle Forms and Reports specific to tax processing using Oracle Forms Developer and Oracle Reports Builder, tailored to your unique operational needs.",
                "PL/SQL Packages: Create PL/SQL packages and procedures to automate complex tax computations and integrations, ensuring high performance and accurate tax reporting.",
                "Personalized Workflows: Design personalized workflows using Oracle Workflow Builder to automate tax-related approvals, notifications, and escalations within Oracle EBS.",
              ],
            },
            {
              subheader: "Fusion Custom Components",
              details: [
                "Tailored Tax Object Functions: Design custom tax object functions within Oracle Fusion Applications to handle complex tax determination rules that are outside standard functionalities.",
                "Fast Formulas for Tax Calculations: Utilize Oracle Fast Formulas for handling advanced tax calculations and conditional tax rules, providing flexibility in tax computation across the Fusion suite.",
                "Oracle Visual Builder: Develop custom web and mobile applications that extend Oracle Fusion tax functionalities using Oracle Visual Builder Cloud Service (VBCS), enabling a seamless user experience.",
              ],
            },
            {
              subheader: "Third-Party Integrations",
              details: [
                "Oracle Integration Cloud (OIC): Leverage Oracle Integration Cloud to connect Oracle environments with third-party tax compliance and reporting services, engaging state-of-the-art connectors for streamlined data exchange.",
                "Web Service APIs: Develop web service APIs to integrate Oracle applications with enterprise systems such as ERP, CRM, and SCM, ensuring end-to-end visibility and control over tax-related transactions.",
                "Robotic Process Automation (RPA): Implement RPA solutions using Oracle Autonomous RPA Cloud to automate repetitive, rule-based tax tasks, significantly reducing manual intervention and increasing operational efficiency.",
              ],
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "40px",
            }}
          >
            GST / VAT Implementation
          </h1>
          <p style={{ fontSize: "158x" }}>
            At Focusr, we specialize in the seamless integration of Goods and
            Services Tax (GST) and Value-Added Tax (VAT) solutions within Oracle
            environments. Our expertise spans regulatory compliance, automated
            tax calculations, advanced reporting, data migration, and custom
            development. Leveraging Oracle E-Business Suite (EBS) and Oracle
            Fusion, we ensure precise tax management, optimized for compliance
            and operational efficiency.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.5em", paddingBottom: "1.6em" }}>
                {section.description}
              </p>
              {section.headers.map((header, headerIndex) => (
                <div key={headerIndex}>
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "1.5em",
                      paddingLeft: "1.5em", // Add padding to the bottom of the header
                    }}
                  >
                    {header.header}
                  </div>
                  {header.subheaders.map((subheader, subheaderIndex) => (
                    <div key={subheaderIndex}>
                      <div
                        style={{
                          fontWeight: "bold",
                          listStyle: "square",
                          paddingTop: "1.5em", // Space between header and subheader
                          paddingLeft: "4em",
                        }}
                      >
                        {subheader.subheader}
                      </div>
                      {Array.isArray(subheader.details) &&
                        subheader.details.length > 0 && (
                          <ul
                            style={{
                              listStyle: "square",
                              padding: "1.5em 0 0 8em", // Adjust padding for indentation
                            }}
                          >
                            {subheader.details.map((detail, detailIndex) => (
                              <li
                                key={detailIndex}
                                style={{ padding: "0.5em" }}
                              >
                                {detail.includes(":") ? (
                                  <span>
                                    <strong>{detail.split(":")[0]}</strong>:{" "}
                                    {detail.split(":")[1]}
                                  </span>
                                ) : (
                                  detail
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                    </div>
                  ))}
                  <p style={{ paddingLeft: "1.4em" }}>
                    By offering these in-depth and highly-technical GST/VAT
                    implementation services, Focusr ensures that your Oracle
                    E-Business Suite (EBS) and Oracle Fusion environments are
                    not only compliant but also operate at peak efficiency,
                    maximizing accuracy and reliability in all tax-related
                    operations.
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GstVat;
