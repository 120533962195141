import React, { useEffect, useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
const BreadCrum = (props) => {
  const [length, setlength] = useState();
  useEffect(() => {
    console.log('Length', props.items.length);
    setlength(props.items.length);
  }, [length]);

  return (
    <span
      style={{
        fontWeight: '600',
        color: props.color
      }}
    >
      {props.items.map((x, i) => {
        return (
          <>
            {x}&nbsp;&nbsp;
            <FiArrowRight
              style={{
                transform: 'translateY(4px)',
                marginRight: '1em'
              }}
            />
          </>
        );
      })}
    </span>
  );
};

export default BreadCrum;
