import React, { useEffect, useState } from "react";
import { FloatButton, Modal, Tabs } from "antd";
import { BiVideo, BiSolidBot, BiPlay } from "react-icons/bi";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "Focusr Technologies | Privacy Policy";
  });
  return (
    // Same className used for terms
    <div>
      <Navbar mode="dark" />
      <div className="Privacy-header">
        <h1 style={{ fontWeight: "normal", marginBottom: "1em" }}>
          Privacy Policy
        </h1>
        <hr />
      </div>
      <div className="Privacy-overall-container">
        <div className="Privacy-container-1" style={{ marginTop: "1em" }}>
          <p>
            This Privacy Policy governs the manner in which FocusR Consultancy
            and Technologies Private Limited collects, uses, maintains and
            discloses information collected from users (each, a "User") of the
            www.focusrtech.com website ("Site"). This privacy policy applies to
            the Site and all products and services offered by PetsGarten{" "}
          </p>
        </div>
        <div className="Privacy-container-2" style={{ marginTop: "1em" }}>
          <h3>Personal identification information </h3>

          <p>
            We may collect personal identification information from Users in a
            variety of ways, including, but not limited to, when Users visit our
            site, register on the site, place an order, subscribe to the
            newsletter, fill out a form, and in connection with other
            activities, services, features or resources we make available on our
            Site. Users may be asked for, as appropriate, name, email address,
            mailing address, phone number, credit card information, social
            security number. Users may, however, visit our Site anonymously. We
            will collect personal identification information from Users only if
            they voluntarily submit such information to us. Users can always
            refuse to supply personally identification information, except that
            it may prevent them from engaging in certain Site related
            activities.{" "}
          </p>
        </div>
        <div className="Privacy-container-3" style={{ marginTop: "1em" }}>
          <h3>Non-personal identification information </h3>
          <p>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer and
            technical information about Users means of connection to our Site,
            such as the operating system and the Internet service providers
            utilized and other similar information. .
          </p>
        </div>
        <div className="Privacy-container-4" style={{ marginTop: "1em" }}>
          <h3>Web browser cookies </h3>
          <p>
            Our Site may use "cookies" to enhance User experience. User's web
            browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. User may
            choose to set their web browser to refuse cookies, or to alert you
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly.{" "}
          </p>
        </div>
        <div className="Privacy-container-5" style={{ marginTop: "1em" }}>
          <h3>How we use collected information </h3>
          <p>
            FocusR Consultancy and Technologies Private Limited may collect and
            use Users personal information for the following purposes:
          </p>
          <li>To improve customer service </li>
          <li>
            Information you provide helps us respond to your customer service
            requests and support needs more efficiently.{" "}
          </li>
          <li>To personalize user experience </li>
          <li>
            We may use information in the aggregate to understand how our Users
            as a group use the services and resources provided on our Site.{" "}
          </li>
          <li>To process payments </li>
          <p>
            We may use the information Users provide about themselves when
            placing an order only to provide service to that order. We do not
            share this information with outside parties except to the extent
            necessary to provide the service. To send periodic emails
          </p>
        </div>
        <div className="Privacy-container-6" style={{ marginTop: "1em" }}>
          <h3>How we protect your information </h3>
          <p>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information, username, password, transaction information and data
            stored on our Site.
            <br />
            Sensitive and private data exchange between the Site and its Users
            happens over a SSL secured communication channel and is encrypted
            and protected.{" "}
          </p>
        </div>
        <div className="Privacy-container-7" style={{ marginTop: "1em" }}>
          <h3>Sharing your personal information </h3>
          <p>
            We do not sell, trade, or rent Users personal identification
            information to others. We may share generic aggregated demographic
            information not linked to any personal identification information
            regarding visitors and users with our business partners, trusted
            affiliates and advertisers for the purposes outlined above.{" "}
          </p>
        </div>
        <div
          className="Privacy-container-8"
          style={{ marginLeft: "-5px", marginTop: "1em" }}
        >
          <h3>Changes to this privacy policy </h3>
          <p>
            FocusR Consultancy and Technologies Private Limited has the
            discretion to update this privacy policy at any time. When we do, we
            will revise the updated date at the bottom of this page. We
            encourage Users to frequently check this page for any changes to
            stay informed about how we are helping to protect the personal
            information we collect. You acknowledge and agree that it is your
            responsibility to review this privacy policy periodically and become
            aware of modifications.{" "}
          </p>
        </div>
        <div
          className="Privacy-container-8"
          style={{ marginLeft: "-5px", marginTop: "1em" }}
        >
          <h3>Your acceptance of these terms </h3>
          <p>
            By using this Site, you signify your acceptance of this policy. If
            you do not agree to this policy, please do not use our Site. Your
            continued use of the Site following the posting of changes to this
            policy will be deemed your acceptance of those changes.{" "}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
