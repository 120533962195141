import {
  Button,
  Collapse,
  Drawer,
  Dropdown,
  Popover,
  Space,
  message,
  Tooltip,
} from "antd";
import React, { useEffect, useState, memo } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { DownOutlined } from "@ant-design/icons";
import { HiOutlineTicket } from "react-icons/hi";
import { FaExchangeAlt } from "react-icons/fa";
import { GrOracle, GrUserManager } from "react-icons/gr";
import {
  AiFillApi,
  AiFillBank,
  AiFillCloud,
  AiFillDatabase,
  AiFillAppstore,
  AiFillAlipaySquare,
} from "react-icons/ai";
import {
  MdOutlineWork,
  MdPublic,
  MdPrecisionManufacturing,
  MdPermMedia,
  MdOutlineAssessment,
  MdApproval,
  MdKeyboardArrowDown,
  MdOutlineMoreHoriz,
} from "react-icons/md";
import { RiCodeView, RiCustomerServiceFill } from "react-icons/ri";
import { GiHealthNormal, GiHeartPlus } from "react-icons/gi";
import { BiCode, BiSolidLandscape } from "react-icons/bi";
import { HiShoppingCart } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { BsFillLightningChargeFill, BsMailbox2, BsRobot } from "react-icons/bs";
import { SiSap } from "react-icons/si";
import {
  FaShuttleSpace,
  FaRobot,
  FaTruckFront,
  FaTruckRampBox,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import officiallogo from "../assets/FocurR_arrow 1.svg";
import { CgNotes } from "react-icons/cg";
import { IoMdBarcode } from "react-icons/io";
import { TbTruckDelivery } from "react-icons/tb";
import { IoPersonAddSharp } from "react-icons/io5";
import focusRlogo from "../assets/logo.png";
import focusRlogoRight from "../assets/FocusR_100px.png";
// import  ProductState  from '../Context/ProductLogic';
//import ProductLogic from '../Context/ProductLogic';
import { useProductState } from "../Context/ProductLogicProvider";
import { motion } from "framer-motion";
import SaleSynx from "../assets/homeSaleSynx.png";
import TechStep from "../assets/hometechstep.png";
import Planify from "../assets/homeplanify.png";
import Scanlogix from "../assets/homescan.png";
import Supply from "../assets/homesupply.png";
import Techzard from "../assets/hometechzard.png";
import Quiz from "../assets/homequiz.png";
import Bid from "../assets/homebid.png";
import Hireflow from "../assets/homehireflow.png";
import Eproc from "../assets/homeeproc.png";
import Solution from "../assets/homesolution.png";
import Product from "../assets/homeproduct.png";
import Resource from "../assets/homeresource.png";
import AI from "../assets/homeai.png";
import './Navbar.css'

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

const Navbar = (props) => {
  console.log("Props", props.mode);
  const [navbarStatus, setnavbarStatus] = useState(false);
  const [navBarMonitor, setnavBarMonitor] = useState(false);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [highlightBlog, setHighlightBlog] = useState(false);
  const [highlightHome, sethighlightHome] = useState(false);
  const [highlightAI, setHighlightAI] = useState(false);
  const [highlightProducts, sethighlightProducts] = useState(false);
  const [highlightsolutions, sethighlightsolutions] = useState(false);
  const [openNavProd, setopenNavProd] = useState(false);
  const navigate = useNavigate();
  const { isProductOpen, setIsProductOpen } = useProductState();
  const [isOpen, setIsOpen] = useState(false);

  const [showProductsModal, setShowProductsModal] = useState(false);
  const [highlightProducts2, setHighlightProducts2] = useState(false);
  const [highlightService, sethighlightService] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showPlatformModal, setShowPlatformModal] = useState(false);
  const [highlightPlatforms, sethighlightPlatforms] = useState(false);
  const [highlightVertical, sethighlightVertical] = useState(false);
  const [highlightCompany, setHighlightCompany] = useState(false);
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  const handleMouseEnterProducts = () => {
    setShowProductsModal(true);
    setHighlightProducts2(true);
    sethighlightService(false);
    setShowServiceModal(false);
    sethighlightPlatforms(false);
    setShowPlatformModal(false);
    sethighlightVertical(false);
  };

  const handleMouseLeaveProducts = () => {
    setShowProductsModal(false);
    setHighlightProducts2(false);
  };

  const handleMouseEnterServices = () => {
    setShowServiceModal(true);
    sethighlightService(true);
    setHighlightProducts2(false);
    setShowProductsModal(false);
    sethighlightPlatforms(false);
    setShowPlatformModal(false);
    sethighlightVertical(false);
  };

  const handleMouseLeaveServices = () => {
    setShowServiceModal(false);
    sethighlightService(false);
  };

  const handleMouseEnterPlatform = () => {
    setShowPlatformModal(true);
    sethighlightPlatforms(true);
    setHighlightProducts2(false);
    setShowProductsModal(false);
    sethighlightService(false);
    setShowServiceModal(false);
    sethighlightVertical(false);
  };

  const handleMouseEnterVertical = () => {
    setShowPlatformModal(false);
    sethighlightPlatforms(false);
    setHighlightProducts2(false);
    setShowProductsModal(false);
    sethighlightService(false);
    setShowServiceModal(false);
    sethighlightVertical(true);
  };

  const handleMouseLeaveVertical = () => {
    sethighlightVertical(false);
  };

  const handleMouseEnterCompany = () => {
    setShowPlatformModal(false);
    sethighlightPlatforms(false);
    setHighlightProducts2(false);
    setShowProductsModal(false);
    sethighlightService(false);
    setShowServiceModal(false);
    setHighlightCompany(true);
  };

  const handleMouseLeaveCompany = () => {
    setHighlightCompany(false);
  };

  const handleMouseEnterBlog = () => {
    setShowPlatformModal(false);
    sethighlightPlatforms(false);
    setHighlightProducts2(false);
    setShowProductsModal(false);
    sethighlightService(false);
    setShowServiceModal(false);
    setHighlightBlog(true);
  };

  const handleMouseLeaveBlog = () => {
    setHighlightBlog(false);
  };

  const handleMouseEnterAI = () => {
    setShowPlatformModal(false);
    sethighlightPlatforms(false);
    setHighlightProducts2(false);
    setShowProductsModal(false);
    sethighlightService(false);
    setShowServiceModal(false);
    setHighlightAI(true);
  };

  const handleMouseLeaveAI = () => {
    setHighlightAI(false);
  };

  const handleMouseLeavePlatform = () => {
    setShowPlatformModal(false);
    sethighlightPlatforms(false);
  };

  const handleMouseEnterModal = () => {
    if (highlightProducts2) {
      setShowProductsModal(true);
    }
    if (highlightService) {
      setShowServiceModal(true);
    }
    if (highlightPlatforms) {
      setShowPlatformModal(true);
    }
  };

  const handleMouseLeaveModal = () => {
    setShowProductsModal(false);
    setShowServiceModal(false);
    setShowPlatformModal(false);
    setHighlightProducts2(false);
    sethighlightService(false);
    sethighlightPlatforms(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const headLocalstyle = {
    fontWeight: "600",
    fontSize: "18px",
  };


  const contentEmployee = (
    <div className="service-inner-menu">
      <div className="inner-menu-item">
        <div className="inner-menu-item-top">
          <AiFillAlipaySquare
            className="inner-menu-icon"
            style={{
              position: "relative",
              top: "0.3em",
              fontSize: "2em",
            }}
          />
          &nbsp;
          <a
            style={{
              fontWeight: "600",
              color: "blue",
            }}
            href="https://mypayroll.paysquare.com/"
            target="_blank"
          >
            Paysquare
          </a>
        </div>
        <div className="inner-menu-item-bottom">
          <p>Paysquare Login</p>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (e.isTrusted && window.scrollY > 0) {
        setnavbarStatus(true);
        setHover(true);
      } else {
        setnavbarStatus(false);
        setHover(false);
      }
    });
    console.log("Window y position", window.scrollY);
    console.log("windowWidth", window.innerWidth);
    //"1px 1px 50px 1px #fff"
    if (window.innerWidth < 1150) {
      setnavBarMonitor(true);
    } else {
      setnavBarMonitor(false);
    }
    window.addEventListener("resize", (e) => {
      e.isTrusted && window.innerWidth < 1150
        ? setnavBarMonitor(true)
        : setnavBarMonitor(false);
    });
  }, [window.innerWidth]);

  const items2 = [
    {
      key: "1",
      label: <span className="drawer-antd-btn1">Products</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/products/SaleSyncX");
              onClose();
            }}
          >
            SalesSyncX
          </li>
          <li
            onClick={() => {
              navigate("/products/TechstepHub");
              onClose();
            }}
          >
            TechstepHub
          </li>
          <li
            onClick={() => {
              navigate("/products/Planify");
              onClose();
            }}
          >
            Planify
          </li>
          <li
            onClick={() => {
              navigate("/products/ScanlogiX");
              onClose();
            }}
          >
            ScanlogiX
          </li>
          <li
            onClick={() => {
              navigate("/products/TechstepHub");
              onClose();
            }}
          >
            SupplySyncX
          </li>
          <li
            onClick={() => {
              navigate("/products/Techzard");
              onClose();
            }}
          >
            Techzard
          </li>
          <li
            onClick={() => {
              navigate("/products/Quizlink");
              onClose();
            }}
          >
            Quizlink
          </li>
          <li
            onClick={() => {
              navigate("/products/BidConnectHub");
              onClose();
            }}
          >
            Bidconnect
          </li>
          <li
            onClick={() => {
              navigate("/products/HireCentral");
              onClose();
            }}
          >
            Hire Flow
          </li>
          <li
            onClick={() => {
              navigate("/products/E-Proc");
              onClose();
            }}
          >
            E-Proc
          </li>
        </ul>
      ),
    },
    {
      key: "3",
      label: <span className="drawer-antd-btn1">Services</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/services/ERP-Service");
              onClose();
            }}
          >
            ERP Services
          </li>
          <li
            onClick={() => {
              navigate("/services/Product-Development");
              onClose();
            }}
          >
            Product Development
          </li>
          {/* <li
            onClick={() => {
              navigate("/services/Out-Sourcing");
              onClose();
            }}
          >
            Out Sourcing
          </li> */}
        </ul>
      ),
    },
    {
      key: "4",
      label: <span className="drawer-antd-btn1">Platforms</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/platforms/Oracle");
              onClose();
            }}
          >
            Oracle
          </li>
          <li
            onClick={() => {
              navigate("/platforms/SAP");
              onClose();
            }}
          >
            SAP
          </li>
          <li
            onClick={() => {
              navigate("/platforms/Cloud");
              onClose();
            }}
          >
            Cloud
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    console.log("first", document.location.pathname);
    const a = document.location.pathname;
    if (String(a).includes("careers")) {
      setHighlightCompany(true);
    } else if (String(a).includes("platforms")) {
      sethighlightPlatforms(true);
    } else if (String(a).includes("products")) {
      sethighlightProducts(true);
    } else if (String(a).includes("verticals")) {
      sethighlightVertical(true);
    } else if (String(a).includes("solutions")) {
      sethighlightsolutions(true);
    } else if (String(a).includes("services")) {
      sethighlightService(true);
    } else if (String(a).includes("blog")) {
      setHighlightBlog(true);
    } else if (String(a).includes("ai")){
      setHighlightAI(true);
    }
     else {
      setHighlightCompany(false);
      sethighlightHome(false);
      sethighlightPlatforms(false);
      sethighlightProducts(false);
      sethighlightVertical(false);
      sethighlightsolutions(false);
      sethighlightService(false);
      setHighlightBlog(false);
    }
  }, [document.location.pathname]);

  // useEffect(() => {
  //   const preloadImage = (src) => {
  //     const img = new Image();
  //     img.src = src;
  //     img.onload = () => {
  //       const targetImg = document.querySelector(`img[data-src="${src}"]`);
  //       if (targetImg) {
  //         targetImg.src = src;
  //       }
  //     };
  //   };
  
  //   const images = [Solution, Product, Resource, AI, SaleSynx, TechStep, Planify, Scanlogix, Supply,  Techzard, Quiz, Bid, Hireflow, Eproc];
  //   images.forEach(preloadImage);
  // }, []);

  useEffect(() => {
    const preloadImages = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = resolve; // Ensure it resolves even if there's an error
      });
    };

    const images = [Solution, Product, Resource, AI, SaleSynx, TechStep, Planify, Scanlogix, Supply,  Techzard, Quiz, Bid, Hireflow, Eproc];
    Promise.all(images.map(preloadImages)).then(() => {
      setImagesPreloaded(true);
    });
  }, []);
  

  // useEffect(() => {
  //   const images = [Solution, Product, Resource, AI, SaleSynx, TechStep, Planify, Scanlogix, Supply,  Techzard, Quiz, Bid, Hireflow, Eproc];
  //   images.forEach((src) => {
  //     const img = new Image();
  //     img.src = src;
  //   });
  // }, []);


  return (
    <div>
      {navBarMonitor ? (
        <div
          className="navbar2"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <button className="hamburger-cus" onClick={showDrawer}>
            <HiMenuAlt1
              style={{
                width: "3em",
                height: "3em",
              }}
            />
          </button>
          <img
              src={focusRlogoRight}
              width="130em"
              alt="logo"
              style={{
                marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: 'auto',
          paddingBottom: '2px',
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
              }}
            />
        </div>
      ) :(
        <div
          className="navbar"
          style={{
            boxShadow: hover ? "1px 1px 10px 1px rgb(227, 218, 218)" : "none",
          }}
        >
          <div className="main-navbar-wrap">
          <li
                className="navbar-logo"
                // style={{ color: "#423E3B" }}
                onClick={() => navigate("/")}
              >
                <img
                  src={focusRlogo}
                  width="90em"
                  alt="logo"
                  style={{
                    // marginTop: "0.1em",
                    // position: "absolute",
                    // left: "1.5em",
                    cursor: "pointer",
                    backgroundSize: "100%",
                    textIndent: "-99999px",
                    backgroundPosition: "1px 5px",
                  }}
                />
              </li>
          <div className="main-nav">
            <ul className="right-nav">
              {/* <li
                onClick={() => navigate("/")}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  color: "#423E3B",
                  marginLeft: "2%",
                }}
              >
                Home
                {highlightHome ? (
                  <span
                    style={{
                      width: "2em",
                      height: "3px",
                      display: "inline-block",
                      backgroundColor: "red",
                      transition: "all 400ms",
                    }}
                  ></span>
                ) : null}
              </li> */}
              <nav
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#423E3B",
                }}
              >
                <div
                  onMouseEnter={handleMouseEnterServices}
                  // onMouseLeave={handleMouseLeaveProducts}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  Services
                  {highlightService && (
                    <span
                      style={{
                        // width: '3em',
                        // height: '3px',
                        // display: 'flex',
                        // backgroundColor: 'red',
                        // transition: 'all 400ms',
                        // position: 'absolute',
                        width: "100%",
                        height: "1.5px",
                        display: "block",
                        backgroundColor: "red",
                        transition: "all 400ms",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                      }}
                    ></span>
                  )}
                </div>
                {showServiceModal && imagesPreloaded && (
                  <div
                    onMouseEnter={handleMouseEnterModal}
                    onMouseLeave={handleMouseLeaveModal}
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      backgroundColor: "white",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      zIndex: 1000,
                      padding: "1em",
                    }}
                  >
                    <div className="service-inner-menu">
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/services/ERP-Service")}
                      >
                        <img
                          src={Solution}
                          alt="Solution logo"
                          width="30em"
                          loading="eager"
                          className="inner-menu-icon"
                          style={{
                            position: "relative",
                            // top: "0.3em",
                            // fontSize: "2em",
                          }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>ERP Services</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>Business software solutions</p>
                        </div>
                      </div>
                      <div
                        className="inner-menu-item"
                        onClick={() =>
                          navigate("/services/Product-Development")
                        }
                      >
                        <img
                          src={Product}
                          alt="Product logo"
                          width="30em"
                           loading="eager"
                          className="inner-menu-icon"
                          style={{
                            position: "relative",
                            // top: "0.3em",
                            // fontSize: "2em",
                          }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>Product Development</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>Integration solutions provider</p>
                        </div>
                      </div>
                      {/* <div
                        className="inner-menu-item"
                        onClick={() => navigate("/services/Out-Sourcing")}
                      >
                        <img
                          src={Resource}
                          alt="Resource logo"
                          width="30em"
                           loading="eager"
                          className="inner-menu-icon"
                          style={{
                            position: "relative",
                            // top: "0.3em",
                            // fontSize: "2em",
                          }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}> Out Sourcing</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>External resource procurement.</p>
                        </div>
                      </div> */}
                      {/* <div
                        className="inner-menu-item"
                        onClick={() => navigate("/services/AI")}
                      >
                        <img
                          src={AI}
                          alt="AI logo"
                           loading="eager"
                          width="30em"
                          className="inner-menu-icon"
                          style={{
                            position: "relative",
                            // top: "0.3em",
                            // fontSize: "2em",
                          }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>AI / RPA</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>Cutting-edge Tech Solutions: AI and RPA</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                )}
              </nav>
              <nav
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#423E3B",
                }}
              >
                {/* <Popover content={contentProducts} trigger="hover">
                  Products
                  {highlightProducts ? (
                    <span
                      style={{
                        width: "3em",
                        height: "3px",
                        display: "flex",
                        backgroundColor: "red",
                        transition: "all 400ms",
                        position: "absolute",
                      }}
                    ></span>
                  ) : null}
                </Popover> */}
                <div
                  onMouseEnter={handleMouseEnterProducts}
                  // onMouseLeave={handleMouseLeaveServices}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  Products
                  {highlightProducts2 && (
                    <span
                      style={{
                        // width: '3em',
                        // height: '3px',
                        // display: 'flex',
                        // backgroundColor: 'red',
                        // transition: 'all 400ms',
                        // position: 'absolute',
                        width: "100%",
                        height: "1.5px",
                        display: "block",
                        backgroundColor: "red",
                        transition: "all 400ms",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                      }}
                    ></span>
                  )}
                </div>
                {showProductsModal && imagesPreloaded && (
                  <div
                    onMouseEnter={handleMouseEnterModal}
                    onMouseLeave={handleMouseLeaveModal}
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      backgroundColor: "white",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      zIndex: 1000,
                      padding: "1em",
                    }}
                  >
                    <div className="service-inner-menu">
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/products/SaleSyncX")}
                      >
                        <img
                          src={SaleSynx}
                          alt="SaleSyncx logo"
                          width="30em"
                           loading="eager"
                          className="inner-menu-icon"
                          style={{ position: "relative" }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>SalesSyncX</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>
                            Dealer portal for purchase orders
                            <br /> with Oracle ERP's three-stage approval.
                          </p>
                        </div>
                      </div>
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/products/TechstepHub")}
                      >
                        <img
                          src={TechStep}
                          alt="Techstephub logo"
                          width="30em"
                           loading="eager"
                          className="inner-menu-icon"
                          style={{ position: "relative" }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>Techstep Hub</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>
                            App that tracks and manages time, simplifying <br />
                            work hour, task, and project recording.
                          </p>
                        </div>
                      </div>
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/products/Planify")}
                      >
                        <img
                          src={Planify}
                          alt="planify logo"
                          width="30em"
                           loading="eager"
                          className="inner-menu-icon"
                          style={{ position: "relative" }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>Planify</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>
                            Dispatch planner for creating POs,
                            <br /> seamlessly integrated with Oracle ERP.
                          </p>
                        </div>
                      </div>
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/products/ScanlogiX")}
                      >
                        <img
                          src={Scanlogix}
                          alt="Scanlogix logo"
                           loading="eager"
                          width="30em"
                          className="inner-menu-icon"
                          style={{ position: "relative" }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>ScanlogiX</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>
                            App integrating barcode creation,
                            <br /> label extraction, and Oracle data storage.
                          </p>
                        </div>
                      </div>
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/products/SupplySyncX")}
                      >
                        <img
                          src={Supply}
                          alt="Supplysync logo"
                          width="30em"
                           loading="eager"
                          className="inner-menu-icon"
                          style={{ position: "relative" }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>Supply Sync</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>
                            Platform for supply chain management,
                            <br /> seamlessly integrated with Oracle ERP.
                          </p>
                        </div>
                      </div>
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/products/Techzard")}
                      >
                        <img
                          src={Techzard}
                          alt="Techzard logo"
                          width="30em"
                           loading="eager"
                          className="inner-menu-icon"
                          style={{ position: "relative" }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>Techzard</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>
                            Platform for ticket management with
                            <br /> robust SLA tracking and project management.
                          </p>
                        </div>
                      </div>
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/products/Quizlink")}
                      >
                        <img
                          src={Quiz}
                           loading="eager"
                          alt="Quizlink logo"
                          width="30em"
                          className="inner-menu-icon"
                          style={{ position: "relative" }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>Quizlink</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>
                            Portal for online evaluations, enabling
                            <br /> educators to assess and grade students.
                          </p>
                        </div>
                      </div>
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/products/BidConnectHub")}
                      >
                        <img
                          src={Bid}
                          alt="Bidconnect logo"
                          width="30em"
                           loading="eager"
                          className="inner-menu-icon"
                          style={{ position: "relative" }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>Bidconnect Hub</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>
                            Oracle-integrated platform for dispatch,
                            <br /> route optimization, and visibility.
                          </p>
                        </div>
                      </div>
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/products/HireCentral")}
                      >
                        <img
                          src={Hireflow}
                          alt="hireflow logo"
                          width="30em"
                           loading="eager"
                          className="inner-menu-icon"
                          style={{ position: "relative" }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>Hire Flow</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>
                            Recruitment software for streamlined
                            <br /> hiring and candidate management.
                          </p>
                        </div>
                      </div>
                      <div
                        className="inner-menu-item"
                        onClick={() => navigate("/products/E-Proc")}
                      >
                        <img
                          src={Eproc}
                           loading="eager"
                          alt="Eproc logo"
                          width="30em"
                          className="inner-menu-icon"
                          style={{ position: "relative" }}
                        />
                        <div className="inner-menu-item-top">
                          <p style={headLocalstyle}>E-Proc</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>
                            Application that streamlines procurement
                            <br /> by managing requests and orders.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </nav>
              <nav
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#423E3B",
                }}
              >
                {/* <Popover content={contentProducts} trigger="hover">
                  Products
                  {highlightProducts ? (
                    <span
                      style={{
                        width: "3em",
                        height: "3px",
                        display: "flex",
                        backgroundColor: "red",
                        transition: "all 400ms",
                        position: "absolute",
                      }}
                    ></span>
                  ) : null}
                </Popover> */}
                <div
                  onMouseEnter={handleMouseEnterPlatform}
                  // onMouseLeave={handleMouseLeavePlatform}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  Platforms
                  {highlightPlatforms && (
                    <span
                      style={{
                        // width: '3em',
                        // height: '3px',
                        // display: 'flex',
                        // backgroundColor: 'red',
                        // transition: 'all 400ms',
                        // position: 'absolute',
                        width: "100%",
                        height: "1.5px",
                        display: "block",
                        backgroundColor: "red",
                        transition: "all 400ms",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                      }}
                    ></span>
                  )}
                </div>
                {showPlatformModal && (
                  <div
                    onMouseEnter={handleMouseEnterModal}
                    onMouseLeave={handleMouseLeaveModal}
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      backgroundColor: "white",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      zIndex: 1000,
                      padding: "1em",
                    }}
                  >
                    <div
                      className="service-inner-menu-1"
                      style={{ display: "flex" }}
                    >
                      <div
                        variants={itemVariants}
                        className="inner-menu-item"
                        onClick={() => navigate("/platforms/Oracle")}
                      >
                        <div className="inner-menu-item-top">
                          <GrOracle
                            className="inner-menu-icon"
                            style={{
                              position: "relative",
                              top: "0.3em",
                              fontSize: "2em",
                            }}
                          />
                          &nbsp;
                          <p style={{marginTop:'1em', fontWeight:'bold'}}>Oracle</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>Oracle Services Provider</p>
                        </div>
                      </div>
                      <div
                        variants={itemVariants}
                        className="inner-menu-item"
                        onClick={() => navigate("/platforms/SAP")}
                      >
                        <div className="inner-menu-item-top">
                          <SiSap
                            className="inner-menu-icon"
                            style={{
                              position: "relative",
                              top: "0.3em",
                              fontSize: "2em",
                            }}
                          />
                          &nbsp;
                          <p style={{marginTop:'1em', fontWeight:'bold'}}>SAP</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>SAP Services Provider</p>
                        </div>
                      </div>
                      <div
                        variants={itemVariants}
                        className="inner-menu-item"
                        onClick={() => navigate("/platforms/Cloud")}
                      >
                        <div className="inner-menu-item-top">
                          <AiFillCloud
                            className="inner-menu-icon"
                            style={{
                              position: "relative",
                              top: "0.3em",
                              fontSize: "2em",
                            }}
                          />
                          &nbsp;
                          <p style={{marginTop:'1em', fontWeight:'bold'}}>Cloud</p>
                        </div>
                        <div className="inner-menu-item-bottom">
                          <p>Cloud Solutions</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </nav>
              <nav
                onClick={() => navigate("/verticals")}
                onMouseEnter={handleMouseEnterVertical}
                onMouseLeave={handleMouseLeaveVertical}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#423E3B",
                  cursor: "pointer",
                }}
              >
                Verticals
                {highlightVertical ? (
                  <span
                    style={{
                      // width: "3em",
                      // height: "3px",
                      // display: "inline-block",
                      // backgroundColor: "red",
                      // transition: "all 400ms",
                      width: "100%",
                      height: "2px",
                      display: "block",
                      backgroundColor: "red",
                      transition: "all 400ms",
                      // position: 'absolute',
                      bottom: 0,
                      left: 0,
                    }}
                  ></span>
                ) : null}
              </nav>
              <nav
                onClick={() => navigate("/careers")}
                onMouseEnter={handleMouseEnterCompany}
                onMouseLeave={handleMouseLeaveCompany}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  color: "#423E3B",
                  cursor: "pointer",
                }}
              >
                Careers
                {highlightCompany ? (
                  <span
                    style={{
                      width: "100%",
                      height: "2px",
                      display: "block",
                      backgroundColor: "red",
                      transition: "all 400ms",
                      bottom: 0,
                      left: 0,
                    }}
                  ></span>
                ) : null}
              </nav>
              <nav
                onClick={() => navigate("/blog")}
                onMouseEnter={handleMouseEnterBlog}
                onMouseLeave={handleMouseLeaveBlog}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  color: "#423E3B",
                  cursor: "pointer",
                }}
              >
                Blog
                {highlightBlog ? (
                  <span
                    style={{
                      width: "100%",
                      height: "2px",
                      display: "block",
                      backgroundColor: "red",
                      transition: "all 400ms",
                    }}
                  ></span>
                ) : null}
              </nav>
              <nav
                onClick={() => navigate("/services/AI")}
                onMouseEnter={handleMouseEnterAI}
                onMouseLeave={handleMouseLeaveAI}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  color: "#423E3B",
                  cursor: "pointer",
                }}
              >
                AI
                {highlightAI ? (
                  <span
                    style={{
                      width: "100%",
                      height: "2px",
                      display: "block",
                      backgroundColor: "red",
                      transition: "all 400ms",
                    }}
                  ></span>
                ) : null}
              </nav>
            </ul>
          </div>
          <div className="right-fixed">
            <ul className="right-nav">
              <li
                className="navbar-right"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  fontWeight: highlightHome ? "700" : "600",
                  color: "#423E3B",
                }}
              >
                <Popover content={contentEmployee} trigger="hover">
                  <MdOutlineMoreHoriz />
                </Popover>
              </li>
              <li>
                <span
                  className="primary-btn-cus"
                  onClick={() => navigate("/contact")}
                >
                  Contact us
                </span>
              </li>
            </ul>
          </div>
          </div>
        </div>
      )}
      <Drawer
        title={
          <div className="logo-small" style={{position: 'relative'}}>
            <img
              src={focusRlogoRight}
              width="130em"
              alt="logo"
              style={{
                marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
              }}
            />
          </div>
        }
        placement="top"
        onClose={onClose}
        open={open}
        height="100%"
      >
        <button
          className="drawer-antd-btn"
          onClick={() => {
            navigate("/");
            onClose();
          }}
        >
          Home
        </button>
        <button
          className="drawer-antd-btn"
          onClick={() => {
            navigate("/verticals");
            onClose();
          }}
        >
          Verticals
        </button>

        <Collapse ghost items={items2} expandIconPosition="right" />
        <button
          className="drawer-antd-btn"
          onClick={() => {
            navigate("/careers");
            onClose();
          }}
        >
          Careers
        </button>
        <button
          className="drawer-antd-btn"
          onClick={() => {
            navigate("/blog");
            onClose();
          }}
        >
          Blog
        </button>
        <button
          className="drawer-antd-btn"
          onClick={() => {
            navigate("/services/AI");
            onClose();
          }}
        >
          AI
        </button>
        <button
          className="drawer-antd-btn"
          onClick={() => {
            navigate("/contact");
            onClose();
          }}
        >
          Contact Us
        </button>
      </Drawer>
    </div>
  );
};

export default Navbar;
