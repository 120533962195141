import { useEffect, useRef, useState } from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { Link, Element, scroller } from "react-scroll";
import {
    AiFillControl,
    AiOutlineApi,
    AiOutlineBranches,
    AiOutlineSetting,
    AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import FocusrAIArrow from '../../assets/focusrarrowai.png'
import IntelligenceIcon from '../../assets/intelligence (1).svg'
import FeatureDots from '../../assets/smb_repeat_circle_bg.svg'
import { MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
    TbNumber10Small,
    TbNumber11Small,
    TbNumber1Small,
    TbNumber2Small,
    TbNumber3Small,
    TbNumber4Small,
    TbNumber5Small,
    TbNumber6Small,
    TbNumber7Small,
    TbNumber8Small,
    TbNumber9Small,
    TbSquareRoundedNumber1Filled,
    TbSquareRoundedNumber2Filled,
    TbSquareRoundedNumber3Filled,
    TbSquareRoundedNumber4Filled,
    TbSquareRoundedNumber5Filled,
    TbSquareRoundedNumber6Filled,
    TbSquareRoundedNumber7Filled,
    TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import { useWindupString } from "windups";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import AzureML from '../../assets/Azure Machine Learning Service.png'
import AzureCognitive from '../../assets/cognative-services.png'
import AzureOpenAI from '../../assets/Azure OpenAI.jpg'
import AzureSynapse from '../../assets/Azure Synapse Analytics.png'
import VertexAI from '../../assets/vertexai.png'
import AutoML from '../../assets/AutoML.png'
import AIHub from '../../assets/aihub.png'
import TensorFlow from '../../assets/TensorFlow New.png'
import NLApi from '../../assets/naturalapi.png'
import VisionAPI from '../../assets/Cloud Vision API.png'
import Sagemaker from '../../assets/SageMaker.png'
import Rekognition from '../../assets/Rekognition.png'
import Polly from '../../assets/Polly.png'
import Lex from '../../assets/Lex.png'
import Comprehend from '../../assets/Comprehend.png'
import UiPath from '../../assets/UiPath_Logo.png'
import Pega from '../../assets/pega-logo-horizontal-positive-rgb-01.png'
import BluePrism from '../../assets/blue-prism-logo.png'
import Automation from '../../assets/Automation Anywhere.png'
import focusRlogoRight from "../../assets/FocusR_100px.png";
import { HiMenuAlt1 } from "react-icons/hi";
import {
    Button,
    Drawer,
    Dropdown,
    Popover,
    Space,
    message,
    Tooltip,
  } from "antd";
import './aiServices.css'

const RPAEUC = () => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const contentRefs = useRef([]);
    const [bottomBoundary, setBottomBoundary] = useState(0);
    const welcomePageRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [navbarStatus, setnavbarStatus] = useState(false);
  const [hover, setHover] = useState(false);
    const [navBarMonitor, setnavBarMonitor] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      window.addEventListener("scroll", (e) => {
        if (e.isTrusted && window.scrollY > 0) {
          setnavbarStatus(true);
          setHover(true);
        } else {
          setnavbarStatus(false);
          setHover(false);
        }
      });
      console.log("Window y position", window.scrollY);
      console.log("windowWidth", window.innerWidth);
      //"1px 1px 50px 1px #fff"
      if (window.innerWidth < 1150) {
        setnavBarMonitor(true);
      } else {
        setnavBarMonitor(false);
      }
      window.addEventListener("resize", (e) => {
        e.isTrusted && window.innerWidth < 1150
          ? setnavBarMonitor(true)
          : setnavBarMonitor(false);
      });
    }, [window.innerWidth]);

    const items2 = [
      {
        key: "1",
        label: <span className="drawer-antd-btn1">Gen AI</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/llmframework");
                onClose();
              }}
            >
              LLM Framework
            </li>
            <li
              onClick={() => {
                navigate("/finetuning");
                onClose();
              }}
            >
              Fine-Tuning
            </li>
          </ul>
        ),
      },
      {
        key: "3",
        label: <span className="drawer-antd-btn1">ERP</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/seamlessintegration");
                onClose();
              }}
            >
              Integration
            </li>
            <li
              onClick={() => {
                navigate("/dataprotection");
                onClose();
              }}
            >
              Data Protection
            </li>
            <li
              onClick={() => {
                navigate("/unstructureddata");
                onClose();
              }}
            >
              Data Processing
            </li>
            <li
              onClick={() => {
                navigate("/cloudai");
                onClose();
              }}
            >
              Cloud AI
            </li>
            <li
              onClick={() => {
                navigate("/rpaeuc");
                onClose();
              }}
            >
              RPA
            </li>
          </ul>
        ),
      },
      {
        key: "4",
        label: <span className="drawer-antd-btn1">AI APP</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/products/HireCentral");
                onClose();
              }}
            >
              Hireflow
            </li>
          </ul>
        ),
      },
      {
        key: "5",
        label: <span className="drawer-antd-btn1">Analytics</span>,
        children: (
          <ul className="inner-accord">
            <li
              onClick={() => {
                navigate("/powerbianalytics");
                onClose();
              }}
            >
              Power BI
            </li>
            <li
              onClick={() => {
                navigate("/oraclesapanalytics");
                onClose();
              }}
            >
              Oracle and SAP
            </li>
            <li
              onClick={() => {
                navigate("/googleanalytics");
                onClose();
              }}
            >
              Google
            </li>
          </ul>
        ),
      },
    ];

    const isMobile = window.innerWidth < 768; // Example mobile check
    const isTablet = window.innerWidth <= 1024 && window.innerWidth >= 768; // Example tablet check

    window.onload = function () {
        const elements = document.querySelectorAll('.framework-icons');
        elements.forEach(el => {
            el.classList.add('anim-init');
        });
    };

    const sections = [
        {
            title: "RPA Services We Offer",
            description: "FocusR provides comprehensive RPA solutions leveraging the industry’s top automation platforms, including UiPath, Pega, Blue Prism, and Automation Anywhere. Our AI-powered RPA implementations empower your business to achieve end-to-end automation with enhanced intelligence. ",
            // images: [
            //     {
            //         imageUrl: UiPath,
            //         alt: "UiPatch"
            //     },
            // ],
            parahs: [
                {
                    text: "1. UiPath: ",
                    image: {
                        imageUrl: UiPath,
                        alt: "UiPath"
                    },
                    details: [
                        "AI-Powered Automation: Leverage UiPath’s robust AI Fabric to integrate machine learning models directly into your RPA workflows. Automate complex decision-making processes, enhance document understanding, and improve overall automation accuracy. ",
                        "Intelligent Document Processing: Automate the extraction of data from unstructured documents, such as invoices and contracts, using UiPath’s built-in AI and OCR technologies. ",
                        "Robust Bot Development: We build scalable and intelligent bots with UiPath, ensuring they adapt to your evolving business needs. "
                    ],
                },
                {
                    text: "2. Pega: ",
                    image: {
                        imageUrl: Pega,
                        alt: "Pega"
                    },
                    details: [
                        "AI-Enhanced Workflow Automation: Pega’s low-code platform combined with AI allows us to create custom RPA solutions that automate routine business tasks while leveraging AI for advanced decision-making.",
                        "Predictive Analytics: Use AI-driven insights from Pega to predict future trends, enhance customer service, and make proactive business decisions.",
                        "Case Management: Automate case management with AI-infused RPA that streamlines workflows, improving customer outcomes and employee productivity. "
                    ],
                },
                {
                    text: "3. Blue Prism:",
                    image: {
                        imageUrl: BluePrism,
                        alt: "BluePrism"
                    },
                    details: [
                        "Cognitive Automation:  Integrate AI capabilities with Blue Prism’s intelligent automation to handle tasks that involve unstructured data, decision-making, and complex rule-based processes.",
                        "AI-Powered Analytics: We enable real-time data analysis with AI-powered Blue Prism bots, allowing for faster and more informed decision-making across your business. ",
                        "Scalability and Security: FocusR ensures that Blue Prism’s RPA solutions are scalable, secure, and compliant with industry standards, supporting your business growth. "
                    ],
                },
                {
                    text: "4. Automation Anywhere:  ",
                    image: {
                        imageUrl: Automation,
                        alt: "Automation Anywhere"
                    },
                    details: [
                        "AI-Driven Bots: Leverage Automation Anywhere’s IQ Bot, which utilizes AI and machine learning to read and process semi-structured and unstructured data, such as invoices, emails, and contracts.",
                        "Natural Language Processing (NLP): Automate processes that require language comprehension, using Automation Anywhere’s NLP capabilities for intelligent email responses, customer service, and more.",
                        "End-to-End Automation: FocusR enables full business process automation with Automation Anywhere’s RPA solutions, from data entry to advanced AI-based decision-making. "
                    ],
                },
            ],
        },
        {
            title: "Enhancing End-User Computing (EUC) with AI ",
            description: "We also bring AI capabilities to your End-User Computing environment, empowering users to make smarter decisions with less manual effort. By embedding AI into your existing EUC applications, such as spreadsheets, databases, and other business tools, we help you:",
            parahs: [
                {
                    details: [
                        "Automate Repetitive EUC Tasks: From complex Excel functions to managing vast databases, AI enhances EUC processes by automating repetitive tasks and reducing errors. ",
                        "Improve Data Accuracy: AI-based validations and predictive models help ensure data accuracy in real-time, minimizing the risk of costly errors.",
                        "Streamline User Workflows: With AI, users can process and analyze data faster, enabling them to make data-driven decisions efficiently."
                    ]
                }
            ],
        },
        {
            title: "Benefits of RPA and AI Integration",
            parahs: [
                {
                    details: [
                        "Smarter Automation: By combining AI with RPA, FocusR allows for the automation of more complex processes, such as decision-making, document understanding, and predictive analytics.",
                        "Reduced Human Error: AI-powered RPA reduces the risk of manual errors, ensuring higher accuracy and reliability across automated workflows.",
                        "Increased Efficiency: Automating repetitive tasks frees up employees for higher-value work, driving productivity and operational efficiency.",
                        "Scalable Solutions: Our RPA and AI solutions are designed to scale as your business grows, ensuring continuous improvement and innovation."
                    ]
                }
            ]
        },
        {
            title: "Unlock the Potential of RPA and AI with FocusR ",
            description: "By integrating AI capabilities into your RPA and EUC systems, FocusR empowers your organization to achieve smarter, more efficient automation. Whether you're leveraging platforms like UiPath, Pega, Blue Prism, or Automation Anywhere, we provide a tailored solution to meet your business needs."
        }
    ];
    useEffect(() => {
        AOS.init();
    }, []);

    const handleScroll = () => {
        const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
        const currentSectionIndex = contentRefs.current.findIndex(
            (ref) => ref && ref.offsetTop > scrollPosition
        );
        setActiveIndex(
            currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
        );
    };

    // useEffect(() => {
    //   // Set bottom boundary after DOM is rendered
    //   const rightSideElement = document.querySelector(".right-side");
    //   if (rightSideElement) {
    //     setBottomBoundary(
    //       rightSideElement.offsetTop + rightSideElement.offsetHeight
    //     );
    //   }

    //   window.addEventListener("scroll", handleScroll);
    //   return () => window.removeEventListener("scroll", handleScroll);
    // }, []);

    useEffect(() => {
        const updateBottomBoundary = () => {
            const rightSideElement = document.querySelector(".right-side");
            if (rightSideElement) {
                setBottomBoundary(
                    rightSideElement.offsetTop + rightSideElement.offsetHeight
                );
            }
        };

        const observer = new ResizeObserver(updateBottomBoundary);
        const rightSideElement = document.querySelector(".right-side");

        if (rightSideElement) {
            observer.observe(rightSideElement);
        }

        // Initial boundary calculation
        updateBottomBoundary();

        window.addEventListener("scroll", updateBottomBoundary);

        return () => {
            window.removeEventListener("scroll", updateBottomBoundary);
            if (rightSideElement) {
                observer.unobserve(rightSideElement);
            }
        };
    }, []);

    window.addEventListener("scroll", handleScroll);

    return (
        <div>
            {/* <Navbar mode="dark" /> */}
            {navBarMonitor ? (
          <div className="navbar2" style={{ backgroundColor: "#fff" }}>
            <button className="hamburger-cus" onClick={showDrawer}>
              <HiMenuAlt1 style={{ width: "3em", height: "3em" }} />
            </button>
            <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
          </div>
        ) : (
          <Navbar mode="dark" />
        )}
        <Drawer
          title={
            <div className="logo-small">
              <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
            </div>
          }
          placement="top"
          onClose={onClose}
          open={open}
          height="100%"
        >
          <button
            className="drawer-antd-btn"
            onClick={() => {
              navigate("/");
              onClose();
            }}
          >
            Home
          </button>
          <Collapse ghost items={items2} expandIconPosition="right" />
        </Drawer>
            {/* <ChatNew /> */}
            <div className="welcome-page-23-llm">
                <center>
                    <div className='welcome-ai-page-2-header' style={{ marginBottom: '3em' }}>
                        <div className='welcome-ai-page-2-wrap' style={{ width: isMobile ? "" : isTablet ? '' : "90%" }}>
                            <div className='welcome-ai-page-2-title' onClick={() => navigate('/services/AI')} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={FocusrAIArrow} width="auto" height={ isMobile? "30px" : "45px"} />
                                <img src={IntelligenceIcon} width={ isMobile? "100px" : "150px"} style={{ marginLeft: '15px' }} />
                            </div>
                            <div className='welcome-ai-page-2-navlinks'>
                                <div className='welcome-ai-page-2-menu' style={{ height: 'auto', top: 'auto', display: 'block' }}>
                                <span className='menu-item'>Gen AI<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/llmframework')} className="dropdown-item">LLM Framework</Link>
      <Link onClick={() => navigate('/finetuning')} className="dropdown-item">Fine-tuning</Link>
    </div>
  </span>
  <span className='menu-item'>ERP<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/seamlessintegration')} className="dropdown-item">Integration</Link>
      <Link onClick={() => navigate('/dataprotection')} className="dropdown-item">Data Protection</Link>
      <Link onClick={() => navigate('/unstructureddata')} className="dropdown-item">Data Processing</Link>
      <Link onClick={() => navigate('/cloudai')} className="dropdown-item">Cloud AI</Link>
      <Link onClick={() => navigate('/rpaeuc')} className="dropdown-item">RPA</Link>
    </div>
  </span>
  <span className='menu-item'>AI APP<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/products/HireCentral')} className="dropdown-item">Hireflow</Link>
    </div>
  </span>
  <span className='menu-item'>Analytics<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/powerbianalytics')} className="dropdown-item">Power BI</Link>
      <Link onClick={() => navigate('/oraclesapanalytics')} className="dropdown-item">Oracle and SAP</Link>
      <Link onClick={() => navigate('/googleanalytics')} className="dropdown-item">Google</Link>
    </div>
  </span>
                                </div>
                                {/* <a href='#aiform' style={{ display: isMobile ? 'none': 'flex', backgroundColor: '#E42527', minWidth: '100px', marginLeft: '10px', justifyContent: 'center', borderRadius: '30px', padding: '7px 20px', color: 'white' }}>
                                    Get Started
                                </a> */}
                            </div>
                        </div>
                    </div>
                    <h1
                        style={{
                            color: "#0067FF",
                            textAlign: "center",
                            width: "80%",
                            fontSize: isMobile? '30px' :  '46px',
                            fontWeight: '800',
                            lineHeight: isMobile? '35px': '52px',
                            marginBottom: '0.5em'
                        }}
                    >
                        RPA and EUC Implementation and Support with AI Capabilities
                    </h1>
                    <p style={{ fontSize: "18px", letterSpacing: '1px' }}>
                        At FocusR, we enhance your Robotic Process Automation (RPA) and End-User Computing (EUC) systems with advanced AI capabilities, driving smarter automation, reducing human errors, and significantly increasing operational efficiency across your business processes. Our expert team ensures seamless integration of AI into your RPA workflows, enabling you to streamline repetitive tasks and free up valuable resources for higher-level decision-making. {" "}
                    </p>
                    <div className="button-container1">
                        <a
                            className="primary-btn-cus"
                            href="#careers"
                            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
                            onClick={() => {
                                navigate("/contact");
                            }}
                        >
                            Connect to know more
                        </a>
                    </div>
                </center>
            </div>
            {/* <div className="llmframework-feature1">
        <div className="anim-init" style={{ color: '#0067ff', textAlign: 'center', fontSize: '60px' }} data-aos="fade-right"
        data-aos-delay="200">
          LLMS we work with
        </div>
        <div style={{ display: 'flex', paddingTop: '35px', height: 'fit-content', justifyContent: 'space-evenly', width: '100%' }} >
          <div className="framework-icon-wrapper" data-aos="fade-right"
        data-aos-delay="300">
            <span className="framework-icons anim-init">
                <img width={150} src={GeminiIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={200} src={OpenAIIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={180} src={ClaudeIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={80} style={{ paddingTop: '2em' }} src={LlamaIcon} />
            </span>
            <span className="framework-icons anim-init">
                <img width={180} style={{ paddingTop: '2.5em' }} src={HuggingIcon} />
            </span>
          </div>
        </div>
      </div> */}
            {/* <div className="llmframework-feature1">
      <div className="anim-init" style={{ color: '#0067ff', textAlign: 'center', fontSize: isMobile? '35px': '60px' }}>
          LLMS we work with
        </div>
      <div className="new-logo-container" data-aos="fade-right"
        data-aos-delay="200">
          {arrayOfImages.map((x, i) => {
            return <img src={x.imageUrl} alt={x.alt} />;
          })}
        </div>
        </div>
        <div className="feature1-main" style={{maxWidth: '1140px', margin: '130px auto 0', position: 'relative'}}>
          <div className="feature1-dots"></div>
          <div style={{background: 'white'}}>
      <div style={{ color: '#0067ff', textAlign: 'center', fontSize:isMobile? '30px': '60px', marginBottom: '1em' }}>
        Other LLMs that can be integrated
      </div>
      <div className="llmframework-feature2">
        <div className="feature2-table">
          <ul className="summary_lst">
            <li style={{ minHeight: '60px' }}>
              <span>AI21 Jur</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Aleph Alpha Luminous</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Baichuan LLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>ChatGLM-6B</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>GigaChat</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>GPT4All</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>IBM Watsonx.ai</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>KoboldAI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Moonshot LLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>MosaicML</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>NLP Cloud</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>OctoAI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>OpenLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Petals</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>SambaNova Sambaverse</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>SparkLLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Symbl.ai Nebula</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Together AI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Tongyi Qwen</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>vLLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Volc Engine Maas</span>
            </li>
          </ul>
        </div>
      </div>
      </div>
      </div>
      <div className="features-vectorbg">
        <div style={{ color: '#0067ff', textAlign: 'center', fontSize: '60px', marginBottom: '1em', marginTop: '1.5em' }}>
          <span style={{position: 'relative', top: '30px', fontSize: isMobile ? '26px' : ''}}>Vector stores we can work with</span>
        </div>
        <div className="llmframework-feature2" style={{borderRadius: '30px'}}>
          <div className="feature2-table" >
            <ul className="summary_lst">
              <li style={{ minHeight: '60px' }}><span>Activeloop Deep Lake</span></li>
              <li style={{ minHeight: '60px' }}><span>Aerospike Vector Search (AVS)</span></li>
              <li style={{ minHeight: '60px' }}><span>Alibaba Cloud OpenSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>ApertureDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Astra DB Vector Store</span></li>
              <li style={{ minHeight: '60px' }}><span>Atlas</span></li>
              <li style={{ minHeight: '60px' }}><span>AwaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Azure AI Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Baidu Cloud ElasticSearch VectorSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Baidu VectorDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Chroma</span></li>
              <li style={{ minHeight: '60px' }}><span>Clarifai</span></li>
              <li style={{ minHeight: '60px' }}><span>DashVector</span></li>
              <li style={{ minHeight: '60px' }}><span>Databricks Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>DingoDB</span></li>
              <li style={{ minHeight: '60px' }}><span>DocArray HnswSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>DocArray InMemorySearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Elasticsearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Epsilla</span></li>
              <li style={{ minHeight: '60px' }}><span>Faiss</span></li>
              <li style={{ minHeight: '60px' }}><span>Google BigQuery Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Google Vertex AI Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Hippo</span></li>
              <li style={{ minHeight: '60px' }}><span>Jaguar Vector Database</span></li>
              <li style={{ minHeight: '60px' }}><span>KDB.AI</span></li>
              <li style={{ minHeight: '60px' }}><span>Kinetica</span></li>
              <li style={{ minHeight: '60px' }}><span>LanceDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Lantern</span></li>
              <li style={{ minHeight: '60px' }}><span>ManticoreSearch VectorStore</span></li>
              <li style={{ minHeight: '60px' }}><span>Milvus</span></li>
              <li style={{ minHeight: '60px' }}><span>Momento Vector Index (MVI)</span></li>
              <li style={{ minHeight: '60px' }}><span>MongoDB Atlas Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Neo4j Vector Index</span></li>
              <li style={{ minHeight: '60px' }}><span>NucliaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>OpenSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Oracle AI Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>PGVector</span></li>
              <li style={{ minHeight: '60px' }}><span>Pinecone</span></li>
              <li style={{ minHeight: '60px' }}><span>Qdrant</span></li>
              <li style={{ minHeight: '60px' }}><span>Redis</span></li>
              <li style={{ minHeight: '60px' }}><span>SAP HANA Cloud Vector Engine</span></li>
              <li style={{ minHeight: '60px' }}><span>ScaNN</span></li>
              <li style={{ minHeight: '60px' }}><span>SemaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>SingleStoreDB</span></li>
              <li style={{ minHeight: '60px' }}><span>SQLite-VSS</span></li>
              <li style={{ minHeight: '60px' }}><span>SurrealDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Tencent Cloud VectorDB</span></li>
              <li style={{ minHeight: '60px' }}><span>ThirdAI NeuralDB</span></li>
              <li style={{ minHeight: '60px' }}><span>TiDB Vector</span></li>
              <li style={{ minHeight: '60px' }}><span>Timescale Vector (Postgres)</span></li>
              <li style={{ minHeight: '60px' }}><span>Upstash Vector</span></li>
              <li style={{ minHeight: '60px' }}><span>USearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Vald</span></li>
              <li style={{ minHeight: '60px' }}><span>Vearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Vectara</span></li>
              <li style={{ minHeight: '60px' }}><span>Vespa</span></li>
              <li style={{ minHeight: '60px' }}><span>viking DB</span></li>
              <li style={{ minHeight: '60px' }}><span>vlite</span></li>
              <li style={{ minHeight: '60px' }}><span>Weaviate</span></li>
            </ul>
          </div>
        </div>
      </div> */}
            <div className="welcome-page-24" ref={welcomePageRef} style={{ marginTop: '5em' }}>
                <div className="left-side">
                    <Sticky top={100} bottomBoundary={bottomBoundary}>
                        {({ style }) => (
                            <div className="left-side" style={style}>
                                {sections.map((section, index) => (
                                    <h2
                                        style={{ fontWeight: "normal", fontSize: "14px" }}
                                        key={index}
                                        className={`left-side-heading ${activeIndex === index ? "active" : ""
                                            }`}
                                        onClick={() =>
                                            window.scrollTo({
                                                top: contentRefs.current[index]?.offsetTop - 100,
                                                behavior: "smooth",
                                            })
                                        }
                                    >
                                        {section.title}
                                    </h2>
                                ))}
                            </div>
                        )}
                    </Sticky>
                </div>
                <div className="right-side" style={{ paddingRight: '0' }}>
                {sections && sections.map((section, index) => (
  <div
    key={index}
    ref={(el) => (contentRefs.current[index] = el)}
    className="content-section"
  >
    <h2>{section.title}</h2>
    <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
    
    {Array.isArray(section.parahs) && section.parahs.length > 0 && section.parahs.map((paragraph, paraIndex) => (
      <div key={paraIndex}>
        {paragraph.image && (
          <div className="new-logo-container" style={{ marginTop: '0',  display: 'flex', justifyContent: 'flex-start', paddingLeft: '1.5em' }}>
            <img src={paragraph.image.imageUrl} alt={paragraph.image.alt} />
          </div>
        )}
        {paragraph.text && (
          <p style={{ marginLeft: "1.6em" }}>
            {paragraph.text.includes(":") ? (
              <span>
                <strong>{paragraph.text.split(":")[0]}</strong>: {paragraph.text.split(":")[1]}
              </span>
            ) : (
              paragraph.text
            )}
          </p>
        )}
        {Array.isArray(paragraph.details) && paragraph.details.length > 0 && (
          <ul style={{ listStyle: "square", padding: "1.5em 0 2em 6em" }}>
            {paragraph.details.map((detail, detailIndex) => (
              <li key={detailIndex} style={{ padding: "0.5em" }}>
                {detail.includes(":") ? (
                  <span>
                    <strong>{detail.split(":")[0]}</strong>: {detail.split(":")[1]}
                  </span>
                ) : (
                  detail
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    ))}
  </div>
))}

                </div>
            </div>
            <div className="bottom-ai-services">
                <h1 style={{ fontSize: '25px', width: '80%' }}>Ready to elevate your automation strategy? <br/> Let FocusR help you enhance your RPA and EUC systems with cutting-edge AI technologies to drive transformative business outcomes. </h1>
                <div className="button-container1">
                    <a
                        className="primary-btn-cus"
                        href="#careers"
                        style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
                        onClick={() => {
                            navigate("/contact");
                        }}
                    >
                        Learn More
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default RPAEUC;
