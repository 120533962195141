import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../../Components/Navbar';
import { useNavigate } from "react-router-dom";
import CLOUDS from 'vanta/dist/vanta.fog.min';
import {
  Button,
  Collapse,
  Drawer,
  Dropdown,
  Popover,
  Space,
  message,
  Tooltip,
} from "antd";
import focusRlogoRight from "../../assets/FocusR_100px.png";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { HiMenuAlt1 } from "react-icons/hi";
import { Link, Element, scroller } from "react-scroll";
import Waves from "vanta/dist/vanta.halo.min";
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import { Col, FloatButton, Modal, Row } from 'antd';
import { RxVideo } from 'react-icons/rx';
import { BiVideo, BiSolidBot, BiPlay } from 'react-icons/bi';
import ReactPlayer from 'react-player';
import { HiViewGrid } from 'react-icons/hi';
import { FiArrowRight } from 'react-icons/fi';
import BreadCrum from '../../Components/BreadCrum';
import ChatNew from '../../Components/Chat2';
import AOS from 'aos';
import 'aos/dist/aos.css';
import aiBrain from '../../assets/AI/flame-brain-character-raising-its-arms-in-triumph.gif';
import aiBrain2 from '../../assets/AI/flame-uploading-files-to-cloud-storage.gif';
import aiBrain3 from '../../assets/AI/flame-blue-line.gif';
import A1 from '../../assets/openai.png';
import A2 from '../../assets/hugging-face.png';
import A3 from '../../assets/blue-prism-logo.png';
import A5 from '../../assets/langchain.png';
import A6 from '../../assets/nividia-nemo.png';
import A7 from '../../assets/pega.png';
import A8 from '../../assets/tensorflow.png';
import A9 from '../../assets/pytorch.png';
import A10 from '../../assets/keras.png';
import * as THREE from 'three';
import A11 from '../../assets/scikit.png';
import A12 from '../../assets/UiPath_Logo.png';
import cloud_img from '../../assets/ai-2.gif';
import rpa_gif from '../../assets/rpa4.gif';
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import FocusrAI from '../../assets/frfulllogoblue.png'
import FocusrAIArrow from '../../assets/focusrarrowai.png'
import ArrowIcon from "../../assets/Icons-lunacy/image37.png";
import IntelligenceIcon from '../../assets/intelligence (1).svg'
import './AI.css'
import GeminiIcon from '../../assets/gemini-icon-new.png'
import OpenAIIcon from '../../assets/OpenAI-new.png'
import ClaudeIcon from '../../assets/Claude Ai.png'
import LlamaIcon from '../../assets/Facebook Meta Icon.png'
import HuggingIcon from '../../assets/hugging-face.png'
import FineTuning from '../aiservices/finetuning';
import SeamlessIntegration from '../aiservices/seamlessIntegration';
import DataProtection from '../aiservices/dataprotection';
import UnstructuredData from '../aiservices/unstructureddata';
 
 
const AI = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [navBarMonitor, setnavBarMonitor] = useState(false);
  const [email, setEmail] = useState('');
  const [about, setAbout] = useState('');
  const [hover, setHover] = useState(false);
  const [navbarStatus, setnavbarStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const vantaRef = useRef(null);
  const navigate = useNavigate();
 
  const showDrawer = () => {
    setOpen(true);
  };
 
  const onClose = () => {
    setOpen(false);
  };
 
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (e.isTrusted && window.scrollY > 0) {
        setnavbarStatus(true);
        setHover(true);
      } else {
        setnavbarStatus(false);
        setHover(false);
      }
    });
    console.log("Window y position", window.scrollY);
    console.log("windowWidth", window.innerWidth);
    //"1px 1px 50px 1px #fff"
    if (window.innerWidth < 1150) {
      setnavBarMonitor(true);
    } else {
      setnavBarMonitor(false);
    }
    window.addEventListener("resize", (e) => {
      e.isTrusted && window.innerWidth < 1150
        ? setnavBarMonitor(true)
        : setnavBarMonitor(false);
    });
  }, [window.innerWidth]);
 
  const servicePaths = {
    LLMFramework: "/llmframework",
    FineTuning: "/finetuning",
    SeamlessIntegration: "/seamlessintegration",
    DataProtection: "/dataprotection",
    UnstructuredData: "/unstructureddata",
    CloudAI: "/cloudAI",
    RPAEUC: "/rpaeuc",
    Security: "/oracleSecurity",
    Optimization: "/optimization",
  };
 
  const navigateToService = (header) => {
    const path = servicePaths[header];
    if (path) {
      navigate(path);
    }
  };
 
  const items = [
    {
      key: "services",
      label: "Services",
      content: (
        <div>
          <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"30px"}}>
            <Element name="services" className="services-container">
              {[
                {
                  title: "Customized LLM Framework Design",
                  header: "LLMFramework",
                  text: "FocusR designs tailored Language Learning Models (LLMs) to meet your specific business needs. Our custom frameworks ensure your AI solutions are built on a strong foundation, aligned perfectly with your goals.",
                  icon: ArrowIcon
                },
                {
                  title: "Fine-Tuning Customized LLMs",
                  header: "FineTuning",
                  text: "We enhance the performance of your LLMs through expert fine-tuning, ensuring they are optimized for accuracy and relevance in real-world applications.",
                  icon: ArrowIcon
                },
                {
                  title: "Seamless Integration with Existing Systems",    
                  header: "SeamlessIntegration",            
                  text: "FocusR ensures smooth integration of AI solutions into your existing IT infrastructure, enhancing efficiency without disrupting your current operations.",
                  icon: ArrowIcon
                },
                {
                  title: "Data Protection from AI Usage",      
                  header: "DataProtection",          
                  text: "We implement robust data protection strategies, securing your information during AI usage with encryption, access controls, and regulatory compliance.",
                  icon: ArrowIcon
                },
                {
                  title: "AI-Based Application Development",                  
                  text: "FocusR develops cutting-edge applications powered by AI, delivering superior performance and user experience tailored to your business needs.",
                  icon: ArrowIcon
                },
                {
                  title: "Unstructured Data to Structured Data Processing",
                  header: "UnstructuredData",                
                  text: "We transform unstructured data into actionable insights, making it easier for you to analyze and utilize your information effectively.",
                  icon: ArrowIcon
                },
                {
                  title: "Cloud AI Infrastructure Implementation and Support",
                  header: "CloudAI",                
                  text: "FocusR offers scalable cloud AI infrastructure services, from setup to ongoing support, ensuring your systems are always performing at their best.",
                  icon: ArrowIcon
                },
                {
                  title: "RPA and EUC Implementation and Support with AI Capabilities",
                  header: "RPAEUC",
                  text: "We enhance your Robotic Process Automation and End-User Computing systems with AI capabilities, enabling smarter automation, reducing errors, and increasing overall efficiency across your business processes.",
                  icon: ArrowIcon
                },
              ].map((service, index) => (
                <div className="service-card" key={index}>
                 
                  <h3>{service.title}</h3>
                  <p>{service.text}</p>
                  <img
                className="uparrow-icon"
                src={service.icon}
                alt="alt"
                onClick={() => navigateToService(service.header)}
              />
                </div>
              ))}
            </Element>
          </div>
        </div>
      ),
    },
  ];
 
 
  useEffect(() => {
    const vantaEffect = CLOUDS({
      el: vantaRef.current,
      THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      highlightColor: "#ffffff",
      midtoneColor: "#79f2fa",
      baseColor: "#ffebeb",
      blurFactor: 0.75,
      zoom: 0.2,
      speed: 8          
    });
 
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);
 
  useEffect(() => {
    document.title = 'Services | AI';
    AOS.init();
  }, []);
 
  const handleSubmit = () => {
    // Handle form submission logic here
  };
 
  const isMobile = window.innerWidth < 768; // Example mobile check
  const isTablet = window.innerWidth <= 1024 && window.innerWidth > 768; // Example tablet check
 
  const arrayOfImages = [
    { imageUrl: A1, alt: 'Logo-A-Series' },
    { imageUrl: A2, alt: 'Logo-A-Series' },
    { imageUrl: A3, alt: 'Logo-A-Series' },
    { imageUrl: A5, alt: 'Logo-A-Series' },
    { imageUrl: A6, alt: 'Logo-A-Series' },
    { imageUrl: A12, alt: 'Logo-A-Series' }
  ];
 
  const arrayOfImages1 = [
    {
      imageUrl: GeminiIcon,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: OpenAIIcon,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: ClaudeIcon,
      alt: 'Logo-A-Series'
    },
 
    {
      imageUrl: LlamaIcon,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: HuggingIcon,
      alt: 'Logo-A-Series'
    },
  ];
 
  const arrayOfImages2 = [
    { imageUrl: A7, alt: 'Logo-A-Series' },
    { imageUrl: A8, alt: 'Logo-A-Series' },
    { imageUrl: A9, alt: 'Logo-A-Series' },
    { imageUrl: A10, alt: 'Logo-A-Series' },
    { imageUrl: A11, alt: 'Logo-A-Series' }
  ];
 
  const items2 = [
    {
      key: "1",
      label: <span className="drawer-antd-btn1">Gen AI</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/llmframework");
              onClose();
            }}
          >
            LLM Framework
          </li>
          <li
            onClick={() => {
              navigate("/finetuning");
              onClose();
            }}
          >
            Fine-Tuning
          </li>
        </ul>
      ),
    },
    {
      key: "3",
      label: <span className="drawer-antd-btn1">ERP</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/seamlessintegration");
              onClose();
            }}
          >
            Integration
          </li>
          <li
            onClick={() => {
              navigate("/dataprotection");
              onClose();
            }}
          >
            Data Protection
          </li>
          <li
            onClick={() => {
              navigate("/unstructureddata");
              onClose();
            }}
          >
            Data Processing
          </li>
          <li
            onClick={() => {
              navigate("/cloudai");
              onClose();
            }}
          >
            Cloud AI
          </li>
          <li
            onClick={() => {
              navigate("/rpaeuc");
              onClose();
            }}
          >
            RPA
          </li>
        </ul>
      ),
    },
    {
      key: "4",
      label: <span className="drawer-antd-btn1">AI APP</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/products/HireCentral");
              onClose();
            }}
          >
            Hireflow
          </li>
        </ul>
      ),
    },
    {
      key: "5",
      label: <span className="drawer-antd-btn1">Analytics</span>,
      children: (
        <ul className="inner-accord">
          <li
            onClick={() => {
              navigate("/powerbianalytics");
              onClose();
            }}
          >
            Power BI
          </li>
          <li
            onClick={() => {
              navigate("/oraclesapanalytics");
              onClose();
            }}
          >
            Oracle and SAP
          </li>
          <li
            onClick={() => {
              navigate("/googleanalytics");
              onClose();
            }}
          >
            Google
          </li>
        </ul>
      ),
    },
  ];
 
  return (
    <div>
      {/* <Navbar mode="dark" /> */}
      {navBarMonitor ? (
          <div className="navbar2" style={{ backgroundColor: "#fff" }}>
            <button className="hamburger-cus" onClick={showDrawer}>
              <HiMenuAlt1 style={{ width: "3em", height: "3em" }} />
            </button>
            <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
          </div>
        ) : (
          <Navbar mode="dark" />
        )}
        <Drawer
          title={
            <div className="logo-small">
              <img
                src={focusRlogoRight}
                width="130em"
                alt="logo"
                style={{
                  marginTop: "0", // Adjust margin as needed
          position: "relative", // Change to relative if absolute positioning is not necessary
          marginLeft: "auto",
          marginRight: "auto",
          display: "block", // Center the image within the container
          maxWidth: "100%", // Ensure the image scales appropriately
          height: "auto",
          cursor: "pointer",
                }}
              />
            </div>
          }
          placement="top"
          onClose={onClose}
          open={open}
          height="100%"
        >
          <button
            className="drawer-antd-btn"
            onClick={() => {
              navigate("/");
              onClose();
            }}
          >
            Home
          </button>
          <Collapse ghost items={items2} expandIconPosition="right" />
        </Drawer>
      <ChatNew />
      <div ref={vantaRef} className="vanta-bg-ai">
        <div className="welcome-ai-page-2">
          <div className='welcome-ai-page-2-header'>
            <div className='welcome-ai-page-2-wrap' style={{width: isMobile ? "": isTablet? '': "90%"}}>
              <div className='welcome-ai-page-2-title' onClick={() => navigate('/services/AI')} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <img src={FocusrAIArrow} width="auto" height="45px"/>
                <img src={IntelligenceIcon} width="150px" style={{marginLeft: '15px'}} />
              </div>
              <div className='welcome-ai-page-2-navlinks'>
                <div className='welcome-ai-page-2-menu' style={{height: 'auto', top: 'auto', display: 'block'}}>
                <span className='menu-item'>Gen AI<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/llmframework')} className="dropdown-item">LLM Framework</Link>
      <Link onClick={() => navigate('/finetuning')} className="dropdown-item">Fine-tuning</Link>
    </div>
  </span>
  <span className='menu-item'>ERP<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/seamlessintegration')} className="dropdown-item">Integration</Link>
      <Link onClick={() => navigate('/dataprotection')} className="dropdown-item">Data Protection</Link>
      <Link onClick={() => navigate('/unstructureddata')} className="dropdown-item">Data Processing</Link>
      <Link onClick={() => navigate('/cloudai')} className="dropdown-item">Cloud AI</Link>
      <Link onClick={() => navigate('/rpaeuc')} className="dropdown-item">RPA</Link>
    </div>
  </span>
  <span className='menu-item'>AI APP<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/products/HireCentral')} className="dropdown-item">Hireflow</Link>
    </div>
  </span>
  <span className='menu-item'>Analytics<MdKeyboardArrowDown/>
    <div className="dropdown">
      <Link onClick={() => navigate('/powerbianalytics')} className="dropdown-item">Power BI</Link>
      <Link onClick={() => navigate('/oraclesapanalytics')} className="dropdown-item">Oracle and SAP</Link>
      <Link onClick={() => navigate('/googleanalytics')} className="dropdown-item">Google</Link>
    </div>
  </span>
                  {/* <span>Pricing</span>
                  <span>Solutions <MdKeyboardArrowDown/></span>
                  <span>Customers</span>
                  <span>Resources</span> */}
                </div>
                <a href='#aiform' style={{display : isMobile? 'none' : 'flex', backgroundColor: '#E42527', minWidth: '100px',marginLeft: '10px', justifyContent: 'center', borderRadius: '30px', padding: '7px 20px', color: 'white'}}>
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className='welcome-ai-page-container' style={{  paddingTop: "5em" }}>
            <div className='welcome-ai-page-left-section'>
              <h1 style={{ fontSize: "70px", fontWeight: 'normal' }}>
                AI-Powered Enterprise
              </h1>
              <p className="hell-p" style={{ color: '#343434', fontSize: "30px", textAlign:'center', width: '100%' }}>
                Transform your business with FocusR's AI-powered ERP Services. Optimize operations and unlock data-driven insights for the future. FocusR—where AI meets ERP excellence.
              </p>
            </div>
            <div className='welcome-ai-page-right-section' id='aiform'>
            <h2 style={{ marginTop: "-1em", fontSize: "1.2rem", marginBottom: "1em", color: "#000", textAlign: "center" }}>
            Try FocusR AI Services
          </h2>
          <form className="custome-ai-form">
            <input
              type="text"
              placeholder="Your Name"
              className="custome-ai-form input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Your Email"
              className="custome-ai-form input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              placeholder="Please tell us how we can assist you with AI..."
              style={{ marginBottom: "1em" }}
              className="custome-ai-form textarea"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
            ></textarea>
            <button onClick={handleSubmit} loading={loading} type="button">
              {loading ? "Loading" : "HAVE A CALL WITH EXPERTS"}
            </button>
          </form>
          <div className="custome-contact-options-ai">
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
              <p style={{ color: "#4E4E4E", marginRight: "1em", marginBottom: "10px" }}>
                or contact us in
              </p>
              <a
                href="https://www.facebook.com/focusrtechnologies/"
                style={{
                  marginRight: "1em",
                  marginTop: "-7px",
                  fontSize: "23px",
                  color: "#1877F2"
                }}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <FaFacebook />
              </a>
              <a
                href="https://www.linkedin.com/company/focusr-consultancy-and-technologies-pvt-ltd/"
                style={{ marginTop: "-6.7px", fontSize: "29px", color: '#0077B5' }}
                target="_blank"
                rel="noreferrer"
              >
                <TiSocialLinkedinCircular />
              </a>
            </div>
          </div>
        </div>
        </div>
      </div>
           
        </div>
          <div className="new-logo-container">
            {arrayOfImages.map((x, i) => (
              <img key={i} src={x.imageUrl} alt={x.alt} />
            ))}
          </div>
          <div className="new-logo-container">
            {arrayOfImages2.map((x, i) => (
              <img key={i} src={x.imageUrl} alt={x.alt} />
            ))}
          </div>
          <div className="tabs-container-ai">
          {items.map((item) => (
            <div style={{width:"100%", display:"flex", justifyContent:"center"}} key={item.key}>{item.content}</div>
          ))}
        </div >
          {/* <center >
            <section className="center-ai-div"></section>
          </center> */}
          <br />
          <br />
          <br />
          {/* <div className="corevalue-ai">
            <p style={{ color: '#fff' }}>
              <span className="corevalue-span-ai">FocusR Custom AI</span>,
              Model Development, AI Model Training and Tuning, Scalable Model Deployment, AI Model Monitoring and
              Maintenance, Edge AI Model Optimization, AI Model Bias and Fairness Mitigation, Model Selection and Evaluation
              and AI Model Deployment Automation (MLOps)
              <br />
              for a <span className="corevalue-span-ai">fast-changing world.</span>
            </p>
          </div> */}
          <div className="ai-new-div-1-wrapper">
            <div className="ai-new-div-1-wrapper-left">
              <img src={cloud_img} alt="cloud" width="100%" />
            </div>
            <div className="ai-new-div-1-wrapper-right">
              <h1>Why FocusR AI!</h1>
              <p>
                Are you ready to transform your industry? With FocusR's AI solutions, you're in for a game-changer! Say
                goodbye to productivity woes, and hello to data-driven decision-making and sustainable growth. Our tailored
                strategies and next-gen tech ensure tangible results. Count on us to optimize efficiency, unlock
                opportunities, and steer you towards success!
              </p>
            </div>
          </div>
          <div className="ai-new-div-1-wrapper">
            <div className="ai-new-div-1-wrapper-right">
            <h1>Why FocusR RPA!</h1>
              <p>
                At FocusR, we're all about simplifying your business with top-notch Robotic Process Automation (RPA)
                services. We use tools like UIpath, Power Automate, and Pega to automate tasks and boost efficiency. Our
                team customizes solutions to fit your needs, whether it's speeding up data entry or enhancing customer
                service. Let us handle the boring stuff so you can focus on what matters most!
              </p>
            </div>
            <div
              className="ai-new-div-1-wrapper-left"
              style={{ backgroundColor: '#fff' }}
            >
              <img className="ai-new-img" src={rpa_gif} alt="RPA" width="100%" />
            </div>
          </div>
          <div className="llmframework-feature1">
      <div className="anim-init" style={{ color: '#0067ff', textAlign: 'center', fontSize: isMobile? '35px': '60px' }}>
          LLMS we work with
        </div>
      <div className="new-logo-container" data-aos="fade-right"
        data-aos-delay="200">
          {arrayOfImages1.map((x, i) => {
            return <img src={x.imageUrl} alt={x.alt} />;
          })}
        </div>
        </div>
        <div className="feature1-main" style={{maxWidth: '1140px', margin: '130px auto 0', position: 'relative'}}>
          <div className="feature1-dots"></div>
          <div style={{background: 'white'}}>
      <div style={{ color: '#0067ff', textAlign: 'center', fontSize:isMobile? '30px': '60px', marginBottom: '1em' }}>
        Other LLMs that can be integrated
      </div>
      <div className="llmframework-feature2">
        <div className="feature2-table">
          <ul className="summary_lst">
            <li style={{ minHeight: '60px' }}>
              <span>AI21 Jur</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Aleph Alpha Luminous</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Baichuan LLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>ChatGLM-6B</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>GigaChat</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>GPT4All</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>IBM Watsonx.ai</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>KoboldAI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Moonshot LLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>MosaicML</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>NLP Cloud</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>OctoAI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>OpenLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Petals</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>SambaNova Sambaverse</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>SparkLLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Symbl.ai Nebula</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Together AI</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Tongyi Qwen</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>vLLM</span>
            </li>
            <li style={{ minHeight: '60px' }}>
              <span>Volc Engine Maas</span>
            </li>
          </ul>
        </div>
      </div>
      </div>
      </div>
      <div className="features-vectorbg">
        <div style={{ color: '#0067ff', textAlign: 'center', fontSize: '60px', marginBottom: '1em', marginTop: '1.5em' }}>
          <span style={{position: 'relative', top: '30px', fontSize: isMobile ? '26px' : ''}}>Vector stores we can work with</span>
        </div>
        <div className="llmframework-feature2" style={{borderRadius: '30px'}}>
          <div className="feature2-table" >
            <ul className="summary_lst">
              <li style={{ minHeight: '60px' }}><span>Activeloop Deep Lake</span></li>
              <li style={{ minHeight: '60px' }}><span>Aerospike Vector Search (AVS)</span></li>
              <li style={{ minHeight: '60px' }}><span>Alibaba Cloud OpenSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>ApertureDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Astra DB Vector Store</span></li>
              <li style={{ minHeight: '60px' }}><span>Atlas</span></li>
              <li style={{ minHeight: '60px' }}><span>AwaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Azure AI Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Baidu Cloud ElasticSearch VectorSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Baidu VectorDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Chroma</span></li>
              <li style={{ minHeight: '60px' }}><span>Clarifai</span></li>
              <li style={{ minHeight: '60px' }}><span>DashVector</span></li>
              <li style={{ minHeight: '60px' }}><span>Databricks Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>DingoDB</span></li>
              <li style={{ minHeight: '60px' }}><span>DocArray HnswSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>DocArray InMemorySearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Elasticsearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Epsilla</span></li>
              <li style={{ minHeight: '60px' }}><span>Faiss</span></li>
              <li style={{ minHeight: '60px' }}><span>Google BigQuery Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Google Vertex AI Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Hippo</span></li>
              <li style={{ minHeight: '60px' }}><span>Jaguar Vector Database</span></li>
              <li style={{ minHeight: '60px' }}><span>KDB.AI</span></li>
              <li style={{ minHeight: '60px' }}><span>Kinetica</span></li>
              <li style={{ minHeight: '60px' }}><span>LanceDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Lantern</span></li>
              <li style={{ minHeight: '60px' }}><span>ManticoreSearch VectorStore</span></li>
              <li style={{ minHeight: '60px' }}><span>Milvus</span></li>
              <li style={{ minHeight: '60px' }}><span>Momento Vector Index (MVI)</span></li>
              <li style={{ minHeight: '60px' }}><span>MongoDB Atlas Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>Neo4j Vector Index</span></li>
              <li style={{ minHeight: '60px' }}><span>NucliaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>OpenSearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Oracle AI Vector Search</span></li>
              <li style={{ minHeight: '60px' }}><span>PGVector</span></li>
              <li style={{ minHeight: '60px' }}><span>Pinecone</span></li>
              <li style={{ minHeight: '60px' }}><span>Qdrant</span></li>
              <li style={{ minHeight: '60px' }}><span>Redis</span></li>
              <li style={{ minHeight: '60px' }}><span>SAP HANA Cloud Vector Engine</span></li>
              <li style={{ minHeight: '60px' }}><span>ScaNN</span></li>
              <li style={{ minHeight: '60px' }}><span>SemaDB</span></li>
              <li style={{ minHeight: '60px' }}><span>SingleStoreDB</span></li>
              <li style={{ minHeight: '60px' }}><span>SQLite-VSS</span></li>
              <li style={{ minHeight: '60px' }}><span>SurrealDB</span></li>
              <li style={{ minHeight: '60px' }}><span>Tencent Cloud VectorDB</span></li>
              <li style={{ minHeight: '60px' }}><span>ThirdAI NeuralDB</span></li>
              <li style={{ minHeight: '60px' }}><span>TiDB Vector</span></li>
              <li style={{ minHeight: '60px' }}><span>Timescale Vector (Postgres)</span></li>
              <li style={{ minHeight: '60px' }}><span>Upstash Vector</span></li>
              <li style={{ minHeight: '60px' }}><span>USearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Vald</span></li>
              <li style={{ minHeight: '60px' }}><span>Vearch</span></li>
              <li style={{ minHeight: '60px' }}><span>Vectara</span></li>
              <li style={{ minHeight: '60px' }}><span>Vespa</span></li>
              <li style={{ minHeight: '60px' }}><span>viking DB</span></li>
              <li style={{ minHeight: '60px' }}><span>vlite</span></li>
              <li style={{ minHeight: '60px' }}><span>Weaviate</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '150px'}}>
      <img src={FocusrAIArrow} width="auto" height="45px"/>
      <img src={IntelligenceIcon} width="150px" style={{marginLeft: '15px'}} />
      </div>
          <Footer />
       
    </div>
  );
};
 
export default AI;