/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const Consulting = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Oracle E-Business Suite (EBS) Implementation ",

      details: [
        "Comprehensive Deployment: FocusR deploys Oracle E-Business Suite (EBS) to streamline and automate business processes. Services include meticulous planning, installation, configuration, and customization tailored to business needs, ensuring seamless integration with existing systems.",
        "Custom Module Developmen: Identifying business objectives and aligning them with Oracle technology capabilities.",
        "Data Migration and Management: Smooth transitions from legacy systems to Oracle EBS are ensured by managing data migration with precision. Experts handle data extraction, transformation, and loading (ETL), maintaining data integrity and minimizing downtime. ",
        "Integration with Third-Party Applications: Specialization in integrating Oracle EBS with third-party applications ensures a unified and efficient IT ecosystem. Integration services include developing and implementing APIs, connectors, and middleware solutions to facilitate seamless data flow and process automation",
        "Post-Implementation Support and Optimization: Commitment extends beyond implementation with ongoing support and optimization services. Monitoring of the Oracle EBS environment, regular performance tuning, and proactive maintenance ensure optimal system performance. ",
      ],
    },
    {
      title: "Oracle Fusion Middleware Implementation ",

      details: [
        "Middleware Architecture Design: Robust Oracle Fusion Middleware architectures are designed to provide a scalable and flexible foundation for IT infrastructure. Ensuring the middleware layer supports seamless integration, high availability, and optimal performance across all applications.",
        "Service-Oriented Architecture (SOA) Integration: Implementation of Oracle SOA Suite enables service-oriented architecture within the organization. Integration of disparate systems and applications facilitates efficient communication and interoperability, enhancing business agility and responsiveness.",
        "Business Process Management (BPM) Implementation: Leveraging Oracle BPM Suite to automate and optimize business processes. BPM solutions are designed and implemented to align with business objectives, improving process efficiency and visibility across the organization",
        "Identity and Access Management (IAM): Oracle Identity and Access Management solutions are implemented to enhance security and compliance. Services include user provisioning, single sign-on (SSO), and role-based access control (RBAC) to protect sensitive data and applications. ",
        "Enterprise Service Bus (ESB) Configuration: Configuration of Oracle Service Bus (OSB) facilitates efficient message brokering and service orchestration. ESB solutions enable reliable communication between different applications and services, ensuring smooth and coordinated operations. ",
      ],
    },
    {
      title: "Oracle Cloud Infrastructure (OCI) Implementation ",

      details: [
        "Cloud Readiness Assessment: Thorough assessments are conducted to evaluate readiness for Oracle Cloud Infrastructure (OCI) adoption. Analysis covers the current IT landscape, identifies potential challenges, and provides strategic recommendations for a successful migration",
        "Infrastructure as a Service (IaaS) Deployment: OCI's Infrastructure as a Service (IaaS) offerings are deployed, providing scalable and secure computing resources. Provisioning, configuration, and management of virtual machines, storage, and networking components meet specific requirements",
        "Platform as a Service (PaaS) Integration: Oracle PaaS solutions are integrated to extend the capabilities of the cloud infrastructure. Services include deploying and configuring Oracle databases, application development platforms, and integration services to support business operations.",
        "Data Security and Compliance: Robust security measures are implemented to protect data in Oracle Cloud. Services include encryption, identity management, access control, and compliance monitoring to ensure the cloud environment meets regulatory requirements and industry standards. ",
        "Disaster Recovery and Business Continuity: Disaster recovery and business continuity solutions are designed and implemented in OCI. Strategies include data backup, replication, and automated failover processes to minimize downtime and ensure uninterrupted business operations. ",
      ],
    },
    {
      title: "Oracle Fusion Applications Implementation ",

      details: [
        "Human Capital Management (HCM) Implementation: Oracle Fusion HCM is deployed to streamline HR processes and enhance workforce management. Services cover the full implementation lifecycle, including configuration, data migration, and integration with other business applications. ",
        "Financial Management Implementation: Oracle Fusion Financials is implemented to improve financial operations and reporting. Modules for general ledger, accounts payable, accounts receivable, and asset management are configured, ensuring accurate and timely financial information. ",
        "Supply Chain Management (SCM) Implementation: Oracle Fusion SCM solutions are deployed to optimize supply chain processes. Implementation services include demand planning, inventory management, procurement, and order fulfillment, enabling efficient and responsive supply chain operations.",
        "Customer Relationship Management (CRM) Integration:Oracle Fusion CRM is integrated to enhance customer engagement and sales processes. Modules for sales automation, marketing, customer service, and analytics are configured, providing a comprehensive view of customer interactions and opportunities.",
        "Project Portfolio Management (PPM) Deployment: Oracle Fusion PPM is implemented to manage project portfolios effectively. Services include configuring project management, resource planning, budgeting, and performance tracking modules to ensure successful project execution and delivery.",
      ],
    },

    {
      title: "Oracle Database and Middleware Integration ",
      // description:
      //   "We provide comprehensive change management support and training to ensure smooth transitions to new Oracle solutions. Our customized training programs equip your teams with the necessary skills and knowledge, facilitating effective adoption and utilization of Oracle technologies.",
      details: [
        "Database Design and Implementation: Oracle databases are designed and implemented to support business applications and data management needs. Services include schema design, database installation, configuration, and performance tuning to ensure high availability and optimal performance. ",
        "Middleware Integration Solutions: Oracle databases are integrated with middleware solutions to enhance data accessibility and interoperability. Implementation of Oracle GoldenGate, Oracle Data Integrator (ODI), and other integration tools facilitates real-time data synchronization and transformation. Oracle databases are integrated with middleware solutions to enhance data accessibility and interoperability. Implementation of Oracle GoldenGate, Oracle Data Integrator (ODI), and other integration tools facilitates real-time data synchronization and transformation.",
        "High Availability and Disaster Recovery:High availability (HA) and disaster recovery (DR) solutions are implemented for Oracle databases. Services include configuring Oracle Data Guard, Real Application Clusters (RAC), and backup and recovery solutions to ensure data resilience and business continuity.",
        "Database Security and Compliance: Security of Oracle databases is enhanced through comprehensive security measures. Services include encryption, access control, auditing, and compliance monitoring to protect sensitive data and meet regulatory requirements",
        "Performance Monitoring and Optimization: Ongoing performance monitoring and optimization services are provided for Oracle databases. Advanced tools and techniques are used to analyze database performance, identify bottlenecks, and implement tuning strategies to ensure optimal performance. ",
      ],
    },
    {
      title: "Oracle Autonomous Database Implementation ",
      // description:
      //   "We offer expert advice and hands-on assistance to optimize the performance of your Oracle EBS and Fusion systems. This includes tuning databases, applications, and middleware to ensure high availability, responsiveness, and efficient resource utilization.",
      details: [
        "Autonomous Database Deployment: Oracle Autonomous Database is implemented, leveraging its self-driving, self-securing, and self-repairing capabilities. Services include provisioning, configuring, and optimizing Autonomous Data Warehouse (ADW) and Autonomous Transaction Processing (ATP) to meet specific data management needs",
        "Data Integration and ETL:Data from various sources is integrated into the Oracle Autonomous Database. Oracle Data Integrator (ODI) and other ETL tools are used to streamline data loading, transformation, and synchronization, ensuring data accuracy and consistency",
        "AI and Machine Learning Integration: Advanced analytics are enabled by integrating AI and machine learning capabilities with Oracle Autonomous Database. Oracle Machine Learning (OML) is implemented to develop predictive models and automate data analysis, driving data-driven decision-making. ",
        "Security and Compliance: The security of autonomous databases is enhanced through comprehensive security measures. Services include data encryption, user access management, and continuous monitoring to ensure compliance with industry standards and regulatory requirements",
        "Performance Optimization and Monitoring: Ongoing performance monitoring and optimization are provided for Oracle Autonomous Database. Advanced analytics and monitoring tools are used to ensure high performance, scalability, and availability, maximizing the value of the autonomous database environment.",
      ],
    },
    {
      title: "Oracle Analytics Cloud Implementation ",
      // description:
      //   "We offer expert advice and hands-on assistance to optimize the performance of your Oracle EBS and Fusion systems. This includes tuning databases, applications, and middleware to ensure high availability, responsiveness, and efficient resource utilization.",
      details: [
        "Analytics Cloud Deployment: Oracle Analytics Cloud (OAC) is deployed to empower organizations with robust data analytics capabilities. Services include provisioning, configuring, and optimizing OAC to deliver powerful business intelligence and data visualization solutions",
        "Data Modeling and Preparation: Data models tailored to business needs are designed and implemented. Oracle Data Visualization and Oracle BI Publisher are used to prepare and transform data, creating a solid foundation for accurate and insightful analysis",
        "Advanced Analytics and Reporting: Advanced analytics solutions are developed using Oracle Analytics Cloud. Interactive dashboards, reports, and visualizations are created, enabling deeper insights into data and informed decision-making. ",
        "Integration with Data Sources: Oracle Analytics Cloud is integrated with various data sources, including Oracle databases, cloud applications, and third-party systems. Seamless data flow and connectivity are ensured, providing a unified view of business data.",
        "Security and Governance: Robust security and governance measures are implemented for Oracle Analytics Cloud. Services include user access control, data encryption, and compliance monitoring, ensuring the security and integrity of the analytics environment. ",
      ],
    },
    {
      title: "Oracle Internet of Things (IoT) Cloud Implementation ",
      // description:
      //   "We offer expert advice and hands-on assistance to optimize the performance of your Oracle EBS and Fusion systems. This includes tuning databases, applications, and middleware to ensure high availability, responsiveness, and efficient resource utilization.",
      details: [
        "IoT Cloud Service Deployment: Oracle IoT Cloud Service is implemented to connect and manage IoT devices. Services include provisioning, configuring, and optimizing IoT cloud environments, enabling real-time data collection and analysis from connected devices. ",
        "Device Integration and Management: A wide range of IoT devices are integrated with Oracle IoT Cloud Service. Device onboarding, configuration, and management are handled, ensuring seamless connectivity and communication between devices and the cloud. ",
        "Data Ingestion and Processing: Fine-tuning Oracle EBS and Fusion applications for better responsiveness and efficiency.",
        "Predictive Maintenance and Analytics: Predictive maintenance solutions are developed using Oracle IoT Cloud Service. By analyzing device data and applying machine learning algorithms, equipment failures are predicted, maintenance schedules are optimized, and operational downtime is reduced.",
        "Security and Compliance: The security of IoT infrastructure is ensured with comprehensive measures. Services include device authentication, data encryption, and continuous monitoring to protect against threats and ensure compliance with industry standards.",
      ],
    },
    {
      title: "Oracle Blockchain Platform Implementation ",
      // description:
      //   "We offer expert advice and hands-on assistance to optimize the performance of your Oracle EBS and Fusion systems. This includes tuning databases, applications, and middleware to ensure high availability, responsiveness, and efficient resource utilization.",
      details: [
        "Blockchain Platform Deployment: Oracle Blockchain Platform is deployed to enable secure, transparent, and tamper-proof transactions. Services include provisioning, configuring, and optimizing the blockchain environment to support business processes and applications.",
        "Smart Contract Development: Smart contracts are developed and implemented on the Oracle Blockchain Platform. Custom contracts tailored to specific use cases are designed, automating business processes and ensuring contract execution without intermediaries. ",
        "Integration with Enterprise Systems: Oracle Blockchain Platform is integrated with existing enterprise systems. APIs and connectors are developed to facilitate seamless data exchange between blockchain and other business applications, enhancing data integrity and transparency",
        "Consortium and Governance Setup: Assistance is provided in setting up consortiums and governance structures for blockchain networks. Roles, permissions, and consensus mechanisms are defined, ensuring a secure and well-governed blockchain environment",
        "Security and Compliance: Robust security measures are implemented for Oracle Blockchain Platform. Services include identity management, access control, and encryption, ensuring the integrity and confidentiality of blockchain transactions and data. ",
      ],
    },
    {
      title: "Oracle Enterprise Performance Management (EPM) Implementation  ",
      // description:
      //   "We offer expert advice and hands-on assistance to optimize the performance of your Oracle EBS and Fusion systems. This includes tuning databases, applications, and middleware to ensure high availability, responsiveness, and efficient resource utilization.",
      details: [
        "EPM Cloud Deployment: Oracle Enterprise Performance Management (EPM) Cloud is implemented to streamline financial and operational planning processes. Services include provisioning, configuring, and optimizing EPM applications to support budgeting, forecasting, and reporting.",
        "Financial Planning and Budgeting: Oracle Planning and Budgeting Cloud Service (PBCS) is deployed to enhance financial planning capabilities. Models for budgeting, forecasting, and scenario analysis are designed and implemented, enabling accurate and strategic financial management. ",
        "Consolidation and Close: Oracle Financial Consolidation and Close Cloud Service (FCCS) is implemented to simplify and accelerate the financial close process. Consolidation rules, intercompany eliminations, and reporting frameworks are configured to ensure timely and accurate financial reporting.",
        "Profitability and Cost Management: Oracle Profitability and Cost Management Cloud Service (PCMCS) is deployed to analyze and optimize cost structures and profitability. Allocation models, cost drivers, and profitability analysis tools are configured to provide insights into financial performance.",
        "Strategic Workforce Planning: Oracle Strategic Workforce Planning Cloud Service is implemented to align the workforce with business objectives. Workforce planning models are designed and configured, enabling forecasting of headcount, analysis of skills gaps, and development of talent strategies.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
            }}
          >
            Implement Oracle with FocusR
          </h1>
          <p>
            FocusR specializes in empowering businesses with comprehensive
            Oracle solutions, from implementation and integration to cloud
            migration and advanced analytics. Our expert services ensure
            seamless operations, optimized performance, and strategic growth
            leveraging Oracle technologies.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Consulting;
