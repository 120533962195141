/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
import ChatNew from "../../Components/Chat2";
import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const Consulting = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Oracle E-Business Suite (EBS) Security Assessments",
      //   description:
      //     "Our Oracle E-Business Suite training programs cover a broad spectrum of modules and functionalities, aiming to equip your team with in-depth knowledge and hands-on experience.",
      details: [
        "Comprehensive Vulnerability Assessment: We begin with a comprehensive vulnerability scan of your Oracle EBS environment using industry-leading security tools. This involves identifying both known and unknown vulnerabilities such as SQL injections, cross-site scripting, and configuration flaws. Utilizing Oracle Enterprise Manager and third-party security tools, we assess your system's security posture.",
        "Penetration Testing: Our security experts conduct rigorous penetration tests to actively exploit identified vulnerabilities. This simulation of real-world attacks helps in understanding the potential consequences and prioritizing the remediation efforts. We utilize both automated tools and manual methods to ensure thorough coverage.",
        "Configuration Audits: We perform a detailed review of the Oracle EBS system configurations against best practices and industry standards. This includes evaluating database configurations, application settings, and network security configurations. Tools like Oracle Configuration Manager (OCM) are employed to benchmark settings against the recommended standards.",
        "Risk Assessment and Impact Analysis: By integrating the results of vulnerability assessments and penetration tests, we perform a risk assessment to identify the critical assets and quantify potential impacts. We use frameworks such as NIST SP 800-30 and ISO/IEC 27005 to guide our risk assessment processes.",
        "Actionable Reports and Recommendations: Finally, we provide a detailed security assessment report, comprising a summary of findings, risk ratings, and actionable recommendations. Each finding includes technical details such as affected systems, likelihood, and potential impact, followed by prioritized remediation steps to enhance your Oracle EBS security posture.",
      ],
    },
    {
      title: "Oracle Fusion Middleware Security Controls Implementation",
      //   description:
      //     "Oracle Fusion Applications represent the next generation of Oracle's enterprise applications. Our training programs are designed to help your team transition smoothly and utilize Fusion's capabilities fully.",
      details: [
        "Identity and Access Management Configuration: We configure Oracle Identity Management (IDM) and Oracle Access Management (OAM) to secure user identities and manage access control policies effectively. This includes setting up user provisioning and de-provisioning processes, and integrating with directories like Oracle Internet Directory (OID) and Microsoft Active Directory (AD).",
        "Single Sign-On (SSO) Setup: Leveraging Oracle Access Manager (OAM) and Oracle Identity Federation (OIF), we set up Single Sign-On (SSO) to simplify user authentication across multiple Oracle Fusion Middleware applications. This involves configuring policy enforcement points (PEP), policy decision points (PDP), and integrating with enterprise SSO solutions.",
        "Role-Based Access Control (RBAC): We implement Role-Based Access Control (RBAC) policies to ensure that users have access only to the resources necessary for their roles. This involves defining roles, permissions, and role hierarchy in Oracle Fusion Middleware, along with configuring Oracle Authorization Policy Manager (APM) for fine-grained access control.",
        "Secure SOA Implementation: Oracle Web Services Manager (OWSM) is configured to enforce security policies on SOA components. We set up policies for message-level security, identity propagation, and service auditing to secure your SOA infrastructure.",
        "Automation of Security Tasks: Using Oracle Enterprise Manager, we automate routine security tasks such as user account reviews, policy compliance checks, and regular audits. This ensures continuous compliance and enhances operational efficiency.",
      ],
    },
    {
      title: "Data Encryption and Masking in Oracle Databases",
      //   description:
      //     "Our Oracle Database training focuses on both foundational and advanced topics, ensuring your team is well-versed in managing, securing, and optimizing Oracle databases.",
      details: [
        "Transparent Data Encryption (TDE) Setup: We enable Transparent Data Encryption (TDE) for encrypting sensitive data at rest within Oracle databases, including Oracle EBS and Fusion Applications databases. This involves setting up encryption keys and wallets, defining tablespaces to be encrypted, and configuring database parameters to enforce encryption.",
        "Data Redaction Configuration: Implementing Oracle Data Redaction to dynamically mask sensitive data in real-time as it is queried by unauthorized users. This involves defining redaction policies, specifying user or application roles that require redaction, and configuring the database to apply these policies without impacting the underlying data.",
        "Column-level Encryption: For enhanced security, we configure column-level encryption for specific sensitive data fields within your Oracle databases. Using Oracle Advanced Security, we define encryption algorithms (e.g., AES256) and set up database triggers to ensure that data is encrypted during insert and update operations.",
        "Key Management using Oracle Key Vault (OKV): We implement Oracle Key Vault (OKV) for centralized key management and encryption key lifecycle management. This involves setting up key vault servers, configuring communication between Oracle databases and OKV, and defining key rotation policies to maintain security over time.",
        "Masking Sensitive Data for Non-production Environments: Using Oracle Data Masking and Subsetting, we create anonymized datasets for use in development, testing, and QA environments. This involves defining masking formats and transformations, applying them to clone databases, and verifying that the masked data retains its usability for testing purposes.",
      ],
    },
    {
      title: "Continuous Compliance Monitoring and Auditing",
      //   description:
      //     "Our training programs for custom application development focus on leveraging Oracle's application development tools and platforms.",
      details: [
        "Database Activity Monitoring (DAM): We configure Oracle Audit Vault and Database Firewall (AVDF) to continuously monitor database activities. This involves setting up audit policies to capture specific actions such as logins, DML operations, and configuration changes. Alerts are configured to notify administrators of suspicious activities in real-time.",
        "Compliance Frameworks and Automated Audits: Using Oracle Enterprise Manager, we set up automated compliance frameworks to ensure adherence to regulations like GDPR, HIPAA, and PCI DSS. This includes defining compliance rules, scheduling regular audits, and generating reports that highlight compliance status and deviations.",
        "Real-time Threat Detection: We deploy Oracle Data Safe for real-time security assessments and alerts. This involves configuring security assessment baselines, continuous monitoring for deviations from the baseline, and setting up data discovery processes to identify and classify sensitive data.",
        "Audit Log Management: We configure Oracle Unified Audit to consolidate and manage audit logs across your Oracle EBS and Fusion environments. This includes defining custom audit policies, setting up log rotation and archiving, and ensuring that audit logs are securely stored and protected from tampering.",
        "Reporting and Remediation Guidance: Regular compliance reports are generated, summarizing audit findings, compliance status, and identified gaps. We provide detailed remediation guidance, including specific steps for addressing non-compliance issues. Custom dashboards are set up in Oracle Enterprise Manager to provide real-time compliance visibility.",
      ],
    },

    {
      title: "Regulatory Compliance and Security Training",
      //   description:
      //     "Our Oracle Analytics and Business Intelligence (BI) training programs are designed to help your team leverage Oracle’s analytical tools to gain insights and drive data-driven decision-making.",
      details: [
        "Customized Training Programs: We develop tailored training programs focused on regulatory compliance and Oracle security best practices. This involves creating custom training materials, interactive exercises, and real-world scenario simulations relevant to your Oracle EBS and Fusion environments.",
        "Secure Coding and Development Practices: Training sessions on secure coding practices are conducted for your development teams, covering topics such as input validation, output encoding, SQL injection prevention, and secure use of APIs. We utilize tools like Oracle Application Express (APEX) to demonstrate secure coding examples.",
        "Compliance Management Workshops: Workshops are provided to educate your compliance teams on effective compliance management using Oracle tools. This includes hands-on sessions on configuring compliance frameworks in Oracle Enterprise Manager, setting up automated audits, and interpreting compliance reports.",
        "Incident Response Planning: We offer training on incident response planning specific to Oracle environments. This involves creating and rehearsing response playbooks, configuring Oracle’s incident management tools, and conducting incident simulations to prepare your teams for potential security incidents.",
        "Continuous Learning and Development: To ensure ongoing competency, we provide access to a learning management system (LMS) with updated training modules, webinars, and technical resources. Regular knowledge assessment tests and feedback loops are integrated to track progress and identify areas for further improvement.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      <ChatNew />
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              textAlign: "center",
              width: "100%",
            }}
            // data-aos="fade-right"
            // data-aos-delay="200"
          >
            Security and Compliance Services
          </h1>
          <p style={{ fontSize: "18px" }}>
            FocusR provides comprehensive Security and Compliance Services for
            Oracle E-Business Suite (EBS) and Oracle Fusion Middleware. Our
            offerings include detailed security assessments, advanced security
            controls implementation, data encryption and masking, continuous
            compliance monitoring, and specialized training. By leveraging
            Oracle’s security features and best practices, we ensure your Oracle
            deployments are secure, compliant, and resilient against evolving
            threats. Trust FocusR Technologies to safeguard your critical assets
            and maintain regulatory compliance.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to Know More
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Consulting;
