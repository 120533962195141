/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
import ChatNew from "../../Components/Chat2";
import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const Consulting = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Strategic IT Roadmap Development",
      description:
        "We provide expert guidance in developing a strategic IT roadmap to align Oracle EBS and Fusion technologies with your business goals. Our consultants analyze your current IT landscape, identify opportunities for improvement, and create a phased implementation plan to achieve your desired outcomes",
      details: [
        "Current State Analysis: Assessing your existing IT infrastructure and Oracle deployments.",
        "Goal Setting: Identifying business objectives and aligning them with Oracle technology capabilities.",
        "Phased Implementation Plan: Creating a detailed, step-by-step roadmap for achieving strategic goals with Oracle EBS and Fusion.",
        "Risk Mitigation: Identifying potential risks and developing mitigation strategies.",
        "Performance Metrics: Establishing KPIs and performance metrics to track progress and success.",
      ],
    },
    {
      title: "Oracle Best Practices Implementation",
      description:
        "Our consultants help you apply Oracle best practices to optimize system performance and functionality. We review your existing setups, recommend improvements, and guide the implementation of industry-standard practices to enhance efficiency and reliability.",
      details: [
        "Configuration Review: Evaluating current Oracle EBS and Fusion configurations against best practices",
        "Optimization Recommendations: Providing tailored recommendations to improve performance and efficiency.",
        "Implementation Support: Assisting in the implementation of recommended changes and best practices.",
        "Process Standardization: Standardizing processes across Oracle deployments to ensure consistency and reliability.",
        "Continuous Improvement: Ongoing monitoring and refinement of practices to maintain optimal performance.",
      ],
    },
    {
      title: "Technology and System Audits",
      description:
        "We conduct thorough audits of your existing Oracle systems to identify performance bottlenecks, security vulnerabilities, and areas for improvement. Our detailed audit reports provide actionable recommendations to address identified issues and optimize system performance.",
      details: [
        "Configuration Review: Evaluating current Oracle EBS and Fusion configurations against best practices",
        "Performance Analysis: Identifying and addressing performance bottlenecks and inefficiencies.",
        "Security Assessments: Evaluating security measures and identifying vulnerabilities.",
        "Compliance Checks: Ensuring compliance with industry standards and regulations.",
        "Detailed Reports: Providing detailed audit reports with actionable recommendations for improvement.",
      ],
    },
    {
      title: "Customized Solution Architecture and Design",
      description:
        "We design and architect custom solutions that leverage Oracle EBS and Fusion technologies to meet your unique business requirements. Our team creates scalable, secure, and high-performance architectures tailored to your specific needs, ensuring seamless integration and optimal functionality.",
      details: [
        "Requirement Gathering: Understanding your specific business needs and objectives.",
        "Solution Design: Designing custom solutions that leverage Oracle EBS and Fusion capabilities.",
        "Architecture Planning: Creating scalable, secure, and high-performance architectural plans.",
        "Integration Strategy: Ensuring seamless integration with existing systems and third-party applications.",
        "Implementation Blueprint: Providing detailed blueprints and guidance for solution implementation.",
      ],
    },

    {
      title: "Change Management and Organizational Training",
      description:
        "We provide comprehensive change management support and training to ensure smooth transitions to new Oracle solutions. Our customized training programs equip your teams with the necessary skills and knowledge, facilitating effective adoption and utilization of Oracle technologies.",
      details: [
        "Change Management Planning: Developing strategies to manage organizational change and minimize disruption.",
        "Training Needs Assessment: Identifying training needs and creating tailored training plans.",
        "Customized Training Programs: Delivering onsite or remote training sessions, workshops, and learning programs.",
        "User Adoption Support: Supporting users throughout the transition to ensure successful adoption of new technologies.",
        "Ongoing Education: Providing continuous learning opportunities to keep your teams updated with the latest Oracle features and best practices.",
      ],
    },
    {
      title: "Performance Optimization and Tuning",
      description:
        "We offer expert advice and hands-on assistance to optimize the performance of your Oracle EBS and Fusion systems. This includes tuning databases, applications, and middleware to ensure high availability, responsiveness, and efficient resource utilization.",
      details: [
        "Performance Assessment: Conducting detailed assessments to identify performance bottlenecks in your Oracle environments.",
        "Database Tuning: Optimizing database configurations and queries for enhanced performance.",
        "Application Tuning: Fine-tuning Oracle EBS and Fusion applications for better responsiveness and efficiency.",
        "Middleware Optimization: Adjusting middleware settings to improve data processing and integration.",
        "Continuous Monitoring: Implementing monitoring tools and techniques to ensure sustained performance optimization.",
      ],
    },
    {
      title: "Regulatory Compliance and Risk Management",
      description:
        "Our consulting services include advising on regulatory compliance and risk management strategies to ensure your Oracle deployments meet industry standards and legal requirements. This includes conducting compliance assessments, implementing necessary controls, and providing ongoing monitoring and support.",
      details: [
        "Compliance Assessments: Evaluating your Oracle EBS and Fusion systems for compliance with industry regulations and standards.",
        "Risk Analysis: Identifying potential risks and vulnerabilities within your Oracle environments.",
        "Control Implementation: Developing and implementing controls to mitigate identified risks.",
        "Regulatory Guidance: Providing ongoing guidance on regulatory changes and their impact on your Oracle systems.",
        "Audit Support: Assisting with internal and external audits to ensure compliance and minimize risk.",
      ],
    },
    {
      title: "Cloud Strategy and Migration Planning",
      description:
        "We develop comprehensive cloud strategies and migration plans to transition your on-premises Oracle systems to Oracle Cloud. Our detailed assessments and roadmaps ensure a smooth and efficient migration, minimizing downtime and maximizing the benefits of cloud adoption.",
      details: [
        "Cloud Readiness Assessment: Evaluating your current IT environment for cloud readiness.",
        "Migration Strategy: Developing a detailed strategy for migrating Oracle EBS and Fusion systems to Oracle Cloud.",
        "Planning and Roadmaps: Creating step-by-step migration plans and roadmaps.",
        "Risk Mitigation: Identifying and addressing potential risks associated with cloud migration.",
        "Post-Migration Support: Providing ongoing support and optimization after migration to ensure cloud environments perform optimally.",
      ],
    },
    {
      title: "Data Governance and Management",
      description:
        "We provide consulting on data governance and management to ensure data integrity, security, and compliance within your Oracle environments. This includes developing data policies, implementing governance frameworks, and advising on best practices for data management and protection.",
      details: [
        "Data Policy Development: Creating comprehensive data governance policies tailored to your business needs.",
        "Governance Frameworks: Implementing frameworks to ensure data integrity and compliance.",
        "Data Security Measures: Advising on and implementing data security best practices.",
        "Compliance Monitoring: Setting up monitoring tools to ensure ongoing compliance with data governance policies.",
        "Data Quality Management: Implementing processes and tools to maintain high data quality and reliability.",
      ],
    },
    {
      title: "Continuous Improvement and Innovation",
      description:
        "Our continuous improvement and innovation services provide ongoing advisory support to ensure your Oracle EBS and Fusion systems remain current and competitive. Regular reviews and assessments identify opportunities to leverage new Oracle features and technologies for business growth and efficiency",
      details: [
        "Regular System Reviews: Conducting periodic reviews of your Oracle systems to identify improvement opportunities.",
        "Innovation Workshops: Hosting workshops to explore new Oracle features and their potential impact on your business.",
        "Feature Adoption: Advising on the adoption of new Oracle features and technologies.",
        "Performance Metrics: Establishing and tracking performance metrics to measure the impact of continuous improvement initiatives.",
        "Strategic Recommendations: Providing strategic recommendations for leveraging Oracle technologies to drive business growth.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      <ChatNew />
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
            }}
            // data-aos="fade-right"
            // data-aos-delay="200"
          >
            Comprehensive Consulting and Advisory Services for Oracle
            Technologies
          </h1>
          <p>
            Our Consulting and Advisory Services empower businesses to optimize
            their Oracle EBS and Fusion deployments. We provide expert guidance
            in strategic planning, performance optimization, compliance, cloud
            migration, and continuous improvement to achieve business
            objectives.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to Know More
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "16px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer className="consultingFooter" />
    </div>
  );
};
export default Consulting;
