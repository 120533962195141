import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const OnPremise = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Advanced Analytics and Reporting",
      description:
        "Leveraging Oracle BI integrated with Fusion Applications to deliver superior analytics and reporting capabilities:",

      details: [
        "OBIEE for Fusion Applications: Implement Oracle Business Intelligence Enterprise Edition (OBIEE) within Fusion environments to create comprehensive, real-time dashboards and reports. OBIEE utilizes a powerful semantic metadata layer that seamlessly integrates with Fusion transactional data, providing a unified view of performance metrics.",
        "Interactive Reporting with Oracle Data Visualization: Utilize Oracle Data Visualization Desktop to create dynamic, interactive reports that empower users to explore data visually. This integration allows data from Oracle E-Business Suite (EBS) and Fusion to be concurrently analyzed, providing holistic insights spanning multiple Oracle platforms.",
        "Pre-built Analytics: Deploy pre-built Fusion Analytics Warehouse (FAW) solutions that come equipped with out-of-the-box Key Performance Indicators (KPIs) and metrics specifically tailored for Oracle Fusion applications. These pre-configured components expedite deployment times and ensure rapid return on investment.",
        "Hyperion Integration: Incorporate Hyperion Planning and Essbase within Oracle BI to perform complex multi-dimensional data analysis, allowing businesses to perform advanced financial consolidation, budgeting, and forecasting by pulling data from both Fusion and EBS sources.",
        "Real-time Data Integration: Implement Oracle GoldenGate for real-time data replication between Oracle EBS and Fusion, enabling up-to-the-minute analytics and reporting capabilities without impacting system performance.",
      ],
    },
    {
      title: "Data Visualization and User Experience (UX)",
      description:
        "Enhancing user engagement through sophisticated data visualizations and intuitive interfaces:",
      details: [
        "Oracle JET (JavaScript Extension Toolkit): Implement Oracle JET to create modern, responsive, and highly interactive data visualizations within Oracle BI dashboards embedded in Fusion applications. This ensures that data presentations are not only informative but also visually appealing.",
        "Pixel-perfect Reporting with BI Publisher: Use Oracle BI Publisher to design and deliver pixel-perfect reports, embedding BI reports within Oracle Fusion applications user interfaces for a seamless user experience. This includes formatted reports like invoices, purchase orders, and regulatory submissions that can be executed from both Fusion and EBS environments.",
        "Personalized Dashboards: Create role-based dashboards in Oracle BI, tailored to the specific needs of different user roles within the Fusion ecosystem. This enables personalized experience and ensures that users have quick access to the most relevant data.",
        "Mobile BI: Deploy Oracle BI Mobile for on-the-go analytics, providing access to critical business insights via mobile devices. This allows executives and field personnel to make informed decisions anytime, anywhere, leveraging data from both Fusion and EBS.",
        "Smart View Integration: Utilize Oracle Smart View to integrate BI content into Microsoft Office applications, allowing users to easily import data from Fusion and EBS into Excel for further analysis and reporting.",
      ],
    },
    {
      title: "Data Integration and ETL Processes",
      description:
        "Ensuring seamless and robust data integration across Oracle platforms:",
      details: [
        "Extract, Transform, Load (ETL) with Oracle Data Integrator (ODI): Implement ODI to manage complex ETL processes that integrate data from Oracle EBS, Fusion applications, and other third-party sources into the Oracle BI data warehouse. ODI’s declarative design approach simplifies data integration while ensuring high performance and scalability.",
        "Data Consistency and Quality Management: Leverage Oracle Enterprise Data Quality (EDQ) tools to maintain data consistency and quality across Oracle BI and Fusion environments. EDQ provides powerful data profiling, cleansing, and matching capabilities to ensure accurate analytics.",
        "SOA Suite for Integration: Employ Oracle SOA Suite for building, deploying, and managing Service-Oriented Architecture (SOA) composite applications. This facilitates real-time data synchronization between Fusion applications and Oracle BI, streamlining data flows and improving reporting accuracy.",
        "Master Data Management (MDM): Implement Oracle Master Data Management to create a single, accurate, and authoritative source of truth for enterprise data across EBS, Fusion, and BI systems, enhancing the reliability of analytics and reporting.",
        "Data Lake Integration: Use Oracle Cloud Infrastructure (OCI) Data Lake capabilities to store and process vast amounts of structured and unstructured data. This allows for comprehensive analysis by combining data from Fusion applications, EBS, and external sources.",
      ],
    },
    {
      title: "Performance Optimization and Scalability",
      description:
        "Ensuring high performance and scalability of integrated Oracle BI and Fusion systems:",
      details: [
        "Hardware and Infrastructure Optimization: Utilize Oracle Exadata and Oracle Database Appliance (ODA) to optimize the performance of Oracle BI and Fusion applications. These engineered systems are designed to provide extreme performance and scalability, enabling fast query processing and analytical operations.",
        "Performance Tuning with AWR and ADDM: Implement Automated Workload Repository (AWR) and Automatic Database Diagnostic Monitor (ADDM) to analyze and optimize database performance for Oracle BI and Fusion environments. Tuning SQL queries and optimizing database configuration are critical for maintaining high system performance.",
        "Parallel Processing and Partitioning: Leverage Oracle Database’s partitioning and parallel processing capabilities to accelerate data loading and query performance in large-scale BI implementations. This ensures that the integrated analytics platform can handle growing volumes of data from Fusion and EBS systems efficiently.",
        "Caching Strategies: Implement Oracle BI Server caching strategies to improve response times for frequently accessed reports and dashboards, reducing the load on the underlying Fusion application databases.",
        "Disaster Recovery and High Availability: Design robust disaster recovery and high availability solutions using Oracle Data Guard and RAC (Real Application Clusters). This ensures continuous availability of BI services and minimizes downtime, maintaining uninterrupted access to critical business insights.",
      ],
    },

    {
      title: "Advanced Security and Compliance Management",
      description:
        "Implementing robust security measures to protect data integrity and ensure compliance:",
      details: [
        "Access Control and Authentication: Employ Oracle Identity and Access Management (IAM) to provide secure access control, authentication, and single sign-on (SSO) across Oracle BI and Fusion applications. This includes implementing Role-Based Access Control (RBAC) to enforce granular permissions.",
        "Data Encryption and Masking: Utilize Oracle Advanced Security features such as Transparent Data Encryption (TDE) and Data Redaction to protect sensitive data in both transit and at rest within Oracle BI and Fusion environments.",
        "Compliance and Auditing: Implement Oracle Audit Vault and Database Firewall (AVDF) to monitor and log database activities, ensuring compliance with regulatory standards such as GDPR, SOX, and HIPAA. AVDF provides comprehensive audit capabilities that can detect suspicious activities and unauthorized access.",
        "Segregation of Duties (SoD): Utilize Oracle GRC (Governance, Risk, and Compliance) Cloud to enforce segregation of duties policies and minimize the risk of fraud and conflicts of interest within Oracle Fusion applications.",
        "Segregation of Duties (SoD): Utilize Oracle GRC (Governance, Risk, and Compliance) Cloud to enforce segregation of duties policies and minimize the risk of fraud and conflicts of interest within Oracle Fusion applications.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              fontSize: "30px",
            }}
          >
            Oracle BI with Fusion: Integrated Data-Driven Solutions for
            Strategic Excellence
          </h1>
          <p style={{ fontSize: "18px" }}>
            At FocusR, our expertise in integrating Oracle Business Intelligence
            (BI) with Oracle Fusion applications ensures that organizations can
            leverage advanced analytics, real-time reporting, and robust data
            visualizations to drive operational efficiency and strategic
            decision-making. Our comprehensive solutions encompass advanced
            analytics and reporting, intuitive data visualizations, seamless
            data integration, performance optimization, and stringent security
            measures. By bridging Oracle E-Business Suite (EBS) and Fusion, we
            deliver unparalleled insights and scalability, transforming data
            into actionable intelligence and fostering a data-driven culture
            across the enterprise.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "14px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default OnPremise;
