import React, { useEffect } from 'react';
import A1 from '../../../assets/Logos/T1.png';
import A2 from '../../../assets/Logos/A2.jpg';
import A3 from '../../../assets/Logos/T2.png';
import A4 from '../../../assets/Logos/T3.png';
import A5 from '../../../assets/Logos/T4.png';
import A6 from '../../../assets/Logos/T5.png';
import A7 from '../../../assets/Logos/T3.png';
import { GiProcessor } from 'react-icons/gi';
import { GrOracle, GrTest } from 'react-icons/gr';
import { MdOutlineModelTraining } from 'react-icons/md';
import { TbSettingsAutomation } from 'react-icons/tb';
import { FcEngineering } from 'react-icons/fc';
import { FaAngular, FaConnectdevelop, FaJava, FaPython, FaReact } from 'react-icons/fa';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { GiUpgrade } from 'react-icons/gi';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { SiGooglecloud, SiSap } from 'react-icons/si';
import { LuBrainCog } from 'react-icons/lu';
import { Avatar, Card } from 'antd';
import Casestudy from './../../../Components/Casestudy';
import AOS from 'aos';
import 'aos/dist/aos.css';
const { Meta } = Card;
const ProductDev = () => {
  const arrayOfImages = [
    {
      imageUrl: A1,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: A2,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: A3,
      alt: 'Logo-A-Series'
    },

    {
      imageUrl: A5,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: A6,
      alt: 'Logo-A-Series'
    },
    {
      imageUrl: A7,
      alt: 'Logo-A-Series'
    }
  ];

  const iconStyle = {
    width: '48px',
    height: '48px'
  };

  const SCW_Top_Array = [
    {
      icon: <FaReact style={iconStyle} />,
      title: 'PWA Suite',
      description: 'Unleashing MERN Excellence Product'
    },
    {
      icon: <FaAngular style={iconStyle} />,
      title: 'B2B Solutions',
      description: 'Mastering MEAN Stack for Business Applications Product'
    },
    {
      icon: <FaJava style={iconStyle} />,
      title: 'Fullstack Development',
      description: 'Empowering Businesses with Comprehensive Java Solutions Product'
    },
    {
      icon: <GrOracle style={iconStyle} />,
      title: 'Integration Suite',
      description: 'Strategic Oracle Advisory for Integrated Projects Product'
    }
  ];

  const SCW_Bottom_Array = [
    {
      icon: <SiSap style={iconStyle} />,
      title: 'SAP Integration',
      description: 'Unleash the Power of ISAP Expertise'
    },
    {
      icon: <SiGooglecloud style={iconStyle} />,
      title: 'Cloud Solutions',
      description: 'Elevating Business with AWS, GCP, Azure Mastery'
    },
    {
      icon: <FaPython style={iconStyle} />,
      title: 'Python Solutions',
      description: 'Revolutionize with Python & AI Neural Networks'
    },
    {
      icon: <LuBrainCog style={iconStyle} />,
      title: 'AI Integration',
      description: 'Transforming Visions into AI Realities'
    }
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="services-new-wrapper">
      <center>
        <br />
        <br />
        <br />
        {/* service first section starts here*/}
        <div className="services-first-section">
          <h1>Innovate, Thrive, Succeed The FocusR Advantage</h1>
          <p
            style={{
              textAlign: 'center'
            }}
          >
            FocusR has a strong product development team to build websites and mobile apps from scratch that meets
            businesses' needs and can be integrated with ERP applications for operational synergy.
          </p>
        </div>
        {/* logo horizantal lists starts here*/}
        <div className="new-logo-container">
          {arrayOfImages.map((x, i) => {
            return <img src={x.imageUrl} alt={x.alt} />;
          })}
        </div>
        {/* casestudy starts here */}

        {/* logo horizantal lists ends here*/}
        <section className="service-container-wrapper">
          <h1>
            Unlock Our Proficiency <br /> Customized Solutions for Every Business Demand
          </h1>
          <div className="SCW-Top">
            {SCW_Top_Array.map((x, i) => {
              return (
                <Card
                  style={{
                    width: 300
                  }}
                  className="card-antd"
                >
                  <Meta avatar={x.icon} title={x.title} description={x.description} />
                </Card>
              );
            })}
          </div>
          <div className="SCW-Bottom">
            {SCW_Bottom_Array.map((x, i) => {
              return (
                <Card
                  style={{
                    width: 300
                  }}
                  className="card-antd"
                >
                  <Meta avatar={x.icon} title={x.title} description={x.description} />
                </Card>
              );
            })}
          </div>
        </section>
        <Casestudy />
      </center>
    </div>
  );
};

export default ProductDev;
