import { useEffect, useRef, useState } from "react";
import {
  AiFillControl,
  AiOutlineApi,
  AiOutlineBranches,
  AiOutlineSetting,
  AiOutlineArrowRight,
} from "react-icons/ai";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import {
  TbNumber10Small,
  TbNumber11Small,
  TbNumber1Small,
  TbNumber2Small,
  TbNumber3Small,
  TbNumber4Small,
  TbNumber5Small,
  TbNumber6Small,
  TbNumber7Small,
  TbNumber8Small,
  TbNumber9Small,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber6Filled,
  TbSquareRoundedNumber7Filled,
  TbSquareRoundedNumber8Filled,
} from "react-icons/tb";
import AOS from "aos";
import "aos/dist/aos.css";
// import Contactform from "../../Components/Contactform";
import { useWindupString } from "windups";
// import ChatNew from "../../Components/Chat2";
// import aboutIllustration from "../../assets/about-page-bg.png";
import { Progress, Collapse, Affix } from "antd";
import { FaGears, FaWandMagicSparkles } from "react-icons/fa6";
import { SiCodeclimate } from "react-icons/si";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { TiLightbulb } from "react-icons/ti";
import { GoLightBulb } from "react-icons/go";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiChartPieSliceDuotone, PiMagicWandLight } from "react-icons/pi";
import { CaretRightOutlined } from "@ant-design/icons";
import { TbNumber12Small } from "react-icons/tb";
import Sticky from "react-stickynode";
import './OracleServices.css'

const Migration = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [bottomBoundary, setBottomBoundary] = useState(0);
  const welcomePageRef = useRef(null);

  const sections = [
    {
      title: "Data Migration ",
      description:
        "Our Data Migration services ensure a seamless and accurate transfer of data from legacy systems or older Oracle versions to Oracle E-Business Suite (EBS) and Oracle Fusion. Detailed activities include",

      details: [
        "Data Discovery and Profiling: We perform comprehensive data discovery and profiling to understand the structure, quality, and quantity of data in the legacy systems. ",
        "Data Mapping and Transformation: Utilizing Oracle Data Integrator (ODI) and other ETL tools, we create detailed data mapping and transformation rules to align legacy data with the new schema in Oracle EBS or Oracle Fusion",
        "Pre-Migration Testing: Conduct rigorous testing including full-cycle UAT (User Acceptance Testing) and data validation checks to ensure that data accuracy and integrity are maintained.",
        "Data Cleansing and Quality Control: Implement sophisticated data cleansing techniques to eliminate duplicates, rectify inconsistencies, and enhance data quality before migration.",
        "Migration Execution and Validation: Execute the data migration using high-speed data transfer protocols and validate through automated scripts to confirm successful migration without data loss",
      ],
    },
    {
      title: "Application Modernization  ",
      description:
        "Our Application Modernization services aim to revamp and enhance the capability of your existing applications by leveraging the full potential of Oracle EBS and Oracle Fusion. Key areas include",
      details: [
        "Code Refactoring and Optimization: Refactor existing codebases using Oracle-friendly languages and frameworks to improve performance, maintainability, and scalability",
        "Component Re-Engineering: Upgrade critical components such as forms, reports, and PL/SQL packages to utilize modern APIs and services offered by Oracle EBS and Fusion Middleware",
        "User Interface Enhancement: Redesign user interfaces using Oracle's ADF (Application Development Framework) or Oracle JET to improve usability and user experience",
        "Integration with Modern Technologies: Incorporate IoT, machine learning, and AI functionalities by integrating Oracle Fusion Cloud Applications with third-party solutions and Oracle’s cloud-native services",
        "Microservices Architecture Implementation: Decompose monolithic applications into microservices architecture to enhance flexibility, scalability, and fault tolerance.",
      ],
    },
    {
      title: " Upgrade Planning ",
      description:
        "We provide comprehensive Upgrade Planning services to meticulously prepare for upgrades of Oracle EBS and Oracle Fusion. Core elements include",
      details: [
        "Current State Assessment: Conduct a thorough assessment of the current environment including hardware, software, customizations, and integrations using Oracle diagnostic tools",
        "Gap Analysis and Impact Assessment:  Identify gaps between the current state and the target upgrade version, and assess the impact on existing operations through Oracle’s pre-upgrade readiness assessment tools",
        "Risk Mitigation Strategy: Develop a comprehensive risk mitigation strategy incorporating fallback plans, backup procedures, and a rollback mechanism",
        "Upgrade Path Definition: Define a detailed upgrade path with clear milestones, leveraging Oracle’s upgrade paths and best practices to ensure alignment with business goals",
        "Resource Allocation and Training: Allocate resources including skilled personnel and ensure they are trained on the new features and functionalities of the upgraded Oracle applications.",
      ],
    },
    {
      title: "Upgrade Execution",
      description:
        "Our team executes upgrades with precision, leveraging best practices and advanced tools to achieve a smooth transition. Activities include: ",
      details: [
        "Environment Preparation: Set up and configure parallel test environments to simulate the upgrade process ensuring minimal disruption to production environments",
        "Database Upgrade: Perform database upgrades utilizing Oracle Data Pump, RMAN (Recovery Manager), and other Oracle tools to upgrade database schemas and objects efficiently",
        "Application Upgrade: Use Oracle’s Rapid Install and AutoPatch tools to upgrade Oracle EBS applications, taking care to address customizations and workflows",
        "Validation and Testing:Conduct comprehensive testing including system testing, performance testing, and regression testing using Oracle Application Testing Suite (OATS)",
        "Cutover Planning and Execution: Develop and execute a detailed cutover plan, including final validation scripts, pre-cutover checkpoints, and post-cutover verification to ensure a seamless transition",
      ],
    },

    {
      title: "Post-Upgrade Support  ",
      description:
        "FocusR offers extensive Post-Upgrade Support to ensure operational stability and performance enhancement. Support activities include:",
      details: [
        "Proactive Monitoring:Implement Oracle Enterprise Manager (OEM) to provide continuous monitoring of system performance, identifying and addressing potential bottlenecks.  ",
        "Performance Tuning: Conduct regular performance tuning exercises on databases and applications, leveraging Oracle’s Advanced Tuning and Diagnostic Packs",
        "Issue Resolution:Provide 24/7 support for troubleshooting and resolving any issues that arise post-upgrade, using Oracle SR (Service Request) for critical issue escalation",
        "Periodic Health Checks: Perform periodic health checks to ensure system integrity and compliance with Oracle’s best practices and standards. ",
        "Feature Utilization Training: Offer additional training sessions to users and administrators, helping them to fully leverage new features and capabilities introduced in the upgrade",
      ],
    },
    {
      title: "Customization and Extension Handling",
      description:
        "Our services ensure that customizations and extensions are managed effectively during the migration and upgrade process for Oracle EBS and Oracle Fusion. Key activities include",
      details: [
        "Customization Inventory: Create a detailed inventory of all customizations, including RICE (Reports, Interfaces, Conversions, Extensions) components and personalized user exits. ",
        "Compatibility Analysis: Conduct a compatibility analysis to determine which customizations are compatible with the new versions of Oracle EBS or Oracle Fusion, and identify any that require rework. ",
        "Redesign and Redevelopment:  Redesign and redevelop customizations using Oracle ADF, Oracle SOA Suite, or Oracle Integration Cloud to align with the upgraded system architecture and capabilities.",
        "Extensibility Framework Utilization:  Leverage Oracle's extensibility frameworks, such as Oracle Application Framework (OAF) for EBS and Oracle Visual Builder for Fusion, to implement customizations that are upgrade-safe and future-proof.",
        "Testing and Validation: Perform extensive testing of all customized components to ensure that they function correctly in the new environment, using Oracle Application Testing Suite (OATS) for automated regression testing. ",
      ],
    },
    {
      title: "Middleware Upgrade  ",
      description:
        "We specialize in upgrading middleware components essential for integrating Oracle EBS and Oracle Fusion, ensuring seamless communication and data exchange. Detailed activities include",
      details: [
        "Middleware Inventory and Assessment: Compile a comprehensive inventory of existing middleware components, including Oracle WebLogic Server, Oracle SOA Suite, and Oracle Integration Cloud. ",
        "Upgrade Pathway Design:Design an upgrade pathway that minimizes downtime and ensures compatibility with upgraded Oracle EBS or Oracle Fusion environments",
        "Configuration and Optimization: Reconfigure middleware settings to optimize performance and functionality in the new environment. This includes tuning WebLogic Server parameters and optimizing SOA composites",
        "Security Enhancement: Enhance security configurations to meet the latest Oracle standards and compliance requirements, implementing best practices for SSL, identity management, and access controls",
        "Integration Testing: Execute end-to-end integration testing to validate that all middleware components work seamlessly with upgraded Oracle applications, ensuring data integrity and process continuity",
      ],
    },
    {
      title: " Performance Benchmarking and Tuning  ",
      description:
        "FocusR offers extensive performance benchmarking and tuning services to ensure that the upgraded Oracle EBS and Oracle Fusion systems operate at peak efficiency. Key areas include",
      details: [
        "Baseline Performance Benchmarking:  Establish baseline performance metrics using Oracle Enterprise Manager (OEM) and other diagnostic tools before the upgrade, to measure improvement post-upgrade",
        "Query Optimizationg: Optimize SQL queries and PL/SQL code using Oracle Automatic Workload Repository (AWR) reports and Oracle SQL Tuning Advisor to enhance database performance",
        "Application Performance Tuning: Tune application layer performance leveraging Oracle Performance Analyzer and Diagnostic Packs to identify and mitigate bottlenecks in application processing.",
        "Hardware and Infrastructure Tuning: Reevaluate and adjust hardware configurations, including CPU, memory, and storage settings, to align with the enhanced requirements of the upgraded systems",
        "Load and Stress Testing: Conduct load and stress testing using Oracle Application Testing Suite (OATS) to ensure that the system can handle increased workloads and user demands.",
      ],
    },
    {
      title: "Integration with Cloud Services ",
      description:
        "We provide services to integrate Oracle EBS and Oracle Fusion with Oracle Cloud and third-party cloud services, enhancing functionality and flexibility. Key activities include: ",
      details: [
        "Cloud Readiness Assessment: Conduct a comprehensive cloud readiness assessment to understand the current state and identify prerequisites for cloud integration",
        "Integration Design: Design robust integration architectures using Oracle Integration Cloud (OIC) and Oracle SOA Suite to connect on-premises Oracle applications with Oracle Cloud services and third-party cloud solutions",
        "API Management:  Implement and manage APIs using Oracle API Platform Cloud Service to facilitate seamless and secure communication between disparate systems.",
        "Data Synchronization: Set up and configure data synchronization processes using Oracle GoldenGate and Oracle Data Integrator (ODI) to ensure real-time data consistency between on-premises and cloud environments",
        "Security and Compliance: Implement stringent security protocols, including data encryption and identity management, to ensure compliance with industry standards and regulatory requirements during cloud integration",
      ],
    },
    {
      title: "Disaster Recovery Planning ",
      description:
        "Our disaster recovery planning services ensure that your upgraded Oracle EBS and Oracle Fusion environments are resilient and can quickly recover from unforeseen events. Key components include",
      details: [
        "Risk Analysis: Perform a detailed risk analysis to identify potential vulnerabilities and threats to your upgraded Oracle environments.",
        "DR Strategy Development: Develop a comprehensive disaster recovery (DR) strategy that includes RTO (Recovery Time Objective) and RPO (Recovery Point Objective) targets, ensuring minimal data and operational loss in the event of a disaster",
        "Backup and Replication Setup: Configure robust backup and data replication solutions using Oracle Recovery Manager (RMAN) and Oracle Data Guard to enable quick and reliable disaster recovery",
        "Disaster Recovery Drills: Conduct regular DR drills and simulations to test the effectiveness of the DR plan and to ensure that staff are trained and prepared for real-world scenarios. ",
        "Post-Disaster Support: Provide expert post-disaster support to help swiftly restore operations, including data restoration, system reconfiguration, and performance tuning to return to normal business operations.",
      ],
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 150; // Adjust the offset as needed
    const currentSectionIndex = contentRefs.current.findIndex(
      (ref) => ref && ref.offsetTop > scrollPosition
    );
    setActiveIndex(
      currentSectionIndex === -1 ? sections.length - 1 : currentSectionIndex - 1
    );
  };

  // useEffect(() => {
  //   // Set bottom boundary after DOM is rendered
  //   const rightSideElement = document.querySelector(".right-side");
  //   if (rightSideElement) {
  //     setBottomBoundary(
  //       rightSideElement.offsetTop + rightSideElement.offsetHeight
  //     );
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const updateBottomBoundary = () => {
      const rightSideElement = document.querySelector(".right-side");
      if (rightSideElement) {
        setBottomBoundary(
          rightSideElement.offsetTop + rightSideElement.offsetHeight
        );
      }
    };

    const observer = new ResizeObserver(updateBottomBoundary);
    const rightSideElement = document.querySelector(".right-side");

    if (rightSideElement) {
      observer.observe(rightSideElement);
    }

    // Initial boundary calculation
    updateBottomBoundary();

    window.addEventListener("scroll", updateBottomBoundary);
    
    return () => {
      window.removeEventListener("scroll", updateBottomBoundary);
      if (rightSideElement) {
        observer.unobserve(rightSideElement);
      }
    };
  }, []);

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
      <div className="welcome-page-23">
        <center>
          <h1
            style={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
            }}
            data-aos="fade-right"
            data-aos-delay="200"
          >
            Oracle Migration and Upgrade Services
          </h1>
          <p data-aos="fade-right" data-aos-delay="400">
            FocusR provides a full spectrum of Oracle Migration and Upgrade
            Services tailored for Oracle E-Business Suite (EBS) and Oracle
            Fusion environments. Our services include data migration,
            application modernization, upgrade planning and execution,
            post-upgrade support, and performance optimization. Leveraging
            advanced tools and methodologies, we ensure seamless transitions,
            minimal disruptions, and maximum benefits, empowering your business
            to fully harness the capabilities of your upgraded Oracle systems.
          </p>
          <div className="button-container1">
            <a
              className="primary-btn-cus"
              href="#careers"
              style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Connect to know more
            </a>
          </div>
        </center>
      </div>
      <div className="welcome-page-24" ref={welcomePageRef}>
        <div className="left-side">
          <Sticky top={100} bottomBoundary={bottomBoundary}>
            {({ style }) => (
              <div className="left-side" style={style}>
                {sections.map((section, index) => (
                  <h2
                    style={{ fontWeight: "normal", fontSize: "16px" }}
                    key={index}
                    className={`left-side-heading ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() =>
                      window.scrollTo({
                        top: contentRefs.current[index]?.offsetTop - 100,
                        behavior: "smooth",
                      })
                    }
                  >
                    {section.title}
                  </h2>
                ))}
              </div>
            )}
          </Sticky>
        </div>
        <div className="right-side">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className="content-section"
            >
              <h2>{section.title}</h2>
              <p style={{ marginLeft: "1.6em" }}>{section.description}</p>
              {Array.isArray(section.details) && section.details.length > 0 && (
                <ul style={{ listStyle: "square", padding: "1.5em 0 0 3em" }}>
                  {section.details.map((detail, detailIndex) => (
                    <li key={detailIndex} style={{ padding: "0.5em" }}>
                      {detail.includes(":") ? (
                        <span>
                          <strong>{detail.split(":")[0]}</strong>:{" "}
                          {detail.split(":")[1]}
                        </span>
                      ) : (
                        detail
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-oracle-services">
        <h1>Explore with our Oracle Services</h1>
        <div className="button-container1">
          <a
            className="primary-btn-cus"
            href="#careers"
            style={{ backgroundColor: "#F0483E", marginTop: "0.4em" }}
            onClick={() => {
              navigate("/services/ERP-Service");
            }}
          >
            Learn More
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Migration;
