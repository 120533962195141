import React,{useEffect,useState} from 'react';
import './Oracle.css';
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import ChatNew from "../../Components/Chat2";
import OracleSecImg from "../../assets/oracle-sec-img.png";
import { FloatButton, message, Modal, Tabs } from "antd";


import Casestudy from "../../Components/Casestudy";

import { FaArrowRightLong } from "react-icons/fa6";
import CombineImg from "../../assets/oracle-combineimg.png";
import ManageOracle from "../../assets/manageimg.png";
import OracleServiceimg from "../../assets/oracle-img-service.png";
import ImplementationIcon from "../../assets/Icons-lunacy/image28.png";
import ConsultingIcon from "../../assets/Icons-lunacy/image29.png";
import MigrationIcon from "../../assets/Icons-lunacy/image30.png";
import TrainingIcon from "../../assets/Icons-lunacy/image31.png";
import SupportIcon from "../../assets/Icons-lunacy/image32.png";
import CustomDevelopmentIcon from "../../assets/Icons-lunacy/image33.png";
import CloudMigrationIcon from "../../assets/Icons-lunacy/image34.png";
import SecurityIcon from "../../assets/Icons-lunacy/image35.png";
import OptimizationIcon from "../../assets/Icons-lunacy/image36.png";
import ArrowIcon from "../../assets/Icons-lunacy/image37.png";
import Ebusiness from "../../assets/Icons-lunacy/E-business.png";
import Enterprice from "../../assets/Icons-lunacy/Enterprice.png";
import SCMCloud from "../../assets/Icons-lunacy/Scmcloud.png";
import CXCloud from "../../assets/Icons-lunacy/cxcloud.png";
import HcmCLoud from "../../assets/Icons-lunacy/HCM.png";
import PLMCloud from "../../assets/Icons-lunacy/Plm.png";
import Hyperion from "../../assets/Icons-lunacy/Hyperine.png";
import JDEdwrds from "../../assets/Icons-lunacy/JDedwards.png";
import PeopleSoft from "../../assets/Icons-lunacy/PeopleSoft.png";
import Sibel from "../../assets/Icons-lunacy/SIEBEL.png";
import Demantra from "../../assets/Icons-lunacy/Demantra.png";
import WMS from "../../assets/Icons-lunacy/WMS.png";
import commerce from "../../assets/Icons-lunacy/Commerce.png";
import CloudMarket from "../../assets/Icons-lunacy/cloudmarket.png";
import Primavers from "../../assets/Icons-lunacy/Primary.png";
import integrahub from "../../assets/Oracle-cardimg.png";
import arrow from "../../assets/ERP/arrow.png";
import arrow2 from "../../assets/Oracle/arrow2.png";
import image1 from "../../assets/Oracle/image1.png";
import image2 from "../../assets/Oracle/image2.png";
import image3 from "../../assets/Oracle/image3.png";
import image4 from "../../assets/Oracle/image4.png";
import image5 from "../../assets/Oracle/image5.png";
import image6 from "../../assets/Oracle/image6.png";
import bulb from "../../assets/Oracle/bulb.png";
import { Link, Element, scroller } from "react-scroll";
import axios from "axios";
import Footer from '../../Components/Footer';

function Oracle() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 500 && window.innerWidth <= 900);

  const { TabPane } = Tabs;

  const navigate = useNavigate();
  const images = [
    { src: Ebusiness, alt: "Ebusiness" },
    { src: Enterprice, alt: "Enterprice" },
    { src: SCMCloud, alt: "SCMCloud" },
    { src: CXCloud, alt: "CXCloud" },
    { src: HcmCLoud, alt: "HcmCLoud" },
    { src: PLMCloud, alt: "PLMCloud" },
    { src: Hyperion, alt: "Hyperion" },
    { src: JDEdwrds, alt: "JDEdwrds" },
    { src: PeopleSoft, alt: "PeopleSoft" },
    { src: Sibel, alt: "Sibel" },
    { src: Demantra, alt: "Demantra" },
    { src: WMS, alt: "WMS" },
    { src: commerce, alt: "commerce" },
    { src: CloudMarket, alt: "CloudMarket" },
    { src: Primavers, alt: "Primavers" },
    { src: CXCloud, alt: "CXCloud" },
  ];

  const groupedImages = [];
  const groupSize = 4;

  for (let i = 0; i < images.length; i += groupSize) {
    groupedImages.push(images.slice(i, i + groupSize));
  }

  // Ensure exactly 4 images in each group
  while (groupedImages.length > 4) {
    groupedImages.pop();
  }
  const [currentSlide, setCurrentSlide] = useState(0);

  

  const servicePaths = {
    Implementation: "/oracleservice",
    Consulting: "/oracleConsulting",
    Migration: "/migration",
    Training: "/oracleTraining",
    Support: "/oraclesupport",
    CustomDevelopment: "/oracleCustomDevelopment",
    CloudMigration: "/cloudmigration",
    Security: "/oracleSecurity",
    Optimization: "/optimization",
  };

  const navigateToService = (title) => {
    const path = servicePaths[title];
    if (path) {
      navigate(path);
    }
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [about, setAbout] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (!name || !email || !about) {
      message.error("All fields are required!");
      return;
    }

    setLoading(true);
    const payload = {
      full_Name: name,
      email: email,
      about: about,
      type: "Platform-Oracle",
    };
    axios
      .post("https://focusrapi.focusrtech.com:83/post/enquery", payload)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        message.success("Submitted Successfully!");
        setName("");
        setEmail("");
        setAbout("");
      })
      .catch((error) => {
        console.error("There was an error making the request!", error);
        message.error("Failed to Submit");
        setLoading(false);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % groupedImages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const items = [
    {
      key: "overview",
      label: "Overview",
      content: (
        <Element name="overview" className="content-container12">
          <div style={{display:"flex", width:"90%", flexDirection:"column", alignItems:"center"}}>
          <h1 style={{ fontWeight: "normal",width:"80%",display:"flex",justifyContent:"center" }}>
            Comprehensive Oracle Solutions <br /> by FocusR
          </h1>
          <p>
            At FocusR, we provide comprehensive Oracle services, including
            implementation, consulting, migration, training, and support. We
            develop custom applications, assist with cloud migration, enhance
            security, optimize performance, and offer managed services to ensure
            our clients maximize their Oracle investments.
          </p>
          </div>
        </Element>
      ),
    },
    {
      key: "services",
      label: "Services",
      content: (
        <div >
          <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"30px"}}>

        <Element name="services" className="services-container">
          {[
            {
              title: "Implementation",
              icon: ImplementationIcon,
              text: "We assist with installing, configuring, customizing, and integrating Oracle solutions to meet your specific needs.",
              icon1: ArrowIcon,
            },
            {
              title: "Consulting",
              icon: ConsultingIcon,
              text: "We provide strategic guidance, best practices, and recommendations to optimize your use of Oracle technologies.",
              icon1: ArrowIcon,
              Path: "/oracleConsulting",
            },
            {
              title: "Migration",
              icon: MigrationIcon,
              text: "We support your migration from legacy systems to newer Oracle versions, including data migration and application modernization.",
              icon1: ArrowIcon,
            },
            {
              title: "Training",
              icon: TrainingIcon,
              text: "Empowering customers with Oracle knowledge through onsite/remote training, workshops, and customized programs.",
              icon1: ArrowIcon,
            },
            {
              title: "Support",
              icon: SupportIcon,
              text: "We provide strategic guidance, best practices, and recommendations to optimize your use of Oracle technologies.",
              icon1: ArrowIcon,
            },
            {
              title: "CustomDevelopment",
              icon: CustomDevelopmentIcon,
              text: "Creating bespoke applications and integrations to enhance Oracle solutions and meet unique business needs.",
              icon1: ArrowIcon,
            },
            {
              title: "CloudMigration",
              icon: CloudMigrationIcon,
              text: "We assist with migrating your on-premises infrastructure to Oracle Cloud, including readiness assessments, migration planning, and ongoing management.",
              icon1: ArrowIcon,
            },
            {
              title: "Security",
              icon: SecurityIcon,
              text: "We enhance security and ensure compliance with regulations through assessments, security controls, and compliance guidance.",
              icon1: ArrowIcon,
            },
            {
              title: "Optimization",
              icon: OptimizationIcon,
              text: "We optimize performance and scalability of your Oracle solutions through tuning, capacity planning, and architectural reviews.",
              icon1: ArrowIcon,
            },
          ].map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.icon} alt={`${service.title} Icon`} />
              <h3>{service.title}</h3>
              <p>{service.text}</p>
              <img
                className="uparrow-icon"
                src={service.icon1}
                alt="alt"
                onClick={() => navigateToService(service.title)}
              />
            </div>
          ))}
          </Element>
          </div>
          
          <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
          <div style={{width:"90%", display:"flex", justifyContent:"center"}}>
            
          <div className="oracle-second-main-down" style={{backgroundColor:"rgb(248, 242, 255)",borderTopRightRadius:"20px", borderTopLeftRadius:"20px"}}>
            <div className="oracle-sec-right-down">
              <img
                className="oracle-sec-right-img-down"
                src={OracleServiceimg}
                alt="oracle-flow"
              />
            </div>
            <div className="oracle-sec-left-down" style={{marginTop:"30px",backgroundColor:"rgb(245, 245, 245)", borderRadius:"20px"}} >
              <img
                className="oracle-sec-img-manage"
                src={ManageOracle}
                alt="oracle-flow"
              />
              <h5 style={{ fontSize: "20px" }}>
                Managed Service and Outsourcing
              </h5>
              <p
                style={{
                  marginTop: "1em",
                  textAlign: "left",
                  marginLeft: "0.5em",
                }}
              >
                We offer managed services to handle day-to-day Oracle
                environment operations, allowing you to focus on core business
                activities.
              </p>
              <button
                onClick={() => navigate("/serviceOutsourcing")}
                type="button"
              >
                KNOW MORE
              </button>
            </div>
          </div>
          </div>
          </div>
          </div>
          
        
      ),
    },
    {
      key: "expertise",
      label: "Expertise",
      content: (
        <Element name="expertise">
          <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
          <div style={{width:"90%", display:"flex", justifyContent:"center"}}>
          <div className="erp-third-1" style={{backgroundColor:"rgb(248, 242, 255)", borderBottomRightRadius:isMobile?"":"20px", borderBottomLeftRadius:isMobile?"":"20px"}}>
            <div style={{width:"50%", display:"flex", justifyContent:"end"}}>
            <div className="erp-integrahub1" >
              <img src={integrahub} width="100%" />
            </div>
            </div>
            <div style={{width:isMobile?"70%":"50%", display:"flex", justifyContent:"center"}}>
            <div className="erp-third-right">
              <h5>Spotlight</h5>
              <h3>
                Integrate and migrate
                <br /> data with ease using
              </h3>
              <hr style={{ fontWeight: "bold", color: "yellow" }} />
              <h1>IntegraHub</h1>
              <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="rgba(2, 2, 2, 0.542)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              width="36"
              height="36"
              style={{marginTop:"5px", display:"flex", justifyContent:"center", width:isMobile?"100%":"", marginTop:"20px"}}
              
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M8 16 L16 8" />
              <polyline points="8 8 16 8 16 16" />
            </svg>
              
            </div>
            </div>
          </div>
          </div>
          </div>
          {!isMobile?(
            <div style={{display:"flex", justifyContent:"center", width:"100%", marginTop:"30px"}}>
          <div style={{display:"flex", justifyContent:"center", width:isMobile?"100%":"90%"}}>
          <div className="oracle-bg" style={{borderRadius:isMobile?"":"20px"}}>
            <div className="oracle-heading">
              <h1>Oracle Expertise</h1>
              <hr
                style={{
                  width: "5em",
                  border: "none",
                  borderTop: "0.1em solid #C33764 #1D7621",
                  marginTop: "4em",
                  marginLeft: "-4em",
                }}
              />
            </div>
            <div className="oracle-container">
              <div className="oracle-one">
                <h4>
                  <u>EBS to Fusion</u>
                </h4>
                <p>
                  Seamless transition from Oracle E-Business Suite to Oracle
                  Fusion with expert data migration and integration.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"20px"}}
  onClick={() => navigate("/ebsFusion")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>

              </div>
              <div className="oracle-one">
              <h4 style={{ textAlign: "center" }}>
                  <u >Support & Maintenance</u>
                </h4>
                <p style={{  }}>
                  Comprehensive support for Oracle systems, including
                  troubleshooting, performance tuning, and monitoring.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/supportandmaintenance")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
              <div className="oracle-one">
                <h4 style={{ textAlign: "center" }}>
                  <u>Green-Field Implementation</u>
                </h4>
                <p style={{  }}>
                  Expert guidance for new Oracle implementations, from design to
                  deployment.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/greenField")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
              <div className="oracle-one">
                <h4>
                  <u>Oracle Primavera</u>
                </h4>
                <p style={{  }}>
                  Advanced project portfolio management solutions for complex
                  projects.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/oraclePrimavera")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
            </div>
            <div className="oracle-grid-two">
              <div className="oracle-one">
                <h4>
                  <u>Extended Oracle Services</u>
                </h4>
                <p style={{ }}>
                  Custom development, integrations, upgrades, and consulting to
                  maximize Oracle investments.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/extendedOracle")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
              <div className="oracle-one">
                <h4>
                  <u>GST/VAT Implementation</u>
                </h4>
                <p style={{ }}>
                  Expert implementation of GST/VAT solutions within Oracle
                  environments for compliance and seamless tax management.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/gstvat")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
              <div className="oracle-one">
                <h4>
                  <u>Automation - Barcode</u>
                </h4>
                <p style={{  }}>
                  Automation solutions using barcode technology for inventory,
                  asset tracking, and supply chain efficiency.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/automationSolutions")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
            </div>
          </div></div>
          </div>):(<div style={{display:"flex", justifyContent:"center", width:"100%", marginTop:"30px"}}>
          <div style={{display:"flex", justifyContent:"center", width:"90%"}}>
          <div className="oracle-bg" style={{borderRadius:"40px"}}>
            <div className="oracle-heading">
              <h1>Oracle Expertise</h1>
              <hr
                style={{
                  width: "5em",
                  border: "none",
                  borderTop: "0.1em solid #C33764 #1D7621",
                  marginTop: "4em",
                  marginLeft: "-4em",
                }}
              />
            </div>
            <div className="oracle-container">
              <div className="oracle-one">
                <h4>
                  <u>EBS to Fusion</u>
                </h4>
                <p style={{ marginTop: "2em" }}>
                  Seamless transition from Oracle E-Business Suite to Oracle
                  Fusion with expert data migration and integration.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/ebsFusion")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>

              </div>
              <div className="oracle-one">
              <h4 style={{ textAlign: "center" }}>
                  <u >Support & Maintenance</u>
                </h4>
                <p style={{ marginTop: "2em" }}>
                  Comprehensive support for Oracle systems, including
                  troubleshooting, performance tuning, and monitoring.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/supportandmaintenance")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
              <div className="oracle-one">
                <h4 style={{ textAlign: "center" }}>
                  <u>Green-Field Implementation</u>
                </h4>
                <p style={{ marginTop: "2em" }}>
                  Expert guidance for new Oracle implementations, from design to
                  deployment.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/greenField")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
              <div className="oracle-one">
                <h4>
                  <u>Oracle Primavera</u>
                </h4>
                <p style={{ marginTop: "2em" }}>
                  Advanced project portfolio management solutions for complex
                  projects.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/oraclePrimavera")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
            </div>
            <div className="oracle-grid-two">
              <div className="oracle-one">
                <h4>
                  <u>Extended Oracle Services</u>
                </h4>
                <p style={{ marginTop: "2em" }}>
                  Custom development, integrations, upgrades, and consulting to
                  maximize Oracle investments.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/extendedOracle")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
              <div className="oracle-one">
                <h4>
                  <u>GST/VAT Implementation</u>
                </h4>
                <p style={{ marginTop: "2em" }}>
                  Expert implementation of GST/VAT solutions within Oracle
                  environments for compliance and seamless tax management.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/gstvat")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
              <div className="oracle-one">
                <h4>
                  <u>Automation - Barcode</u>
                </h4>
                <p style={{ marginTop: "2em" }}>
                  Automation solutions using barcode technology for inventory,
                  asset tracking, and supply chain efficiency.
                </p>
                <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="rgba(2, 2, 2, 0.542)"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="36"
  height="36"
  style={{marginTop:"2px"}}
  onClick={() => navigate("/automationSolutions")}
>
  <circle cx="12" cy="12" r="10" />
  <path d="M8 16 L16 8" />
  <polyline points="8 8 16 8 16 16" />
</svg>
              </div>
            </div>
          </div></div>
          </div>
          )}
        </Element>
      ),
    },
  ];

  const [activeKey, setActiveKey] = useState("overview");

  const handleTabChange = (key) => {
    setActiveKey(key);
    scroller.scrollTo(key, {
      duration: 100,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -80,
    });
  };
 
useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
      setIsTablet(window.innerWidth > 500 && window.innerWidth <= 900)
    };
 
    window.addEventListener('resize', handleResize);
 
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{width:"100vw", maxWidth:isMobile?"450px":""}}>
      <Navbar mode="dark" />
      {/* <ChatNew /> */}
    <div style={{width:"100%", backgroundColor:"#0e2b46",paddingTop:"100px"}}>
      <div className="custome-oracle-container" style={{height:"fit-content",paddingBottom:isMobile?"50px":'150px'}}>
      <div className="custome-oracle-left-section">
        <h1>
          <span className="custome-oracle-highlight-o" style={{ letterSpacing: '5px' }}>O</span><span style={{color:"white", fontSize:"60px",letterSpacing: '5px'}}>racle</span>
          <br /> <span style={{color:"white",fontSize:"60px",letterSpacing: '5px'}}>With</span>
          <br />
          <span className="custome-oracle-highlight-r" style={{ letterSpacing: '5px' }}>FocusR</span>
        </h1>
        <p className="custome-oracle-description">
          Empower excellence with FocusR, your top Oracle Partner. Our experts
          ensure seamless implementation and rock-solid support. Elevate your
          business with FocusR!
        </p>
        <div style={{display: isMobile?"flex":isTablet?"flex":"",justifyContent: isMobile?"center":isTablet?"center":""}}>
        <a href="/verticals" className="custome-oracle-link">
          Industries we support →
        </a>
        </div>
      </div>

      <div className="custome-oracle-right-section">
        <div style={{display:"flex",justifyContent:'center'}}>
        <div style={{display:"flex", justifyContent:"center",width:isMobile?"90%":""}}>
        <h2><span style={{color:"whitesmoke"}}>Oracle Services with Us</span></h2>
        </div>
        </div>
        <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
        <form className="custome-oracle-form"style={{display:"flex", justifyContent:"center", width: isMobile?"":isTablet?"":"50%",flexDirection:"column",}}>
          <input  type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}/>
          <input type="email"
              placeholder="Email"
              className="oracle-form input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}/>
          <input type="tel"
              required
              placeholder="Please tell us how we can assist you"
              className="oracle-form input"
              value={about}
              onChange={(e) => setAbout(e.target.value)}/>
          <div style={{width:"100%", display:"flex", justifyContent:'center'}}>
          <button onClick={handleSubmit} loading={loading} type="button">{loading ? "Loading" : "GET STARTED"}</button>
          </div>
        </form>
        </div>
      </div>
      </div>
      <div style={{display:"flex", width:"100%",}}>
    </div>
    </div>
    <div style={{width:"100%",display:"flex", justifyContent:"center"}}>
    <div style={{ position: "relative", width: "80%", height: "fit-content", maxWidth:"1300px" }}>

   {!isMobile? (<div style={{ position: "absolute", marginTop:isMobile? "-40px": isTablet? "-90px": "-60px",marginLeft:isMobile? "25px": isTablet? "25%": "0%",  maxWidth: isMobile? "321px": isTablet? "481px": "500px", maxheight: isMobile? "72px": isTablet? "107px": "400px",backgroundColor:"rgb(240, 240, 240)", width: isMobile? "220px": isTablet? "107px":"450px", padding:"1em",display:"flex",flexDirection:"column", alignItems:"center", borderRadius:"20px" }}>
          <p>PRODUCTS THAT WE SUPPORT</p>
          <div className="slider" style={{marginTop:"20px"}}>
            {groupedImages.map((group, index) => (
              <div
                className={`slider-group ${
                  currentSlide === index ? "active" : ""
                }`}
                key={index}
              >
                {group.map((item, idx) => (
                  <div className="slide-item" key={idx}>
                    <img src={item.src} alt={item.alt} className="slide-img" />
                  </div>
                ))}
              </div>
            ))}
            <div className="slider-dots" style={{marginTop:"20px"}}>
              {groupedImages.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${currentSlide === index ? "active" : ""}`}
                  onClick={() => handleDotClick(index)}
                ></span>
              ))}
            </div>
          </div>
        </div>):(<div style={{width:"100%", display:"flex", justifyContent:"center"}}><div style={{ position: "absolute", marginTop:isMobile? "-40px": isTablet? "-90px": "-60px",marginLeft:isMobile? "": isTablet? "": "0%",  maxWidth: isMobile? "321px": isTablet? "481px": "500px", maxheight: isMobile? "72px": isTablet? "107px": "400px",backgroundColor:"rgb(240, 240, 240)", width: isMobile? "250px": isTablet? "107px":"450px", padding:"1em",display:"flex",flexDirection:"column", alignItems:"center", borderRadius:"20px" }}>
          <p>PRODUCTS THAT WE SUPPORT</p>
          <div className="slider" style={{marginTop:"20px"}}>
            {groupedImages.map((group, index) => (
              <div
                className={`slider-group ${
                  currentSlide === index ? "active" : ""
                }`}
                key={index}
              >
                {group.map((item, idx) => (
                  <div className="slide-item" key={idx}>
                    <img src={item.src} alt={item.alt} className="slide-img" />
                  </div>
                ))}
              </div>
            ))}
            <div className="slider-dots" style={{marginTop:"20px"}}>
              {groupedImages.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${currentSlide === index ? "active" : ""}`}
                  onClick={() => handleDotClick(index)}
                ></span>
              ))}
            </div>
          </div>
        </div>
        </div>)}
      
  {!isMobile&&!isTablet&&<img 
    src={OracleSecImg} 
    alt="Your Image Description" 
    style={{ position: "absolute", marginTop:isMobile? "-60px": isTablet? "-90px": "-129px",marginLeft:isMobile? "10%": isTablet? "25%": "60%",  maxWidth: isMobile? "321px": isTablet? "481px": "500px", maxheight: isMobile? "72px": isTablet? "107px": "143px" }}
  />
}
  </div>
    </div>
    <div style={{display:'flex', justifyContent:"center",width:"100%",flexDirection:"column",alignItems:"center"}}>
      <div className="tabs-container" style={{marginTop:"250px"}}>
          <Tabs activeKey={activeKey} onChange={handleTabChange}>
            {items.map((item) => (
              <TabPane tab={item.label} key={item.key}>
                <Link to={item.key} smooth={true} duration={600}>
                  {item.label}
                </Link>
              </TabPane>
            ))}
          </Tabs>
          {items.map((item) => (
            <div style={{width:"100%", display:"flex", justifyContent:"center"}} key={item.key}>{item.content}</div>
          ))}
        </div >
        <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
        <div style={{width:"90%", display:"flex", justifyContent:"center", borderRadius:"40px"}}>
        <div className="oracle-expertise">
        <div style={{alignItems:"center", justifyContent:"center",display:"flex",width:"100%"}}>
          <div className="oracle-expertise-left" >
          <div className="oracle-expertise-one">
            <img src={image1} alt="image1" className="oracle-exp-img" />
            <h4>Implement and Support</h4>
            <p>
              Implementation, roll-out, upgrades, and ASM for Oracle solutions.
            </p>
             <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="rgba(2, 2, 2, 0.542)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="36"
            height="36"
            style={{marginTop:"2px"}}
            onClick={() => {
              navigate("/implement");
            }}
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 16 L16 8" />
            <polyline points="8 8 16 8 16 16" />
          </svg>
          </div>
          {/* two */}
          <div className="oracle-expertise-one">
            <img src={image6} alt="image1" className="oracle-exp-img" />
            <h4>Fusion & BI</h4>
            <p>Expertise in Oracle Fusion and Oracle Business Intelligence.</p>
            
            <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="rgba(2, 2, 2, 0.542)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="36"
            height="36"
            style={{marginTop:"2px"}}
            onClick={() => {
              navigate("/fusionBi");
            }}
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 16 L16 8" />
            <polyline points="8 8 16 8 16 16" />
          </svg>
          </div>
          {/* three */}
          <div className="oracle-expertise-one">
            <img src={image3} alt="image1" className="oracle-exp-img" />
            <h4>BI Integration</h4>
            <p>Integrated Oracle BI with Fusion applications.</p>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="rgba(2, 2, 2, 0.542)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="36"
            height="36"
            style={{marginTop:"2px"}}
            onClick={() => {
              navigate("/bifusion");
            }}
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 16 L16 8" />
            <polyline points="8 8 16 8 16 16" />
          </svg>
          </div>
          {/* four */}
          <div className="oracle-expertise-one">
            <img src={image4} alt="image1" className="oracle-exp-img" />
            <h4>Custom RICE</h4>
            <p>
              Custom development for Reports, Interfaces, Conversions, and
              Extensions.
            </p>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="rgba(2, 2, 2, 0.542)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="36"
            height="36"
            style={{marginTop:"2px"}}
            onClick={() => {
              navigate("/customRice");
            }}
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 16 L16 8" />
            <polyline points="8 8 16 8 16 16" />
          </svg>
            
          </div>
          {/* five */}
          <div className="oracle-expertise-one">
            <img src={image5} alt="image1" className="oracle-exp-img" />
            <h4>Enterprise Integration</h4>
            <p>
              Solutions for integrating Oracle with ERP, CRM, and third-party
              apps.
            </p>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="rgba(2, 2, 2, 0.542)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="36"
            height="36"
            style={{marginTop:"2px"}}
            onClick={() => {
              navigate("/enterpriseIntegration");
            }}
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 16 L16 8" />
            <polyline points="8 8 16 8 16 16" />
          </svg>
          </div>
          {/* six */}
          <div className="oracle-expertise-one">
            <img src={image2} alt="image1" className="oracle-exp-img"  />
            <h4>Cloud/On-Prem</h4>
            <p>Cloud and on-premise solutions for Oracle environments.</p>
            
            <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="rgba(2, 2, 2, 0.542)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="36"
            height="36"
            style={{marginTop:"2px"}}
            onClick={() => {
              navigate("/onPremise");
            }}
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 16 L16 8" />
            <polyline points="8 8 16 8 16 16" />
          </svg>
          </div>
        </div>
        </div>
        
        <div className="oracle-expertise-right">
          <div className="oracle-expertise-top">
            <p>
              Maximizing Oracle
              <br /> Performance
            </p>
            <hr
            />
            <h1>Solution Capability</h1>
          </div>

          <img src={bulb} className="oracle-bulb" />
        </div>
      </div>
      </div>
      </div>
      </div>
      <div style={{width:"100vw", display:"flex", justifyContent:"center",backgroundColor:'rgb(0, 29, 55)', height:"fit-content", marginTop:"30px"}}>
      <div style={{width:"100%", display:"flex", justifyContent:"center",height:"fit-content",backgroundColor:'rgb(0, 29, 55)'}}>
      <div className="erp-sixth">
        <div className="erp-sixth-top">
          <p>Turn your AI in ERP ideas to reality quickly</p>
        </div>
        <div className="erp-sixth-button" style={{marginBottom:"20px"}}>
          <p
            onClick={() => {
              navigate("/contact");
            }}
          >
            START WITH A CALL
          </p>
        </div>
      </div>
      </div>
      </div>

      <div className="erp-fifth" style={{marginTop:"50px"}}>
        <div className="erp-fifth-top">
          <h1 style={{ fontWeight: "normal", textAlign: "center" }}>
            Ready-to-use ERP apps
          </h1>
          <p style={{ textAlign: "center" }}>
            Use our collection of applications for your unique ERP requirements{" "}
          </p>
          <p style={{ textAlign: "center" }}>
            Easily integrate with your Oracle and SAP system.
          </p>
        </div>
      </div>
      <div className="erp-fifth-train-two">
        <img src={CloudMarket} alt="cloudmarket" className="oracle-train" />
        <img src={commerce} alt="Commerce" className="oracle-train" />
        <img src={Demantra} alt="Demantra" className="oracle-train" />
        <img src={Hyperion} alt="Hyperine" className="oracle-train" />
        <img src={HcmCLoud} alt="HCM" className="oracle-train" />
        <img src={JDEdwrds} alt="JDedwards" className="oracle-train" />
        <img src={PeopleSoft} alt="PeopleSoft" className="oracle-train" />
        <img src={PLMCloud} alt="Plm" className="oracle-train" />
        <img src={Primavers} alt="Primary" className="oracle-train" />
        <img src={SCMCloud} alt="Scmcloud" className="oracle-train" />
        <img src={Sibel} alt="SIEBEL" className="oracle-train" />
        <img src={WMS} alt="WMS" className="oracle-train" />
        <img src={CXCloud} alt="cxcloud" className="oracle-train" />
        <img src={Enterprice} alt="Enterprice" className="oracle-train" />
      </div>
      <Footer />
    </div>
  );
}

export default Oracle;
