import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Contactform from '../../Components/Contactform';
import Footer from '../../Components/Footer';
import { FloatButton, Modal, Tabs } from 'antd';
import { RxVideo } from 'react-icons/rx';
import { BiVideo, BiSolidBot, BiPlay } from 'react-icons/bi';
import ReactPlayer from 'react-player';
import OracleImage from '../../assets/SAP-Methodology.svg';
import SAPFocus from '../../assets/SAP-OWN.svg';
import { FiArrowRight } from 'react-icons/fi';
// import videoOne from "../../assets/ERP.mp4"
import BreadCrum from '../../Components/BreadCrum';
import ChatNew from '../../Components/Chat2';
import Casestudy from '../../Components/Casestudy';
import { useNavigate } from 'react-router-dom';
const SAP = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const onChange = (key) => {
    console.log(key);
  };
  useEffect(() => {
    document.title = 'Platforms | SAP';
  });
  const items = [
    {
      key: '1',
      label: <p>Strategy Consulting</p>,
      children: (
        <ul
          style={{
            listStyle: 'square'
          }}
        >
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            SAP Strategies
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Sourcing Strategies
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Architecture Consulting
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Organizational Consulting
          </li>
        </ul>
      )
    },
    {
      key: '2',
      label: <p>Business Consulting</p>,
      children: (
        <ul
          style={{
            listStyle: 'square'
          }}
        >
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Business Intelligence
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Compliant Identity Management
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Knowledge Management & User Productivity
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Human Capital Management
          </li>
        </ul>
      )
    },
    {
      key: '3',
      label: <p>Technology Consulting</p>,
      children: (
        <ul
          style={{
            listStyle: 'square'
          }}
        >
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Application Lifecycle Management
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            SAP Upgrades & Migrations
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            SAP Administration
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            SAP Out-Tasking
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Virtualization Solutions
          </li>
        </ul>
      )
    }
  ];
  return (
    <div>
      {/* navbar here */}
      <Navbar mode="dark" />
      <ChatNew />
      {/* First Page starts */}
      <div className="first-page-wrapper-platforms-sap">
        {/* <video autoPlay loop muted className="videoBg-1">
          <source src={videoOne} type='video/mp4'/>
        </video> */}
        <div className="inner-page-left-product-dealerportal">
          {/* <span
            style={{
              fontWeight: "600",
            }}
          >
            FocusR <FiArrowRight /> Platforms <FiArrowRight /> SAP
          </span> */}
          {/* <BreadCrum color="#fff" items={['FocusR', 'Platforms', 'SAP']} /> */}
          <br />
          <h1>Unleashing Potential with SAP </h1>
          <br />
          <p>
            FocusR, a trusted partner, excels in implementing and supporting SAP solutions, ensuring businesses benefit
            from efficient and reliable system integration.
          </p>
          <br />
          <br />
          <button
            className="primary-btn-cus2"
            style={{
              backgroundColor: 'blue'
            }}
            onClick={() => navigate("/contact")}
          >
            Get Details
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className="SAP-img-holder">
        <h1 className="Big-Text">Our Methodology</h1>
        <img src={OracleImage} alt="oracle-flow" />
      </div>
      {/* explanation page starts here */}
      {/* for oracle skills */}
      <h1 className="Big-Text">Our Skills</h1>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        style={{
          display: 'flex',
          width: '90%',
          // justifyContent:'center',
          // alignItems:'center'
          position: 'relative',
          left: '5%',
          marginBottom: '2.5em'
        }}
      />
      {/* oracle skills ended */}
      <div className="SAP-img-holder">
        <h1 className="Big-Text">Our Focus Area</h1>
        <img src={SAPFocus} alt="oracle-flow" />
      </div>
      {/* Oracle Solutions */}
      <h1 className="Big-Text">SAP Expertise</h1>
      <div className="oracle-solutions">
        {/* first div */}
        <ul>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            EECC to HANA Environment
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            End to End Support & Maintenance
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            SAP Green-Field Implementation
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            E-Commerce Solution - Hybris
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Extended SAP Services
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            GST / VAT Implementation
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Automation Solutions - Barcode
          </li>
        </ul>
      </div>
      {/* Oracle Solutions Ended Here */}
      {/* Oracle Solutions Capabilities */}
      <h1 className="Big-Text">Solution Capabilities</h1>
      <div className="oracle-solutions">
        {/* first div */}
        <ul>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Implement / Roll-Out / Upgrade / ASM
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            SAP HANA, SAP-BI / BW
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            SAP BI with HANA
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Custom Development RICEFW
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Solutions for enterprise integration
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            PI/XI, PO
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Ariba, Fiori, Mobile, UI5, EP, WebDynPro
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Cloud / On-Premise Solutions
          </li>
          <li
            style={{
              listStyle: 'square',
              margin: '1em',
              fontWeight: 'bold'
            }}
          >
            Success Factor, HCM
          </li>
        </ul>
      </div>
      {/* Oracle Solutions Capabilities ended here */}
      <center>
        <Casestudy />
      </center>
      {/* <div id="Contact-Form">
        <Contactform />
      </div> */}
      <Footer />
    </div>
  );
};

export default SAP;
