import React from 'react';
import Image1 from '../assets/Business-ctaright.png';
import { useNavigate } from 'react-router-dom';
const CallToAction = (props) => {
  const naviagte = useNavigate()
  return (
    <div className="call-to-action">
      <div className="cta-left">
        <h1>{props.title}</h1>
        <p>{props.description}</p>
        <button className="glass-btn" onClick={() => naviagte("/contact")}>{props.buttonTitle}</button>
      </div>
      <div className="cta-right">
        <img src={Image1} alt="cta-topright" />
      </div>
    </div>
  );
};

export default CallToAction;
